import {observable, action} from 'mobx';

class MobileMenu {
  @observable visible = false;

  @action
  hide = () => {
    this.visible = false;
  };

  @action
  toggle = () => {
    this.visible = !this.visible;
  };
}

export default MobileMenu;