import { action, observable, runInAction } from 'mobx';
import { DASHBOARD_ANALYTICS_TYPE } from 'utils/constants';
import { demicalFormat } from 'utils/demical-format';

const { SUMMARY, TIME_IN_STATUS, AVG_FEEDBACK, NEW_VS_RETURNING } = DASHBOARD_ANALYTICS_TYPE;

const GST_NAME = 'SST';
const GST_COUNTRY_PHONE_CODE = '65';
const typesForGST = 'GST';

class SalesWidget {
  @observable mode = SUMMARY;
  @observable currencySign;
  @observable countryMobileCode;

  // @action
  // summaryMode = () => {
  //   this.mode = SUMMARY;
  // };
  //
  // @action
  // diningMode = () => {
  //   this.mode = DINING;
  // };
  //
  // @action
  // revStructureMode = () => {
  //   this.mode = REV_STRUCTURE;
  // };

  @action
  setMode = mode => {
    this.mode = mode;
  };

  normalizeSales = data => {
    return {
      ...data,
      data: data.data.sort((current, next) => Number(next.income) - Number(current.income)),
      isPrice: true,
      title: 'dashboard.sales.tab1.title',
      subtitle: 'dashboard.sales.tab1.subtitle',
    };
  };

  normalizeCustomerRetrospective = data => {
    return {
      data: Object.keys(data).map(k => ({
        type: k,
        income: data[k],
      })),
      format: 'ordersCount',
      title: 'dashboard.sales.tab4.title',
      subtitle: 'dashboard.sales.tab4.subtitle',
    };
  };

  normalizeFeedback = data => {
    return {
      data: Object.keys(data)
        .map(k => ({
          type: Number(k),
          income: data[k],
        }))
        .sort((a, b) => a.type - b.type),
      isBar: true,
      format: 'date',
      title: 'dashboard.sales.tab3.title',
      subtitle: 'dashboard.sales.tab3.subtitle',
    };
  };

  normalizeTimeInStatus = data => {
    return {
      data: ['NEW', 'IN_KITCHEN', 'ASSEMBLING', 'READY_FOR_DELIVER', 'DELIVERED']
        .map(k => ({
          type: k,
          income: data[k],
        }))
        .filter(i => i.income),
      format: 'orderType',
      title: 'dashboard.sales.tab2.title',
      subtitle: 'dashboard.sales.tab2.subtitle',
    };
  };

  @action
  updateData = (analyticsData, currencySign) => {
    const {
      salesSummaryResponse,
      avgTimeInStatusMap,
      avgFeedbackMap,
      customerRetrospective,
    } = analyticsData;

    this.summaryData = this.normalizeSales(salesSummaryResponse);
    this.avgTimeInStatusMap = this.normalizeTimeInStatus(avgTimeInStatusMap);
    this.avgFeedbackMap = this.normalizeFeedback(avgFeedbackMap);
    (this.customerRetrospective = this.normalizeCustomerRetrospective(customerRetrospective)),
      (this.currencySign = currencySign);
  };

  @action
  setActiveMerchantCountryCode = code => {
    runInAction(() => {
      this.countryMobileCode = code;
    });
  };

  @observable summaryData = {
    data: [],
    totalAmount: 0,
  };

  @observable avgTimeInStatusMap = { data: [] };
  @observable avgFeedbackMap = { data: [] };
  @observable customerRetrospective = { data: [] };

  widgetData = () => {
    switch (this.mode) {
      case SUMMARY:
        return this.summaryData;
      case TIME_IN_STATUS:
        return this.avgTimeInStatusMap;
      case AVG_FEEDBACK:
        return this.avgFeedbackMap;
      case NEW_VS_RETURNING:
        return this.customerRetrospective;
      default:
        return this.summaryData;
    }
  };

  chartData = () => {
    const { data } = this.widgetData();

    return data.map(({ type, income }) => ({
      x: type,
      y: Number(income),
    }));
  };

  tableData = () => {
    const { data, isPrice, totalAmount, isBar, format, title, subtitle } = this.widgetData();
    const malaysiaMerchant = this.countryMobileCode === GST_COUNTRY_PHONE_CODE;
    let total = 0;

    let titleTime = title === 'dashboard.sales.tab2.title';

    const list = data.map(({ type, income }) => {
      total += income;

      if (malaysiaMerchant && (type.includes(typesForGST) || type.includes(typesForGST.slice(1)))) {
        return {
          type: type.replace(typesForGST, GST_NAME),
          income: `${isPrice ? this.currencySign : ''} ${income}`,
        };
      }
      return {
        type,
        income: `${isPrice ? this.currencySign : ''} ${income}`,
        incomeDemical: `${isPrice ? this.currencySign : ''} ${
          titleTime ? income : demicalFormat(income)
        }`,
      };
    });

    return {
      list,
      isBar,
      title,
      subtitle,
      format,
      totalAmount: isPrice
        ? `${this.currencySign} ${titleTime ? totalAmount : demicalFormat(totalAmount)}`
        : null,
      isEmpty: !total,
    };
  };
}

export default SalesWidget;
