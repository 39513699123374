import APIConnector from './utils/APIConnector';
import { extractFiltersQuery } from './utils/helpers';

const Request = APIConnector.instance;
const ENDPOINT = '/merchant/table';

class TablesAPI {
  static list = filters => {
    const filtersQuery = extractFiltersQuery(filters);

    return Request.GET(`${ENDPOINT}/list?${filtersQuery}`);
  };

  static fetchTable = tableId => Request.GET(`${ENDPOINT}/${tableId}`);

  static create = table => Request.POST(ENDPOINT, table);

  static update = table => Request.PUT(ENDPOINT, table);

  static remove = tableId => Request.DELETE(ENDPOINT, { id: tableId });
}

export default TablesAPI;
