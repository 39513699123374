import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment';
import { getLocalizedString } from 'i18n/utils';
import capitalize from 'utils/capitalize';
import LaunchAPI from 'api/launch';
import history, { routes } from '../../routes';

const INIT_DATA = {
  checklistItems: [],
  launchTimestamp: '',
  questions: [],
};

class LaunchStore {
  @observable data = { ...INIT_DATA };
  @observable loading = false;
  @observable validationErrors = {};
  @observable percentage = 0;

  @computed get launchDate() {
    const formattedDate = moment(this.data.launchTimestamp)
      .format('DD MM')
      .toString();
    const [day, month] = formattedDate.split(' ');
    const localizedMonth = getLocalizedString(`charts.month.${month}`) || '';

    return `${Number(day)} ${capitalize(localizedMonth)}`;
  }

  @action
  validateQuestions = () => {
    let validationError = {};
    this.data.questions.map((item, index) => {
      if ((!item.answerId && index > 0) || (!this.data.launchTimestamp && index === 0)) {
        validationError[item.id] = true;
      }
    });

    runInAction(() => {
      this.validationErrors = validationError;
    });
  };

  @action
  clearValidation = () => {
    runInAction(() => {
      this.validationErrors = {};
    });
  };

  @action
  getLaunchData = async () => {
    this.loading = true;
    try {
      const data = await LaunchAPI.getLaunchData();
      runInAction(() => {
        this.data = data;
        this.loading = false;
      });
      this.setPercentage();
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  changeAnswer = (index, answerId) => {
    const newQuestions = [...this.data.questions];
    const changedItem = newQuestions[index];
    newQuestions[index] = {
      ...changedItem,
      answerId: answerId,
    };
    this.clearValidation();

    runInAction(() => {
      this.data = {
        ...this.data,
        questions: newQuestions,
      };
    });

    this.saveAnswers();
  };

  @action
  setPercentage = () => {
    const total = this.data.checklistItems.length;
    let count = 0;
    this.data.checklistItems.map(checkbox => {
      if (checkbox.result) {
        count++;
      }
    });

    runInAction(() => {
      this.percentage = parseInt((count / total) * 100).toFixed();
    });
  };

  @action
  changeChecklistItem = (index, checked) => {
    const newChecklistItems = [...this.data.checklistItems];
    const changedItem = newChecklistItems[index];
    newChecklistItems[index] = {
      ...changedItem,
      result: checked,
      timestamp: moment().valueOf(),
    };

    runInAction(() => {
      this.data = {
        ...this.data,
        checklistItems: newChecklistItems,
      };
    });

    this.setPercentage();
    this.saveChecklist();
  };

  @action
  setLaunchDate = date => {
    const timestamp = moment(date).valueOf();
    const newQuestions = [...this.data.questions];
    const changedItem = newQuestions[0];
    newQuestions[0] = {
      ...changedItem,
      customAnswer: timestamp,
    };
    this.clearValidation();

    runInAction(() => {
      this.data = {
        ...this.data,
        launchTimestamp: timestamp,
        questions: newQuestions,
      };
    });

    this.saveAnswers();
  };

  @action
  saveAnswers = async () => {
    const obj = {
      launchTimestamp: this.data.launchTimestamp,
      questions: this.data.questions,
    };

    try {
      await LaunchAPI.putAnswers(obj);
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  handleNextPage = () => {
    this.validateQuestions();
    if (Object.keys(this.validationErrors).length === 0) {
      history.location.pathname.includes('admin')
        ? history.replace(routes.adminChecklist)
        : history.replace(routes.checklist);
    }
  };

  @action
  saveChecklist = async () => {
    const obj = {
      checklistItems: this.data.checklistItems,
    };

    try {
      await LaunchAPI.putChecklist(obj);
    } catch (e) {
      throw new Error(e.message);
    }
  };
}

export default LaunchStore;
