import KitchenStore from './Kitchens';
import KitchenEditorStore from './KitchenEditor';

const Kitchens = new KitchenStore();
const KitchenEditor = new KitchenEditorStore(Kitchens);

export default {
  Kitchens,
  KitchenEditor,
};
