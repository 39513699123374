import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/merchant/invoice';

class InvoicesAPI {
  static list = (merchantId, page = 0, size) =>
    Request.GET(`${ENDPOINT}/${merchantId}?page=${page}&size=${size}`);

  static getSubscriptions = () => Request.GET(`/merchant-group-subscription`);
}

export default InvoicesAPI;
