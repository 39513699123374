import { action, computed, observable, runInAction } from 'mobx';
import ReportsAPI from 'api/reports';
import moment from 'moment';
import { demicalFormat } from 'utils/demical-format';
import { getUTCOffset, formatReportDate } from 'utils/time';
import history, { routes } from 'routes';
import { DASHBOARD_MIX_TYPE, CURRENCY_CODE_TO_SIGN } from 'utils/constants';

const { PRODUCT } = DASHBOARD_MIX_TYPE;

const utcOffset = getUTCOffset();

const PERIOD = 'period';
const AMOUNT = 'totalAmount';
const SUBMITTED_DATE = 'submittedDate';
const CLOSED = 'closedDate';
const OPENED = 'openedDate';
const MANAGER_NAME = 'managerName';

const DEFAULT_START_DATE =
  moment()
    .startOf('day')
    .valueOf() + utcOffset;
const DEFAULT_END_DATE =
  moment()
    .endOf('day')
    .valueOf() + utcOffset;
const TABLE_FORMAT = new Map([
  [AMOUNT, { id: 'reports.amount', defaultMessage: 'Amount' }],
  [SUBMITTED_DATE, { id: 'reports.submittedDate', defaultMessage: 'Closing time' }],
  [PERIOD, { id: 'reports.period', defaultMessage: 'Period' }],
  [MANAGER_NAME, { id: 'reports.managerName', defaultMessage: 'Passed' }],
]);

const LIST_FORMAT = {
  headerProperty: null,
  properties: new Map([
    [AMOUNT, { id: 'reports.amount', defaultMessage: 'Amount' }],
    [SUBMITTED_DATE, { id: 'reports.submittedDate', defaultMessage: 'Closing time' }],
    [PERIOD, { id: 'reports.period', defaultMessage: 'Period' }],
    [MANAGER_NAME, { id: 'reports.managerName', defaultMessage: 'Passed' }],
  ]),
};

const initReport = {
  details: {
    productMixMap: [],
    productMixWithoutOptionsMap: [],
  },
};

class YReports {
  constructor(MerchantsGroup) {
    this.merchantsGroup = MerchantsGroup;
  }
  tableFormat = TABLE_FORMAT;
  listFormat = LIST_FORMAT;
  dateFilter = CLOSED;

  @observable list = [];
  @observable totalPages = 0;
  @observable currentPage = -1;
  @observable options = null;
  @observable generatedReport = null;
  @observable mode = PRODUCT;
  @observable activeFilters = {};
  @observable currencySign = CURRENCY_CODE_TO_SIGN[this.merchantsGroup.currentGroup.currency];
  @observable filters = {};
  @observable paginateOptions = { page: 0, size: 20 };
  @observable showModifiers = false;
  @observable productMixWithoutOptionsResponse = [];
  @observable report = initReport;

  @action
  setShowModifiers = value => {
    runInAction(() => (this.showModifiers = value));
  };

  @action
  changePaginateOptions = value => {
    runInAction(() => {
      this.paginateOptions = value;
    });
  };

  @action
  setFilters = (key, value) => {
    runInAction(() => {
      this.filters[key] = value;
    });
  };

  @computed get hasMore() {
    return this.currentPage + 1 < this.totalPages;
  }

  @computed get data() {
    const list = [...this.list];
    // if (this.generatedReport) {
    //   list.unshift(this.generatedReport);
    // }

    return list.map(report => ({
      ...report,
      details: JSON.parse(report.detailsJson),
      [OPENED]: formatReportDate(report[OPENED]),
      [CLOSED]: formatReportDate(report[CLOSED]),
      [SUBMITTED_DATE]:
        report[SUBMITTED_DATE] === 10800000 || report[SUBMITTED_DATE] === 0
          ? ''
          : formatReportDate(report[SUBMITTED_DATE]),
    }));
  }

  @action
  setMode = mode => {
    this.mode = mode;
  };

  @computed get currentMode() {
    return this.mode;
  }

  @computed get productMixTableData() {
    const { productMixMap, productMixWithoutOptionsMap } = this.report.details;

    const newData = !this.showModifiers
      ? !productMixWithoutOptionsMap
        ? productMixMap
        : productMixWithoutOptionsMap
      : productMixMap;

    return (newData || []).map(
      ({ item, category, sold, soldPct, received, receivedPct, modifiers, spent }) => ({
        name: item,
        category,
        sold: `${sold.toFixed(2)} (${soldPct.toFixed(2)} %)`,
        soldValue: sold.toFixed(2),
        receivedValue: received.toFixed(2),
        soldPctValue: soldPct,
        receivedPctValue: receivedPct,
        received: received
          ? `${this.currencySign} ${received.toFixed(2)} (${receivedPct.toFixed(2)} %)`
          : '-',
        modifiers,
        spent: `${spent.toFixed(2)}`,
      }),
    );
  }

  @computed get categoryMixTableData() {
    return (
      this.report.details &&
      (this.report.details.productCategoryMixMap || []).map(item => {
        const { category, sold, soldPct, received } = item;

        return {
          ...item,
          category,
          sold: `${sold.toFixed(2)} (${soldPct.toFixed(2)} %)`,
          received: `${this.currencySign} ${demicalFormat(received.toFixed(2))}`,
        };
      })
    );
  }

  @action
  fetchOptions = async () => {
    const options = await ReportsAPI.loadYOptions();

    runInAction(() => {
      this.options = options;
    });

    return options;
  };

  @action
  generateActive = async ({ size, ...filters }) => {
    const data = await ReportsAPI.generateActiveYReport(filters);
    const report = {
      ...data,
      details: JSON.parse(data.detailsJson),
    };

    runInAction(() => {
      this.generatedReport = report;
    });

    return report;
  };

  @action
  submitYReport = params => ReportsAPI.submitYReport(params);

  @action
  fetchData = (page = this.paginateOptions.page, filters) => {
    const { dateFrom, dateTo } = filters;

    const requestFilters = {
      startDate: dateFrom
        ? moment(dateFrom).valueOf() + getUTCOffset(moment(dateFrom).valueOf())
        : DEFAULT_START_DATE,
      endDate: dateTo
        ? moment(dateTo).valueOf() + getUTCOffset(moment(dateTo).valueOf())
        : DEFAULT_END_DATE,
      size: this.paginateOptions.size,
    };

    this.activeFilters = filters;

    return ReportsAPI.listYReports(page, requestFilters);
  };

  @action
  fetchTablePage = async (filters, page) => {
    const { items, totalPages, number } = await this.fetchData(page, filters);

    runInAction(() => {
      this.list = items;
      this.totalPages = totalPages;
      this.currentPage = number;
    });
  };

  @action
  fetchListPage = async (filters, page) => {
    const { items, totalPages, number } = await this.fetchData(page, filters);

    runInAction(() => {
      if (page === 0) {
        this.list = items;
      } else {
        this.list = [...this.list, ...items];
      }

      this.totalPages = totalPages;
      this.currentPage = number;
    });
  };

  @action
  viewReport = (report, convertDate) => {
    if (convertDate) {
      const newReport = {
        ...report,
        [OPENED]: formatReportDate(report[OPENED]),
        [CLOSED]: formatReportDate(report[CLOSED]),
        [SUBMITTED_DATE]: formatReportDate(report[SUBMITTED_DATE]),
      };
      this.report = newReport;
    } else {
      this.report = { ...report, totalAmount: report.totalAmountClone };
    }
    history.push(`${routes.yReports}/${report.uid}`);
  };

  @action
  setReport = report => {
    this.report = {
      ...report,
      [OPENED]: formatReportDate(report[OPENED]),
      [CLOSED]: formatReportDate(report[CLOSED]),
      [SUBMITTED_DATE]: formatReportDate(report[SUBMITTED_DATE]),
    };
  };

  // @action
  // resetYReportsStore = () => {
  //   this.list = [];
  //   this.totalPages = 0;
  //   this.currentPage = -1;
  //   this.activeFilters = {};
  //   this.filters = [];
  //   this.dateFilter = CLOSED;
  //   this.tableFormat = TABLE_FORMAT;
  //   this.listFormat = LIST_FORMAT;
  // };
  @action
  exportData = async requestFilters => {
    const { items } = await ReportsAPI.listYReports(0, requestFilters);
    return items.map(report => ({
      ...report,
      details: JSON.parse(report.detailsJson),
      [OPENED]: formatReportDate(report[OPENED]),
      [CLOSED]: formatReportDate(report[CLOSED]),
      [SUBMITTED_DATE]:
        report[SUBMITTED_DATE] === 10800000 || report[SUBMITTED_DATE] === 0
          ? ''
          : formatReportDate(report[SUBMITTED_DATE]),
    }));
  };
}

export default YReports;
