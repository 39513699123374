import { configure } from 'mobx';

import ORTYStore from './ORTY';
import NavigationStore from './Navigation';
import AccountStore from './Account';
import MerchantStore from './Merchant';
import MenuStore from './Menu';
import ModifiersStore from './modifiers';
import ModifiersEditorStore from './modifiers/editor';
import SelfServiceStore from './self-service';

import YReportsStore from './statistics/reports/YReports';
import CollectionPointStore from './collection-point';
import ProductsStore from './inventory/products/Products';
import MerchantsGroupStore from './merchants/MerchantsGroup';
import MerchantEditorStore from './merchants/MerchantEditor';
import PropertyEditorStore from './inventory/products/PropertyEditor';
import ProductEditorStore from './inventory/products/ProductEditor';

import kitchens from './kitchens';
import users from './users';
import tables from './tables';
import loyalty from './loyalty';
import InvoicesStore from './invoices';
import PromotionsStore from './promotions/Promotions';
import PromotionEditorStore from './promotions/PromotionEditor';
import PromotionAnalyticsStore from './promotions/promotion-analytics';
import printers from './printers';
import statistics from './statistics';
import legalEntity from './legal-entity';
import UI from './UI';
import VouchersStore from './inventory/vouchers/Vouchers';
import BroadcastMessageStore from './inventory/broadcast-message/BroadcastMessage';
import BroadcastMessageDetailsStore from './inventory/broadcast-message/BroadcastMessageDetails';
import PostsStore from './inventory/posts/Posts';
import VoucherEditorStore from './inventory/vouchers/VoucherEditor';
import PostsEditorStore from './inventory/posts/PostsEditor';
import CategoriesStore from './categories/Categories';
import CategoryEditorStore from './categories/CategoryEditor';
import LaunchStore from './launch';

configure({ enforceActions: 'observed' });

const initStores = () => {
  const ORTY = new ORTYStore();
  const Navigations = new NavigationStore();
  const Account = new AccountStore();
  const Invoices = new InvoicesStore();
  const SelfService = new SelfServiceStore();
  const Merchant = new MerchantStore(Account);
  const Menu = new MenuStore();
  const Categories = new CategoriesStore(Menu);
  const CategoryEditor = new CategoryEditorStore(Categories);
  const CollectionPoint = new CollectionPointStore();
  const MerchantsGroup = new MerchantsGroupStore(Account, Merchant, Navigations);
  const Products = new ProductsStore(MerchantsGroup, Merchant, Menu);
  const ProductEditor = new ProductEditorStore(
    Products,
    Merchant,
    MerchantsGroup,
    Menu,
    Categories,
  );
  const MerchantEditor = new MerchantEditorStore(MerchantsGroup);
  const PropertyEditor = new PropertyEditorStore(ProductEditor, MerchantsGroup, Merchant);
  const Vouchers = new VouchersStore();
  const Posts = new PostsStore();
  const BroadcastMessage = new BroadcastMessageStore();
  const BroadcastMessageDetails = new BroadcastMessageDetailsStore(BroadcastMessage);
  const Promotions = new PromotionsStore();
  const PromotionEditor = new PromotionEditorStore(Promotions);
  const PromotionAnalytics = new PromotionAnalyticsStore(Promotions, PromotionEditor);
  const VoucherEditor = new VoucherEditorStore(Vouchers);
  const PostsEditor = new PostsEditorStore(Posts);
  const Modifiers = new ModifiersStore(MerchantsGroup, Merchant);
  const ModifiersEditor = new ModifiersEditorStore(Modifiers, MerchantsGroup, Merchant);
  const YReports = new YReportsStore(MerchantsGroup);
  const Launch = new LaunchStore();

  return {
    ORTY,
    Menu,
    Navigations,
    Account,
    Invoices,
    Merchant,
    MerchantsGroup,
    MerchantEditor,
    Products,
    CollectionPoint,
    SelfService,
    ProductEditor,
    PropertyEditor,
    Vouchers,
    VoucherEditor,
    Posts,
    PostsEditor,
    BroadcastMessage,
    BroadcastMessageDetails,
    Modifiers,
    ModifiersEditor,
    YReports,
    Promotions,
    PromotionEditor,
    PromotionAnalytics,
    Categories,
    CategoryEditor,
    Launch,
    ...legalEntity,
    // ...categories,
    ...users,
    ...tables,
    ...loyalty,
    ...printers,
    ...statistics,
    ...UI,
    ...kitchens,
  };
};

export default initStores;
