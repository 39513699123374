import { extractFiltersQuery } from '../utils/helpers';
import APIConnector from '../utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/customer';

class CustomersAPI {
  static list = (page, size) => Request.GET(`${ENDPOINT}/list?page=${page}&size=${size}`);

  static search = (page, params, filters) => {
    const filtersQuery = extractFiltersQuery(params);
    return Request.POST(`${ENDPOINT}/search?page=${page}${filtersQuery}`, filters);
  };

  // static search = (phoneNumber, page, size) =>
  //   Request.GET(`${ENDPOINT}/search/${phoneNumber}?page=${page}&size=${size}`);
  static fetchCustomersList = customerIds => Request.POST(`${ENDPOINT}/id-list`, customerIds);

  static fetchCustomer = phoneNumber => Request.GET(`${ENDPOINT}/fetch?phoneNumber=${phoneNumber}`);

  static create = customerInfo => Request.POST(ENDPOINT, customerInfo);

  static edit = customerId => Request.GET(`${ENDPOINT}/${customerId}`);

  static getCustomerById = customerId => Request.GET(`${ENDPOINT}/${customerId}`);

  static update = customerInfo => Request.PUT(ENDPOINT, customerInfo);

  static remove = customerId => Request.DELETE(`${ENDPOINT}/${customerId}`);

  static uploadCsv = file => {
    const data = new FormData();
    data.append('file', file);

    return Request.POST_FILE(`${ENDPOINT}/import/file`, data);
  };

  static exportCsv = params => Request.POST(`${ENDPOINT}/export/file`, params);

  static getOrderHistory = (mobile, { page = 0, size = 20 } = {}) =>
    Request.GET(`${ENDPOINT}/order/${mobile}/history?page=${page}&size=${size}`);

  static getVoucherHistory = (id, { page = 0, size = 20 } = {}) =>
    Request.GET(`${ENDPOINT}/${id}/voucherList?page=${page}&size=${size}`);

  static sendBroadcastMessage = params => Request.POST(`/broadcastMessage`, params);

  static sendMessage = params => Request.POST(`${ENDPOINT}/sendMessage`, params);

  static getAvailableFilters = () => Request.GET('/filter/customer/searchFilter');

  static getSavedFilters = () => Request.GET('/filter/customer/savedFilter');

  static addSavedFilters = params => Request.POST('/filter/customer/savedFilter', params);

  static removeSavedFilters = params => Request.DELETE('/filter/customer/savedFilter', params);

  static addLoyaltyPoints = params => Request.PUT(`${ENDPOINT}/loyaltyPoints`, params);

  static getCashbackHistory = (customerId, page, size) => {
    return Request.GET(`${ENDPOINT}/lp/${customerId}/history?page=${page}&size=${size}`);
  };

  static getTagsList = () => {
    return Request.GET(`${ENDPOINT}/tags`);
  };

  static createTag = name => {
    return Request.POST(`${ENDPOINT}/tags`, name);
  };

  static deleteTag = id => {
    return Request.DELETE(`${ENDPOINT}/tags`, id);
  };

  static setTagOnCustomers = data => {
    return Request.POST(`${ENDPOINT}/assignTags`, data);
  };

  static setProgramOnCustomers = data => {
    return Request.POST(`${ENDPOINT}/assignProgram`, data);
  };
}

export default CustomersAPI;
