import { createGlobalStyle } from 'styled-components';
import { MOBILE_BREAKPOINT } from 'utils/constants';

import './semantic.min.css';
import './react-sidenav.css';

const modalBorderRadius = '12px';

const blueColor = '#1976d2';
const datePickerMobileWidth = 525;

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
  }

  input {
    border: none;
  }

  ul {
    list-style: none;
  }

  object {
    pointer-events: none;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .Popover {
    z-index: 10000;
  }

  .intl-tel-input {
    width: 100%;
  }

  .logo-link {
    display: flex;
    align-items: center;
    height: 100%;
  }

  button.button.backButton {
    background-color: white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e0e1e2;
  }

  .backButton > i.icon {
      background-color: #d6d7d8;
  }

  div.tableFilterInput {
    width: 100%;
  }

  .tableFilterInput > input {
      width: 100%
  }

  .statusField {
    font-family: Roboto, sans-serif;
  }

  .inactiveRow td {
      opacity: .4;
      pointer-events: none;
  }

  .clickableRow {
      & > td:first-child {
        max-width: 400px;
        padding-left: 40px;

        .clickableRowSpan {
           white-space: pre-line;
        }
      }

      cursor: pointer;
      z-index: 999 !important;

      &:hover {
        rgba(0, 0, 0, 0.07)
      }
  }

  div.listWrapper {
    width: 100%;
    min-height: 50px;
    position: relative;
    border: none !important;
    box-shadow: none !important;
  }

  form.newAdvancedEditorContainer {
    width: 100%;
    padding: 0 10px;
  }

  section.controls {
    border-bottom: none;
  }

  div.rangeFilterContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
  }

  img.imageLoaderImage {
    width: 346px;
    height: auto;
  }

  button.button.imageLoaderButton {
    background-color: white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e0e1e2;
  }

  button.button.imageLoaderButton > i.icon {
    background-color: #d6d7d8;
  }

  div.label {
    margin-bottom: 5px;
  }

  .confirmationEmailContainer {
    top: 300px;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      top: 200px;
    }
  }

  .confirmationEmail {
    text-align: center;
  }

  button.button.editPropertyButton {
    display: block;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e0e1e2;
  }

  button.button.editPropertyButton > i.icon {
    background-color: #d6d7d8;
  }

  .navigationLink {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #3f3f4b;
    text-decoration: none !important;
    margin-top: -1px;
    margin-bottom: -1px;

  }



  .timeInput {
    min-width: 90px;
  }

  .analyticsTableCell {
    height: 35px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: normal;
    color: #3f3f4b;

    @media screen and (max-width: 479px) {
      height: 30px;
      font-size: 12px;
      line-height: 1;
    }
  }

  .analyticsTableCell.padded {
    padding-left: 15px;
    padding-right: 8px;
  }

  .analyticsTableCell.padded.totalAmount {
     padding-left: 32px;
  }

  .analyticsTableCell.padded.colored {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .analyticsHeaderCell {
    font-weight: normal;
  }

  form.createProduct {
    width: 100%;
    margin-bottom: 60px;
  }

  .mobileNavigation {
    background-color: #ffffff;
  }

  div.input-range__track--active {
    background: #2185d0;
  }

  div.input-range__slider {
    background: #2185d0;
    border: #2185d0;
  }

  .ui.modal.popupModal {
    border-radius: 12px;

    & .header,
    & .content {
      padding: 16px;
      text-align: center;
    };

    & .header,
    & .content {
      border-bottom: 0;
      padding-top: 40px !important;
      border-top-left-radius: ${modalBorderRadius};
      border-top-right-radius: ${modalBorderRadius};
    }

    & .content {
      border-bottom-left-radius: ${modalBorderRadius};
      border-bottom-right-radius: ${modalBorderRadius};
    }
  }

  .country-list {
    z-index: 990 !important;
    min-width: 200px;
    width: auto;
    max-height: 300px !important;
    position: absolute !important;
    background: rgb(255,255,255);
    border: 1px solid rgb(229,238,252) !important;
    box-shadow: 0 8px 15px 0 rgba(80,95,121,0.3) !important;

    @media all and (max-width: ${MOBILE_BREAKPOINT}px) {
      top: 23%;
      max-width: 260px;
    }
  }

  .react-tel-input {
    margin-bottom: 1rem;
  }

  .react-tel-input .country-list .country {
    text-align: left;
  }

  .react-tel-input .country-list .search-box {
    width: 80% !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .dimmed.dimmable > .ui.animating.dimmer,
  .dimmed.dimmable>.ui.visible.dimmer,
  .ui.active.dimmer {
    bottom: 0;
    right: 0;

    .ui.modal {
      flex: none;
      position: static;
    }

    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      .actions {
        padding-bottom: 1rem !important;
      }
    }
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
     p.shareable {
        margin-bottom: 10px;
     }
  }

  .paginate-size {
    right: 60px !important;

    .Popover-body {
      display: flex;
      justify-content: flex-end;
    }
    .ui.vertical.menu {
      width: 50px;
    }
  }

  .ui.disabled.input,
  .ui.input:not(.disabled) input[disabled] {
    opacity: 1;
    cursor: no-drop;
    background-color: #dededf;
    border-radius: 5px;
  }

  .ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
    pointer-events: none;
    opacity: .7;
  }

  .ui.labeled.button {
    position: relative;
    display: inline-block;
    transition: all 150ms ease-in-out;

    // &::after {
    //   content: '';
    //   position: absolute;
    //   z-index: -1;
      // width: 100%;
      // height: 100%;
      // opacity: 0;
      // left: 0;
      // top: 0;
      // border-radius: 4px;
      // box-shadow: 0 5px 9px rgba(0, 0, 0, .19), 0 6px 6px rgba(0, 0, 0, .11);
      // transition: opacity 150ms ease-in-out;
    // }

    &:hover::after {
      opacity: 1;
    }
  }

  .ui.radio.checkbox input:checked~.box:after, .ui.radio.checkbox input:checked~label:after {
    background-color: #1d79be;
  }

  .percent {
    max-width: 130px;
    width: 100%;

    .ui.input {
      &::after {
        content: "%";
        position: absolute;
        left: 40px;
        top: 10px;
      }
    }

    &.hiddenPercent {
      .ui.input {
        &::after {
          content: "";
        }
      }
    }

    @media all and (max-width: ${MOBILE_BREAKPOINT}px) {
      .ui.input {
        min-width: 60px;
      }
    }
  }

  .ui.basic.button, .ui.basic.buttons .button {
    font-weight: 700;
  }

  // .ui.labeled.button::after {
  //   display: none;
  // }

  .rs-picker-menu.rs-picker-daterange-menu {
    z-index: 999999 !important;
  }

  .rs-calendar-table-cell-content {
    border-radius: 50% !important;
  }

  .rs-calendar-header-backward,
  .rs-calendar-header-forward {
    width: 30px !important;
    padding: 5px 8px !important;
    border-radius: 50% !important;
    transition: background-color 150ms cubic-bezier(.4, 0, .2, 1) 0ms;
  }

  .rs-picker-menu,
  .rs-calendar,
  .rs-calendar-table-cell-content {
    transition: background-color 150ms cubic-bezier(.4, 0, 0.2, 1) 0ms;
    &:hover {
      background: rgb(226, 225, 225)!important;
      transition: background-color 150ms cubic-bezier(.4, 0, 0.2, 1) 0ms;
    }
  }

  .rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled {
    background-color: #fff !important;
    color: ${blueColor} !important;
  }

  .rs-picker-toolbar-right-btn-ok {
    background-color: #fff !important;
    color: ${blueColor} !important;
    font-weight: 500 !important;
    line-height: 1.75 !important;
    border-radius: 4px !important;
    min-width: 64px !important;
    transition: background-color 250ms cubic-bezier(.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(.4, 0, 0.2, 1) 0ms;

    span {
      letter-spacing: .02857em!important;
      text-transform: uppercase!important;
      font-size: .875rem!important;
      font-weight: 600!important;
    }

    &:hover {
      background-color: rgba(25, 118, 210, 0.08)!important
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  .rs-picker-daterange-header {
    height: 75px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem !important;
    font-weight: 400 !important;
    line-height: 1.17 !important;
    letter-spacing: .00735em !important;
    background-color: ${blueColor};
  }

  .rs-calendar-table-cell-selected-end,
  .rs-calendar-table-cell-selected-start {
    &::before {
      background-color: rgba(204, 233, 255, .5);
      width: 50% !important;
    }
  }

  .rs-calendar-table-cell-selected-start {
    &::before {
      right: -1px;
    }
  }

  .rs-picker-toggle,
  .rs-picker-toggle-placeholder {
    line-height: 1.3 !important;
  }

  .rs-picker-toggle-value {
    color: #3f3f4b !important;
  }

  .rs-picker-menu:hover,
  .rs-calendar:hover {
    background: #fff !important;
  }

  .rs-picker-daterange-header {
    @media all and (max-width: ${datePickerMobileWidth}px) {
      font-size: 16px !important;
      height: 54px;
    }
  }

  .rs-picker-daterange-calendar-group {
    display: flex;
    min-width: 0 !important;

    @media all and (max-width: ${datePickerMobileWidth}px) {
      & > .rs-calendar:first-child {
        border-right: transparent !important;
      }
      & > .rs-calendar:last-child {
        display: none;
      }
    }
  }

  .rs-picker-toggle-clean {
    display: none;
  }

  .rs-calendar-table-cell.rs-calendar-table-cell-selected-end.rs-calendar-table-cell-selected {
    .rs-calendar-table-cell-content {
      background-color: rgb(52, 152, 255) !important;
    }
  }

  .react-tel-input input[type=text],
  .react-tel-input input[type=tel] {
    padding-left: 48px !important;
  }

  .ui.pointing.secondary.menu {
    -webkit-overflow-scrolling: touch;
  }
  .ui.pointing.secondary.menu::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .error .creatable-dropdown > div {
    color: #9f3a38;
    border-color: #9f3a38;
    background: #fff6f6;
  }
`;

export default GlobalStyle;
