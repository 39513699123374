import { action, computed, observable, runInAction } from 'mobx/lib/mobx';
import { arrayMove } from 'react-sortable-hoc';
import { get as getLodash } from 'lodash.get';
import { getUniqueObjectValues } from 'utils/unique';
import ImagesAPI from 'api/images';
import ProductsAPI from 'api/products';

import { convertTaxToPrice, toFixedTwo, deductTax } from 'utils/numbers';
import { PROPERTY_PRODUCT_CHANGE_TYPES } from './constants';

const NAME = 'name';
const CATEGORY = 'categoryName';
const SELL_PRICE = 'sellPrice';
const POS_ENABLED = 'posEnabled';
const SELF_SERVICE_ENABLED = 'selfServiceEnabled';

export const PRODUCTS_TABLE_FORMAT = new Map([
  [NAME, { id: 'products.data.name', defaultMessage: 'Display Name' }],
  [SELL_PRICE, { id: 'products.data.price', defaultMessage: 'Price' }],
  // ['stock', { id: 'products.editor.general.types.stock', defaultMessage: 'Stock' }],
  [
    POS_ENABLED,
    {
      id: '-',
      defaultMessage: 'POS',
      tooltipMessage: { id: 'products.data.header.pos', defaultMessage: 'POS' },
    },
  ],
  [
    SELF_SERVICE_ENABLED,
    {
      id: '-',
      defaultMessage: 'SS',
      tooltipMessage: { id: 'products.data.header.service', defaultMessage: 'Self-service' },
    },
  ],
  ['kitchen', { id: 'products.editor.kitchen.heading', defaultMessage: 'Kitchen' }],
  ['legalEntity', { id: 'legalEntity', defaultMessage: 'Legal Entity' }],
]);

export const PRODUCTS_TABLE_FORMAT_MULTI_SHOP = new Map([
  [NAME, { id: 'products.data.name', defaultMessage: 'Display Name' }],
  [SELL_PRICE, { id: 'products.data.price', defaultMessage: 'Price' }],
  [
    POS_ENABLED,
    {
      id: '-',
      defaultMessage: 'POS',
      tooltipMessage: { id: 'products.data.header.pos', defaultMessage: 'POS' },
    },
  ],
  [
    SELF_SERVICE_ENABLED,
    {
      id: '-',
      defaultMessage: 'SS',
      tooltipMessage: { id: 'products.data.header.service', defaultMessage: 'Self-service' },
    },
  ],
]);

export const PRODUCTS_LIST_FORMAT = {
  headerProperty: [NAME, { id: 'products.data.name', defaultMessage: 'Display Name' }],
  properties: new Map([
    [SELL_PRICE, { id: 'products.data.price', defaultMessage: 'Price' }],
    [POS_ENABLED, { id: 'products.data.header.pos', defaultMessage: 'POS' }],
    [SELF_SERVICE_ENABLED, { id: 'products.data.header.service', defaultMessage: 'Self-service' }],
  ]),
};

class ProductsStore {
  constructor(MerchantsGroup, Merchant, Menu) {
    this.menu = Menu;
    this.merchantGroupStore = MerchantsGroup;
    this.merchantStore = Merchant;
  }

  @computed get distributionCenter() {
    return getLodash(this.merchant, 'info.merchantConfiguration.distributionCenter');
  }

  tableFormat = PRODUCTS_TABLE_FORMAT;
  tableFormatMulti = PRODUCTS_TABLE_FORMAT_MULTI_SHOP;
  listFormat = PRODUCTS_LIST_FORMAT;

  @observable list = [];
  @observable defaultProducts = [];
  @observable actions = {};
  @observable changes = {};
  @observable loading = false;
  @observable loaded = false;
  @observable totalPages = 0;
  @observable currentPage = -1;
  @observable merchatSettings = {};
  @observable groupSettings = {};
  @observable settingsClone = {};
  @observable activeFilters = {};
  @observable stopList = { loading: true, items: [] };
  @observable stopListSettings = {};
  @observable stopListSettingsClone = {};
  // @observable taxInclusive = this.merchantStore.currentGroup.taxInclusive;
  @computed get taxFee() {
    const { turnoverTax, taxInclusive, taxManagedByChain, currency } = this.merchantGroupStore.currentGroup;
    const {
      taxInclusive: restaurantTaxInclusive,
      turnoverTax: restaurantTurnoverTax,
    } = this.merchantStore.info.merchantConfiguration;

    const taxFee = taxManagedByChain ? turnoverTax : restaurantTurnoverTax
    return taxFee
  }

  @computed get taxInclusive() {
    const { turnoverTax, taxInclusive, taxManagedByChain, currency } = this.merchantGroupStore.currentGroup;
    const {
      taxInclusive: restaurantTaxInclusive,
      turnoverTax: restaurantTurnoverTax,
    } = this.merchantStore.info.merchantConfiguration;
    
    const taxFee = taxManagedByChain ? turnoverTax : restaurantTurnoverTax
    let isTaxInclusive = false
    if (taxManagedByChain) {
      isTaxInclusive = taxInclusive;
    } else {
      isTaxInclusive = restaurantTaxInclusive;
    }
    return isTaxInclusive
  }

  @computed get hasMore() {
    return this.currentPage + 1 < this.totalPages;
  }

  updateList = () => {
    const updatedList = this.list.map(product => {
      const data = this.actions[product.id] || product;
      return {
        ...data,
        // [SELL_PRICE]: convertTaxToPrice(product.price, this.merchantGroupStore.currentGroup.turnoverTax),
      };
    });

    runInAction(() => {
      this.list = updatedList;
    });
  };

  @action
  fetchData = async ({ admin, ...filters }) => {
    this.activeFilters = filters;
    const request = filters.name && this.menu.searchValue ? 'search' : 'list';
    const list = await ProductsAPI[request](filters);
    const ids = list.items.reduce((acc, item) => [...acc, item.id], []);

    if (admin) {
      await this.getProductSettingsByGroup(ids);
    } else {
      await this.getProductSettingsByMerchant(ids);
    }

    return list;
  };

  @action
  fetchTablePage = async (filters, loadSellPrice) => {
    if (filters.clearList) {
      runInAction(() => {
        this.list = [];
        this.loaded = true;
      });

      return [];
    } else {
      runInAction(() => {
        this.loading = true;
      });
      const response = await this.fetchData(filters);
      const { items, totalPages, number } = response;
      runInAction(() => {
        this.loading = false;
        this.list = items;
        const price = this.taxInclusive ? 'sellPrice' : 'price';
        if (loadSellPrice) {
          this.groupSettings.productPriceMap = items
            .map(i => ({ ...i }))
            .reduce((acc, i) => ((acc[i.id] = toFixedTwo(i[price])), acc), {});
          this.merchatSettings.productPriceMap = items
            .map(i => ({ ...i }))
            .reduce((acc, i) => ((acc[i.id] = toFixedTwo(i[price])), acc), {});
          this.settingsClone.productPriceMap = items
            .map(i => ({ ...i }))
            .reduce((acc, i) => ((acc[i.id] = toFixedTwo(i[price])), acc), {});
        }
        this.loaded = true;
        this.defaultProducts = items;
        this.totalPages = totalPages;
        this.currentPage = number;
      });
      this.updateList();

      return response;
    }
  };

  @action
  fetchListPage = async (page, filters, loadSellPrice) => {
    const { items, totalPages, number } = await this.fetchData(page, filters);

    runInAction(() => {
      let productPriceMap = [];
      if (page === 0) {
        this.list = items;
        if (loadSellPrice) {
          productPriceMap = items.reduce(
            (acc, i) => ((acc[i.id] = toFixedTwo(i.sellPrice)), acc),
            {},
          );
        }
      } else {
        this.list = [...this.list, ...items];
        if (loadSellPrice) {
          productPriceMap = [...this.list, ...items].reduce(
            (acc, i) => ((acc[i.id] = toFixedTwo(i.sellPrice)), acc),
            {},
          );
        }
      }
      if (loadSellPrice) {
        this.groupSettings.productPriceMap = { ...productPriceMap };
        this.settingsClone.productPriceMap = { ...productPriceMap };
      }
      this.totalPages = totalPages;
      this.currentPage = number;
    });
  };

  @action
  fetchStopList = async menuId => {
    try {
      const { items } = await ProductsAPI.getStopList(menuId);

      const pos = [...items]
        .map(i => ({ ...i }))
        .reduce((acc, i) => ((acc[i.id] = i.posEnabled), acc), {});
      const ss = [...items]
        .map(i => ({ ...i }))
        .reduce((acc, i) => ((acc[i.id] = i.selfServiceEnabled), acc), {});

      runInAction(() => {
        this.stopList = { loading: false, items: items };
        this.stopListSettings = {
          posChannelMap: { ...pos },
          selfServiceChannelMap: { ...ss },
        };

        this.stopListSettingsClone = {
          posChannelMap: { ...pos },
          selfServiceChannelMap: { ...ss },
        };
      });
    } catch (error) {
      return new Error(error);
    }
  };

  @action
  cancelStopListСhanges = () => {
    runInAction(() => {
      this.stopListSettings = {
        ...this.stopListSettingsClone,
        posChannelMap: { ...this.stopListSettingsClone.posChannelMap },
        selfServiceChannelMap: { ...this.stopListSettingsClone.selfServiceChannelMap },
      };
    });
  };

  @action
  getLength = obj => {
    return Number(Object.keys(obj).length);
  };

  @action
  getStopListCountChanges = () => {
    let newSettings = {
      posChannelMap: { ...this.stopListSettings.posChannelMap },
      selfServiceChannelMap: { ...this.stopListSettings.selfServiceChannelMap },
    };

    const posChannelMap = getUniqueObjectValues(
      newSettings,
      'posChannelMap',
      this.stopListSettingsClone,
    );
    const ssChannelMap = getUniqueObjectValues(
      newSettings,
      'selfServiceChannelMap',
      this.stopListSettingsClone,
    );

    return this.getLength(posChannelMap) + this.getLength(ssChannelMap);
  };

  @action
  confirmStopListСhanges = async currentMenu => {
    let newSettings = {
      posChannelMap: { ...this.stopListSettings.posChannelMap },
      selfServiceChannelMap: { ...this.stopListSettings.selfServiceChannelMap },
    };

    const posChannelMap = getUniqueObjectValues(
      newSettings,
      'posChannelMap',
      this.stopListSettingsClone,
    );
    const ssChannelMap = getUniqueObjectValues(
      newSettings,
      'selfServiceChannelMap',
      this.stopListSettingsClone,
    );
    runInAction(() => {
      this.stopListSettingsClone = {
        ...this.stopListSettings,
        posChannelMap: { ...this.stopListSettings.posChannelMap },
        selfServiceChannelMap: { ...this.stopListSettings.selfServiceChannelMap },
      };
    });
    try {
      await ProductsAPI.bulkMerchantUpdate({ posChannelMap, ssChannelMap });
    } catch (e) {
      await this.fetchStopList(currentMenu);
      throw new Error(e.message);
    }
  };

  @action
  cancelProductСhanges = admin => {
    runInAction(() => {
      this[admin ? 'groupSettings' : 'merchatSettings'] = {
        ...this.settingsClone,
        posChannelMap: { ...this.settingsClone.posChannelMap },
        selfServiceChannelMap: { ...this.settingsClone.selfServiceChannelMap },
        // stockMap: { ...this.settingsClone.stockMap },
        productPriceMap: this.settingsClone.productPriceMap
          ? { ...this.settingsClone.productPriceMap }
          : {},
      };
    });
  };

  @action
  getProductCountChanges = (admin, singleShop) => {
    const settings = admin ? 'groupSettings' : 'merchatSettings';
    let newSettings = {
      posChannelMap: { ...this[settings].posChannelMap },
      selfServiceChannelMap: { ...this[settings].selfServiceChannelMap },
      // stockMap: { ...this[settings].stockMap },
      productPriceMap: this[settings].productPriceMap ? { ...this[settings].productPriceMap } : {},
    };

    const posChannelMap = getUniqueObjectValues(newSettings, 'posChannelMap', this.settingsClone);
    const ssChannelMap = getUniqueObjectValues(
      newSettings,
      'selfServiceChannelMap',
      this.settingsClone,
    );

    let productIdToPrice = {};
    let productIdToStock = {};
    if (admin || singleShop) {
      productIdToPrice = getUniqueObjectValues(newSettings, 'productPriceMap', this.settingsClone);
    }
    // if (!admin || singleShop) {
    //   productIdToStock = getUniqueObjectValues(newSettings, 'stockMap', this.settingsClone);
    // }

    return (
      this.getLength(productIdToPrice) +
      this.getLength(productIdToStock) +
      this.getLength(posChannelMap) +
      this.getLength(ssChannelMap)
    );
  };

  @action
  confirmProductСhanges = async (admin, reloadData, singleShop) => {
    const settings = admin ? 'groupSettings' : 'merchatSettings';
    let newSettings = {
      posChannelMap: { ...this[settings].posChannelMap },
      selfServiceChannelMap: { ...this[settings].selfServiceChannelMap },
      // stockMap: { ...this[settings].stockMap },
      productPriceMap: this[settings].productPriceMap ? { ...this[settings].productPriceMap } : {},
    };

    const posChannelMap = getUniqueObjectValues(newSettings, 'posChannelMap', this.settingsClone);
    const ssChannelMap = getUniqueObjectValues(
      newSettings,
      'selfServiceChannelMap',
      this.settingsClone,
    );

    let params = { posChannelMap, ssChannelMap };
    if (admin || singleShop) {
      let idToPrice = getUniqueObjectValues(newSettings, 'productPriceMap', this.settingsClone);
      // TODO: torevisit - comment
      // if (this.taxInclusive) {
      //   Object.keys(idToPrice).map(i => {
      //     idToPrice[i] = deductTax(idToPrice[i], this.taxFee);
      //   });
      // }
      
      params.idToPrice = idToPrice;
    }
    // if (!admin || singleShop) {
    //   params.idToStock = getUniqueObjectValues(newSettings, 'stockMap', this.settingsClone);
    // }

    runInAction(() => {
      this.settingsClone = {
        ...this[settings],
        posChannelMap: { ...this[settings].posChannelMap },
        selfServiceChannelMap: { ...this[settings].selfServiceChannelMap },
        // stockMap: { ...this[settings].stockMap },
        productPriceMap: this[settings].productPriceMap
          ? { ...this[settings].productPriceMap }
          : {},
      };
    });
    try {
      if (admin) {
        const merchantId = this.merchantStore.info.id
        await ProductsAPI.bulkMerchantGroupUpdate(params,merchantId);
        await reloadData();
      } else {
        await ProductsAPI.bulkMerchantUpdate(params);
      }
    } catch (e) {
      await reloadData();
      throw new Error(e.message);
    }
  };

  @action
  resetToDefaultAvaliability = () => {
    runInAction(() => {
      this.actions = {};
      this.changes = {};
      this.list = this.defaultProducts;
    });
    this.updateList();
  };

  @action
  changePosFields = (productId, field) => {
    const list = this.list.map(item => {
      if (productId === item.id) {
        return {
          ...item,
          [field]: !item[field],
        };
      }
      return item;
    });
    runInAction(() => {
      this.list = list;
    });
  };

  @action
  changeMerchatSettings = (key, id, value) => {
    runInAction(() => {
      const newSettings = { ...this.merchatSettings };
      newSettings[key][id] = value;
      this.merchatSettings = { ...newSettings };
    });
  };

  @action
  changeGroupSettings = (key, id, value) => {
    runInAction(() => {
      const newSettings = { ...this.groupSettings };
      newSettings[key][id] = value;
      this.groupSettings = { ...newSettings };
    });
  };

  @action
  fetchProduct = productId => ProductsAPI.getProductInfo(productId);

  @action
  createProduct = async (product, { imageFile }) => {
    let newProduct = { ...product };

    if (imageFile) {
      const { url: productImage, thumbnailUrl } = await ImagesAPI.upload(imageFile);
      // const { url: thumbnail } = await ImagesAPI.upload(thumbnailImage);

      newProduct.image = productImage;
      newProduct.thumbnail = thumbnailUrl;
    }

    const response = await ProductsAPI.createProduct(newProduct);
    return response;
  };

  findProductIndexById = productId => this.list.findIndex(product => product.id === productId);

  findProductById = productId => this.defaultProducts.find(product => product.id === productId);

  resolvePosField = (productId, key) => {
    const changeKey = `${productId}_${key}`;
    const product = this.findProductById(productId);
    const isChanged =
      this.changes.hasOwnProperty(changeKey) && product[key] === this.changes[changeKey];

    if (isChanged) {
      delete this.changes[changeKey];
    } else {
      this.changes[changeKey] = product[key];
    }
  };

  @action
  changeAvaliability = (productId, data, key) => {
    if (key === 'posEnabled') {
      this.actions[productId] = { ...data, posType: PROPERTY_PRODUCT_CHANGE_TYPES.posEnabled };
    } else {
      this.actions[productId] = { ...data, type: PROPERTY_PRODUCT_CHANGE_TYPES.selfServiceEnabled };
    }
    this.resolvePosField(productId, key);
    this.updateList();
  };

  @action
  updateProduct = async (product, { imageFile, thumbnailImage } = {}) => {
    let productToUpdate = { ...product };

    if (imageFile) {
      const { url: productImage, thumbnailUrl } = await ImagesAPI.upload(imageFile);
      // const { url: thumbnail } = await ImagesAPI.upload(thumbnailImage);
      // await new Promise(resolve => setTimeout(resolve, 10000));

      productToUpdate.image = productImage;
      productToUpdate.thumbnail = thumbnailUrl;
    }

    const updatedProduct = await ProductsAPI.updateProduct(productToUpdate);

    runInAction(() => {
      const index = this.findProductIndexById(updatedProduct.id);

      this.list = [...this.list.slice(0, index), updatedProduct, ...this.list.slice(index + 1)];
    });
  };

  @action
  filterProductsToUpdate = (productsToUpdate = []) => {
    const posProducts = productsToUpdate.filter(
      product => product.posType === PROPERTY_PRODUCT_CHANGE_TYPES.posEnabled,
    );
    const selfServiceProducts = productsToUpdate.filter(
      product => product.type === PROPERTY_PRODUCT_CHANGE_TYPES.selfServiceEnabled,
    );

    return {
      posProducts,
      selfServiceProducts,
    };
  };

  @action
  updateExtrasChannelOn = async (channelOnProductIds, type) => {
    if (channelOnProductIds.length > 0) {
      try {
        await ProductsAPI.enableChannel(type, channelOnProductIds);
      } catch (e) {
        throw new Error(e.message);
      }
    }
  };

  @action
  updateExtrasChannelOff = async (channelOffProductIds, type) => {
    if (channelOffProductIds.length > 0) {
      try {
        await ProductsAPI.disableChannel(type, channelOffProductIds);
      } catch (e) {
        throw new Error(e.message);
      }
    }
  };

  @action
  updateProductExtrasChannel = async (productsToUpdate = [], field, type) => {
    const channelOnProducts = productsToUpdate.filter(product => product[field]);
    const channelOffProducts = productsToUpdate.filter(product => !product[field]);
    const channelOnProductIds = channelOnProducts.map(product => product.id);
    const channelOffProductIds = channelOffProducts.map(product => product.id);

    await this.updateExtrasChannelOn(channelOnProductIds, type);
    await this.updateExtrasChannelOff(channelOffProductIds, type);
  };

  @action
  updateProducts = async () => {
    this.loading = true;
    const productsToUpdate = Object.values(this.actions);
    const { posProducts, selfServiceProducts } = this.filterProductsToUpdate(productsToUpdate);

    try {
      await this.updateProductExtrasChannel(
        posProducts,
        'posEnabled',
        PROPERTY_PRODUCT_CHANGE_TYPES.posEnabled,
      );
      await this.updateProductExtrasChannel(
        selfServiceProducts,
        'selfServiceEnabled',
        PROPERTY_PRODUCT_CHANGE_TYPES.selfServiceEnabled,
      );
    } catch (error) {
      return new Error(error);
    } finally {
      runInAction(() => {
        this.actions = {};
        this.changes = {};
        this.defaultProducts = this.list;
        this.loading = false;
      });
    }
  };

  @action
  removeProduct = async productId => {
    await ProductsAPI.deleteProduct(productId);

    runInAction(() => {
      const index = this.findProductIndexById(productId);

      if (index >= 0) {
        this.list = [...this.list.slice(0, index), ...this.list.slice(index + 1)];
      }
    });
  };

  @action
  resetProductStore = () => {
    runInAction(() => {
      this.list = [];
      this.actions = [];
      this.activeFilters = {};
      this.filters = [NAME, CATEGORY];
    });
  };

  @action
  onDragEnd = ({ oldIndex, newIndex, categoryId }) => {
    const sortedList = arrayMove(this.list, oldIndex, newIndex);
    const reorderedProducts = async listAfterDrag => {
      try {
        const result = listAfterDrag
          .map((product, index) => {
            return {
              itemId: product.id,
              orderNumber: ++index,
              parentItemId: categoryId,
            };
          })
          .filter(item => !!item);
        await ProductsAPI.updateProductsOrder(result);
      } catch (error) {
        return new Error(error);
      }
    };

    runInAction(() => {
      this.list = sortedList;
      reorderedProducts(sortedList);
    });
  };

  @action
  getProductSettingsByMerchant = async ids => {
    try {
      const settings = await ProductsAPI.getProductSettingsByMerchant({ ids });

      runInAction(() => {
        this.merchatSettings = { ...this.merchatSettings, ...settings };
        this.settingsClone = { ...this.settingsClone, ...settings };
      });
    } catch (e) {
      console.warn(e);
    }
  };

  @action
  getProductSettingsByGroup = async ids => {
    try {
      const settings = await ProductsAPI.getProductSettingsByGroup({ ids });

      runInAction(() => {
        this.groupSettings = { ...this.groupSettings, ...settings };
        this.settingsClone = { ...this.settingsClone, ...settings };
      });
    } catch (e) {
      console.warn(e);
    }
  };

  @action
  productLegalAssign = (id, legalId) => {
    try {
      ProductsAPI.productLegalAssign({ ids: [id] }, legalId);

      runInAction(() => {
        try {
          this.merchatSettings.legalEntityMap[id] = { id: legalId };
        } catch (e) {
          console.warn(e);
        }
      });
    } catch (e) {
      console.warn(e);
    }
  };

  @action
  productLegalUnAssign = id => {
    try {
      ProductsAPI.productLegalUnAssign({ ids: [id] });

      runInAction(() => {
        try {
          this.merchatSettings.legalEntityMap[id] = { id: null };
        } catch (e) {
          console.warn(e);
        }
      });
    } catch (e) {
      console.warn(e);
    }
  };

  @action
  productKitchenAssign = (id, kitchenId) => {
    try {
      ProductsAPI.productKitchenAssign({ ids: [id] }, kitchenId);

      runInAction(() => {
        try {
          this.merchatSettings.kitchenStationMap[id] = { id: kitchenId };
        } catch (e) {
          console.warn(e);
        }
      });
    } catch (e) {
      console.warn(e);
    }
  };

  @action
  productKitchenUnAssign = id => {
    try {
      ProductsAPI.productKitchenUnAssign({ ids: [id] });

      runInAction(() => {
        try {
          this.merchatSettings.kitchenStationMap[id] = { id: null };
        } catch (e) {
          console.warn(e);
        }
      });
    } catch (e) {
      console.warn(e);
    }
  };

  @action
  switchPOSChannel = async (id, on, admin, { stopList } = {}) => {
    runInAction(() => {
      if (stopList) {
        this.stopListSettings.posChannelMap[id] = on;
      } else {
        this[admin ? 'groupSettings' : 'merchatSettings'].posChannelMap[id] = on;
      }
    });
  };

  @action
  switchSelfServiceChannel = (id, on, admin, { stopList } = {}) => {
    runInAction(() => {
      if (stopList) {
        this.stopListSettings.selfServiceChannelMap[id] = on;
      } else {
        this[admin ? 'groupSettings' : 'merchatSettings'].selfServiceChannelMap[id] = on;
      }
    });
  };
}

export default ProductsStore;
