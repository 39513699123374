import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/inventory/tag';

class TagsAPI {
  static list = () => Request.GET(`${ENDPOINT}/list`);

  static create = tagInfo => Request.POST(ENDPOINT, tagInfo);
}

export default TagsAPI;
