import MerchantsStore from 'stores/Merchant';

import SalesWidgetStore from './SalesWidget';
import AnalyticsStore from './Analytics';
import RetrospectiveWidgetStore from './RetrospectiveWidget';
import ProblemOrdersStore from './ProblemOrders';

const SalesWidget = new SalesWidgetStore();
const Merchants = new MerchantsStore();
const RetrospectiveWidget = new RetrospectiveWidgetStore();
const Analytics = new AnalyticsStore(SalesWidget, RetrospectiveWidget, Merchants);
const ProblemOrders = new ProblemOrdersStore(Analytics);

export default {
  SalesWidget,
  Analytics,
  RetrospectiveWidget,
  ProblemOrders,
};
