import queryString from 'query-string';
import APIConnector from './utils/APIConnector';
import { extractFiltersQuery } from './utils/helpers';

const Request = APIConnector.instance;
const ENDPOINT = '/dashboard';

class DashboardAPI {
  static fetchData = data => {
    return Request.POST(`${ENDPOINT}/summary`, data);
  };

  static fetchGroupData = data => Request.POST(`${ENDPOINT}/groupSummary`, data);

  static fetchDiscountReport = (page, filters) => {
    const filtersQuery = extractFiltersQuery(filters);

    return Request.GET(`${ENDPOINT}/discountReport?page=${page}${filtersQuery}`);
  };

  static fetchProblemOrders = (params, group) =>
    Request.GET(`/sales/problem/order${group ? '' : '/merchant'}?${queryString.stringify(params)}`);

  static updateTotalSumPlan = data => {
    return Request.POST(`${ENDPOINT}/total-month-plan`, data);
  };
}

export default DashboardAPI;
