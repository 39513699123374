import { action, observable, runInAction } from 'mobx';
import PromotionsAPI from 'api/promotions';
import moment from 'moment';
import { getUTCOffset } from '../../../utils/time';

const NEW_PROMOTION_ANALYTICS = {
  averageBill: 0,
  itemSales: [],
  totalAmount: 0,
  totalCount: 0,
};

const TOTAL_AMOUNT = 'totalAmount';
const TOTAL_COUNT = 'totalCount';
const AVERAGE_BILL = 'averageBill';

export const PROMOTIONS_CHECKS_TABLE_FORMAT = new Map([
  [TOTAL_COUNT, { id: 'promotions.product.quantity', defaultMessage: 'Quantity' }],
  [TOTAL_AMOUNT, { id: 'promotions.product.totalAmount', defaultMessage: 'Total' }],
  [AVERAGE_BILL, { id: 'promotions.product.averageBill', defaultMessage: 'Average check' }],
]);

export const PROMOTIONS_CHECKS_LIST_FORMAT = {
  headerProperty: null,
  properties: new Map([
    [TOTAL_COUNT, { id: 'promotions.product.quantity', defaultMessage: 'Quantity' }],
    [TOTAL_AMOUNT, { id: 'promotions.product.totalAmount', defaultMessage: 'Total' }],
    [AVERAGE_BILL, { id: 'promotions.product.averageBill', defaultMessage: 'Average check' }],
  ]),
};

const initFilters = {
  startDate: moment()
    .startOf('day')
    .valueOf(),
  endDate: moment()
    .endOf('day')
    .valueOf(),
  merchantId: 'all',
  sorted: undefined,
};

class PromotionAnalytics {
  constructor(Promotions) {
    this.promotions = Promotions;
    this.onSave = this.promotions.createPromotion;
  }

  @observable promotionAnalytics = NEW_PROMOTION_ANALYTICS;
  @observable checksListFormat = PROMOTIONS_CHECKS_LIST_FORMAT;
  @observable checksTableFormat = PROMOTIONS_CHECKS_TABLE_FORMAT;
  @observable allFilters = initFilters;

  @action
  fetchPromotionAnalytics = async id => {
    const { startDate, endDate, merchantId, sorted } = this.allFilters;
    const newSorted = sorted && sorted.length > 5 ? sorted.split('|') : [];
    const allFilters = {
      ...this.allFilters,
      merchantId: merchantId === 'all' ? '' : merchantId,
      startDate:
        moment(startDate)
          .startOf('day')
          .valueOf() + getUTCOffset(startDate),
      endDate:
        moment(endDate)
          .endOf('day')
          .valueOf() + getUTCOffset(startDate),
      sortingDirection: newSorted.length > 0 ? newSorted[1] : '',
    };
    const data = await PromotionsAPI.fetchAnalytics(id, { ...allFilters });
    runInAction(() => {
      this.promotionAnalytics = data;
    });
  };

  @action
  changeAllFilters = (name, value) => {
    runInAction(() => {
      this.allFilters = { ...this.allFilters, [name]: value };
    });
  };

  @action
  clearAllFilters = () => {
    runInAction(() => {
      this.allFilters = initFilters;
    });
  };
}

export default PromotionAnalytics;
