import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';

import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { IntlProvider } from 'react-intl';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import GlobalStyle from './styles';
import App from './components/App';

const IS_LOCAL = process.env.CONFIG === 'local';

const renderApp = (stores, history, i18n) => {
  let Component = (
    <Provider {...stores}>
      <IntlProvider locale={i18n.language} messages={i18n.messages}>
        <Router history={history}>
          <Fragment>
            <GlobalStyle />
            <App />
          </Fragment>
        </Router>
      </IntlProvider>
    </Provider>
  );

  if (!IS_LOCAL) {
    const bugsnagClient = bugsnag({
      apiKey: 'e34fa403565e69ce7925866b003ed584',
      appVersion: String(process.env.appVersion),
      releaseStage: process.env.CONFIG,
    });
    bugsnagClient.use(bugsnagReact, React);
    const ErrorBoundary = bugsnagClient.getPlugin('react');
    Component = <ErrorBoundary>{Component}</ErrorBoundary>;
  }

  ReactDOM.render(Component, document.getElementById('root'));
};

export default renderApp;
