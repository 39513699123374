import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/broadcastMessage';

class BroadcastAPI {
  static fetchBroadcastMessage = (page, size, type) =>
    Request.GET(`${ENDPOINT}?page=${page}&size=${size}&type=${type}`);

  static fetchBroadcastMessageId = (id, page = 0, size = 20) =>
    Request.GET(`${ENDPOINT}/${id}?page=${page}&size=${size}`);

  static changeStatus = obj => Request.PUT(ENDPOINT, obj);
}

export default BroadcastAPI;
