import moment from 'moment';
import { action, computed, observable, runInAction } from 'mobx';
import VouchersAPI from 'api/vouchers';
import CustomersAPI from 'api/loyalty/customers';
import UIStore from 'stores/UI';
import { utcOffset } from 'utils/time';
import { DEFAULT_PAGINATE_SIZES, ISSUES_STATUS, VOUCHER_STATUSES } from 'utils/constants';
import {
  LOYALTY_LIST_FORMAT_ISSUES,
  LOYALTY_TABLE_FORMAT_ISSUES,
  LOYALTY_LIST_FORMAT,
  LOYALTY_TABLE_FORMAT,
  STATUSES_OPTIONS,
} from './constants';

const DATE_FORMAT = 'D MMM YYYY';
const EXPIRATION_DATE = 'endDate';
const { ACTIVE, INACTIVE } = VOUCHER_STATUSES;

const initVouchersState = () => ({
  pagination: {},
});

class VouchersStore {
  issueListFormat = LOYALTY_LIST_FORMAT_ISSUES;
  issueTableFormat = LOYALTY_TABLE_FORMAT_ISSUES;
  listFormat = LOYALTY_LIST_FORMAT;
  tableFormat = LOYALTY_TABLE_FORMAT;

  @observable list = [];
  @observable loading = false;
  @observable totalPages = 0;
  @observable currentPage = -1;
  @observable currentVoucher = {};
  @observable issuesVoucher = initVouchersState();
  @observable crmList = [];
  @observable customerIds = [];
  @observable customersList = [];
  @observable paginateOptions = { page: 0, size: 20 };
  @observable issuesTableSize = DEFAULT_PAGINATE_SIZES.desktop;
  @observable allFilters = { statuses: [] };
  @observable statusesOptions = STATUSES_OPTIONS;

  @computed get hasMore() {
    return this.currentPage + 1 < this.totalPages;
  }

  @computed get data() {
    return this.list.map(voucher => ({
      ...voucher,
      [EXPIRATION_DATE]: moment(voucher[EXPIRATION_DATE] - utcOffset).format(DATE_FORMAT),
    }));
  }

  @action
  changePaginateOptions = value => {
    runInAction(() => {
      this.paginateOptions = value;
    });
  };

  @action
  setIssuesTableSize = value => {
    runInAction(() => {
      this.issuesTableSize = value;
    });
  };

  @action
  startFetch = voucherId => {
    let loading = true;
    if (voucherId && this.issuesVoucher[voucherId]) {
      loading = false;
    }
    runInAction(() => {
      this.loading = loading;
    });
  };

  @action
  fetchTablePage = async () => {
    const { page, size } = this.paginateOptions;
    const { items, totalPages, number } = await VouchersAPI.list(page, size, this.allFilters);

    runInAction(() => {
      this.list = items;
      this.totalPages = totalPages;
      this.currentPage = number;
    });
  };

  @action
  changeAllFilters = (name, value, loadData) => {
    let newValue = value;
    if (name === 'statuses') {
      if (value[0] === 'all') {
        newValue = value.filter(i => i !== 'all');
      } else if (value.some(i => i === 'all')) {
        newValue = [];
      } else {
        newValue = value;
      }
      runInAction(() => {
        this.allFilters = { ...this.allFilters, statuses: [...newValue] };
      });
    } else {
      runInAction(() => {
        this.allFilters = { ...this.allFilters, [name]: newValue };
      });
    }
    loadData();
  };

  @action
  changeReleaseVoucherStatus = async (id, status) => {
    const ids = [id];
    try {
      if (status === ACTIVE) {
        await VouchersAPI.resumeReleaseVoucher({ ids });
      } else {
        await VouchersAPI.stopReleaseVoucher({ ids });
      }

      const newList = this.list.map(item => {
        if (item.id == id) {
          return { ...item, status };
        } else {
          return item;
        }
      });
      runInAction(() => {
        this.list = [...newList];
      });
    } catch (errors) {}
  };

  @action
  fetchListPage = async () => {
    const { page, size } = this.paginateOptions;
    const { items, totalPages, number } = await VouchersAPI.list(page, size, this.allFilters);

    runInAction(() => {
      if (page === 0) {
        this.list = items;
      } else {
        this.list = [...this.list, ...items];
      }

      this.totalPages = totalPages;
      this.currentPage = number;
    });
  };

  @action
  loadOptions = async (search, prevOptions, page = 0) => {
    let filteredOptions = [];
    let hasMore = false;
    let newItems = [];
    if (!search) {
      const { items, last } = await VouchersAPI.crmList(page, 20);
      filteredOptions = items;
      newItems = items;
      hasMore = !last;
    } else {
      const { items } = await VouchersAPI.crmList(0, 20, search);
      filteredOptions = [...items];
    }
    runInAction(() => {
      if (!search) {
        this.crmList = page === 0 ? [...newItems] : [...this.crmList, ...newItems];
      }
    });

    const slicedOptions = filteredOptions.map(item => ({
      value: item.id,
      label: item.name,
    }));
    return {
      options: slicedOptions,
      hasMore,
    };
  };

  @action
  fetchVoucherIssuesItems = async () => {
    if (this.list.length > 0) {
      const response = await VouchersAPI.getUsedIssues({
        voucherIds: this.list.map(item => item.id),
      });
      this.structureVouchersIssues(response);
    }
    runInAction(() => {
      this.loading = false;
    });
  };

  @action
  structureVouchersIssues = (data = []) => {
    const list = [...this.list];
    const updatedList = list.map(voucher => {
      const usedIssue = data.items.find(({ voucherId }) => voucherId === voucher.id);
      return usedIssue ? { ...voucher, ...usedIssue } : voucher;
    });

    runInAction(() => {
      this.loading = false;
      this.list = updatedList;
    });
  };

  @action
  fetchCustomers = async () => {
    if (this.customerIds.length) {
      let items = [];
      try {
        const data = await CustomersAPI.fetchCustomersList({ ids: this.customerIds });
        items = data.items;
      } catch (error) {
        UIStore.Notification.showError(error.message);
      } finally {
        runInAction(() => {
          this.customersList = items;
        });
      }
    }
  };

  @action
  fetchVoucherIssuedList = async ({ voucherId, page, list }) => {
    const issues = { ...this.issuesVoucher };
    this.startFetch(voucherId);

    try {
      const data = await VouchersAPI.getVoucherIssuesList(voucherId, page, this.issuesTableSize);

      if (list) {
        issues[voucherId] = page === 0 ? data.items : [...issues[voucherId], ...data.items];
      } else {
        issues[voucherId] = data.items;
      }

      issues.pagination = { ...data };
    } catch (error) {
      UIStore.Notification.showError(error.message);
    }

    runInAction(() => {
      this.issuesVoucher = issues;
      this.loading = false;
      this.customerIds = issues[voucherId].map(item => item.customerId);
      this.fetchCustomers();
    });
  };

  @action
  fetchVoucher = async voucherId => {
    this.startFetch();

    const user = await VouchersAPI.fetchVoucher(voucherId);

    runInAction(() => {
      this.loading = false;
      this.currentVoucher = user;
    });
  };

  @action
  createVoucher = async voucher => {
    this.startFetch();

    try {
      return await VouchersAPI.createVoucher({
        ...voucher,
      });
    } catch (error) {
      throw new Error(error.message);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  createVoucherIssue = async data => {
    this.startFetch();

    try {
      await VouchersAPI.createVoucherIssue(data);
      UIStore.Notification.showSuccess('Voucher was issued successfully');
      this.fetchVoucherIssuedList({ voucherId: data.voucherId, page: 0 });
    } catch (error) {
      UIStore.Notification.showError(error.message);
    }

    runInAction(() => {
      this.loading = false;
    });
  };

  findIndexById = productId => this.list.findIndex(product => product.id === productId);

  @action
  updateVoucher = async voucher => {
    const updatedVoucher = await VouchersAPI.updateVoucher(voucher);

    runInAction(() => {
      const index = this.findIndexById(updatedVoucher.id);

      this.list = [...this.list.slice(0, index), updatedVoucher, ...this.list.slice(index + 1)];
    });
  };

  @action
  removeVoucher = async voucherId => {
    this.startFetch();
    const issues = { ...this.issuesVoucher };

    try {
      await VouchersAPI.removeVoucher(voucherId);
      issues[voucherId] = [];
      runInAction(() => {
        const index = this.findIndexById(voucherId);

        if (index >= 0) {
          this.list = [...this.list.slice(0, index), ...this.list.slice(index + 1)];
          this.issuesVoucher = issues;
        }
      });
    } catch (error) {
      UIStore.Notification.showError(error.message || 'Something was wrong');
    }
    runInAction(() => {
      this.loading = false;
    });
  };

  changeVoucherStatus = (voucherId, { voucherIds = [] }, status = ISSUES_STATUS.NEW) => {
    const issues = { ...this.issuesVoucher };
    const updatedVouchers = this.issuesVoucher[voucherId].map(item =>
      voucherIds.includes(item.id)
        ? {
            ...item,
            status,
          }
        : item,
    );
    issues[voucherId] = updatedVouchers;
    return issues;
  };

  @action
  markVouchersCodeAsSent = async (voucherId, voucherIds) => {
    let issues = null;
    this.startFetch();

    try {
      await VouchersAPI.markVouchersCodeAsSent(voucherIds);
      issues = this.changeVoucherStatus(voucherId, voucherIds, ISSUES_STATUS.SENT);
      UIStore.Notification.showSuccess('Issue was sent successfully');
    } catch (error) {
      UIStore.Notification.showError(error.message || 'Something was wrong');
    } finally {
      runInAction(() => {
        (this.loading = false), (this.issuesVoucher = issues || this.issuesVoucher);
      });
    }
  };

  @action
  deactivateVoucher = async (voucherId, voucherIds = []) => {
    let issues = null;
    this.startFetch();

    try {
      await VouchersAPI.deactivateVoucher(voucherIds);
      issues = this.changeVoucherStatus(voucherId, voucherIds, ISSUES_STATUS.DEACTIVATED);
      UIStore.Notification.showSuccess('Voucher was deactivated successfully');
    } catch (error) {
      UIStore.Notification.showError(error.message);
    } finally {
      runInAction(() => {
        this.loading = false;
        this.issuesVoucher = issues || this.issuesVoucher;
      });
    }
  };
}

export default VouchersStore;
