import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/cms/post';

class PostsAPI {
  static fetchPosts = () => Request.GET(`${ENDPOINT}`);

  static createPost = postInfo => Request.POST(ENDPOINT, postInfo);

  static fetchPost = id => Request.GET(`${ENDPOINT}/${id}`);

  static updatePost = (id, postInfo) => Request.PUT(`${ENDPOINT}`, postInfo);

  static removePost = id => Request.DELETE(`${ENDPOINT}/${id}`);

  static updatePostsOrder = itemOrderDtos => Request.PUT(`${ENDPOINT}/order`, { itemOrderDtos });
}

export default PostsAPI;
