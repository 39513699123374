import {
  FRIDAY,
  MONDAY,
  SATURDAY,
  SUNDAY,
  THURSDAY,
  TUESDAY,
  WEDNESDAY,
} from 'stores/merchants/constants';
import { getLocalizedString } from '../i18n/utils';

export const CURRENCY_CODE_TO_SIGN = {
  USD: '$',
  SGD: 'S$',
  CNY: '¥',
  UAH: '₴',
  PHP: '₱',
  RM: 'RM',
  PLN: 'zł',
  CAD: 'C$',
  EUR: '€',
  AUD: 'A$',
  KZT: '₸',
  RUR: '₽',
};

export const CURRENCY_CODE_TO_SMS_PRICE = {
  USD: '0.25 $',
  AUD: '0.25 A$',
  SGD: '0.25 S$',
  CNY: '1 ¥',
  UAH: '0.6 ₴',
  CAD: '0.25 C$',
  EUR: '0.5 €',
  PHP: '10 ₱',
  RM: '0.3 RM',
  PLN: '1 zł',
  KZT: '8.20 ₸',
  RUR: '1.6 ₽',
};

export const DEFAULT_SEARCH_INPUT_DELAY = 1200;

export const AVAILABLE_PAGINATE_SIZES = [10, 20, 40, 100];
export const DEFAULT_PAGINATE_SIZES = {
  mobile: 10,
  desktop: 20,
};

export const NONE = 'none';
export const INGREDIENT_CARD = 'ingredient.card';
export const PRODUCT = 'product';

export const DEVELOPMENT = 'development';
export const DEMO = 'demo';
export const PRODUCTION = 'production';
export const BLOCKED_MERCHANTS_STATUS = 'INACTIVE';

export const IS_PRODUCTION = process.env.CONFIG === PRODUCTION;
export const IMAGE_PLACEHOLDER =
  'https://orty-public.sgp1.digitaloceanspaces.com/placeholder/merchant_logo_placeholder.png';

export const PRODUCT_IMAGE_PLACEHOLDER =
  'https://orty-public.sgp1.digitaloceanspaces.com/placeholder/Placeholder2x.jpg';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const REPORT_TIME_FORMAT = 'DD-MM-YYYY HH:mm';

export const MOBILE_BREAKPOINT = 767;
export const TABLET_BREAKPOINT = 991;

export const ISSUES_STATUS = {
  NEW: 'NEW',
  SENT: 'SENT',
  USED: 'USED',
  EXPIRED: 'EXPIRED',
  DEACTIVATED: 'DEACTIVATED',
};

export const VOUCHER_TYPE = {
  DEPOSIT: 'DEPOSIT',
  TAG: 'TAG',
  DISCOUNT: 'DISCOUNT',
  CUMULATIVE: 'CUMULATIVE',
};

export const PRODUCTS_TYPE = {
  PRODUCT: 'PRODUCT',
  MEAL: 'MEAL',
};

export const DASHBOARD_MIX_TYPE = {
  PRODUCT: 'PRODUCT',
  COGS_DYNAMICS: 'COGS_DYNAMICS',
  CATEGORY: 'CATEGORY',
};

export const LOYALTY_PROGRAM_TYPE = {
  CASHBACK: 'CASHBACK',
  DISCOUNT: 'DISCOUNT',
};

export const DASHBOARD_ANALYTICS_TYPE = {
  SUMMARY: 'SUMMARY',
  TIME_IN_STATUS: 'TIME_IN_STATUS',
  AVG_FEEDBACK: 'AVG_FEEDBACK',
  NEW_VS_RETURNING: 'NEW_VS_RETURNING',
};

export const DASHBOARD_RETROSPECTIVE_TYPE = {
  DAILY: 'DAILY',
  HOURLY: 'hourly',
};

export const ISSUES_STATUS_COLOR = {
  NEW: '#1b8eff',
  SENT: '#03b26d',
  USED: 'rgba(0, 0, 0, .7)',
  DEACTIVATED: '#ff0000',
};

export const NOTIFICATIONS_COLOR = {
  SUCCESS: '#20b142',
  ERROR: '#db2727',
};

export const NEW_ROUTE_PREFIX = 'new';

export const modalTypes = {
  DELETE: 'DELETE',
  WARNING: 'WARNING',
};

export const IMAGE_DIMENSIONS = {
  LOGO: {
    WIDTH: 256,
    HEIGHT: 256,
  },
  THUMBNAIL: {
    WIDTH: 400,
    HEIGHT: 120,
  },
  PRODUCT: {
    WIDTH: 700,
    HEIGHT: 600,
  },
};

export const ASCENDING = 'ascending';
export const DESCENDING = 'descending';
export const ALL = 'all';
export const PLUS = '+';
export const MINUS = '-';

export const CLASSIC_RPO = 'classic';
export const PROGRAM_RPO = 'program';
export const VCHASNO_RPO = 'vchasno';

export const TARGET_SERVICE_TYPE = {
  TAKEAWAY: 'TAKEAWAY',
  DINE_IN: 'DINE_IN',
  DELIVERY: 'DELIVERY',
  CURBSIDE: 'CURBSIDE',
};

export const VOUCHER_STATUSES = {
  EXPIRED: 'EXPIRED',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const WRITE_DOWN_PAYMENT_METHODS_ARR = [
  'Write_down._Manager_discretion',
  'Write_down._Staff_error',
  'Write_down._Customer_left',
  'Write_down',
];

export const ARRAY_DAYS = [FRIDAY, MONDAY, SATURDAY, SUNDAY, THURSDAY, TUESDAY, WEDNESDAY];

export const TARGET_SERVICE_TYPE_OPTIONS = [
  { text: getLocalizedString('vouchers.editor.all'), value: 'all' },
  ...Object.keys(TARGET_SERVICE_TYPE).map(i => ({
    text: getLocalizedString(`vouchers.editor.targetServiceType.${i}`),
    value: i,
    key: i,
  })),
];
