import { action, computed, observable, runInAction } from 'mobx';
import RealTimeAPI from 'api/real-time';
import { getLocalizedString } from 'i18n/utils';
import { demicalFormat } from 'utils/demical-format';
import { DASHBOARD_MIX_TYPE } from 'utils/constants';

const { PRODUCT } = DASHBOARD_MIX_TYPE;

const USER_NAME = 'username';
const ASSEMBLING = 'assembling';
const DELIVERED = 'delivered';
const IN_KITCHEN = 'inKitchen';
const READY_FOR_DELIVER = 'readyForDeliver';
const NEW = 'new';
const AMOUNT = 'amount';

const ORDERS_PER_STAFF_TABLE = [
  {
    name: getLocalizedString('sales.data.username'),
    variant: ['wrap'],
    property: USER_NAME,
    width: '16.6%',
  },
  {
    name: getLocalizedString('sales.data.сreated.onPOS'),
    variant: ['wrap'],
    property: NEW,
    width: '16.6%',
  },
  {
    name: getLocalizedString('sales.data.orderStatus.IN_KITCHEN'),
    variant: ['wrap'],
    property: IN_KITCHEN,
    width: '16.6%',
  },
  {
    name: getLocalizedString('sales.data.orderStatus.ASSEMBLING'),
    variant: ['wrap'],
    property: ASSEMBLING,
    width: '16.6%',
  },
  {
    name: getLocalizedString('sales.data.orderStatus.READY_FOR_DELIVER'),
    variant: ['wrap'],
    property: READY_FOR_DELIVER,
    width: '16.6%',
  },
  {
    name: getLocalizedString('sales.data.orderStatus.DELIVERED'),
    variant: ['wrap'],
    property: DELIVERED,
    width: '16.6%',
  },
];

const USER_CASH_IN_DRAWER_HEADERS = [
  {
    name: getLocalizedString('sales.data.username'),
    variant: ['wrap'],
    property: USER_NAME,
  },
  {
    name: getLocalizedString('realTime.data.totalAmount'),
    variant: ['wrap'],
    property: AMOUNT,
  },
];

const initSummary = {
  avgHourlySalesResponse: {},
  productMixCategoryResponse: { list: [] },
  productMixResponse: { list: [] },
  productMixWithoutOptionsResponse: { list: [] },
  totalDeliveredOrdersAmount: '0',
  totalDeliveredOrdersCount: 0,
  totalInProgressOrdersAmount: '0',
  totalInProgressOrdersCount: 0,
  userCashInDrawer: {},
  userStats: [],
};

class RealTime {
  @observable ordersPerStaffTableHeaders = ORDERS_PER_STAFF_TABLE;
  @observable userCashInDrawerHeaders = USER_CASH_IN_DRAWER_HEADERS;
  @observable summary = initSummary;
  @observable charCurrency = null;
  @observable mode = PRODUCT;
  @observable showModifiers = false;

  @action
  getSummary = async () => {
    try {
      const data = await RealTimeAPI.getSummary();
      runInAction(() => {
        this.summary = data;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  setMerchantCurrency = currency => {
    this.charCurrency = currency;
  };

  @computed get chartData() {
    let chartData = [];
    const { summaryMap } = this.summary.avgHourlySalesResponse;

    const amount = getLocalizedString('merchant.paymentMethod.amount') || 'Amount';
    const count = getLocalizedString('merchant.paymentMethod.count') || 'Orders count';
    const summaryKeys = Object.keys(summaryMap || {}).sort((a, b) => Number(a) - Number(b));
    summaryKeys.map(key => {
      if (summaryMap[key]) {
        let newData = {};
        newData = {
          name: key + 'h',
          [count]: Number(summaryMap[key].count),
          [amount]: Number(summaryMap[key].amount),
        };
        chartData.push(newData);
      }
    });

    const total = chartData.reduce((sum, item) => sum + item[amount], 0);

    return total > 0 ? chartData : [];
  }

  @action
  setShowModifiers = value => {
    runInAction(() => (this.showModifiers = value));
  };

  @action
  setMode = mode => {
    runInAction(() => {
      this.mode = mode;
    });
  };

  @computed get currentMode() {
    return this.mode;
  }

  @computed get productMixTableResponse() {
    const { productMixResponse, productMixWithoutOptionsResponse } = this.summary;

    const newData = !this.showModifiers ? productMixWithoutOptionsResponse : productMixResponse;

    return (newData.list || []).map(oneItem => {
      const { item, category, modifiers } = oneItem;
      const sold = Number(oneItem.sold) || 0;
      const soldPct = Number(oneItem.soldPct) || 0;
      const received = Number(oneItem.received) || 0;
      const receivedPct = Number(oneItem.receivedPct) || 0;
      const spent = Number(oneItem.spent) || 0;

      return {
        name: item,
        category,
        sold: `${sold.toFixed(2)} (${soldPct.toFixed(2)} %)`,
        soldValue: sold.toFixed(2),
        receivedValue: received.toFixed(2),
        soldPctValue: soldPct,
        receivedPctValue: receivedPct,
        received: received
          ? `${this.charCurrency || ''} ${received.toFixed(2)} (${receivedPct.toFixed(2)} %)`
          : '-',
        modifiers,
        spent: `${spent.toFixed(2)}`,
      };
    });
  }

  @computed get categoryMixTableResponse() {
    const { list } = this.summary.productMixCategoryResponse;
    return (list || []).map(item => {
      const { category } = item;
      const sold = Number(item.sold) || 0;
      const soldPct = Number(item.soldPct) || 0;
      const received = Number(item.received) || 0;

      return {
        ...item,
        category,
        sold: `${sold.toFixed(2)} (${soldPct.toFixed(2)} %)`,
        received: `${this.charCurrency || ''} ${demicalFormat(received.toFixed(2))}`,
      };
    });
  }

  @computed get userCashInDrawer() {
    return Object.entries(this.summary.userCashInDrawer).map(i => ({
      username: i[0],
      amount: demicalFormat(i[1]),
    }));
  }
}

export default RealTime;
