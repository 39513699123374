import { action, computed, observable, runInAction } from 'mobx';
import history, { routes } from 'routes';
import moment from 'moment';
import uniq from 'lodash/uniq';
import MenuAPI from 'api/menu';
import TagsAPI from 'api/tags';
import MerchantAPI from 'api/merchant';
import CategoriesAPI from 'api/categories';
import ProductsAPI from 'api/products';
import { getLocalizedString } from 'i18n/utils';
import { getUTCOffset } from 'utils/time';
import { convertTaxToPrice, deductTax, toFixedTwo } from 'utils/numbers';
import validate from 'validate.js';
import get from 'lodash/get';
import { ARRAY_DAYS, MINUS, PLUS } from 'utils/constants';

import { toObject } from 'utils/common';
import { millisecondsToTime, convertTimeToMilliseconds } from 'utils/format-date';
import { DAYS_OF_THE_WEEK, RULER_SCALE_VALUES } from 'stores/merchants/constants';

import {
  CUSTOMERS_GIFT_TYPE,
  INITIAL_PERCENT_COUNT,
  PROMOTION_CONDITIONS_TYPE,
  PROMOTION_TYPE_TO_DISPLAY_NAME,
  PROMOTION_TYPES,
  SELECTION_LIST,
  SELECTION_MAPPER_LIST,
} from './constants';

const CHANGED_PROMO_KEYS = [
  'channel',
  'startTimestamp',
  'endTimestamp',
  'schedule',
  'description',
  'targetMerchantIds',
  'paymentMethods',
  'cohortIds',
  'discountPercent',
  'fixedSellPrice',
  'presentQuantity,',
];

const OPEN = {
  start: RULER_SCALE_VALUES[0],
  end: RULER_SCALE_VALUES[RULER_SCALE_VALUES.length - 1],
  workingDay: true,
};

const CLOSE = {
  start: RULER_SCALE_VALUES[0],
  end: RULER_SCALE_VALUES[RULER_SCALE_VALUES.length - 1],
  workingDay: false,
};

const DEFAULT_SCHEDULE = toObject(DAYS_OF_THE_WEEK, OPEN);

const CLOSED_DEFAULT_SCHEDULE = toObject(DAYS_OF_THE_WEEK, CLOSE);

const NEW_PROMOTION = {
  name: '',
  type: CUSTOMERS_GIFT_TYPE.DISCOUNT,
  channel: 'OPC',
  condition: {
    quantity: 1,
    productSelector: {
      tags: [],
      categoryIds: [],
      productIds: [],
      all: false,
    },
    cohortIds: [],
    paymentMethods: [],
    totalPrice: '',
    schedule: DEFAULT_SCHEDULE,
    conditionType: PROMOTION_CONDITIONS_TYPE.MORE_THAN,
  },
  bonus: {
    quantity: 1,
    productSelector: {
      tags: [],
      categoryIds: [],
      productIds: [],
      menuIds: [],
      all: false,
    },
    presentQuantity: 1,
    fixedPrice: 0,
    fixedSellPrice: 0,
    discountPercent: INITIAL_PERCENT_COUNT,
  },
  startTimestamp:
    moment()
      .startOf('day')
      .valueOf() + getUTCOffset(),
  endTimestamp:
    moment()
      .endOf('day')
      .valueOf() + getUTCOffset(),
  targetMerchantIds: [],
  bonusAuditList: [],
  targetServiceType: [],
};

const VALIDATION_SCHEMA = {
  name: { presence: { allowEmpty: false } },
  type: { presence: { allowEmpty: false } },
  startTimestamp: { presence: { allowEmpty: false } },
  endTimestamp: { presence: { allowEmpty: false } },
};

class PromotionEditor {
  constructor(Promotions) {
    this.promotions = Promotions;
    this.onSave = this.promotions.createPromotion;
  }

  @observable promotion = NEW_PROMOTION;
  @observable conditionSelections = [];
  @observable customersSelections = [];
  @observable discountPercent = INITIAL_PERCENT_COUNT;
  @observable sameDiscountPercent = INITIAL_PERCENT_COUNT;
  @observable availablePaymentMethods = [];
  @observable positiveNegative = PLUS;

  @observable availableTypes = PROMOTION_TYPES.map(type => ({
    text: PROMOTION_TYPE_TO_DISPLAY_NAME[type],
    value: type,
  }));

  @observable tags = [];
  @observable changedPromoKeys = CHANGED_PROMO_KEYS;

  fetchTags = async () => {
    const { items } = await TagsAPI.list();

    runInAction(() => {
      this.tags = items.map(({ name }) => ({
        label: name,
        value: name,
        type: 'tags',
      }));
    });
  };

  @observable products = [];

  fetchProducts = async () => {
    const { items } = await ProductsAPI.loadAsOptions();

    runInAction(() => {
      this.products = items.map(({ name, menuName, optionId }) => ({
        label: `${name} (${menuName})`,
        value: optionId,
        type: 'products',
      }));
    });
  };

  @observable menus = [];

  fetchMenus = async () => {
    const { items } = await MenuAPI.fetchMenuForMerchantGroup();

    runInAction(() => {
      this.menus = items.map(({ name, id }) => ({
        label: name,
        value: id,
        type: 'menus',
      }));
    });
  };

  @computed get ruler() {
    return RULER_SCALE_VALUES;
  }

  @computed get weekdays() {
    return DAYS_OF_THE_WEEK;
  }

  @action
  changePositiveNegative = value => {
    runInAction(() => {
      this.positiveNegative = value;
    });
  };

  @action
  togglePromotionDay = day => {
    const promotion = { ...this.promotion };
    promotion.condition.schedule[day].workingDay = !promotion.condition.schedule[day].workingDay;

    runInAction(() => {
      this.promotion = promotion;
    });
  };

  @action
  fetchAvailablePaymentMethods = async () => {
    const { items } = await MerchantAPI.fetchAvailablePaymentMethods();

    runInAction(() => {
      this.availablePaymentMethods = items;
    });
  };

  @action
  changePromotionSchedule = (day, prop, time) => {
    const promotion = { ...this.promotion };
    promotion.condition.schedule[day][prop] = time;

    runInAction(() => {
      this.promotion = promotion;
    });
  };

  @observable categories = [];

  fetchCategories = async () => {
    const { items } = await CategoriesAPI.loadAsOptions();

    runInAction(() => {
      this.categories = items.map(({ name, optionId, menuName }) => ({
        label: `${name} (${menuName})`,
        value: optionId,
        type: 'categories',
      }));
    });
  };

  @action
  changeConditionSelections = value => {
    runInAction(() => {
      this.conditionSelections = [...value];
    });
  };

  @action
  changeTargetMerchantIds = value => {
    this.promotion.targetMerchantIds = value;
  };

  @action
  changePaymentMethods = value => {
    if (value[0] === 'all') {
      runInAction(() => {
        this.promotion.condition.paymentMethods = value.filter(i => i !== 'all');
      });
    } else if (value.some(i => i === 'all')) {
      runInAction(() => {
        this.promotion.condition.paymentMethods = [];
      });
    } else {
      runInAction(() => {
        this.promotion.condition.paymentMethods = value;
      });
    }
  };

  @action
  changeChannel = value => {
    runInAction(() => {
      this.promotion = {
        ...this.promotion,
        channel: value,
      };
    });
  };

  @action
  changeGiftType = value => {
    runInAction(() => {
      this.promotion = {
        ...this.promotion,
        channel: value,
      };
    });
  };

  @action
  changeType = value => {
    runInAction(() => {
      this.promotion = {
        ...this.promotion,
        channel: value,
      };
    });
  };

  @action
  changeCustomersSelections = value => {
    runInAction(() => {
      this.customersSelections = [...value];
    });
  };

  @action
  create = admin => {
    this.promotion = { ...NEW_PROMOTION };
    this.onSave = this.promotions.createPromotion;
    this.clearValidation();

    admin ? history.push(`${routes.adminPromotions}/new`) : history.push(routes.promotionEditorNew);
  };

  structureScheduleEditTimes = (timesData = {}) => {
    const times = {};

    for (const key in timesData) {
      if (timesData.hasOwnProperty(key)) {
        times[key] = {
          workingDay: true,
          start: millisecondsToTime(timesData[key][0]),
          end: millisecondsToTime(timesData[key][1]),
        };
      }
    }
    return {
      ...CLOSED_DEFAULT_SCHEDULE,
      ...times,
    };
  };

  setPromotionSelections = (list = {}, listName = '') => {
    const result = [];

    if (list.all) {
      result.push({ type: 'all', value: 'all', label: getLocalizedString('vouchers.editor.all') });
    }

    SELECTION_LIST.map(key => {
      list[key].map(item => {
        this[SELECTION_MAPPER_LIST[key]].forEach(property => {
          if (item === property.value) {
            result.push({
              ...property,
              type: SELECTION_MAPPER_LIST[key],
            });
          }
        });
      });
    });

    runInAction(() => {
      this[listName] = result;
    });
  };

  setDiscountPercent = (applyToSame, percent) => {
    runInAction(() => {
      if (applyToSame) {
        this.sameDiscountPercent = percent;
      } else {
        this.discountPercent = percent;
      }
    });
  };

  getSheduleChanges = (prevShedule, thisShedule) => {
    let changedValues = [];

    ARRAY_DAYS.map(key => {
      if (JSON.stringify(prevShedule[key]) !== JSON.stringify(thisShedule[key])) {
        const prevWorkingDay = prevShedule[key].workingDay
          ? getLocalizedString('global.yes')
          : getLocalizedString('global.no');
        const prevStart = prevShedule[key].start;
        const prevEnd = prevShedule[key].end;
        const thisStart = thisShedule[key].start;
        const thisEnd = thisShedule[key].end;
        const thisWorkingDay = thisShedule[key].workingDay
          ? getLocalizedString('global.yes')
          : getLocalizedString('global.no');
        const dayOfWeek = `${getLocalizedString(`merchant.day.${key}`)}`;
        const workingDayText = `${getLocalizedString('promotions.editor.history.workingDay')}`;
        const workingDayStartText = `${getLocalizedString(
          'promotions.editor.history.workingDayStart',
        )}`;
        const workingDayEndText = `${getLocalizedString(
          'promotions.editor.history.workingDayEnd',
        )}`;
        changedValues.push(
          `${dayOfWeek}(${workingDayText}(${prevWorkingDay} => ${thisWorkingDay}), ${workingDayStartText}(${prevStart} => ${thisStart}), ${workingDayEndText}(${prevEnd} => ${thisEnd}))`,
        );
      }
    });

    return changedValues.join(', ');
  };

  getHistoryChanges = (data, item, index) => {
    const prevItem = data[index - 1] || {};
    let changedValues = {};
    if (index > 0) {
      CHANGED_PROMO_KEYS.map(key => {
        if (JSON.stringify(prevItem[key]) !== JSON.stringify(item[key])) {
          if (key === 'cohortIds' || key === 'targetMerchantIds' || key === 'paymentMethods') {
            changedValues[key] = { key, prevVal: prevItem[key], thisVal: item[key] };
          } else if (key === 'schedule') {
            changedValues.schedule = this.getSheduleChanges(prevItem.schedule, item.schedule);
          } else if (key === 'channel') {
            const prevChannel = getLocalizedString(
              `products.editor.channel.types.${prevItem[key].toLowerCase()}`,
            );
            const thisChannel = getLocalizedString(
              `products.editor.channel.types.${item[key].toLowerCase()}`,
            );
            changedValues.channel = `${prevChannel} => ${thisChannel}`;
          } else {
            changedValues[key] = `${prevItem[key]} => ${item[key]}`;
          }
        }
      });
    }
    return changedValues;
  };

  @action
  edit = async (id, { taxInclusive, turnoverTax, duplicate }) => {
    this.clearValidation();
    const promotion = await this.promotions.fetchPromotion(id);
    await Promise.all([
      this.fetchTags(),
      this.fetchCategories(),
      this.fetchProducts(),
      this.fetchMenus(),
    ]);
    const { bonus, condition, type } = promotion;

    this.setDiscountPercent(bonus.applyToSame, bonus.discountPercent);
    this.setPromotionSelections(bonus.productSelector, 'customersSelections');
    this.setPromotionSelections(condition.productSelector, 'conditionSelections');
    this.changePositiveNegative(
      !bonus.discountPercent
        ? bonus.fixedPrice > 0
          ? PLUS
          : MINUS
        : bonus.discountPercent > 0
        ? MINUS
        : PLUS,
    );

    const bonusAuditList = promotion.bonusAuditList.map(item => {
      const startTimestamp = get(item, 'promotionSnapshot.startTimestamp') || 0;
      const endTimestamp = get(item, 'promotionSnapshot.endTimestamp') || 0;
      const fixedSellPrice = toFixedTwo(item.fixedSellPrice);
      const discountPercent = toFixedTwo(item.discountPercent);
      const presentQuantity = toFixedTwo(item.presentQuantity);
      return {
        ...item,
        fixedSellPrice,
        discountPercent,
        presentQuantity,
        targetMerchantIds: get(item, 'promotionSnapshot.targetMerchantIds') || [],
        schedule: this.structureScheduleEditTimes(
          get(item, 'promotionSnapshot.condition.schedule') || {},
        ),
        channel: get(item, 'promotionSnapshot.channel') || '',
        description: get(item, 'promotionSnapshot.description') || '',
        cohortIds: get(item, 'promotionSnapshot.condition.cohortIds') || [],
        paymentMethods: get(item, 'promotionSnapshot.condition.paymentMethods') || [],
        startTimestamp: moment(startTimestamp - getUTCOffset(startTimestamp)).format('DD-MM-YYYY'),
        endTimestamp: moment(endTimestamp - getUTCOffset(endTimestamp)).format('DD-MM-YYYY'),
      };
    });

    const getDiscountPercent = () => {
      if (type === CUSTOMERS_GIFT_TYPE.PRICE_SURGE && this.positiveNegative === PLUS) {
        return Math.abs(bonus.discountPercent);
      }
      return bonus.discountPercent || undefined;
    };

    const getFixedPrice = () => {
      if (type === CUSTOMERS_GIFT_TYPE.PRICE_SURGE && this.positiveNegative === MINUS) {
        return Math.abs(bonus.fixedPrice);
      }
      return bonus.fixedPrice || undefined;
    };

    let newPromo = {
      ...promotion,
      bonus: {
        ...bonus,
        fixedPrice: taxInclusive
          ? convertTaxToPrice(getFixedPrice(), turnoverTax)
          : getFixedPrice(),
        discountPercent: getDiscountPercent(),
      },
      name: duplicate ? `${promotion.name}_1` : promotion.name,
      condition: {
        ...condition,
        schedule: this.structureScheduleEditTimes(condition.schedule),
      },
      startTimestamp: promotion.startTimestamp - getUTCOffset(promotion.startTimestamp),
      endTimestamp: promotion.endTimestamp - getUTCOffset(promotion.endTimestamp),
      bonusAuditList: bonusAuditList,
      targetServiceType:
        promotion.targetServiceType === '' ? [] : promotion.targetServiceType.split(','),
      historyChanges: bonusAuditList.map((item, index) =>
        this.getHistoryChanges(bonusAuditList, item, index),
      ),
    };

    if (duplicate) {
      delete newPromo.status;
      delete newPromo.id;
    }

    runInAction(() => {
      this.promotion = newPromo;
      if (duplicate) {
        this.onSave = this.promotions.createPromotion;
      } else {
        this.onSave = this.promotions.updatePromotion;
      }
    });
  };

  @observable validationErrors = {};

  @action
  validatePromotion = () => {
    this.validationErrors = validate(this.promotion, VALIDATION_SCHEMA) || {};

    if (!this.promotion.bonus.fixedPrice && !this.promotion.bonus.discountPercent) {
      this.validationErrors['fixedPrice'] = ['Value cannot be 0'];
    }

    if (!this.promotion.condition.quantity && !this.promotion.condition.totalPrice) {
      this.validationErrors['quantity'] = ['quantity cannot be 0'];
    }

    if (!this.conditionSelections.length) {
      this.validationErrors['categoryId'] = ['quantity cannot be 0'];
    }

    if (this.promotion.type === 'PRESENT' && !this.promotion.bonus.presentQuantity) {
      this.validationErrors['presentQuantity'] = ['quantity cannot be 0'];
    }

    if (this.promotion.type === 'PRESENT' && !this.customersSelections.length) {
      this.validationErrors['customersSelections'] = ['quantity cannot be 0'];
    }

    return [!Object.keys(this.validationErrors).length, this.validationErrors];
  };

  @action
  changePromotion = (key, value) => {
    if (VALIDATION_SCHEMA[key]) {
      this.validationErrors[key] = validate.single(value, VALIDATION_SCHEMA[key]);
    }
    runInAction(() => {
      if (key === 'targetServiceType') {
        if (value[0] === 'all') {
          runInAction(() => {
            this.promotion[key] = value.filter(i => i !== 'all');
          });
        } else if (value.some(i => i === 'all')) {
          this.promotion[key] = [];
        } else {
          this.promotion[key] = value;
        }
      } else {
        this.promotion[key] = value;
      }
    });
  };

  @action
  changeCondition = (key, value) => {
    if (key && this.promotion.condition.hasOwnProperty(key)) {
      const newCondition = { ...this.promotion.condition };
      newCondition[key] = value;
      this.promotion.condition = { ...newCondition };
    }
  };

  @action
  changeBonus = (key, value) => {
    if (key && this.promotion.bonus.hasOwnProperty(key)) {
      this.promotion.bonus[key] = value;
    }
  };

  @action
  changePercantage = (key, value) => {
    if (key) {
      runInAction(() => {
        this[key] = value;
      });
    }
  };

  @action
  changeDiscount = value => {
    this.promotion.bonus.discountPercent = value;
  };

  @action
  changeFixedPrice = value => {
    this.promotion.bonus.fixedPrice = value;
  };

  @action
  changePromotionType = type => {
    this.promotion.type = type;
  };

  @action
  setStartTimestamp = value => {
    this.promotion.startTimestamp = value;
  };

  @action
  setEndTimestamp = value => {
    this.promotion.endTimestamp = value;
  };

  structureScheduleTimes = (timesData = {}) => {
    const times = {};
    for (const key in timesData) {
      if (timesData.hasOwnProperty(key) && timesData[key].workingDay) {
        times[key] = [
          convertTimeToMilliseconds(timesData[key].start),
          convertTimeToMilliseconds(timesData[key].end),
        ];
      }
    }
    return times;
  };

  getCustomersSelections = (field, key = 'value') => {
    return this.customersSelections.filter(item => item.type === field).map(item => item[key]);
  };

  getConditionSelections = (field, key = 'value') => {
    return this.conditionSelections.filter(item => item.type === field).map(item => item[key]);
  };

  getStructuredPromotion = (taxInclusive = false, turnoverTax = 0) => {
    const bonusTag = this.getCustomersSelections('tags', 'label');
    const bonusProductsIds = this.getCustomersSelections('products');
    const bonusCategoryIds = this.getCustomersSelections('categories');
    const bonusMenuIds = this.getCustomersSelections('menus');

    const tags = this.getConditionSelections('tags', 'label');
    const productIds = this.getConditionSelections('products');
    const categoryIds = this.getConditionSelections('categories');
    const menuIds = this.getConditionSelections('menus');
    const all = !!this.getConditionSelections('all').length;
    const schedule = this.structureScheduleTimes(this.promotion.condition.schedule);
    const startTimestamp = moment(this.promotion.startTimestamp)
      .startOf('day')
      .valueOf();
    const endTimestamp = moment(this.promotion.endTimestamp)
      .endOf('day')
      .valueOf();

    const getFixedPrice = () => {
      if (
        this.promotion.type === CUSTOMERS_GIFT_TYPE.PRICE_SURGE &&
        this.positiveNegative === MINUS
      ) {
        return -Math.abs(this.promotion.bonus.fixedPrice);
      }
      return Number(this.promotion.bonus.fixedPrice) || undefined;
    };

    const fixedPrice = this.promotion.bonus.fixedPrice
      ? taxInclusive
        ? deductTax(getFixedPrice(), turnoverTax)
        : getFixedPrice()
      : undefined;

    const getDiscountPercent = () => {
      if (
        this.promotion.type === CUSTOMERS_GIFT_TYPE.PRICE_SURGE &&
        this.positiveNegative === PLUS
      ) {
        return -Math.abs(this.promotion.bonus.discountPercent);
      }
      return Number(this.promotion.bonus.discountPercent) || undefined;
    };

    return {
      ...this.promotion,
      bonus: {
        ...this.promotion.bonus,
        fixedPrice,
        fixedSellPrice: getFixedPrice(),
        discountPercent: getDiscountPercent(),
        productSelector: {
          tags: uniq(bonusTag),
          categoryIds: uniq(bonusCategoryIds),
          productIds: uniq(bonusProductsIds),
          menuIds: uniq(bonusMenuIds),
        },
      },
      condition: {
        ...this.promotion.condition,
        cohortIds: this.promotion.condition.cohortIds,
        paymentMethods: this.promotion.condition.paymentMethods,
        conditionType:
          this.promotion.type === CUSTOMERS_GIFT_TYPE.PRESENT ||
          this.promotion.type === CUSTOMERS_GIFT_TYPE.PRICE_SURGE
            ? null
            : this.promotion.condition.conditionType,
        schedule,
        productSelector: {
          all,
          menuIds: uniq(menuIds),
          tags: uniq(tags),
          categoryIds: uniq(categoryIds),
          productIds: uniq(productIds),
        },
      },
      startTimestamp: startTimestamp + getUTCOffset(startTimestamp),
      endTimestamp: endTimestamp + getUTCOffset(endTimestamp) - 1,
      targetServiceType:
        this.promotion.targetServiceType[0] === 'all'
          ? ''
          : this.promotion.targetServiceType.join(','),
    };
  };

  @action
  save = async ({ taxInclusive = false, turnoverTax, admin }) => {
    const structuredPromotion = this.getStructuredPromotion(taxInclusive, turnoverTax);

    const promotion = {
      ...this.promotion,
      ...structuredPromotion,
    };

    await this.onSave(promotion);

    admin ? history.push(routes.adminPromotions) : history.push(routes.promotions);
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.customersSelections = [];
    this.conditionSelections = [];
    this.discountPercent = INITIAL_PERCENT_COUNT;
    this.sameDiscountPercent = INITIAL_PERCENT_COUNT;
  };
}

export default PromotionEditor;
