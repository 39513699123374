import React from 'react';
import loadable from '@loadable/component';
import { Redirect } from 'react-router-dom';
import { hot } from 'react-hot-loader/root'; // eslint-disable-line import/no-extraneous-dependencies
import { inject, observer } from 'mobx-react';

import { routes } from 'routes';

const Authentication = loadable(() => import('components/authentication'));

const AuthenticationScene = inject('Account')(
  observer(({ Account, location }) => {
    const isConfirmRoute = location.pathname === routes.signupConfirmation;
    return Account.isAuthorized && !isConfirmRoute ? (
      <Redirect to={routes.dashboard} />
    ) : (
      <Authentication />
    );
  }),
);

export default hot(AuthenticationScene);
