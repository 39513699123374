import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/notification';

class NotificationsAPI {
  static resend = code => Request.POST(`${ENDPOINT}/resend`, { key: code });

  static printPoster = () => Request.PUT('/notification/printed-poster');
}

export default NotificationsAPI;
