const getTimeAsNumberOfMinutes = time => {
  let timeParts = time.split(':');

  return parseInt(timeParts[0] * 60 + timeParts[1]);
};

export const validateDay = day => {
  let dayTmpEnd = getTimeAsNumberOfMinutes(day.slots[0].end);
  let dayTmpStart = getTimeAsNumberOfMinutes(day.slots[0].start);

  return day.slots.some((slot, i) => {
    let errorEnd = dayTmpEnd > getTimeAsNumberOfMinutes(slot.end);

    let errorStart = getTimeAsNumberOfMinutes(slot.start) < dayTmpStart;
    let errorPrevStart =
      i === 0
        ? false
        : getTimeAsNumberOfMinutes(slot.start) < getTimeAsNumberOfMinutes(day.slots[i - 1].end);

    dayTmpEnd = getTimeAsNumberOfMinutes(slot.end);
    dayTmpStart = getTimeAsNumberOfMinutes(slot.start);
    if (errorEnd === true) {
      return true;
    }
    if (errorPrevStart === true) {
      return true;
    }
    if (errorStart === true) {
      return true;
    }
    return false;
  });
};
