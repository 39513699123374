import { VOUCHER_STATUSES } from '../../../../utils/constants';
import { getLocalizedString } from '../../../../i18n/utils';

const { ACTIVE, INACTIVE, EXPIRED } = VOUCHER_STATUSES;

const NAME = 'name';
const USED = 'used';
const VOUCHERTYPE = 'voucherType';
const ISSUES = 'issues';
const ENDDATE = 'endDate';
const VOUCHERCODE = 'voucherCode';
const STARTDATE = 'startDate';
const FACEVALUELEFTOVER = 'faceValueLeftover';
const ISSUEDBYNAME = 'issuedByName';
const STATUS = 'status';
const TARGET_MERCHANT_ID = 'targetMerchantId';
const CUSTOMER = 'customer';
const TARGET_SERVICE_TYPE = 'targetServiceType';
// const STATUS = 'status';

export const LOYALTY_TABLE_FORMAT = new Map([
  [NAME, { id: 'vouchers.data.name', defaultMessage: 'Name' }],
  [USED, { id: 'vouchers.data.remainingAmount', defaultMessage: 'Remaining amount' }],
  [VOUCHERTYPE, { id: 'vouchers.data.type', defaultMessage: 'Type' }],
  [ISSUES, { id: 'vouchers.data.used', defaultMessage: 'Used / Issued' }],
  [ENDDATE, { id: 'vouchers.data.regDate', defaultMessage: 'Exp. date' }],
  [STATUS, { id: 'vouchers.data.issue.status', defaultMessage: 'Status' }],
  [TARGET_MERCHANT_ID, { id: 'navigation.restaurant', defaultMessage: 'Restaurant' }],
  [
    TARGET_SERVICE_TYPE,
    { id: 'vouchers.editor.targetServiceType.title', defaultMessage: 'Target service type' },
  ],
]);

export const LOYALTY_LIST_FORMAT = {
  headerProperty: [NAME, { id: 'vouchers.data.name', defaultMessage: 'Name' }],
  properties: new Map([
    [USED, { id: 'vouchers.data.remainingAmount', defaultMessage: 'Remaining amount' }],
    [VOUCHERTYPE, { id: 'vouchers.data.type', defaultMessage: 'Type' }],
    [ISSUES, { id: 'vouchers.data.used', defaultMessage: 'Used / Issued' }],
    [ENDDATE, { id: 'vouchers.data.regDate', defaultMessage: 'Exp. date' }],
    [STATUS, { id: 'vouchers.data.issue.status', defaultMessage: 'Status' }],
    [TARGET_MERCHANT_ID, { id: 'navigation.restaurant', defaultMessage: 'Restaurant' }],
    [
      TARGET_SERVICE_TYPE,
      { id: 'vouchers.editor.targetServiceType.title', defaultMessage: 'Target service type' },
    ],
  ]),
};

export const LOYALTY_TABLE_FORMAT_ISSUES = new Map([
  [VOUCHERCODE, { id: '#', defaultMessage: '#' }],
  [STARTDATE, { id: 'vouchers.data.issue.date', defaultMessage: 'Issued date' }],
  [ENDDATE, { id: 'vouchers.data.regDate', defaultMessage: 'Exp. date' }],
  [FACEVALUELEFTOVER, { id: 'vouchers.data.remainingAmount', defaultMessage: 'Remaining amount' }],
  [ISSUEDBYNAME, { id: 'vouchers.data.issue.by', defaultMessage: 'Issued by' }],
  [CUSTOMER, { id: 'create.entity.customer', defaultMessage: 'Issued by' }],
  // [ORDERUID, { id: 'vouchers.data.issue.order', defaultMessage: 'Order' }],
  [STATUS, { id: 'vouchers.data.issue.status', defaultMessage: 'Status' }],
  [TARGET_MERCHANT_ID, { id: 'navigation.restaurant', defaultMessage: 'Restaurant' }],
]);

export const LOYALTY_LIST_FORMAT_ISSUES = {
  headerProperty: [VOUCHERCODE, { id: '#', defaultMessage: '#' }],
  properties: new Map([
    [STARTDATE, { id: 'vouchers.data.issue.date', defaultMessage: 'Issued date' }],
    [ENDDATE, { id: 'vouchers.data.regDate', defaultMessage: 'Exp. date' }],
    [
      FACEVALUELEFTOVER,
      { id: 'vouchers.data.remainingAmount', defaultMessage: 'Remaining amount' },
    ],
    [ISSUEDBYNAME, { id: 'vouchers.data.issue.by', defaultMessage: 'Issued by' }],
    [CUSTOMER, { id: 'create.entity.customer', defaultMessage: 'Issued by' }],
    // [ORDERUID, { id: 'vouchers.data.issue.order', defaultMessage: 'Order' }],
    [STATUS, { id: 'vouchers.data.issue.status', defaultMessage: 'Status' }],
    [TARGET_MERCHANT_ID, { id: 'navigation.restaurant', defaultMessage: 'Restaurant' }],
  ]),
};

const statusesOptions = [ACTIVE, INACTIVE, EXPIRED].map(i => ({
  key: i,
  value: i,
  text: getLocalizedString(`vouchers.statuses.${i}`),
}));

export const STATUSES_OPTIONS = [
  { key: 'all', value: 'all', text: getLocalizedString('vouchers.editor.all') },
  ...statusesOptions,
];
