import { action, runInAction, observable, computed } from 'mobx';
import KitchenAPI from 'api/kitchens';

class KitchensStore {
  @observable list = [];
  @observable loading = false;
  @observable сonnectsStorage = [null];

  @computed get availableKitchens() {
    return this.list.map(({ id, name }) => ({
      value: id,
      text: name,
    }));
  }

  @action
  startFetch = () => {
    runInAction(() => {
      this.loading = true;
    });
  };

  endFetch = () => {
    runInAction(() => {
      this.loading = false;
    });
  };

  @action
  fetchListPage = async () => {
    this.startFetch();

    try {
      const { items } = await KitchenAPI.getKitchens();
      runInAction(() => {
        this.list = items;
      });
    } catch (e) {
      throw Error(e.message);
    } finally {
      this.endFetch();
    }
  };

  @action
  fetchConnectStorage = async id => {
    let kitchenIds = {};
    if (id) {
      kitchenIds = { ids: [id] };
    } else {
      kitchenIds = { ids: this.list.map(i => i.id).filter(i => i !== undefined) };
    }
    try {
      const { kitchenStorageMap } = await KitchenAPI.fetchStorages(kitchenIds);

      runInAction(() => {
        this.сonnectsStorage = kitchenStorageMap;
      });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  removeKitchenFromList = async kitchenId => {
    const duplicatedList = [...this.list];

    await runInAction(() => {
      this.list = duplicatedList.filter(item => item.id !== kitchenId);
    });
  };

  @action
  deleteKitchen = async kitchenId => {
    try {
      this.startFetch();
      await KitchenAPI.delete(kitchenId);
      await this.removeKitchenFromList(kitchenId);
    } catch (e) {
      throw new Error(e.message);
    } finally {
      this.endFetch();
    }
  };
}

export default KitchensStore;
