import { toObject } from 'utils/common';

import { DAYS_OF_THE_WEEK, RULER_SCALE_VALUES } from '../merchants/constants';

const OPEN = {
  slots: [{ start: RULER_SCALE_VALUES[0], end: RULER_SCALE_VALUES[RULER_SCALE_VALUES.length - 1] }],
  workingDay: true,
};

const DEFAULT_SCHEDULE = toObject(DAYS_OF_THE_WEEK, OPEN);

export const EMPTY_MENU = {
  id: 0,
  merchantGroupId: 0,
  name: '',
  categories: [],
  merchantProxies: [],
  status: '',
  updatedTimestamp: 0,
  availableHours: { daysOfWeek: DEFAULT_SCHEDULE },
};

export const MENU_VALIDATION_SCHEMA_SINGLE = {
  name: { presence: { allowEmpty: false } },
  // categories: { presence: { allowEmpty: false } },
};

export const MENU_VALIDATION_SCHEMA = {
  ...MENU_VALIDATION_SCHEMA_SINGLE,
  merchantProxies: { presence: { allowEmpty: false } },
};
