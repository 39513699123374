import styled from 'styled-components';

export const PopupActions = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    margin-bottom: -1rem !important;
  }

  & > .button {
    flex-basis: 50%;
    border-radius: 0;
  }

  .ui.button {
    margin: 0px;
    border-radius: 0px 0px 0px 10px;
    border: 1px solid #e0e1e2;
    font-weight: 400;
  }

  .ui.primary {
    border-radius: 0px 0px 10px 0px;
    font-weight: 400;
    border: 0;
  }
`;

export const Content = styled.div`
  padding: 16px;

  @media (max-width: 767px) {
    margin-top: -1rem !important;
  }
`;

export const Headline = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 1.22;
  text-align: center;
  margin-bottom: 12px;
`;

export const Text = styled.p`
  font-family: Roboto, san-serif;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: normal;
`;

export const Icon = styled.img`
  display: block;
  margin: 0 auto 24px;
`;
