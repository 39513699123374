import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getAcceptLanguageHeader, getLocalizedString } from 'i18n/utils';

import { routes } from 'routes';

import {
  BAD_REQUEST,
  FORBIDDEN,
  UNAUTHORIZED,
  SUCCESS_CODE,
  INACTIVE_USER_TEXT,
  SERVER_URL,
  APIMethods,
} from './constants';

let instance = null;

const USER_ENDPOINT = '/user';

export default class APIConnector {
  constructor() {
    if (instance) {
      throw new Error('Cannot create APIConnector instance');
    }

    this.isTokenRefreshing = false;
    this.requestStack = [];
    this.authorizationToken = localStorage.getItem('authToken') || null;
  }

  static get instance() {
    if (!instance) {
      instance = new APIConnector();
    }

    return instance;
  }

  updateTokens = (token, refreshToken) => {
    this.token = token;
    this.refreshToken = refreshToken;
  };

  sendRefreshedRequest = async (requestData, body) => {
    const { url, method } = requestData;

    const request = this.prepareRequest(url.replace(SERVER_URL, ''), method, body);
    const { data, status } = await this.makeRequest(request, body);

    return {
      data,
      status,
    };
  };

  showSessionExpiredNotification = () => {
    const message = getLocalizedString('auth.session.expired');
    alert(message);
    throw new Error(message);
  };

  refreshTokenOnly = async (request, body) => {
    const refreshTokenUrl = `${SERVER_URL}${USER_ENDPOINT}/refresh-token`;

    if (this.isTokenRefreshing) {
      return new Promise(resolve => {
        this.requestStack.push({ request, callback: resolve, body });
      });
    }

    this.isTokenRefreshing = true;

    return await fetch(refreshTokenUrl, {
      method: 'POST',
      body: JSON.stringify({
        token: this.authorizationToken,
        refreshToken: this.refreshToken,
      }),
      headers: {
        Accept: 'application/json',
        'Accept-Language': getAcceptLanguageHeader(),
        'Content-Type': 'application/json',
        token: this.authorizationToken,
      },
    })
      .then(response => response.json())
      .then(({ response }) => {
        if (
          response.code === FORBIDDEN ||
          response.code === BAD_REQUEST ||
          response.code === UNAUTHORIZED
        ) {
          this.redirectUnauthorized();
          this.showSessionExpiredNotification();
        } else {
          const { token, refreshToken } = response.data;
          this.updateTokens(token, refreshToken);

          const result = this.sendRefreshedRequest(request, body);

          this.isTokenRefreshing = false;

          this.requestStack.forEach(({ request, body, callback }) => {
            return this.sendRefreshedRequest(request, body).then(callback);
          });

          return result;
        }
      });
  };

  set token(token) {
    if (token) {
      localStorage.setItem('authToken', token);

      this.authorizationToken = token;
    } else {
      localStorage.removeItem('authToken');

      this.authorizationToken = null;
    }
  }

  set refreshToken(token) {
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken() {
    return localStorage.getItem('refreshToken') || null;
  }

  prepareRequest = (url, method, data, options) => {
    let headers = {
      Accept: 'application/json',
      'Accept-Language': getAcceptLanguageHeader(),
      'Content-Type': 'application/json',
      token: this.authorizationToken,
    };

    if (options && options.headers) {
      headers = {
        ...headers,
        ...options.headers,
      };
    }
    const requestOptions = {
      method: method,
      headers,
    };

    if (method !== APIMethods.GET) {
      requestOptions.body = JSON.stringify(data);
    }

    return new Request(SERVER_URL + url, requestOptions);
  };

  makeRequest = async request => {
    try {
      window.startRequest();
      const response = await fetch(request);
      const data = await response.json();

      window.endRequest();
      return {
        data,
        status: response.status,
      };
    } catch (error) {
      window.endRequest();
      throw new Error(getLocalizedString('global.errors.503'));
    }
  };

  redirectUnauthorized = () => {
    const { pathname } = location;
    if (pathname !== routes.login) {
      this.token = null;
      location.href = routes.login;
    }
  };

  sendRequest = async (request, body, options = {}) => {
    const { errorHandler } = options;
    const { data: responseData, status } = await this.makeRequest(request, body);
    const { code, errors, data } = responseData.response;

    if (status > 299 && errorHandler) {
      return errorHandler(responseData.response);
    }

    if (status === 401 && code === UNAUTHORIZED) {
      const { data } = await this.refreshTokenOnly(request, body);
      return data.response.data;
    }

    if (code === FORBIDDEN) {
      this.redirectUnauthorized();
      throw new Error(
        (
          <FormattedMessage
            id="unauthorized"
            defaultMessage="Unauthorized. Access to this resource is denied."
          />
        ),
      );
    }

    if (code !== SUCCESS_CODE) {
      if (errors) {
        errors.forEach(error => {
          const isBlockedError = error.message.toString().includes(INACTIVE_USER_TEXT);
          if (isBlockedError) {
            this.redirectUnauthorized();
          }

          throw new Error(error.message);
        });
      }
    } else {
      return data;
    }
  };

  GET = (url, options) => this.sendRequest(this.prepareRequest(url, APIMethods.GET), null, options);

  POST = (url, data, options) =>
    this.sendRequest(this.prepareRequest(url, APIMethods.POST, data, options), data, options);

  PUT = (url, data, options) =>
    this.sendRequest(this.prepareRequest(url, APIMethods.PUT, data, options), data, options);

  DELETE = (url, data, options) =>
    this.sendRequest(this.prepareRequest(url, APIMethods.DELETE, data), data, options);

  POST_FILE = (url, data) => {
    const requestOptions = {
      method: APIMethods.POST,
      body: data,
      headers: new Headers({
        token: this.authorizationToken,
      }),
    };
    const request = new Request(SERVER_URL + url, requestOptions);

    return this.sendRequest(request, data);
  };
}
