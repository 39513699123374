import { observable, action, runInAction, computed } from 'mobx';
import moment from 'moment';
import { demicalFormat } from 'utils/demical-format';
import difference from 'lodash.difference';
import CustomersAPI from 'api/loyalty/customers';
import without from 'lodash.without';
import get from 'lodash.get';
import uniq from 'lodash/uniq';

const NAME = 'name';
const BIRTH_DATE = 'birthDate';
const PROGRAM_NAME = 'programName';
const PHONE_NUMBER = 'phoneNumber';
const LOYALTY_POINTS = 'loyaltyPoints';
const MONEY_SPENT = 'moneySpent';
const LAST_DATE_OF_USE = 'lastDateOfUse';
const TAGS = 'tags';
const REGISTRATION_DATE = 'registrationDate';

export const customerTableFormat = (points, totalSales) => {
  return new Map([
    [
      PHONE_NUMBER,
      {
        id: 'customers.data.phoneNumber',
        defaultMessage: 'Phone',
        property: 'PHONE_NUMBER',
        sortable: true,
      },
    ],
    [
      NAME,
      {
        id: 'customers.data.name',
        defaultMessage: 'Name',
        property: 'NAME',
        sortable: true,
      },
    ],
    [
      LAST_DATE_OF_USE,
      {
        id: 'customers.data.lastDateOfUse',
        defaultMessage: 'lastDateOfUse',
        property: 'LAST_DATE_OF_USE',
        sortable: true,
      },
    ],
    [
      REGISTRATION_DATE,
      {
        id: 'customers.data.registrationDate',
        defaultMessage: 'Registration date',
        property: 'REGISTRATION_DATE',
        sortable: true,
      },
    ],
    [
      BIRTH_DATE,
      {
        id: 'customers.data.birthDate',
        defaultMessage: 'Birth date',
        property: 'BIRTH_DATE',
        sortable: true,
      },
    ],
    [
      PROGRAM_NAME,
      {
        id: 'loyalty.customers.data.programName',
        defaultMessage: 'Program Name',
        property: 'PROGRAM_NAME',
        sortable: true,
      },
    ],
    [TAGS, { id: 'loyalty.customers.filters.name.TAG', defaultMessage: 'Tags' }],
    [
      LOYALTY_POINTS,
      {
        id: 'customers.data.loyaltyPoints',
        defaultMessage: 'Points',
        property: 'LOYALTY_POINTS',
        sortable: true,
        totalValue: demicalFormat(points),
      },
    ],
    [
      MONEY_SPENT,
      {
        id: 'loyalty.customers.data.moneySpent',
        defaultMessage: 'Total sales',
        property: 'MONEY_SPENT',
        sortable: true,
        totalValue: demicalFormat(totalSales),
      },
    ],
  ]);
};

export const CUSTOMERS_LIST_FORMAT = {
  headerProperty: [PHONE_NUMBER, { id: 'customers.data.phoneNumber', defaultMessage: 'Phone' }],
  properties: new Map([
    [NAME, { id: 'customers.data.name', defaultMessage: 'Name', placeholder: ' - ' }],
    ['lastDateOfUse', { id: 'customers.data.lastDateOfUse', defaultMessage: 'lastDateOfUse' }],
    [
      REGISTRATION_DATE,
      {
        id: 'customers.data.registrationDate',
        defaultMessage: 'Registration date',
      },
    ],
    [BIRTH_DATE, { id: 'customers.data.birthDate', defaultMessage: 'Birth date' }],
    [PROGRAM_NAME, { id: 'loyalty.customers.data.programName', defaultMessage: 'Program Name' }],
    [TAGS, { id: 'loyalty.customers.filters.name.TAG', defaultMessage: 'Tags' }],
    [LOYALTY_POINTS, { id: 'customers.data.loyaltyPoints', defaultMessage: 'Points' }],
    [MONEY_SPENT, { id: 'loyalty.customers.data.moneySpent', defaultMessage: 'Total sales' }],
  ]),
};

const initAssignTags = {
  customerIds: [],
  forAll: false,
  searchFields: [],
  tags: [],
  action: 'ADD',
};
const initAssignProgram = {
  customerIds: [],
  forAll: false,
  searchFields: [],
  programId: '',
};

class Customers {
  constructor(Programs) {
    this.programs = Programs;
  }

  listFormat = CUSTOMERS_LIST_FORMAT;

  @observable list = [];
  @observable searchValue = '';
  @observable fileLoading = '';
  @observable currentPage = -1;
  @observable totalPages = 0;
  @observable hasMore = true;
  @observable downloadCustomersLink = '';
  @observable availableFilters = [];
  @observable savedFilters = [];
  @observable totalElements = 0;
  @observable totalMoneySpent = '';
  @observable totalCashback = '';
  @observable loading = true;
  @observable paginateOptionst = { page: 0, size: 20 };
  @observable filters = [];
  @observable tags = [];
  @observable assignTags = initAssignTags;
  @observable assignProgram = initAssignProgram;

  @action
  fetchTags = async () => {
    const { items } = await CustomersAPI.getTagsList();

    runInAction(() => {
      this.tags = items;
    });
  };

  @action
  setCustomerTags = (ids, tags) => {
    if (tags) {
      this.assignTags.tags = uniq([...tags.filter(i => i)]);
    } else {
      if (ids.length === 1 && ids[0] === 'all') {
        const findCustomers = this.list
          .reduce((acc, item) => [...acc, ...item.tags], [])
          .filter(i => i);
        runInAction(() => {
          this.assignTags.tags = uniq([...this.assignTags.tags, ...findCustomers]);
        });
      } else if (ids.length > 0) {
        const findCustomers = ids
          .reduce(
            (acc, customerId) => [
              ...acc,
              ...get(
                this.list.find(item => item.id === customerId),
                'tags',
              ),
            ],
            [],
          )
          .filter(i => i);
        runInAction(() => {
          this.assignTags.tags = uniq(findCustomers);
        });
      } else {
        runInAction(() => {
          this.assignTags.tags = [];
        });
      }
    }
  };

  @action
  addTag = async (_tag, checkedList, filters) => {
    let tag = _tag;
    const haveThisTag = this.tags.find(item => item.name === tag);
    if (!haveThisTag) {
      await CustomersAPI.createTag({ name: _tag });
      await this.fetchTags();
    }

    runInAction(() => {
      const haveTag = this.assignTags.tags.find(item => item === tag);
      if (!haveTag) {
        const forAll = checkedList[0] === 'all';
        if (forAll) {
          this.assignTags.customerIds = [];
          this.assignTags.forAll = true;
        } else {
          this.assignTags.customerIds = [...checkedList];
          this.assignTags.forAll = false;
        }
        this.assignTags.tags.push(tag);
        this.assignTags.searchFields = filters;

        if (forAll || checkedList.length > 0) {
          this.setTagOnCustomers('ADD', tag);
          this.changeCustometTags(tag, checkedList, 'ADD');
        }
      }
    });
  };

  @action
  removeTag = (tag, checkedList, filters) => {
    runInAction(() => {
      const forAll = checkedList[0] === 'all';
      if (forAll) {
        this.assignTags.customerIds = [];
        this.assignTags.forAll = true;
      } else {
        this.assignTags.customerIds = [...checkedList];
        this.assignTags.forAll = false;
      }
      this.assignTags.tags = without(this.assignTags.tags, tag);
      this.assignTags.searchFields = filters;

      if (forAll || checkedList.length > 0) {
        this.setTagOnCustomers('REMOVE', tag);
        this.changeCustometTags(tag, checkedList, 'REMOVE');
      }
    });
  };

  @action
  changeCustometTags = async (tag, checkedList, action) => {
    if (checkedList.length === 1 && checkedList[0] === 'all') {
      runInAction(() => {
        this.list = this.list.map(item => {
          return {
            ...item,
            tags: action === 'ADD' ? [...item.tags, tag] : without([...item.tags], tag),
          };
        });
      });
    } else if (checkedList.length > 0) {
      runInAction(() => {
        this.list = this.list.map(item => {
          const findItem = checkedList.find(i => i === item.id);
          if (findItem) {
            return {
              ...item,
              tags: action === 'ADD' ? [...item.tags, tag] : without([...item.tags], tag),
            };
          } else {
            return item;
          }
        });
      });
    }
  };

  @action
  changeCustomerProgram = (programId, checkedList) => {
    if (checkedList.length === 1 && checkedList[0] === 'all') {
      runInAction(() => {
        this.list = this.list.map(item => {
          return {
            ...item,
            loyaltyProgramId: programId,
          };
        });
      });
    } else if (checkedList.length > 0) {
      runInAction(() => {
        this.list = this.list.map(item => {
          const findItem = checkedList.find(i => i === item.id);
          if (findItem) {
            return {
              ...item,
              loyaltyProgramId: programId,
            };
          } else {
            return item;
          }
        });
      });
    }
  };

  @action
  setTagOnCustomers = async (action, tag) => {
    const data = {
      ...this.assignTags,
      action,
      tags: [tag],
    };

    try {
      await CustomersAPI.setTagOnCustomers(data);
      await this.fetchTags();
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  setProgramOnCustomers = async programId => {
    const data = {
      ...this.assignProgram,
      programId,
    };

    try {
      await CustomersAPI.setProgramOnCustomers(data);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  changeProgram = async (id, checkedList, filters) => {
    const forAll = checkedList[0] === 'all';

    runInAction(() => {
      const haveProgram = this.assignProgram.programId.length > 0;
      if (!haveProgram) {
        if (forAll) {
          this.assignProgram.customerIds = [];
          this.assignProgram.forAll = true;
        } else {
          this.assignProgram.customerIds = [...checkedList];
          this.assignProgram.forAll = false;
        }
        this.assignProgram.programId = id;
        this.assignProgram.searchFields = filters;
      }
    });

    if (forAll || checkedList.length > 0) {
      this.changeCustomerProgram(id, checkedList);
      await this.setProgramOnCustomers(id);
    }
  };

  @action
  deleteTag = async _tag => {
    let tag = _tag;

    const thisTag = this.tags.find(item => item.name === tag);

    if (thisTag) {
      await CustomersAPI.deleteTag({ id: thisTag.id });
      await this.fetchTags();
    }
  };

  @computed get availableTags() {
    return difference(this.tags, this.assignTags.tags).map(tag => ({
      text: tag.name || tag,
      value: tag.name || tag,
    }));
  }

  @computed get data() {
    return this.list.map(customer => {
      const program = this.getLoyaltyProgramById(customer.loyaltyProgramId);

      const programName = program ? program.name : '';

      return {
        programName,
        id: customer.id,
        phoneNumber: `${customer.phoneNumber}`,
        name: `${customer.firstName} ${customer.lastName}`,
        fullName: `${customer.firstName} ${customer.lastName}`,
        loyaltyPoints: customer.loyaltyPoints > 0 ? customer.loyaltyPoints : '-',
        birthDate:
          customer.yearOfBirth === 0
            ? 'N/D'
            : moment()
                .year(customer.yearOfBirth)
                .month(customer.monthOfBirth)
                .date(customer.dayOfBirth)
                .format('DD-MM-YYYY'),
        lastDateOfUse: customer.lastDateOfUse
          ? moment(customer.lastDateOfUse).format('DD-MM-YYYY')
          : 'N/D',
        registrationDate: customer.registrationDate
          ? moment(customer.registrationDate).format('DD-MM-YYYY')
          : 'N/D',
        moneySpent: customer.moneySpent,
        tags: customer.tags.filter(i => i),
        ordersCount: customer.ordersCount,
      };
    });
  }

  @action
  changePaginateOptionst = value => {
    runInAction(() => {
      this.paginateOptionst = value;
    });
  };

  @action
  setFilters = value => {
    runInAction(() => {
      this.filters = value;
    });
  };

  @action
  uploadFile = async file => {
    try {
      runInAction(() => {
        this.fileLoading = true;
      });
      await CustomersAPI.uploadCsv(file);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      runInAction(() => {
        this.fileLoading = false;
      });
    }
  };

  @action
  getTableFormat = (points, totalSales) => {
    return customerTableFormat(points, totalSales);
  };

  @action
  getDownloadLink = async params => {
    try {
      const response = await CustomersAPI.exportCsv(params);

      runInAction(() => {
        this.downloadCustomersLink = '';
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getLoyaltyProgramById = programId => this.programs.list.find(program => program.id === programId);

  @action
  fetchTablePage = async (filters, sortingFieldName, sortingDirection) => {
    runInAction(() => {
      this.loading = true;
    });

    const params = {
      size: this.paginateOptionst.size,
      sortingFieldName: sortingFieldName,
      sortingDirection: sortingDirection,
    };
    const {
      items,
      last,
      totalPages,
      number,
      totalElements,
      totalMoneySpent,
      totalCashback,
    } = await CustomersAPI.search(this.paginateOptionst.page, params, filters);

    runInAction(() => {
      this.list = items;
      this.currentPage = number;
      this.totalPages = totalPages;
      this.hasMore = !last;
      this.totalElements = totalElements;
      this.totalMoneySpent = totalMoneySpent;
      this.totalCashback = totalCashback;
      this.loading = false;
    });
  };

  @action
  fetchListPage = async filters => {
    runInAction(() => {
      this.loading = true;
    });
    const params = {
      size: this.paginateOptionst.size,
    };
    const { items, last, totalPages, number } = await CustomersAPI.search(
      this.paginateOptionst.page,
      params,
      filters,
    );

    runInAction(() => {
      if (this.paginateOptionst.page === 0) {
        this.list = items;
      } else {
        this.list = [...this.list, ...items];
      }

      this.currentPage = number;
      this.totalPages = totalPages;
      this.hasMore = !last;
      this.loading = false;
    });
  };

  @action
  getAvailableFilters = async () => {
    const { items } = await CustomersAPI.getAvailableFilters();
    runInAction(() => {
      this.availableFilters = items;
    });
  };

  @action
  getSavedFilters = async () => {
    const { items } = await CustomersAPI.getSavedFilters();
    runInAction(() => {
      this.savedFilters = items;
    });
  };

  @action
  removeSavedFilters = async id => {
    await CustomersAPI.removeSavedFilters({ id });
    runInAction(() => {
      this.savedFilters = this.savedFilters.filter(filter => filter.id !== id);
    });
  };

  @action
  searchCustomers = async () => {
    const { page, size } = this.paginateOptionst;
    try {
      const { items, last, totalPages, number } = await CustomersAPI.search(page, size);

      runInAction(() => {
        if (page === 0) {
          this.list = items;
        } else {
          this.list = [...this.list, ...items];
        }

        this.currentPage = number;
        this.totalPages = totalPages;
        this.hasMore = !last;
      });
    } catch {}
  };

  findCustomer = customerId => this.list.find(customer => customer.id === customerId);

  findCustomerIndex = customerId => this.list.findIndex(customer => customer.id === customerId);

  @action
  create = async customer => {
    const createdCustomer = await CustomersAPI.create(customer);

    runInAction(() => {
      this.list.push(createdCustomer);
    });
  };

  @action
  update = async customer => {
    const updatedCustomer = await CustomersAPI.update(customer);

    runInAction(() => {
      const index = this.findCustomerIndex(customer.id);

      this.list = [...this.list.slice(0, index), updatedCustomer, ...this.list.slice(index + 1)];
    });
  };

  @action
  fetchCustomerByPhone = async phoneNumber => {
    return await CustomersAPI.fetchCustomer(phoneNumber);
  };

  @action
  fetchCustomer = customerId => {
    const { phoneNumber } = this.findCustomer(customerId);

    return this.fetchCustomerByPhone(phoneNumber);
  };

  @action
  getCustomerById = async customerId => {
    try {
      return await CustomersAPI.getCustomerById(customerId);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  remove = async customerId => {
    try {
      await CustomersAPI.remove(customerId);

      runInAction(() => {
        this.list = this.list.filter(customer => customer.id !== customerId);
      });
    } catch {}
  };
}

export default Customers;
