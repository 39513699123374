import momentTimezone from 'moment-timezone';
import { toObject } from 'utils/common';

export const SUNDAY = 'SUNDAY';
export const MONDAY = 'MONDAY';
export const TUESDAY = 'TUESDAY';
export const WEDNESDAY = 'WEDNESDAY';
export const THURSDAY = 'THURSDAY';
export const FRIDAY = 'FRIDAY';
export const SATURDAY = 'SATURDAY';

export const RULER_SCALE_VALUES = ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00', '23:59'];

export const ROUNDLING_MODES = {
  NO_ROUNDING: 'NO_ROUNDING',
  ROUND_ANYWHERE: 'ROUND_ANYWHERE',
  ROUND_UP: 'ROUND_UP',
  ROUND_DOWN: 'ROUND_DOWN',
};

export const DAYS_OF_THE_WEEK = [SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY];

export const ORDER_MANAGEMENT_TYPE = {
  FULL: 'FULL',
  SIMPLE: 'SIMPLIFIED',
  NONE: 'NONE',
};

const OPEN = {
  start: RULER_SCALE_VALUES[0],
  end: RULER_SCALE_VALUES[RULER_SCALE_VALUES.length - 1],
  workingDay: true,
};

const CLOSED = {
  start: RULER_SCALE_VALUES[0],
  end: RULER_SCALE_VALUES[RULER_SCALE_VALUES.length - 1],
  workingDay: false,
};

export const DEFAULT_SCHEDULE = toObject(DAYS_OF_THE_WEEK, OPEN);
export const CLOSED_SCHEDULE = toObject(DAYS_OF_THE_WEEK, CLOSED);

export const EMPTY_MERCHANT = {
  name: '',
  mobile: '',
  description: '',
  code: '',
  projectCode: '',
  address: '',
  countryCode: 'US',
  currency: '',
  serviceFee: '0',
  printPhoneNumber: false,
  logo: 'https://orty-public.sgp1.digitaloceanspaces.com/placeholder/merchant_logo_placeholder.png',
  merchantConfiguration: {
    discountShortcuts: [],
    discountsEnabled: true,
    printDocket: true,
    printChit: true,
    locale: 'en',
    roundingMode: ROUNDLING_MODES.NO_ROUNDING,
    qsr: true,
    setMealPrintout: true,
    payOnMPos: true,
    blindHandover: false,
    turnoverTax: '0',
    taxInclusive: false,
    externalSystemIntegration: '',
    receiptClosingStatement: '',
    orderManagement: 'NONE',
    businessDayOffsetMap: { daysOfWeek: DEFAULT_SCHEDULE },
    timezone: momentTimezone.tz.guess(),
    slaTakeawayTimeLimit: '',
    slaDeliveryTimeLimit: '',
    printDeliverySlipAtKitchen: false,
    printMerchantGroupNameOnSlip: false,
    printCashierNameOnSlip: false,
    printTxIdOnSlip: false,
    printDiscountsEachProduct: true,
    shakeToPayRestoId: '',
    shakeToPaySecretKey: '',
    yreportSubmissionStartTime: '00:00',
  },
  paymentMethods: [],
};

export const VALIDATION_SCHEMA = {
  name: { presence: { allowEmpty: false } },
  mobile: { presence: { allowEmpty: false } },
};
