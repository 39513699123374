import PostsAPI from 'api/posts';
import { action, observable, runInAction } from 'mobx/lib/mobx';
import { arrayMove } from 'react-sortable-hoc';

const NAME = 'name';
const CATEGORY = 'beforeCategoryNames';

export const BLOCKS_TABLE_FORMAT = new Map([
  [NAME, { id: 'vouchers.data.name', defaultMessage: 'Name' }],
  [CATEGORY, { id: 'products.data.categories', defaultMessage: 'Category' }],
]);

export const BLOCKS_LIST_FORMAT = {
  headerProperty: [NAME, { id: 'vouchers.data.name', defaultMessage: 'Name' }],
  properties: new Map([]),
};

class PostsStore {
  listFormat = BLOCKS_LIST_FORMAT;
  tableFormat = BLOCKS_TABLE_FORMAT;
  @observable data = [];
  @observable loading = false;

  @action
  fetchData = async () => {
    runInAction(() => {
      this.loading = true;
    });

    const { items } = await PostsAPI.fetchPosts();
    runInAction(() => {
      this.loading = false;
      this.data = items;
    });
  };

  @action
  onDragEnd = ({ oldIndex, newIndex }) => {
    const sortedList = arrayMove(this.data, oldIndex, newIndex);
    const reorderedMenus = async listAfterDrag => {
      try {
        const result = listAfterDrag.map((category, index) => {
          return {
            itemId: category.id,
            orderNumber: ++index,
          };
        });

        await PostsAPI.updatePostsOrder(result);
      } catch (error) {
        return new Error(error);
      }
    };

    runInAction(() => {
      this.data = sortedList;
      reorderedMenus(sortedList);
    });
  };
}

export default PostsStore;
