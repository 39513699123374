import {action, computed, observable} from 'mobx';
import without from 'lodash.without';
import difference from 'lodash.difference';
import validate from 'validate.js';

const VALIDATION_SCHEMA = {
  tags: {presence: {allowEmpty: false}},
  buyQuantity: {
    presence: {
      allowEmpty: false
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
    },
  },
  increaseQuantity: {
    presence: {
      allowEmpty: false
    },
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
    },
  },
};

class TwoPlusOne {
  constructor(PromotionEditor, rules) {
    this.editor = PromotionEditor;

    if (rules) {
      this.rules = rules;
    }
  }

  @observable rules = {
    buyQuantity: 0,
    tags: [],
    increaseQuantity: 0,
  };

  @action
  changeQuantity = (quantity, volume) => {
    this.validateValue(quantity, volume);
    this.rules[quantity] = volume;
  };

  @action
  addTag = (tag) => {
    this.rules.tags.push(tag);
    this.validateValue('tags', this.rules.tags);
  };

  @action
  removeTag = (tag) => {
    this.rules.tags = without(this.rules.tags, tag);
    this.validateValue('tags', this.rules.tags);
  };

  @computed get availableTags() {
    return difference(this.editor.tags, this.rules.tags).map((tag) => ({
      text: tag,
      value: tag,
    }));
  }

  @observable validationErrors = {};

  @action
  validateValue = (key, value) => {
    const validator = VALIDATION_SCHEMA[key];

    if (validator) {
      this.validationErrors[key] = validate.single(value, validator);
    }
  };

  @action
  validateRules = () => {
    this.validationErrors = validate(this.rules, VALIDATION_SCHEMA) || {};
  };

  @computed get isValid() {
    const validationErrors = validate(this.rules, VALIDATION_SCHEMA);

    return !validationErrors;
  }
}

export default TwoPlusOne;