import { action, observable, runInAction, computed } from 'mobx';
import validate from 'validate.js';
import LegalEntityAPI from 'api/legal-entity';
import history, { routes } from 'routes';
import { NEW_ROUTE_PREFIX, CLASSIC_RPO, PROGRAM_RPO, VCHASNO_RPO } from 'utils/constants';

const INITIAL_FISCAL_PRINTER = {
  brand: '',
  connectionType: 'Network',
  ipAddress: '',
  name: '',
};

const INITIAL_CHECKBOX_CREDENTIALS = {
  checkboxLogin: '',
  checkboxPassword: '',
  checkboxLicenceKey: '',
  checkboxEmail: '',
};

const INITIAL_VCHASNO_CREDENTIALS = {
  login: '',
  password: '',
};

const initLegalEntity = () => ({
  address: '',
  contacts: '',
  fiscalPrinterSetupDto: INITIAL_FISCAL_PRINTER,
  checkboxCredentialsDto: INITIAL_CHECKBOX_CREDENTIALS,
  vchasnoCredentialsDto: INITIAL_VCHASNO_CREDENTIALS,
  legalName: '',
  logo: 'https://orty-public.sgp1.digitaloceanspaces.com/placeholder/merchant_logo_placeholder.png',
  merchantGroupId: 0,
  merchantId: 0,
  legalEntityId: '',
  name: '',
  productsGroup: '',
  notFiscalByDefaultMethods: [],
  printOnReceipt: false,
  fiscalPayedOnlineOrders: true,
});

export const VALIDATION_SCHEMA = {
  name: { presence: { allowEmpty: false } },
  productsGroup: { presence: { allowEmpty: false } },
};

class LegalEntityEditor {
  constructor(LegalEntity) {
    this.legalEntitys = LegalEntity;
  }

  @observable imageFile = '';
  @observable legalEntity = initLegalEntity();
  @observable legalEntityClone = initLegalEntity();
  @observable validationErrors = {};
  @observable printersBrands = [];
  @observable typeRPO = CLASSIC_RPO;
  @observable availablePaymentMethods = [];

  @computed get availableIpAddress() {
    return this.kitchen.kdsList.filter(item => !!item.ipAddress.trim());
  }

  @action
  setAvailablePaymentMethods = value => {
    runInAction(() => {
      this.availablePaymentMethods = value;
    });
  };

  @computed get productsGroupOptions() {
    return ['А', 'Б', 'В', 'Г', 'Д', 'М', 'Н'].map((item, index) => ({
      value: item,
      text: item,
      key: index,
    }));
  }

  @computed get availablePrinterBrands() {
    return this.printersBrands.map(item => ({
      value: item.name,
      text: item.displayName,
    }));
  }

  @action
  togglePaymentMethod = paymentMethods => {
    runInAction(() => {
      this.legalEntity.notFiscalByDefaultMethods = paymentMethods.map(id => {
        return this.availablePaymentMethods.find(i => i.id == id) || {};
      });
    });
  };

  @action
  fetchFiscalPrinterOptions = async () => {
    const { brands } = await LegalEntityAPI.getFiscalPrinterOptions();
    runInAction(() => {
      this.printersBrands = brands;
    });
  };

  @action
  changeLegalEntity = (key, value) => {
    runInAction(() => {
      if (Object.keys(this.validationErrors).length) {
        this.validationErrors = {};
      }
      this.legalEntity[key] = value;
    });
  };

  @action
  changeFiscalOrders = (e, { name, checked }) => {
    runInAction(() => {
      this.legalEntity = { ...this.legalEntity, [name]: checked };
    });
  };

  @action
  setLegalEntity = async id => {
    if (this.legalEntitys.list.length === 0) {
      await this.legalEntitys.fetchListPage();
    }
    const findItem = this.legalEntitys.list.find(i => i.id == id);

    const getPaymentMetod = paymentMetodName => {
      return this.availablePaymentMethods.find(i => i.name == paymentMetodName);
    };
    runInAction(() => {
      this.legalEntity = {
        ...findItem,
        checkboxCredentialsDto: findItem.checkboxCredentialsDto
          ? findItem.checkboxCredentialsDto
          : INITIAL_CHECKBOX_CREDENTIALS,
        vchasnoCredentialsDto: findItem.vchasnoCredentialsDto
          ? findItem.vchasnoCredentialsDto
          : INITIAL_VCHASNO_CREDENTIALS,
        fiscalPrinterSetupDto: findItem.fiscalPrinterSetupDto
          ? findItem.fiscalPrinterSetupDto
          : INITIAL_FISCAL_PRINTER,
        notFiscalByDefaultMethods: this.availablePaymentMethods.length
          ? findItem.notFiscalByDefaultMethods.filter(i => i).map(i => getPaymentMetod(i))
          : [],
      };
      this.legalEntityClone = {
        ...findItem,
        checkboxCredentialsDto: findItem.checkboxCredentialsDto
          ? findItem.checkboxCredentialsDto
          : INITIAL_CHECKBOX_CREDENTIALS,
        vchasnoCredentialsDto: findItem.vchasnoCredentialsDto
          ? findItem.vchasnoCredentialsDto
          : INITIAL_VCHASNO_CREDENTIALS,
        fiscalPrinterSetupDto: findItem.fiscalPrinterSetupDto
          ? findItem.fiscalPrinterSetupDto
          : INITIAL_FISCAL_PRINTER,
        notFiscalByDefaultMethods: this.availablePaymentMethods.length
          ? findItem.notFiscalByDefaultMethods.filter(i => i).map(i => getPaymentMetod(i))
          : [],
      };

      this.typeRPO =
        findItem.checkboxCredentialsDto !== null
          ? PROGRAM_RPO
          : findItem.vchasnoCredentialsDto !== null
          ? VCHASNO_RPO
          : CLASSIC_RPO;
    });
  };

  @action
  setTypeRPO = value => {
    runInAction(() => {
      this.typeRPO = value;
    });
  };

  @action
  validate = (setError, admin = false, singleShop) => {
    let validationErrors = {};
    const newLegalEntity = { ...this.legalEntity };
    if (admin || singleShop) {
      validationErrors = validate(newLegalEntity, VALIDATION_SCHEMA) || {};
    } else if (!admin || singleShop) {
      const { fiscalPrinterSetupDto } = newLegalEntity;

      if (
        (fiscalPrinterSetupDto.name && this.typeRPO === CLASSIC_RPO) ||
        (fiscalPrinterSetupDto.ipAddress && this.typeRPO === CLASSIC_RPO)
      ) {
        if (!fiscalPrinterSetupDto.name) {
          validationErrors['printerName'] = ['error'];
        }
        if (!fiscalPrinterSetupDto.ipAddress) {
          validationErrors['ipAddress'] = ['error'];
        }
        if (!fiscalPrinterSetupDto.brand) {
          validationErrors['brand'] = ['error'];
        }
        if (!fiscalPrinterSetupDto.connectionType) {
          validationErrors['connectionType'] = ['error'];
        }
      }

      const {
        checkboxLogin,
        checkboxPassword,
        checkboxLicenceKey,
        checkboxEmail,
      } = this.legalEntity.checkboxCredentialsDto;

      if (
        Object.values(this.legalEntity.checkboxCredentialsDto).some(i => i != '') &&
        this.typeRPO === PROGRAM_RPO
      ) {
        if (!checkboxLogin) {
          validationErrors['checkboxLogin'] = ['error'];
        }
        if (!checkboxPassword) {
          validationErrors['checkboxPassword'] = ['error'];
        }
        if (!checkboxLicenceKey) {
          validationErrors['checkboxLicenceKey'] = ['error'];
        }
        if (validate.single(checkboxEmail, { email: true })) {
          validationErrors['checkboxEmail'] = ['error'];
        }
      }
    }
    if (setError !== false) {
      this.validationErrors = validationErrors;
    }
    const isValid = !Object.keys(this.validationErrors).length;

    return [isValid, this.validationErrors];
  };

  @action
  create = admin => {
    history.push(`${admin ? routes.adminLegalEntity : routes.legalEntity}/${NEW_ROUTE_PREFIX}`);
  };

  @action
  legalEntityDtoHaveChanges = () => {
    const haveChanges =
      JSON.stringify({
        productsGroup: this.legalEntity.productsGroup,
        name: this.legalEntity.name,
        printOnReceipt: this.legalEntity.printOnReceipt,
      }) !==
      JSON.stringify({
        productsGroup: this.legalEntityClone.productsGroup,
        name: this.legalEntityClone.name,
        printOnReceipt: this.legalEntityClone.printOnReceipt,
      });
    return haveChanges;
  };

  @action
  fiscalSetupDtoHaveChanges = () => {
    const haveChanges =
      JSON.stringify({
        checkboxCredentialsDto: this.legalEntity.checkboxCredentialsDto,
        vchasnoCredentialsDto: this.legalEntity.vchasnoCredentialsDto,
        fiscalPrinterSetupDto: this.legalEntity.fiscalPrinterSetupDto,
        notFiscalByDefaultMethods: this.legalEntity.notFiscalByDefaultMethods,
        fiscalPayedOnlineOrders: this.legalEntity.fiscalPayedOnlineOrders,
      }) !==
      JSON.stringify({
        checkboxCredentialsDto: this.legalEntityClone.checkboxCredentialsDto,
        vchasnoCredentialsDto: this.legalEntityClone.vchasnoCredentialsDto,
        fiscalPrinterSetupDto: this.legalEntityClone.fiscalPrinterSetupDto,
        notFiscalByDefaultMethods: this.legalEntityClone.notFiscalByDefaultMethods,
        fiscalPayedOnlineOrders: this.legalEntityClone.fiscalPayedOnlineOrders,
      });
    return haveChanges;
  };

  @action
  save = async (isEdit = false, { id, merchantGroupId }, admin, singleShop) => {
    if (admin) {
      const newLegalEntity = {
        ...this.legalEntity,
        productsGroup: this.legalEntity.productsGroup,
        merchantId: id,
        merchantGroupId,
      };

      try {
        if (isEdit) {
          await LegalEntityAPI.update(newLegalEntity);
        } else {
          await LegalEntityAPI.create(newLegalEntity);
        }
      } catch (e) {
        throw new Error(e.message);
      }
    } else if (singleShop) {
      const legalEntityDto = {
        ...this.legalEntity,
        productsGroup: this.legalEntity.productsGroup,
        merchantId: id,
        merchantGroupId,
      };
      delete legalEntityDto.checkboxCredentialsDto;
      delete legalEntityDto.vchasnoCredentialsDto;
      delete legalEntityDto.fiscalPrinterSetupDto;
      delete legalEntityDto.notFiscalByDefaultMethods;
      delete legalEntityDto.fiscalPayedOnlineOrders;
      const newLegalEntity = {
        legalEntityDto: isEdit
          ? this.legalEntityDtoHaveChanges()
            ? legalEntityDto
            : null
          : legalEntityDto,
        fiscalSetupDto:
          (!this.legalEntity.checkboxCredentialsDto.checkboxLogin &&
            !this.legalEntity.fiscalPrinterSetupDto &&
            this.legalEntity.notFiscalByDefaultMethods.length === 0) ||
          !this.fiscalSetupDtoHaveChanges()
            ? null
            : {
                legalEntityId: this.legalEntity.legalEntityId,
                checkboxCredentialsDto:
                  this.legalEntity.checkboxCredentialsDto.checkboxLogin &&
                  this.typeRPO === PROGRAM_RPO
                    ? this.legalEntity.checkboxCredentialsDto
                    : null,
                vchasnoCredentialsDto:
                  this.typeRPO === VCHASNO_RPO ? this.legalEntity.vchasnoCredentialsDto : null,
                fiscalPrinterSetupDto:
                  this.legalEntity.fiscalPrinterSetupDto &&
                  this.legalEntity.fiscalPrinterSetupDto.name &&
                  this.typeRPO === CLASSIC_RPO
                    ? this.legalEntity.fiscalPrinterSetupDto
                    : null,
                notFiscalByDefaultMethods: this.legalEntity.notFiscalByDefaultMethods.map(
                  item => item && item.name,
                ),
                fiscalPayedOnlineOrders: this.legalEntity.fiscalPayedOnlineOrders,
              },
      };

      if (newLegalEntity.fiscalSetupDto || newLegalEntity.legalEntityDto) {
        try {
          if (isEdit) {
            await LegalEntityAPI.updateSingleShop(newLegalEntity);
          } else {
            await LegalEntityAPI.createSingleShop(newLegalEntity);
          }
        } catch (e) {
          throw new Error(e.message);
        }
      }
    } else {
      const newLegalEntity = {
        legalEntityId: this.legalEntity.legalEntityId,
        checkboxCredentialsDto:
          this.legalEntity.checkboxCredentialsDto.checkboxLogin && this.typeRPO === PROGRAM_RPO
            ? this.legalEntity.checkboxCredentialsDto
            : null,
        vchasnoCredentialsDto:
          this.typeRPO === VCHASNO_RPO ? this.legalEntity.vchasnoCredentialsDto : null,
        fiscalPrinterSetupDto:
          this.legalEntity.fiscalPrinterSetupDto && this.typeRPO === CLASSIC_RPO
            ? this.legalEntity.fiscalPrinterSetupDto
            : null,
        notFiscalByDefaultMethods: this.legalEntity.notFiscalByDefaultMethods.map(
          item => item && item.name,
        ),
        fiscalPayedOnlineOrders: this.legalEntity.fiscalPayedOnlineOrders,
      };
      try {
        await LegalEntityAPI.saveEntity(newLegalEntity);
      } catch (e) {
        throw new Error(e.message);
      }
    }
  };

  @action
  changeConnectionType = connectionType => {
    const legal = {
      ...this.legalEntity,
      fiscalPrinterSetupDto: {
        ...this.legalEntity.fiscalPrinterSetupDto,
        connectionType,
      },
    };

    runInAction(() => {
      this.legalEntity = legal;
    });
  };

  @action
  changeProducer = brand => {
    const legal = {
      ...this.legalEntity,
      fiscalPrinterSetupDto: {
        ...this.legalEntity.fiscalPrinterSetupDto,
        brand,
      },
    };

    runInAction(() => {
      this.legalEntity = legal;
    });
  };

  @action
  edit = async id => {
    try {
      const response = await LegalEntityAPI.getEntityById(id);

      runInAction(() => {
        this.legalEntity = {
          ...response,
        };
        this.legalEntityClone = { ...response };
      });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  changePrinter = (key, value) => {
    runInAction(() => {
      this.legalEntity = {
        ...this.legalEntity,
        fiscalPrinterSetupDto: {
          ...this.legalEntity.fiscalPrinterSetupDto,
          [key]: value,
        },
      };
    });
  };

  @action
  updateImage = ({ image }) => {
    this.imageFile = image;
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.legalEntity = initLegalEntity();
    this.typeRPO = CLASSIC_RPO;
  };
}

export default LegalEntityEditor;
