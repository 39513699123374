export const getFullCookingPercent = ingredientItem => {
  return (
    (['wasteRatio', 'cookingRatio', 'fryingRatio', 'stewingRatio', 'roastingRatio'].reduce(
      (acc, item) => {
        if (ingredientItem[item]) {
          return acc + Number(ingredientItem[`${item}Persent`]);
        } else {
          return acc;
        }
      },
      0,
    ) || 0) * 100
  );
};
