import {observable, action, runInAction, computed} from 'mobx';
import validate from 'validate.js';
import history, {routes} from 'routes';

const EMPTY_TABLE = {
  tableNo: '',
  zone: '',
  capacity: 0,
};

const VALIDATION_SCHEMA = {
  tableNo: {presence: {allowEmpty: false}},
  zone: {presence: {allowEmpty: false}},
  capacity: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
    },
  },
};

class TableEditor {
  constructor(Tables) {
    this.tables = Tables;
    this.onSave = this.tables.create;
  }
  
  @observable visible = false;

  @observable table = EMPTY_TABLE;

  @observable validationErrors = {};

  @action
  validateTable = () => {
    this.validationErrors = validate(this.table, VALIDATION_SCHEMA) || {};
  };

  @computed get isValid() {
    const errors = validate(this.table, VALIDATION_SCHEMA);

    return !errors;
  }

  @action
  changeTable = (key, value) => {
    if (VALIDATION_SCHEMA[key]) {
      this.validationErrors[key] = validate.single(value, VALIDATION_SCHEMA[key]);
    }

    this.table[key] = value;
  };

  @action
  create = () => {
    this.table = EMPTY_TABLE;
    this.onSave = this.tables.create;
    history.push(routes.tableEditor);
  };

  @action
  edit = async (tableId) => {
    const table = await this.tables.fetchTable(tableId);

    runInAction(() => {
      this.table = table;
      this.onSave = this.tables.update;
      history.push(`${routes.tables}/${tableId}`);
    });
  };
  
  @action
  save = async () => {
    await this.onSave(this.table);
    history.push(routes.tables);
  };
  
  @action
  clearValidation = () => {
    this.validationErrors = {};
  }
}

export default TableEditor;