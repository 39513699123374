import { action, computed, observable, runInAction } from 'mobx';

import InvoicesAPI from 'api/invoices';

import { DEFAULT_PAGINATE_SIZES } from 'utils/constants';

const subscriptionsRestaurantManagementArray = [
  'customerDisplaySystem',
  'deliveryRiderApp',
  'kitchenDisplaySystem',
  'posBusiness',
  'posIndependent',
  'selfServiceKiosk',
];

const subscriptionsMarketingArray = [
  'less30OrderMonthShop',
  'less100OrderMonthShop',
  'less200OrderMonthShop',
  'unlimitedOrderMonthShop',
  'promotions',
  'giftCards',
];

const getTotalRestaurantManagementAndMarketing = (subscriptions = {}) => {
  const data = [subscriptionsRestaurantManagementArray, subscriptionsMarketingArray].map(array => {
    return array.reduce(
      (acc, item) =>
        (acc += Number(subscriptions[`${item}`]) * Number(subscriptions[`${item}Cost`]) || 0),
      0,
    );
  });
  return { totalRestaurantManagement: data[0], totalMarketing: data[1] };
};

const getData = (arr, subscriptions) => {
  let newArr = [];
  arr.map(item => {
    if (subscriptions[item] > 0) {
      newArr.push({
        value: subscriptions[item],
        price: subscriptions[`${item}Price`],
        name: item,
      });
    }
  });
  return newArr;
};

const initSubscriptions = {
  customerDisplaySystem: 0,
  customerDisplaySystemCost: 15,
  customerDisplaySystemPrice: 0,
  deliveryRiderApp: 0,
  deliveryRiderAppCost: 15,
  deliveryRiderAppPrice: 0,
  giftCards: 0,
  giftCardsCost: 50,
  giftCardsPrice: 0,
  id: 0,
  kitchenDisplaySystem: 0,
  kitchenDisplaySystemCost: 15,
  kitchenDisplaySystemPrice: 0,
  less30OrderMonthShop: 0,
  less30OrderMonthShopCost: 10,
  less30OrderMonthShopPrice: 0,
  less100OrderMonthShop: 0,
  less100OrderMonthShopCost: 0,
  less100OrderMonthShopPrice: 0,
  less200OrderMonthShop: 0,
  less200OrderMonthShopCost: 30,
  less200OrderMonthShopPrice: 0,
  unlimitedOrderMonthShop: 0,
  unlimitedOrderMonthShopCost: 0,
  unlimitedOrderMonthShopPrice: 0,
  merchantGroupId: 0,
  posBusiness: 0,
  posBusinessCost: 15,
  posBusinessPrice: 0,
  posIndependent: 0,
  posIndependentCost: 0,
  posIndependentPrice: 0,
  promotions: 0,
  promotionsCost: 20,
  promotionsPrice: 0,
  selfServiceKiosk: 0,
  selfServiceKioskCost: 30,
  selfServiceKioskPrice: 0,
  totalRestaurantManagement: 0,
  totalMarketing: 0,
};

class InvoicesStore {
  @observable list = [];
  @observable loading = false;
  @observable totalPages = 0;
  @observable currentPage = -1;
  @observable totalElements = 0;
  @observable listSize = DEFAULT_PAGINATE_SIZES.desktop;
  @observable subscriptions = initSubscriptions;

  @computed get restaurantManagementArray() {
    return getData(subscriptionsRestaurantManagementArray, this.subscriptions);
  }

  @computed get marketingArray() {
    return getData(subscriptionsMarketingArray, this.subscriptions);
  }

  @action
  getSubscriptions = async () => {
    this.loading = true;
    try {
      const data = await InvoicesAPI.getSubscriptions();
      runInAction(() => {
        this.subscriptions = { ...data, ...getTotalRestaurantManagementAndMarketing(data) };
        this.loading = false;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  startFetch = () => {
    runInAction(() => {
      this.loading = true;
    });
  };

  @action
  fetchListInvoices = async (mechantId, page, size) => {
    this.startFetch();
    const { items, totalPages, number, totalElements } = await InvoicesAPI.list(
      mechantId,
      page,
      size,
    );

    runInAction(() => {
      this.list = items;
      this.currentPage = number;
      this.totalPages = totalPages;
      this.totalElements = totalElements;
      this.loading = false;
    });
  };

  @action
  removeStore = () => {
    runInAction(() => {
      this.subscriptions = initSubscriptions;
      this.loading = false;
    });
  };
}

export default InvoicesStore;
