export function uniqueArray(list, propertyName) {
  if (!Array.isArray(list)) {
    throw new Error('First parameter should be array!');
  }
  return list.filter(
    (item, index) => list.findIndex(elem => elem[propertyName] === item[propertyName]) === index,
  );
}

export const getUniqueObjectValues = (obj, key, clone) => {
  Object.keys(clone[key]).map(i => {
    if (obj[key][i] === clone[key][i]) {
      delete obj[key][i];
    }
  });
  return obj[key];
};
