import {action, computed, observable, runInAction} from 'mobx';
import moment from 'moment';
import SalesAPI from 'api/sales';
import TablesAPI from 'api/tables';
import {getLocalizedString} from 'i18n/utils';
import history, {routes} from 'routes';
import {ALL, ASCENDING, DESCENDING} from 'utils/constants';
import {getUTCOffset} from 'utils/time';
import {
  AUDIT_LIST,
  AUDIT_TABLE,
  DATE,
  DEFAULT_END_DATE,
  DEFAULT_START_DATE,
  DELIVERY_LIST,
  DELIVERY_TABLE,
  getSalesTableFormat,
  ORDER_CUSTOMER_INFO_LIST,
  ORDER_CUSTOMER_INFO_TABLE,
  ORDER_LIST,
  ORDER_STATUS,
  ORDER_TABLE,
  ordersStatus,
  PAYMENT_LIST,
  PAYMENT_TABLE,
  REMARKS_LIST,
  REMARKS_TABLE,
  SALES_LIST_FORMAT,
  terminalStatus,
  USERNAME,
} from './constants';

const initAllFilters = {
  username: '',
  size: '20',
  page: '0',
  paymentMethod: '',
  legalEntityId: '',
  status: '',
};

class SalesStore {
  listFormat = SALES_LIST_FORMAT;
  filters = [USERNAME];
  dateFilter = DATE;
  statusKey = ORDER_STATUS;
  customerInfoTable = ORDER_CUSTOMER_INFO_TABLE;
  customerInfoList = ORDER_CUSTOMER_INFO_LIST;
  paymentTable = PAYMENT_TABLE;
  paymentList = PAYMENT_LIST;
  deliveryTable = DELIVERY_TABLE;
  deliveryList = DELIVERY_LIST;
  auditTable = AUDIT_TABLE;
  auditList = AUDIT_LIST;
  orderList = ORDER_LIST;
  orderTable = ORDER_TABLE;
  remarksList = REMARKS_LIST;
  remarksTable = REMARKS_TABLE;

  @observable loading = false;
  @observable list = [];
  @observable tables = [];
  @observable totalPages = 0;
  @observable currentPage = -1;
  @observable filteredPrice = '';
  @observable currentAuditHistory = [];
  @observable defaultData = [];
  @observable totalAmount = '';
  @observable totalCount = '';
  @observable paymentMethodOption = [];
  @observable legalEntityOption = [];
  @observable ordersStatusOptions = ordersStatus;
  @observable allFilters = initAllFilters;
  @observable order = {};

  @action
  getPaymentInfosMethods = data => {
    let paymentInfosMethods = [];
    if (data.paymentInfos && data.paymentInfos.length > 0) {
      data.paymentInfos.map(item => {
        if (item.payment && item.payment.length > 0) {
          item.payment.map(i => {
            const string =
              getLocalizedString(
                `merchant.paymentMethod.${i.paymentMethod.split(' ').join('_')}`,
              ) || i.paymentMethod;
            const voucherName =
              i.paymentMethod === 'Voucher' && i.voucherName ? `(${i.voucherName})` : '';
            return (
              i.confirmed &&
              (paymentInfosMethods.push(`${string} ${voucherName}`) || i.paymentMethod)
            );
          });
        }
      });
    }
    const filteredPaymentInfosMethods = paymentInfosMethods.filter(i => i);
    return filteredPaymentInfosMethods.length > 0 ? filteredPaymentInfosMethods.join(', ') : '-';
  };

  @computed get data() {
    return this.list.map(order => {
      const { deliveredTimestamp, createdTimestamp, tableId, uid, orderType } = order;
      const searchedTable = this.getTableById(tableId);
      const table = searchedTable || {};
      const { zone, tableNo } = table;

      const timestamp =
        deliveredTimestamp > createdTimestamp ? deliveredTimestamp : createdTimestamp;
      return {
        ...order,
        tableNumber: tableNo && zone ? `${tableNo} - ${zone.toUpperCase()}` : '-',
        orderType: getLocalizedString(`sales.data.orderType.${orderType}`) || '',
        // orderStatus: getLocalizedString(`sales.data.orderStatus.${orderStatus}`) || '',
        date: moment(timestamp - getUTCOffset(timestamp)).format('HH:mm DD-MM-YYYY'),
        totalPrice: (Math.round(Number(order.totalPrice) * 100) / 100).toFixed(2),
        displayName: order.userHumanName
          ? `${order.userHumanName} (${order.username})`
          : order.username,
        remarks: order.orderItems
          .map(item => item.remarks)
          .filter(remarks => remarks)
          .join('. '),
        shortUid: uid.substring(0, uid.indexOf('-')) + '...',
        paymentInfosMethod: this.getPaymentInfosMethods(order),
      };
    });
  }

  @computed get hasMore() {
    return this.currentPage + 1 < this.totalPages;
  }
  @action
  fetchTables = async (page, filters) => {
    const { items } = await TablesAPI.list(filters);

    runInAction(() => {
      this.tables = items;
    });
  };

  @action
  changeFilteredPrice = value => {
    let data = [...this.list];
    if (value === ASCENDING) {
      data.sort((a, b) => a.totalPrice - b.totalPrice);
    } else if (value === DESCENDING) {
      data.sort((a, b) => b.totalPrice - a.totalPrice);
    }
    runInAction(() => {
      if (value === ALL || value === '') {
        this.list = [...this.defaultData];
      } else {
        this.list = [...data];
      }

      this.filteredPrice = value;
    });
  };

  @action
  getTableFormat = totalPrice => {
    return getSalesTableFormat(totalPrice);
  };

  @action
  terminalStatusRowColor = status => {
    return !!terminalStatus.find(i => i === status);
  };

  @action
  changeFilter = (key, value) => {
    runInAction(() => {
      if (key !== 'page') {
        this.allFilters[key] = value;
        this.allFilters.page = '0';
      } else {
        this.allFilters[key] = value;
      }
    });
  };

  @action
  fetchData = allFilters => {
    const { dateFrom, dateTo, paymentMethod, username, page, ...rest } = allFilters;
    const requestFilters = {
      startDate: dateFrom
        ? moment(dateFrom).valueOf() + getUTCOffset(dateFrom)
        : DEFAULT_START_DATE,
      endDate: dateTo ? moment(dateTo).valueOf() + getUTCOffset(dateTo) : DEFAULT_END_DATE,
      paymentMethod: paymentMethod,
      username,
      ...rest,
    };

    return SalesAPI.list(page, requestFilters);
  };

  getTableById = tableId => this.tables.find(table => table.id === tableId);

  getSalesOrderById = async id => {
    return await SalesAPI.getOrderById(id);
  };

  @action
  fetchOrder = async ({ id, uid }) => {
    if (id) {
      const orderInStore = Object.keys(this.order).length > 0 && this.order.id === id;
      if (!orderInStore) {
        try {
          await this.fetchOrderById(id);
          await this.fetchAuditHistory(id);
        } catch (error) {
          throw new Error(error.message);
        }
      }
    } else if (uid) {
      try {
        const order = await this.fetchOrderByUid(uid);
        await this.fetchAuditHistory(order.id);
      } catch (error) {
        throw new Error(error.message);
      }
    }
  };



  @action
  fetchOrderById = async id => {
    try {
      const order = await this.getSalesOrderById(id);
      runInAction(() => {
        this.order = {
          ...order,
          orderStatus: getLocalizedString(`sales.data.orderStatus.${order.orderStatus}`) || '',
          orderStatusCopy: order.orderStatus,
          orderType: getLocalizedString(`sales.data.orderType.${order.orderType}`) || '',
        };
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  getFilterPaymentMethod = async merchantId => {
    try {
      const { items } = await SalesAPI.getFilterPaymentMethod(merchantId);
      runInAction(() => {
        this.paymentMethodOption = items;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  getFilterLegalEntity = async () => {
    try {
      const { items } = await SalesAPI.getFilterLegalEntity();
      runInAction(() => {
        this.legalEntityOption = items;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  fetchOrderByUid = async uid => {
    try {
      const order = await SalesAPI.getOrderByUid(uid);
      runInAction(() => {
        this.order = {
          ...order,
          orderStatus: getLocalizedString(`sales.data.orderStatus.${order.orderStatus}`) || '',
          orderStatusCopy: order.orderStatus,
          orderType: getLocalizedString(`sales.data.orderType.${order.orderType}`) || '',
        };
      });
      return this.order;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  downloadEReceipt = async id => {
    const { html } = await SalesAPI.downloadEReceipt(id);
    return html;
  };

  @action
  fetchAuditHistory = async orderId => {
    try {
      const { items } = await SalesAPI.getAuditHistory(orderId);

      const arr = items.map(item => ({
        ...item,
        timestamp: moment(item.timestamp - getUTCOffset(item.timestamp)).format(
          'HH:mm_ss - DD MMM YYYY',
        ),
      }));

      runInAction(() => {
        this.currentAuditHistory = arr;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  fetchTablePage = async filters => {
    const newFilters = {
      ...filters,
      ...this.allFilters,
    };
    runInAction(() => {
      this.loading = true;
    });
    const { items, totalPages, number, totalAmount, totalCount } = await this.fetchData(newFilters);

    runInAction(() => {
      this.list = items;
      this.defaultData = items;
      this.totalPages = totalPages;
      this.currentPage = number;
      this.loading = false;
      this.totalAmount = totalAmount;
      this.totalCount = totalCount;
      if (this.filteredPrice) {
        this.changeFilteredPrice(this.filteredPrice);
      }
    });
  };

  @action
  fetchListPage = async filters => {
    const newFilters = {
      ...filters,
      ...this.allFilters,
    };

    runInAction(() => {
      this.loading = true;
    });

    const { items, totalPages, number } = await this.fetchData(newFilters);

    runInAction(() => {
      if (this.allFilters.page === 0) {
        this.list = items;
        this.defaultData = items;
      } else {
        this.list = [...this.list, ...items];
        this.defaultData = [...this.list, ...items];
      }

      this.totalPages = totalPages;
      this.currentPage = number;
      this.loading = false;
      if (this.filteredPrice) {
        this.changeFilteredPrice(this.filteredPrice);
      }
    });
  };

  @action
  viewOrder = (orderId, order, customerId) => {
    this.order = order;

    if (customerId && customerId !== 0) {
      history.push(`${routes.sales}/${orderId}/customer/${customerId}`);
    } else {
      history.push(`${routes.sales}/${orderId}`);
    }
  };

  pickStatusColor = ({ orderStatus }) => {
    switch (orderStatus) {
      case 'DELIVERED':
        return '#03b26d';
      case 'REFUNDED':
        return '#d84315';
      case 'VOID':
        return '#9e9e9e';
      default:
        return;
    }
  };

  @action
  downloadCsvFile = async ({
    username,
    paymentMethod,
    legalEntityId,
    status,
    startDate,
    endDate,
    email = '',
  }) => {
    try {
      await SalesAPI.exportSalesToCsv({
        paymentMethod,
        legalEntityId,
        startDate: startDate ? startDate + getUTCOffset(startDate) : DEFAULT_START_DATE,
        endDate: endDate ? endDate + getUTCOffset(endDate) : DEFAULT_END_DATE,
        email,
        status,
        username,
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  resetSalesStore = () => {
    this.list = [];
    this.defaultData = [];
    this.totalPages = 0;
    this.currentPage = -1;
    this.dateFilter = DATE;
    this.filters = [USERNAME];
    this.statusKey = ORDER_STATUS;
    this.listFormat = SALES_LIST_FORMAT;
    this.paymentMethodOption = [];
    this.legalEntityOption = [];
  };
}

export default SalesStore;
