import {observable, action, computed, runInAction} from 'mobx';
import PrintersAPI from 'api/printers';

import { EDIT_CONNECTION_TYPE } from './constants';

const NAME = 'name';
const BRAND = 'brand';
const TARGET = 'ipAddress';

export const PRINTERS_TABLE_FORMAT = new Map([
  [NAME, {id: 'printers.data.name', defaultMessage: 'Name'}],
  [BRAND, {id: 'printers.data.printerProducer', defaultMessage: 'Brand'}],
  [TARGET, {id: 'printers.data.target', defaultMessage: 'IP address'}],
]);

export const PRINTERS_LIST_FORMAT = {
  headerProperty: [NAME, {id: 'printers.data.name', defaultMessage: 'Name'}],
  properties: new Map([
    [BRAND, {id: 'printers.data.printerProducer', defaultMessage: 'Brand'}],
    [TARGET, {id: 'printers.data.target', defaultMessage: 'IP address'}],
  ]),
};

const KITCHEN_PRINTER_TYPE = 'KITCHEN';

class Printers {
  tableFormat = PRINTERS_TABLE_FORMAT;
  listFormat = PRINTERS_LIST_FORMAT;

  @observable list = [];
  @observable totalPages = 0;
  @observable currentPage = -1;

  @action
  fetchPrinters = async () => {
    const {items} = await PrintersAPI.list();

    const list = items.map(printer => {
      return {
        ...printer,
        printerTarget: printer.printerConnectionType === EDIT_CONNECTION_TYPE.WIRED ? '' : printer.printerTarget
      };
    });

    runInAction(() => {
      this.list = list;
      this.totalPages = 1;
      this.currentPage = 0;
    });
  };

  @computed get availablePrinters() {
    return this.list.map((printer) => ({
      value: printer.id,
      text: printer.printerName,
    }));
  }

  @computed get availableKitchenPrinters() {
    return this.list.filter(({printerType}) => (printerType === KITCHEN_PRINTER_TYPE)).map((printer) => ({
      value: printer.id,
      text: printer.printerName,
    }));
  }

  @action
  fetchPrinter = async (printerId) => PrintersAPI.fetchPrinter(printerId);

  @action
  create = async (table) => {
    const createdPrinter = await PrintersAPI.create(table);

    runInAction(() => this.list.push(createdPrinter));
  };

  findPrinterIndex = (printerId) => this.list.findIndex((printer) => printer.id === printerId);

  @action
  update = async (printer) => {
    const updatedPrinter = await PrintersAPI.update(printer);

    runInAction(() => {
      const index = this.findPrinterIndex(printer.id);

      this.list = [
        ...this.list.slice(0, index),
        updatedPrinter,
        ...this.list.slice(index + 1),
      ];
    });
  } ;

  @action
  remove = async (printerId) => {
    await PrintersAPI.remove(printerId);

    runInAction(() => {
      const index = this.findPrinterIndex(printerId);

      this.list = [
        ...this.list.slice(0, index),
        ...this.list.slice(index + 1),
      ];
    });
  };
}

export default Printers;