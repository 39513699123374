export const isKybio = true;

export const brandName = isKybio ? 'WEEBODOS' : 'ORTY';
export const hostname = isKybio ? `opc.${window.DOMAIN}` : 'opc.orty.io';

export const intercomKey = isKybio ? 'u5n3lyks' : 'yhxl0nl5';

export const domainName = isKybio ? `${window.DOMAIN}` : 'orty.io';

export const appName = isKybio ? 'me.kybio' : 'io.orty';

export const logoLong = isKybio
  ? 'https://orty-public.sgp1.digitaloceanspaces.com/kybio/HORIZONTALKYBIODOSBLACK.svg'
  : require('images/logo-long.svg');

export const logoLongWhite = isKybio
  ? require('images/white_logo.svg')
  : require('images/logo-long-white.svg');

export const logoWhite = isKybio
  ? require('images/applications/circle.png')
  : require('images/orty-logo-white.svg');

export const blackLogo = isKybio
  ? require('images/applications/WEEBODOS_NO_BOX.png')
  : require('images/blackOrty.svg');

export const posterExample = isKybio
  ? 'https://orty-public.sgp1.digitaloceanspaces.com/kybio/poster_thumbnail.jpg'
  : require('images/dashboard/poster-example.png');

export const posterEnE = isKybio
  ? require('components/dashboard/SelfService/artifacts/images/kybio_poster-en-e.jpg')
  : require('components/dashboard/SelfService/artifacts/images/poster-en-e.jpg');

export const posterEn = isKybio
  ? require('components/dashboard/SelfService/artifacts/images/kybio_poster-en.jpg')
  : require('components/dashboard/SelfService/artifacts/images/poster-en.jpg');

export const poster = isKybio
  ? require('images/poster/vouchers/kybio_poster.jpg')
  : require('images/poster/vouchers/poster.png');

export const tablePosterImageEn = isKybio
  ? require('images/poster/table/kybio_table-en.jpg')
  : require('images/poster/table/table-en.jpg');

export const userComAPIkey = window.location.hostname === 'admin.orty.io' ? 'e5LcUV' : 'egPine';
