import APIConnector from './utils/APIConnector';
import { extractFiltersQuery } from './utils/helpers';

const Request = APIConnector.instance;
const ENDPOINT = '/voucher';

class VouchersAPI {
  static list = (page, size, allFilters) => {
    const queryFilters = extractFiltersQuery(allFilters);
    return Request.GET(`${ENDPOINT}/list?page=${page}&size=${size}${queryFilters}`);
  };

  static crmList = (page, size, name = '') => {
    const newName = name.length ? `&name=${name}` : '';
    return Request.GET(`${ENDPOINT}/crm/list?page=${page}&size=${size}${newName}`);
  };

  static fetchVoucher = voucherId => Request.GET(`${ENDPOINT}/${voucherId}`);

  static createVoucher = voucher => Request.POST(ENDPOINT, voucher);

  static updateVoucher = voucher => Request.PUT(ENDPOINT, voucher);

  static getVoucherIssuesList = (voucherId, page, size) =>
    Request.GET(`${ENDPOINT}/issued/list?voucherId=${voucherId}&page=${page}&size=${size}`);

  static removeVoucher = voucherId => Request.DELETE(ENDPOINT + '/' + voucherId);

  static createVoucherIssue = data => Request.POST(`${ENDPOINT}/issue`, data);

  static markVouchersCodeAsSent = voucherIds => Request.POST(`${ENDPOINT}/mark-sent`, voucherIds);

  static deactivateVoucher = voucherIds => Request.POST(`${ENDPOINT}/deactivate`, voucherIds);

  static getUsedIssues = voucherIds => Request.POST(`${ENDPOINT}/usage/summary`, voucherIds);

  static stopReleaseVoucher = voucherIds =>
    Request.POST(`${ENDPOINT}/stop-voucher-issuance`, voucherIds);

  static resumeReleaseVoucher = voucherIds =>
    Request.POST(`${ENDPOINT}/start-voucher-issuance`, voucherIds);
}

export default VouchersAPI;
