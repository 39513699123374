import { observable, action, runInAction, computed } from 'mobx';
import validate from 'validate.js';

const EMPTY_CATEGORY = { name: '' };

const VALIDATION_SCHEMA = {
  name: { presence: { allowEmpty: false } },
};

class CategoryEditor {
  constructor(Categories) {
    this.categories = Categories;
    this.onSave = this.categories.create;
  }

  @observable category = EMPTY_CATEGORY;

  @observable validationErrors = {};

  @action
  validateCategory = () => {
    this.validationErrors = validate(this.category, VALIDATION_SCHEMA) || {};
  };

  @computed get isValid() {
    const errors = validate(this.category, VALIDATION_SCHEMA);

    return !errors;
  }

  @action
  changeCategory = (key, value) => {
    if (VALIDATION_SCHEMA[key]) {
      this.validationErrors[key] = validate.single(value, VALIDATION_SCHEMA[key]);
    }

    this.category[key] = value;
  };

  @action
  create = async currentMenu => {
    try {
      this.onSave = this.categories.create;
      await this.save(currentMenu);
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  update = async () => {
    try {
      await this.save();
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  edit = async categoryId => {
    const category = await this.categories.fetchCategory(categoryId);

    runInAction(() => {
      this.category = category;
      this.onSave = this.categories.update;
    });
  };

  @action
  save = async currentMenu => {
    try {
      await this.onSave(this.category, currentMenu);
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  clearCategory = async () => {
    runInAction(() => {
      this.category = EMPTY_CATEGORY;
    });
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
  };
}

export default CategoryEditor;
