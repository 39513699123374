import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/launch';

class LaunchAPI {
  static getLaunchData = () => Request.GET(ENDPOINT);

  static putAnswers = payload => Request.PUT(`${ENDPOINT}/answers`, payload);

  static putChecklist = payload => Request.PUT(`${ENDPOINT}/check-list`, payload);
}

export default LaunchAPI;
