import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/supply/product-recipe';

class ProductRecipeAPI {
  static fetchProductRecipe = id => Request.GET(`${ENDPOINT}/${id}`);

  static create = productRecipe => Request.POST(ENDPOINT, productRecipe);

  static update = productRecipe => Request.PUT(ENDPOINT, productRecipe);

  static deleteProductRecipe = id => Request.DELETE(`${ENDPOINT}/${id}`);

  static default = id => Request.PUT(`${ENDPOINT}/${id}/default`);
}

export default ProductRecipeAPI;
