import { action, observable, runInAction, computed } from 'mobx';
import history, { routes } from 'routes';
import validate from 'validate.js';
import StorageAPI from 'api/storage';
import KitchenAPI from 'api/kitchens';
import { NEW_ROUTE_PREFIX } from 'utils/constants';
import uniq from 'lodash/uniq';
import { VALIDATION_SCHEMA } from '../printers/constants';
import { getLocalizedString } from '../../i18n/utils';

const WIRED_CONNECTION_TYPE = 'USB';

const INITIAL_KDS = {
  ipAddress: '',
  touch: false,
};

const INITIAL_PRINTER = {
  brand: '',
  connectionType: '',
  ipAddress: '',
  name: '',
  supportOldModels: false,
  perItemPrinting: false,
};

const initKitchen = () => ({
  name: '',
  perItemPrinting: false,
  collectionPointId: 0,
  kdsList: [INITIAL_KDS],
  printers: [INITIAL_PRINTER],
  fastBitmapPrinting: false,
});

class KitchenEditor {
  constructor(Kitchens) {
    this.kitchens = Kitchens;
  }

  @observable kitchen = initKitchen();
  @observable validationErrors = {};
  @observable storageList = [];
  @observable storageId = '0';
  @observable encodingsOptions = [];
  @observable paperSizesOptions = [];

  @computed get availableIpAddress() {
    return this.kitchen.kdsList.filter(item => !!item.ipAddress.trim());
  }

  @computed get availablePrinters() {
    return this.kitchen.printers
      .filter(item => !!item.brand.trim() && !!item.name.trim())
      .map(item => {
        const { connectionType, ipAddress } = item;

        return {
          ...item,
          ipAddress: connectionType === WIRED_CONNECTION_TYPE ? '' : ipAddress,
        };
      });
  }

  @action
  uniqueIPAddress = () => {
    const allIpAddress = data => {
      const ipAddressList = item => {
        return item.reduce((acc, i) => [...acc, i.ipAddress], []);
      };
      let kdsListIP = [];
      let printerListIP = [];
      if (data.kdsList) {
        kdsListIP.push(...ipAddressList(data.kdsList));
      }
      if (data.printers) {
        printerListIP.push(...ipAddressList(data.printers));
      }
      return [...kdsListIP, ...printerListIP].filter(i => i !== '');
    };
    const newAllIpAddress = allIpAddress(this.kitchen);
    return uniq(newAllIpAddress).length === newAllIpAddress.length;
  };

  // @action
  // uniqueIPAddress = () => {
  //   const data = this.kitchens.list.map(item => {
  //     if (item.id === this.kitchen.id) {
  //       return this.kitchen;
  //     } else {
  //       return item;
  //     }
  //   });
  //   const allIpAddress = data => {
  //     const ipAddressList = item => {
  //       return item.reduce((acc, i) => [...acc, i.ipAddress], []);
  //     };
  //     let kdsListIP = [];
  //     let printerListIP = [];
  //     data.map(item => {
  //       if (item.kdsList) {
  //         kdsListIP.push(...ipAddressList(item.kdsList));
  //       }
  //       if (item.printers) {
  //         printerListIP.push(...ipAddressList(item.printers));
  //       }
  //     });
  //     return [...kdsListIP, ...printerListIP];
  //   };
  //   const newAllIpAddress = allIpAddress(data);
  //   return uniq(newAllIpAddress).length === newAllIpAddress.length;
  // };

  @action
  create = () => {
    history.push(`${routes.kitchens}/${NEW_ROUTE_PREFIX}`);
  };

  @action
  loadStorages = async () => {
    const { items } = await StorageAPI.list();
    runInAction(() => {
      const newItems = items.map(item => ({
        value: item.id,
        text: item.name,
        key: item.id,
      }));
      this.storageList = [
        { value: '0', text: getLocalizedString('actions.empty'), key: '0' },
        ...newItems,
      ];
    });
  };

  @action
  changeStorage = value => {
    runInAction(() => {
      this.storageId = value;
    });
  };

  @action
  connectStorage = async id => {
    const obj = {
      kitchenId: id ? id : this.kitchen.id,
      storageId: this.storageId > 0 ? this.storageId : '',
    };
    try {
      await KitchenAPI.connectStorage(obj);
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  save = async (isEdit = false, ads) => {
    const kdsList = this.availableIpAddress;
    const printers = this.availablePrinters;
    const kitchen = {
      ...this.kitchen,
      kdsList,
      printers,
      collectionPointId: ads,
    };

    try {
      if (isEdit) {
        await KitchenAPI.update(kitchen);
        if (this.storageId) {
          await this.connectStorage();
        }
      } else {
        const data = await KitchenAPI.create(kitchen);
        if (this.storageId) {
          await this.connectStorage(data.id);
        }
      }

      history.go(-1);
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  structureKitchen = kitchen => {
    const { kdsList: kitchenKdsList, printers: kitchenPrinters } = kitchen;

    const kdsList = kitchenKdsList.length > 0 ? kitchenKdsList : [INITIAL_KDS];
    const printers = kitchenPrinters.length > 0 ? kitchenPrinters : [INITIAL_PRINTER];

    return {
      ...kitchen,
      kdsList,
      printers,
    };
  };

  @action
  edit = async kitchenId => {
    try {
      const response = await KitchenAPI.getKitchenById(kitchenId);
      if (this.kitchens.сonnectsStorage[0] === null) {
        await this.kitchens.fetchConnectStorage(kitchenId);
      }
      runInAction(() => {
        this.kitchen = { ...this.structureKitchen(response) };
      });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  changeKitchen = (key, value) => {
    this.kitchen[key] = value;
  };

  @action
  changeAllKitchen = kitchen => {
    runInAction(() => {
      this.kitchen = kitchen;
    });
  };

  @action
  changeKdsAddress = (ipIndex, key, value) => {
    const kitchen = { ...this.kitchen };

    const ipKdsIndex = kitchen.kdsList.findIndex((_item, index) => index === ipIndex);
    if (ipKdsIndex !== -1) {
      kitchen.kdsList[ipKdsIndex][key] = value;
    }

    runInAction(() => {
      this.kitchen = { ...kitchen };
    });
  };

  @action
  removeIpProperty = ipIndex => {
    const kitchen = { ...this.kitchen };
    const kdsList = kitchen.kdsList.filter((_item, index) => ipIndex !== index);

    runInAction(() => {
      this.kitchen = { ...kitchen, kdsList };
    });
  };

  @action
  addIpProperty = () => {
    const kitchen = { ...this.kitchen };

    kitchen.kdsList.push(INITIAL_KDS);
    runInAction(() => {
      this.kitchen = kitchen;
    });
  };

  @action
  removePrinter = printerIndex => {
    const kitchen = { ...this.kitchen };
    if (printerIndex === 0) {
      const printers = [{ brand: '', connectionType: '', ipAddress: '', name: '' }];

      runInAction(() => {
        this.kitchen = { ...kitchen, printers };
      });
    } else {
      const printers = kitchen.printers.filter((_item, index) => printerIndex !== index);

      runInAction(() => {
        this.kitchen = { ...kitchen, printers };
      });
    }
  };

  @action
  addPrinter = () => {
    const kitchen = { ...this.kitchen };

    kitchen.printers.push(INITIAL_PRINTER);
    runInAction(() => {
      this.kitchen = kitchen;
    });
  };

  @action
  changePrinter = (printerIndex, key, value) => {
    if (VALIDATION_SCHEMA[key]) {
      this.validationErrors[key] = validate.single(value, VALIDATION_SCHEMA[key]);
    }

    this.kitchen.printers[printerIndex][key] = value;
  };

  @action
  changeProducer = (printerIndex, brand) => {
    runInAction(() => {
      this.kitchen.printers[printerIndex].brand = brand;
    });
  };

  @action
  changeLegacySupport = (printerIndex, value) => {
    runInAction(() => {
      this.kitchen.printers[printerIndex].supportOldModels = value;
    });
  };

  @action
  changePerItemPrinting = value => {
    runInAction(() => {
      this.kitchen.perItemPrinting = value;
    });
  };

  @action
  changeConnectionType = (printerIndex, connType) => {
    runInAction(() => {
      this.kitchen.printers[printerIndex].connectionType = connType;
    });
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.kitchen = initKitchen();
    this.storageId = '';
  };
}

export default KitchenEditor;
