import { action, observable, runInAction } from 'mobx';
import MerchantAPI from 'api/merchant';
import queryString from 'query-string';
import ImagesAPI from 'api/images';

import history, { routes } from 'routes';
import { BLOCKED_MERCHANTS_STATUS } from 'utils/constants';
import { computed } from 'mobx/lib/mobx';
import validate from 'validate.js/validate';
import { hostname, isKybio } from 'utils/env';

const MERCHANT_NAME = 'name';

export const MERCHANTS_LIST_FORMAT = {
  headerProperty: [MERCHANT_NAME, { id: 'merchantsGroup.data.name', defaultMessage: 'Name' }],
  properties: new Map([]),
};

export const MERCHANTS_TABLE_FORMAT = new Map([
  [MERCHANT_NAME, { id: 'merchantsGroup.data.name', defaultMessage: 'Name' }],
]);

export const VALIDATION_SCHEMA = {
  currency: { presence: { allowEmpty: false } },
  language: { presence: { allowEmpty: false } },
};

class MerchantsGroup {
  constructor(accountStore, merchantStore, Navigations) {
    this.accountStore = accountStore;
    this.merchantStore = merchantStore;
    this.navigations = Navigations;
  }

  logo = null;
  listFormat = MERCHANTS_LIST_FORMAT;
  tableFormat = MERCHANTS_TABLE_FORMAT;

  @action
  setCurrentMerchant = async id => {
    if (id) {
      await MerchantAPI.setCurrentMerchant(id, {
        errorHandler: () => {},
      });
      history.push(routes.dashboard);
    }
  };

  @observable list = [];
  @observable listMerchantGroup = [];
  @observable supportedLocales = [];
  @observable totalPages = 0;
  @observable currentPage = -1;
  @observable isLoading = false;
  @observable currentGroup = {
    opcMerchantIds: [],
    adminOnBoardingPassed: null,
    taxInclusive: false,
  };
  @observable currentGroupLoaded = false;
  @observable blockedMerchantIds = [];
  @observable validationErrors = {};

  @computed get isValid() {
    const { name } = this.currentGroup;
    return !!name;
  }

  @computed get isNewMerchant() {
    const { distributorTag } = this.currentGroup;
    return distributorTag === 'NONE' || distributorTag === 'SETUP';
  }

  @action
  createLink = ({ path = '', categoryId, ...rest } = {}) => {
    const { id, alias } = this.currentGroup;
    const params = {
      ...rest,
    };
    if (!alias) {
      params.gid = id;
    }

    if (categoryId) {
      params.c = categoryId;
    }

    const query = queryString.stringify(params);

    return `https://${alias || hostname}/g/${path}${query ? `?${query}` : ''}`;
  };

  @action
  validateMerchant = () => {
    this.validationErrors = validate(this.currentGroup, VALIDATION_SCHEMA) || {};
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
  };

  @action
  fetchMerchantGroupInfo = async () => {
    const response = await MerchantAPI.fetchMerchantGroupInfo();
    if (!isKybio && window.civchat) {
      window.civchat = {
        ...window.civchat,
        company: {
          ...window.civchat.company,
          name: `${response.name}`,
          company_id: `${response.id}`,
        },
      };
    }
    runInAction(() => {
      this.currentGroup = response;
      this.currentGroupLoaded = true;
    });
  };

  @action
  startLoading = async () => {
    runInAction(() => {
      this.isLoading = true;
    });
  };

  @action
  stopLoading = async () => {
    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action
  fetchGroup = async () => {
    const { items } = await MerchantAPI.fetchGroup();
    runInAction(() => {
      this.listMerchantGroup = items;
      this.blockedMerchantIds = items
        .filter(item => item.status === BLOCKED_MERCHANTS_STATUS)
        .map(item => item.id);
    });
  };

  editCurrentGroup = async (key, value) => {
    runInAction(() => {
      this.currentGroup = {
        ...this.currentGroup,
        [key]: value,
      };
    });
  };

  updateImage = ({ image }) => {
    this.logo = image;
  };

  @action
  fetchSupportedLocales = async () => {
    const { items } = await MerchantAPI.fetchSupportedLocales();

    const supportedLocales = items.map(({ key }) => ({
      text: key,
      value: key,
    }));

    runInAction(() => {
      this.supportedLocales = supportedLocales;
    });
  };

  updateGroup = async () => {
    try {
      this.startLoading();
      const params = { ...this.currentGroup };
      if (this.logo) {
        const { url } = await ImagesAPI.upload(this.logo);

        params.logo = url;
      }
      const response = await MerchantAPI.updateGroup(params);

      runInAction(() => {
        this.currentGroup = response;
      });
    } catch (error) {
      throw new Error(error.message);
    } finally {
      this.stopLoading();
    }
  };

  updateGroupName = async name => {
    try {
      this.startLoading();
      const response = await MerchantAPI.updateGroup({ ...this.currentGroup, name: name });

      runInAction(() => {
        this.currentGroup = response;
      });
    } catch (error) {
      throw new Error(error.message);
    } finally {
      this.stopLoading();
    }
  };

  delete = async itemId => {
    try {
      this.startLoading();
      await MerchantAPI.removeGroupItem(itemId);

      runInAction(() => {
        this.listMerchantGroup = this.listMerchantGroup.filter(({ id }) => id !== itemId);
      });

      const permissions = await this.accountStore.deletePermission(itemId);
      await this.accountStore.refreshUserInfo();

      return this.switchDeletedMerchant(itemId, permissions);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      this.stopLoading();
    }
  };

  switchDeletedMerchant = async (itemId, permissions) => {
    if (this.merchantStore.info.id === itemId && permissions[0]) {
      await MerchantAPI.setCurrentMerchant(permissions[0].merchantId);
    }
  };

  fetchMerchant = id => {
    if (Number.isInteger(id)) {
      MerchantAPI.fetchMerchant(id);
    }
  };

  fetchCurrentMerchant = () => MerchantAPI.fetchCurrentMerchant();

  @action
  createMerchant = async (merchant, { image }) => {
    let newMerchant = { ...merchant };
    if (image) {
      const { url } = await ImagesAPI.upload(image);

      newMerchant.logo = url;
    }

    await MerchantAPI.addToGroup(newMerchant);

    this.listMerchantGroup = [{ ...this.listMerchantGroup, ...newMerchant }];

    this.navigations.changeShop();
    await this.accountStore.authorizeUser();
    await this.accountStore.refreshToken();
  };

  @action
  updateMerchant = async (merchant, imageFile) => {
    let merchantToUpdate = { ...merchant };

    if (imageFile) {
      const { url } = await ImagesAPI.upload(imageFile);

      merchantToUpdate.logo = url;
    }

    await MerchantAPI.asignPaymentMethods(merchantToUpdate.id, merchantToUpdate.paymentMethods);
    const updatedMerchant = await MerchantAPI.updateMerchant(merchantToUpdate);
    if (updatedMerchant.id === this.merchantStore.info.id) {
      await this.merchantStore.fetchInfo();
      await this.accountStore.refreshToken();
    }
  };
}

export default MerchantsGroup;
