export const EMPTY_PINTER = {
  name: '',
  brand: '',
  ipAddress: '',
  connectionType: 'Network',
  supportOldModels: false,
  paperSize: '',
  supportedEncoding: '',
  fastBitmapPrinting: false,
};

export const EDIT_CONNECTION_TYPE = {
  WIRED: 'WIRED',
  WIFI: 'WIFI',
  BUILT_IN: 'BUILT_IN',
};

export const VALIDATION_SCHEMA = {
  name: { presence: { allowEmpty: false } },
  brand: { presence: { allowEmpty: false } },
  ipAddress: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/i,
      message: function() {
        return 'Is not a valid ip address';
      },
    },
  },
};
