import { PRODUCT_IMAGE_PLACEHOLDER } from 'utils/constants';

export const EMPTY_PRODUCT = {
  name: '',
  setMeal: false,
  description: '',
  categoryId: '',
  categories: [],
  relatedProductIds: [],
  buyPrice: '',
  price: 0,
  sellPrice: 0,
  taxFee: 0,
  productProperties: [],
  externalProductId: '',
  stock: 0,
  tags: [],
  status: 'ACTIVE',
  posEnabled: true,
  loyaltyEnabled: true,
  cashbackEnabled: true,
  kitchenStationId: 0,
  legalEntityId: 0,
  legalEntityName: '',
  selfServiceEnabled: true,
  image: PRODUCT_IMAGE_PLACEHOLDER,
  thumbnail: PRODUCT_IMAGE_PLACEHOLDER,
  estimatedTimeArrival: '',
  divisible: false,
  weightedProduct: false,
  enterWeightManuallyEnabled: false,
  taxGroupIds: [],
  weightGram: 0,
};

export const PRODUCT_VALIDATION_SCHEMA = {
  name: { presence: { allowEmpty: false } },
  categories: { presence: { allowEmpty: false } },
  sellPrice: { presence: { allowEmpty: false } },
  price: { presence: { allowEmpty: false } },
};

export const MEAL_VALIDATION_SCHEMA = {
  ...PRODUCT_VALIDATION_SCHEMA,
  productProperties: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
};

export const EMPTY_OPTION = {
  name: '',
  price: 0,
  sellPrice: 0.0,
  categoryName: '',
  externalProductId: '',
};

export const PROPERTY_PRODUCT_CHANGE_TYPES = {
  posEnabled: 'POS',
  selfServiceEnabled: 'SELF_SERVICE',
};

export const EMPTY_PROPERTY = {
  name: '',
  compulsory: false,
  productPropertyOptions: [],
};

export const PROPERTY_VALIDATION_SCHEMA = {
  name: { presence: { allowEmpty: false } },
  categoryId: { presence: { allowEmpty: false } },
};

export const PROPERTY_EDIT_VALIDATION_SCHEMA = {
  name: { presence: { allowEmpty: false } },
};
