import { getLocalizedString } from 'i18n/utils';

export const ADMIN = 'POS_ADMIN';
export const MANAGER = 'POS_MANAGER';
export const MARKETING_MANAGER = 'POS_MARKETING_MANAGER';
export const RIDER = 'POS_RIDER';
export const SHIFT_MANAGER = 'POS_SHIFT_MANAGER';
export const CASHIER = 'POS_CASHIER';
export const WAITER = 'POS_WAITER';
export const EXECUTIVE = 'POS_EXECUTIVE';

export const DISPLAY_USER_ROLES = {
  [ADMIN]: getLocalizedString('global.users.role.POS_ADMIN'),
  [MANAGER]: getLocalizedString('global.users.role.POS_MANAGER'),
  [CASHIER]: getLocalizedString('global.users.role.POS_CASHIER'),
  [WAITER]: getLocalizedString('global.users.role.POS_WAITER'),
  [MARKETING_MANAGER]: getLocalizedString('global.users.role.POS_MARKETING_MANAGER'),
  [SHIFT_MANAGER]: getLocalizedString('global.users.role.POS_SHIFT_MANAGER'),
  [RIDER]: getLocalizedString('global.users.role.POS_RIDER'),
  [EXECUTIVE]: getLocalizedString('global.users.role.POS_EXECUTIVE'),
};
