import { observable, action, runInAction } from 'mobx';

import { modalTypes } from 'utils/constants';

class Modals {
  @observable context = {};
  @observable loading = false;
  @observable openModalType = '';
  @observable executeCallback = () => {};

  @action
  show = (type = modalTypes.DELETE, context = {}, callback) => {
    runInAction(() => {
      this.context = context;
      this.openModalType = type;
      this.executeCallback = callback;
    });
  };

  @action
  closeModal = () => {
    runInAction(() => {
      this.openModalType = '';
      this.loading = false;
    });
  };

  @action
  resolve = async () => {
    this.loading = true;

    try {
      await this.executeCallback();
    } catch {}
    finally {
      this.closeModal();
    }
  };

  @action
  hide = () => {
    this.closeModal();
  };
}

export default Modals;
