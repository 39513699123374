import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/legal-entity';

class LegalEntityAPI {
  static getEntityById = entityId => Request.GET(`${ENDPOINT}/${entityId}`);

  static getFiscalPrinterOptions = () => Request.GET(`${ENDPOINT}/fiscal/options`);

  static list = () => Request.GET(`${ENDPOINT}`);

  static create = entity => Request.POST(ENDPOINT, entity);

  static createSingleShop = entity => Request.POST(`${ENDPOINT}/single-shop`, entity);

  static update = entity => Request.PUT(ENDPOINT, entity);

  static updateSingleShop = entity => Request.PUT(`${ENDPOINT}/single-shop`, entity);

  static getAllEntity = ids => Request.POST(`${ENDPOINT}/fiscal/setup`, ids);

  static saveEntity = data => Request.PUT(`${ENDPOINT}/fiscal/setup`, data);

  // static deleteCheckboxCredentials = legalEntityId =>
  //   Request.DELETE(`${ENDPOINT}/checkbox/${legalEntityId}/credentials`);

  static remove = entityId => Request.DELETE(`${ENDPOINT}/${entityId}`);

  static list = () => Request.GET(`${ENDPOINT}`);
}

export default LegalEntityAPI;
