export const EMPTY_OPTION = {
  name: '',
  price: 0,
  sellPrice: 0.0,
  categoryName: '',
  externalProductId: '',
};

export const EMPTY_PROPERTY = {
  name: '',
  compulsory: false,
  productPropertyOptions: [],
};
