import ModalsStore from './Modals';
import MobileMenuStore from './MobileMenu';
import PaginationStore from './Pagination';
import NotificationStore from './Notification';

const Modals = new ModalsStore();
const Pagination = new PaginationStore();
const MobileMenu = new MobileMenuStore();
const Notification = new NotificationStore();

export default {
  Modals,
  Notification,
  MobileMenu,
  Pagination
};