import XReportsStore from './XReports';
import ZReportsStore from './ZReports';
import CertainReprtsStore from './CertainReports';

const XReports = new XReportsStore();
const ZReports = new ZReportsStore();
const CertainReports = new CertainReprtsStore();

export default {
  XReports,
  ZReports,
  CertainReports,
};
