import { LOYALTY_PROGRAM_TYPE } from 'utils/constants';

export const MS_IN_DAY = 86400000;

export const DISCOUNT_VALIDATION_SCHEMA = {
  name: { presence: { allowEmpty: false } },
};

// export const PERCENT_VALIDATION_SCHEMA = {
//   takeaway: {
//     presence: {
//       allowEmpty: false,
//     },
//     numericality: {
//       greaterThanOrEqualTo: 0,
//     },
//   },
//   dinein: {
//     presence: {
//       allowEmpty: false,
//     },
//     numericality: {
//       greaterThanOrEqualTo: 0,
//     },
//   },
//   delivery: {
//     presence: {
//       allowEmpty: false,
//     },
//     numericality: {
//       greaterThanOrEqualTo: 0,
//     },
//   },
// };

export const CASHBACK_VALIDATION_SCHEMA = {
  ...DISCOUNT_VALIDATION_SCHEMA,
  lpStartsInMs: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
    },
  },
};

export const status = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const initLoyaltyProgram = () => ({
  name: '',
  status: status.ACTIVE,
  lpStartsInMs: 0,
  type: LOYALTY_PROGRAM_TYPE.DISCOUNT,
  cashbackPercentMap: { takeaway: '', dinein: '', delivery: '' },
});
