import Discount from './rules/Discount';
import HappyHours from './rules/HappyHours';
import TwoPlusOne from './rules/TwoPlusOne';
import TwoPlusOneDiscounted from './rules/TwoPlusOneDiscounted';

export const HAPPY_HOURS = 'HAPPY_HOURS';
export const TWO_PLUS_ONE = 'TWO_PLUS_ONE';
export const DISCOUNT = 'DISCOUNT';
export const TWO_PLUS_ONE_DISCOUNTED = 'TWO_PLUS_ONE_DISCOUNTED';

export const PROMOTION_TYPES = [HAPPY_HOURS, TWO_PLUS_ONE, TWO_PLUS_ONE_DISCOUNTED, DISCOUNT];

export const PROMOTION_TYPE_TO_RULES_CONSTRUCTOR = {
  [DISCOUNT]: Discount,
  [HAPPY_HOURS]: HappyHours,
  [TWO_PLUS_ONE]: TwoPlusOne,
  [TWO_PLUS_ONE_DISCOUNTED]: TwoPlusOneDiscounted,
};

export const PROMOTION_TYPE_TO_DISPLAY_NAME = {
  [TWO_PLUS_ONE]: 'Buy two get one free',
  [TWO_PLUS_ONE_DISCOUNTED]: 'Buy two get one with discount',
  [HAPPY_HOURS]: 'Happy hours',
  [DISCOUNT]: 'Product discounts',
};

export const PROMOTION_CONDITIONS_TYPE = {
  MORE_THAN: 'MORE_THAN',
  PER_EACH: 'PER_EACH',
};

export const PROMOTION_CHANNEL_TYPE = {
  POS: 'POS',
  OPC: 'OPC',
  POS_OPC: 'POS_OPC',
};

export const CUSTOMERS_GIFT_TYPE = {
  DISCOUNT: 'DISCOUNT',
  PRESENT: 'PRESENT',
  APPLY_TO_SAME: 'APPLY_TO_SAME',
  PRICE_SURGE: 'PRICE_SURGE',
  // FIXED_PRICE: 'FIXED_PRICE',
};

// export const CUSTOMERS_TYPE_TO_DISPLAY = {
//   DISCOUNT: 'DISCOUNT',
//   PRESENT: 'GIFT',
//   FIXED_PRICE: 'FIXED PRICE',
// };

export const SELECTION_LIST = ['categoryIds', 'tags', 'productIds', 'menuIds'];

export const SELECTION_MAPPER_LIST = {
  categoryIds: 'categories',
  tags: 'tags',
  productIds: 'products',
  menuIds: 'menus',
};

export const INITIAL_PERCENT_COUNT = 0;
