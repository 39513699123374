import styled from 'styled-components';

export const PopupActions = styled.div`
  display: flex;
  justify-content: space-between;

  & > .button {
    flex-basis: 50%;
    border-radius: 0;
    margin: 0 4px;
  }
`;

export const Content = styled.div`
  padding: 16px;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const Text = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #3d474b;
  margin-bottom: 16px;
`;
