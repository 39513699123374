import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment';
import DashboardAPI from 'api/dashboard';
import { getUTCOffset } from 'utils/time';

const utcOffset = getUTCOffset();

const DATE = 'date';
const CASHIER = 'cashier';
const TOTAL_DISCOUNT = 'totalDiscount';
const PERCENT = 'discountPct';
const AMOUNT = 'discountAmount';
const BUS_DAY_NUMBER = 'busDayNumber';

const DEFAULT_START_DATE =
  moment()
    .startOf('day')
    .valueOf() + utcOffset;
const DEFAULT_END_DATE =
  moment()
    .endOf('day')
    .valueOf() + utcOffset;

export const DISCOUNTS_TABLE_FORMAT = new Map([
  [BUS_DAY_NUMBER, { id: 'discounts.data.busDayNumber', defaultMessage: 'Bussiness day number' }],
  [CASHIER, { id: 'discounts.data.cashier', defaultMessage: 'Cashier' }],
  [
    TOTAL_DISCOUNT,
    {
      id: 'discounts.data.totalDiscount',
      defaultMessage: 'Total Discount',
    },
  ],
  [DATE, { id: 'discounts.data.date', defaultMessage: 'Date' }],
]);

export const DISCOUNTS_LIST_FORMAT = {
  headerProperty: null,
  properties: new Map([
    [BUS_DAY_NUMBER, { id: 'discounts.data.busDayNumber', defaultMessage: 'Bussiness day number' }],
    [CASHIER, { id: 'discounts.data.cashier', defaultMessage: 'Cashier' }],
    [TOTAL_DISCOUNT, { id: 'discounts.data.totalDiscount', defaultMessage: 'Total Discount' }],
    [DATE, { id: 'discounts.data.date', defaultMessage: 'Date' }],
  ]),
};

const initFilters = { discountPctFrom: '', discountPctTo: '', username: '' };

class DiscountsStore {
  tableFormat = DISCOUNTS_TABLE_FORMAT;
  listFormat = DISCOUNTS_LIST_FORMAT;
  dateFilter = DATE;
  rangeFilters = [PERCENT];

  @observable list = [];
  @observable totalDiscountAmount = '';
  @observable totalPages = 0;
  @observable currentPage = -1;
  @observable filters = initFilters;
  @observable paginateOptions = { page: 0, size: 20 };

  @action
  changePaginateOptions = value => {
    runInAction(() => {
      this.paginateOptions = value;
    });
  };

  @action
  setFilters = value => {
    runInAction(() => {
      this.filters = value;
    });
  };

  @computed get data() {
    return this.list.map(discount => ({
      ...discount,
      [DATE]: moment(discount.date - getUTCOffset(discount.date)).format('HH:mm DD-MM-YYYY'),
      [PERCENT]: Number(discount[PERCENT] * 100).toFixed(2),
      [AMOUNT]: Number(discount[AMOUNT]).toFixed(2),
    }));
  }

  @computed get hasMore() {
    return this.currentPage + 1 < this.totalPages;
  }

  fetchData = (page = this.paginateOptions.page, filters) => {
    const { dateFrom, dateTo } = filters;
    const requestFilters = {
      startDate: dateFrom
        ? moment(dateFrom).valueOf() + getUTCOffset(moment(dateFrom).valueOf())
        : DEFAULT_START_DATE,
      endDate: dateTo
        ? moment(dateTo).valueOf() + getUTCOffset(moment(dateTo).valueOf())
        : DEFAULT_END_DATE,
      startDiscount: this.filters.discountPctFrom / 100 || 0,
      endDiscount: this.filters.discountPctTo / 100 || 1,
      size: this.paginateOptions.size,
      username: this.filters.username,
    };

    return DashboardAPI.fetchDiscountReport(page, requestFilters);
  };

  @action
  fetchTablePage = async (filters, page) => {
    const { items, totalPages, number, totalDiscountAmount } = await this.fetchData(page, filters);

    runInAction(() => {
      this.list = items;
      this.totalPages = totalPages;
      this.currentPage = number;
      this.totalDiscountAmount = totalDiscountAmount;
    });
  };

  @action
  fetchListPage = async (filters, page) => {
    const { items, totalPages, number } = await this.fetchData(page, filters);

    runInAction(() => {
      if (page === 0) {
        this.list = items;
      } else {
        this.list = [...this.list, ...items];
      }

      this.totalPages = totalPages;
      this.currentPage = number;
    });
  };

  @action
  resetDiscountStore = () => {
    this.list = [];
    this.totalPages = 0;
    this.currentPage = -1;
    this.dateFilter = DATE;
    this.activeFilters = {};
    this.rangeFilters = [PERCENT];
    this.tableFormat = DISCOUNTS_TABLE_FORMAT;
    this.listFormat = DISCOUNTS_LIST_FORMAT;
    this.filters = initFilters;
  };
}

export default DiscountsStore;
