import { observable, action, runInAction, computed } from 'mobx';
import validate from 'validate.js';
import history, { routes } from 'routes';

import PrintersAPI from 'api/printers';

import { EMPTY_PINTER, VALIDATION_SCHEMA } from './constants';

class PrinterEditor {
  constructor(Printers) {
    this.printers = Printers;
    this.onSave = this.printers.create;
  }

  @observable brands = [];
  @observable connectionTypes = [];
  @observable validationErrors = {};
  @observable printer = EMPTY_PINTER;
  @observable encodingsOptions = [];
  @observable paperSizesOptions = [];

  @action
  fetchPrintersInfo = async () => {
    const {
      brands,
      connectionTypes,
      encodings,
      paperSizes,
    } = await PrintersAPI.fetchPrintersInfo();

    runInAction(() => {
      this.brands = brands;
      this.connectionTypes = connectionTypes;
      this.encodingsOptions = encodings.map(i => ({
        text: i,
        value: i,
      }));
      this.paperSizesOptions = paperSizes.map(i => ({
        text: i,
        value: i,
      }));
    });
  };

  @computed get availablePrinterBrands() {
    return this.brands.map(name => ({
      value: name,
      text: name,
    }));
  }

  @computed get availablePrinterTypes() {
    return this.connectionTypes.map(name => ({
      value: name,
      text: name,
    }));
  }

  @action
  validatePrinter = () => {
    this.validationErrors = validate(this.printer, VALIDATION_SCHEMA) || {};
  };

  @computed get isValid() {
    const errors = validate(this.printer, VALIDATION_SCHEMA);

    return !errors;
  }

  @action
  changePrinter = (key, value) => {
    if (VALIDATION_SCHEMA[key]) {
      this.validationErrors[key] = validate.single(value, VALIDATION_SCHEMA[key]);
    }

    this.printer[key] = value;
  };

  @action
  changeConnectionType = value => {
    this.printer.connectionType = value;
  };

  @action
  create = () => {
    this.printer = EMPTY_PINTER;
    this.onSave = this.printers.create;

    history.push(routes.printers + '/new');
  };

  @action
  edit = async printerId => {
    const printer = await this.printers.fetchPrinter(printerId);

    runInAction(() => {
      this.printer = printer;
      this.onSave = this.printers.update;

      history.push(`${routes.printers}/${printerId}`);
    });
  };

  @action
  save = async () => {
    const newPrinter = {
      ...this.printer,
      supportedEncoding:
        this.printer.supportOldModels === true ? this.printer.supportedEncoding : '',
    };
    await this.onSave(newPrinter);

    history.push(routes.printers);
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.printer = { ...EMPTY_PINTER };
  };
}

export default PrinterEditor;
