import React from 'react';
import { hot } from 'react-hot-loader/root'; // eslint-disable-line import/no-extraneous-dependencies
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import { routes } from 'routes';

const Admin = loadable(() => import('components/admin'));

const DashboardScene = inject('Account')(
  observer(({ Account }) => {
    return Account.isAuthorized ? <Admin /> : <Redirect to={routes.login} />;
  }),
);

export default hot(DashboardScene);
