import APIConnector from '../utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/loyalty/program';

class LoyaltyProgramsAPI {
  static fetch = () => Request.GET(ENDPOINT);

  static fetchLoyaltyTransitions = () => Request.GET(`${ENDPOINT}/trigger`);

  static fetchById = programId => Request.GET(`${ENDPOINT}/${programId}`);

  static create = programInfo => Request.POST(ENDPOINT, programInfo);

  static update = programInfo => Request.PUT(ENDPOINT, programInfo);

  static updateLoyaltyTransitions = obj => Request.PUT(`${ENDPOINT}/trigger`, obj);

  static delete = programId => Request.DELETE(ENDPOINT, { id: programId });

  static activate = ids => Request.PUT(`${ENDPOINT}/activate`, { ids });

  static deactivate = ids => Request.PUT(`${ENDPOINT}/deactivate`, { ids });
}

export default LoyaltyProgramsAPI;
