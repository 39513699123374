export const NEW_CUSTOMER = {
  phoneNumber: '',
  firstName: '',
  lastName: '',
  dayOfBirth: 0,
  loyaltyProgramId: '',
  monthOfBirth: 0,
  yearOfBirth: 1970,
  active: true,
  discountPercent: '',
  lp: 0,
  remarks: '',
  gender: '',
  addressList: [],
};

export const VALIDATION_SCHEMA = {
  phoneNumber: {
    presence: {
      allowEmpty: false,
    },
  },
  loyaltyProgramId: {
    presence: {
      allowEmpty: false,
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
    },
  },
  firstName: {
    presence: {
      allowEmpty: false,
    },
  },
};
