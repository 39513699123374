import { action, runInAction, observable, computed } from 'mobx';

import NavigationAPI from 'api/navigation';

class NavigationStore {
  @observable list = [];
  @observable loading = false;

  @computed get hasInventory() {
    return this.list.existingRecipes
  }

  @action
  startFetch = () => {
    runInAction(() => {
      this.loading = true;
    });
  };

  endFetch = () => {
    runInAction(() => {
      this.loading = false;
    });
  };

  changeShop = () => {
    runInAction(() => {
      this.list.singleShop = false;
    });
  };

  @action
  fetchListPage = async () => {
    // this.startFetch();

    try {
      const response = await NavigationAPI.getNavigationEntity();
      runInAction(() => {
        this.list = { ...response };
      });
    } catch (e) {
      throw Error(e.message);
    } finally {
      // this.endFetch();
    }
  };
}

export default NavigationStore;
