import { observable, action, runInAction, computed } from 'mobx';
import moment from 'moment';
import { demicalFormat } from 'utils/demical-format';
import { getUTCOffset } from 'utils/time';
import DashboardAPI from 'api/dashboard';
import { CURRENCY_CODE_TO_SIGN, DASHBOARD_MIX_TYPE } from 'utils/constants';

const { PRODUCT } = DASHBOARD_MIX_TYPE;

const initTipsSummary = {
  data: [],
  totalAmount: '',
  title: 'dashboard.tipsSummary.title',
  subtitle: 'dashboard.sales.tab1.subtitle',
};

const initDataLoaded = {
  dashboard: false,
  sales: false,
  reportsX: false,
  reportsZ: false,
  discounts: false,
  reportsY: false,
  userStats: false,
};

class Analytics {
  constructor(SalesWidget, RetrospectiveWidget, Merchants) {
    this.merchant = Merchants;
    this.salesWidget = SalesWidget;
    this.retrospectiveWidget = RetrospectiveWidget;
    this.initialSalesWidget = SalesWidget;
  }

  @observable mode = PRODUCT;
  @observable ready = false;
  @observable currencySign = '';
  @observable sourceFilter = 'ALL';
  @observable username = '';
  @observable customerFilter = undefined;
  @observable paymentTypeFilter = '';
  @observable tipsSummaryMap = initTipsSummary;
  @observable newDataLoaded = initDataLoaded;
  @observable showModifiers = false;
  @observable productMixWithoutOptionsResponse = [];
  @observable dates = {
    startDate: moment()
      .startOf('day')
      .valueOf(),
    endDate: moment()
      .endOf('day')
      .valueOf(),
  };
  @observable cogsDynamicsReportMix = [];

  @action
  setShowModifiers = value => {
    runInAction(() => (this.showModifiers = value));
  };

  @action
  changeNewDataLoaded = (key, value) => {
    runInAction(() => (this.newDataLoaded[key] = value));
  };

  @action
  resetNewDataLoaded = () => {
    runInAction(() => (this.newDataLoaded = initDataLoaded));
  };

  @action
  changeAnalyticsFilter = (key, value) => {
    runInAction(() => (this[key] = value));
  };

  @action
  setMode = mode => {
    this.mode = mode;
  };

  @computed get currentMode() {
    return this.mode;
  }

  @computed get cogsDynamicsReportMixTableData() {
    return [...this.cogsDynamicsReportMix];
  }

  @action
  fetchAnalyticsData = async (group, requestData) => {
    return group
      ? await DashboardAPI.fetchGroupData(requestData)
      : await DashboardAPI.fetchData(requestData);
  };

  @action
  updateTotalSumPlan = async data => {
    try {
      const { totalSumPlan } = await DashboardAPI.updateTotalSumPlan(data);
      runInAction(() => {
        this.summary.totalSumPlanAmount = totalSumPlan;
      });
    } catch (err) {
      throw err;
    }
  };

  @action
  fetchAnalytics = async (group = false, currency = CURRENCY_CODE_TO_SIGN.USD) => {
    runInAction(() => (this.ready = false));

    const { startDate, endDate } = this.dates;
    const requestData = {
      startDate: startDate + getUTCOffset(startDate),
      endDate: endDate + getUTCOffset(endDate),
      sourceFilter: this.sourceFilter !== 'ALL' ? this.sourceFilter : undefined,
      savedFilterId: this.customerFilter,
      paymentTypeFilter: this.paymentTypeFilter,
      username: this.username,
    };
    try {
      const analyticsData = await this.fetchAnalyticsData(group, requestData);

      this.updateData(analyticsData, currency);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.ready = true));
    }
  };

  @action
  normalizeTipsSummary = data => {
    return {
      data: Object.keys(data)
        .map(k => ({
          type: k,
          income: demicalFormat(data[k]),
        }))
        .sort((a, b) => a.type - b.type),
      title: 'dashboard.tipsSummary.title',
      subtitle: 'dashboard.sales.tab1.subtitle',
      totalAmount: '',
    };
  };

  @action
  updateData = (analyticsData, currency) => {
    if (analyticsData !== null) {
      const {
        summaryHeaderResponse,
        salesRetrospectiveResponse,
        productMixResponse,
        sensitiveOperationResponse,
        productMixCategoryResponse,
        productMixWithoutOptionsResponse,
        tipsSummaryMap,
        cogsDynamicsReport,
      } = analyticsData;
      this.currencySign = currency;
      this.summary = summaryHeaderResponse;
      this.productMix = productMixResponse.list;
      this.productMixWithoutOptionsResponse = productMixWithoutOptionsResponse.list;
      this.cogsDynamicsReportMix = cogsDynamicsReport;
      this.categoryMix = productMixCategoryResponse.list;
      this.salesRetrospective = salesRetrospectiveResponse;
      this.sensitiveOperationResponse = sensitiveOperationResponse;
      this.tipsSummaryMap = this.normalizeTipsSummary(tipsSummaryMap);

      this.retrospectiveWidget.updateData(analyticsData);
      this.salesWidget.updateData(analyticsData, this.currencySign);
    }
  };

  @action
  setStartDate = value => {
    this.dates.startDate = moment(value)
      .startOf('day')
      .valueOf();
    this.resetNewDataLoaded();
  };

  @action
  setEndDate = value => {
    this.dates.endDate = moment(value)
      .endOf('day')
      .valueOf();

    if (this.dates.endDate - this.dates.startDate < 0) {
      this.dates.startDate = moment(value)
        .startOf('day')
        .valueOf();
    }
    this.resetNewDataLoaded();
  };

  @observable summary = {};

  @computed get summaryData() {
    const {
      averageCheck,
      totalGuests,
      monthlyForecast,
      yesterdayGuests,
      sameDayLastWeekGuests,
      yesterdayAverageCheck,
      sameDayLastWeekAverageCheck,
      yesterdayMonthlyForecast,
      sameDayLastWeekMonthlyForecast,
      totalOrdersCount,
      yesterdayTotalOrdersCount,
      sameDayLastWeekTotalOrdersCount,
      totalSumPlanAmount,
      ...rest
    } = this.summary;

    return {
      totalOrdersCount: {
        totalOrdersCount,
        yesterdayTotalOrdersCount,
        sameDayLastWeekTotalOrdersCount,
      },
      // totalGuests: { totalGuests, yesterdayGuests, sameDayLastWeekGuests },
      averageCheck: {
        averageCheck: `${this.currencySign} ${demicalFormat(averageCheck)}`,
        yesterdayAverageCheck: `${this.currencySign} ${yesterdayAverageCheck}`,
        sameDayLastWeekAverageCheck: `${this.currencySign} ${sameDayLastWeekAverageCheck}`,
      },
      monthlyForecast: {
        monthlyForecast: `${this.currencySign} ${demicalFormat(monthlyForecast)}`,
        yesterdayMonthlyForecast: `${this.currencySign} ${yesterdayMonthlyForecast}`,
        sameDayLastWeekMonthlyForecast: `${this.currencySign} ${sameDayLastWeekMonthlyForecast}`,
        totalSumPlanAmount: `${this.currencySign} ${demicalFormat(totalSumPlanAmount, 0)}`,
        totalSumPlanAmountValue: totalSumPlanAmount,
        totalSumPlanAmountImplPct: `${((monthlyForecast / totalSumPlanAmount) * 100).toFixed(2)}%`,
      },
      percents: { ...rest },
    };
  }

  @observable productMix = [];

  @computed get productMixTableData() {
    const data = !this.showModifiers
      ? !this.productMixWithoutOptionsResponse
        ? this.productMix
        : this.productMixWithoutOptionsResponse
      : this.productMix;
    return data.map(
      ({
        item,
        category,
        sold,
        soldPct,
        received,
        receivedPct,
        modifiers,
        spent,
        kitchenStationName,
      }) => ({
        name: item,
        category,
        sold: `${sold} (${soldPct} %)`,
        soldValue: sold,
        received: `${this.currencySign} ${received} (${receivedPct} %)`,
        receivedValue: received,
        soldPctValue: soldPct,
        receivedPctValue: receivedPct,
        modifiers,
        spent,
        kitchenStationName,
      }),
    );
  }

  @observable categoryMix = [];

  @computed get categoryMixTableData() {
    return this.categoryMix.map(item => {
      const { category, sold, soldPct, received, kitchenStationName } = item;

      return {
        ...item,
        category,
        sold: `${sold} (${soldPct} %)`,
        received,
        kitchenStationName,
      };
    });
  }

  @action
  resetAnalyticsStore = () => {
    runInAction(() => {
      this.ready = false;
      this.currencySign = '';
      this.summary = {};
      this.salesRetrospective = {};
      this.productMix = [];
      this.categoryMix = [];
      this.currencySign = '';
      this.salesWidget = this.initialSalesWidget;
    });
  };
}

export default Analytics;
