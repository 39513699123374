import { action, computed, observable, runInAction } from 'mobx';
import uuid from 'uuid/v4';
import validate from 'validate.js';

import { convertTaxToPrice, deductTax, toFixedTwo } from 'utils/numbers';

import {
  EMPTY_OPTION,
  EMPTY_PROPERTY,
  PROPERTY_VALIDATION_SCHEMA,
  PROPERTY_EDIT_VALIDATION_SCHEMA,
} from './constants';

class PropertyEditor {
  constructor(ProductEditor, MerchantsGroup, Merchant) {
    this.productEditor = ProductEditor;
    this.merchantStore = Merchant;
    this.merchantGroupStore = MerchantsGroup;
    this.onSave = this.productEditor.createProperty;
  }

  initProperty = (name = '', radio = false, productPropertyId = '', compulsory = false) => ({
    ...EMPTY_PROPERTY,
    name,
    radio,
    compulsory,
    productPropertyId,
    productPropertyOptions: [this.initOption()],
  });

  @observable property = this.initProperty();
  @observable visible = false;
  @observable validationErrors = {};

  @action
  open = () => {
    this.visible = true;
  };

  @computed get VALIDATION_SCHEMA() {
    return this.property.merchantGroupId
      ? PROPERTY_EDIT_VALIDATION_SCHEMA
      : PROPERTY_VALIDATION_SCHEMA;
  }

  @action
  validateProperty = () => {
    this.validationErrors = validate(this.property, this.VALIDATION_SCHEMA) || {};
  };

  @computed get isValid() {
    const { productPropertyOptions } = this.property;
    const errors = validate(this.property, this.VALIDATION_SCHEMA);
    const optionsAreValid = productPropertyOptions.every(({ name, price }) => name && price >= 0);

    return !!(!errors && optionsAreValid);
  }

  @action
  close = () => {
    this.visible = false;
  };

  @action
  create = (name, radio, productPropertyId, compulsory) => {
    this.property = this.initProperty(name, radio, productPropertyId, compulsory);
    this.onSave = this.productEditor.createProperty;
    this.open();
  };

  @action
  edit = async id => {
    const property = await this.productEditor.fetchProperty(id);
    const { turnoverTax, taxInclusive, taxManagedByChain, currency } = this.merchantGroupStore.currentGroup;
    const {
      taxInclusive: restaurantTaxInclusive,
      turnoverTax: restaurantTurnoverTax,
    } = this.merchantStore.info.merchantConfiguration;

    const taxFee = taxManagedByChain ? turnoverTax : restaurantTurnoverTax
    let isTaxInclusive = false
    if (taxManagedByChain) {
      isTaxInclusive = taxInclusive;
    } else {
      isTaxInclusive = restaurantTaxInclusive;
    }

    const ppoWithTax = property.productPropertyOptions.map(ppo => {
      return {
        ...ppo,
        price: !isTaxInclusive ? toFixedTwo(ppo.price) : deductTax(ppo.sellPrice, taxFee),
        sellPrice: isTaxInclusive ? toFixedTwo(ppo.sellPrice) : convertTaxToPrice(ppo.price, taxFee),
      };
    });

    runInAction(() => {
      this.property = { ...property, productPropertyOptions: ppoWithTax };
      this.onSave = this.productEditor.updateProperty;
      this.open();
    });
  };

  getSelledPropducOption = (options = []) => {
    return options.map(option => ({
      ...option,
      // sellPrice: option.price,
      // price:
      //   option.sellPrice || deductTax(option.price, this.merchantStore.currentGroup.turnoverTax),
    }));
  };

  @action
  save = async (setMeal = false, menuId) => {
    await this.onSave({
      ...this.property,
      menuIds: [menuId],
      productPropertyOptions: this.getSelledPropducOption(this.property.productPropertyOptions),
      setMeal,
    });

    this.close();
  };

  @action
  changeName = name => {
    if (PROPERTY_VALIDATION_SCHEMA['name']) {
      this.validationErrors['name'] = validate.single(name, PROPERTY_VALIDATION_SCHEMA['name']);
    }

    runInAction(() => {
      this.property.name = name;
    });
  };

  @action
  toggleCompulsory = () => {
    this.property.compulsory = !this.property.compulsory;
  };

  initOption = () => ({
    tempId: uuid(),
    sellPrice: 0.0,
    ...EMPTY_OPTION,
  });

  findOptionIndex = optionId => {
    return this.property.productPropertyOptions
      .map(option => ({ ...option, sellPrice: option.sellPrice || '0.000' }))
      .findIndex(({ id, tempId }) => {
        return id ? id === optionId : tempId === optionId;
      });
  };

  @action
  addPropertyOption = () => {
    this.property.productPropertyOptions.push(this.initOption());
  };

  @action
  changePropertyOption = (optionId, key, value) => {
    const index = this.findOptionIndex(optionId);
    const { turnoverTax, taxInclusive, taxManagedByChain, currency } = this.merchantGroupStore.currentGroup;
    const {
      taxInclusive: restaurantTaxInclusive,
      turnoverTax: restaurantTurnoverTax,
    } = this.merchantStore.info.merchantConfiguration;

    const taxFee = taxManagedByChain ? turnoverTax : restaurantTurnoverTax

    if (PROPERTY_VALIDATION_SCHEMA['categoryId']) {
      this.validationErrors['categoryId'] = validate.single(
        value,
        PROPERTY_VALIDATION_SCHEMA['categoryId'],
      );
    }

    if (key === 'sellPrice') {
      this.property.productPropertyOptions[index].price = deductTax(value, taxFee);
    }
    else if (key === 'price') {
      this.property.productPropertyOptions[index].sellPrice = convertTaxToPrice(value, taxFee);
    }

    runInAction(() => {
      //TODO: investigate categoryId purpose
      this.property.categoryId = value;
      this.property.productPropertyOptions[index][key] = value;
    });
  };

  @action
  removePropertyOption = optionId => {
    const { productPropertyOptions } = this.property;

    this.property.productPropertyOptions = productPropertyOptions.filter(option => {
      return option.id ? option.id !== optionId : option.tempId !== optionId;
    });
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
  };
}

export default PropertyEditor;
