import APIConnector from './utils/APIConnector';
import { extractFiltersQuery } from './utils/helpers';

const Request = APIConnector.instance;
const ENDPOINT = '/sales';

class SalesAPI {
  static list = (page, filters) => {
    const filtersQuery = extractFiltersQuery(filters);

    return Request.GET(`${ENDPOINT}/list?page=${page}${filtersQuery}`);
  };

  static getAuditHistory = async orderId => await Request.GET(`${ENDPOINT}/audit/${orderId}`);

  static getOrderById = async id => await Request.GET(`${ENDPOINT}/order/id/${id}`);

  static getOrderByUid = async uid => await Request.GET(`${ENDPOINT}/order/${uid}`);

  static downloadEReceipt = async id => await Request.GET(`${ENDPOINT}/${id}/e-receipt`);

  static exportSalesToCsv = async filters => {
    const filtersQuery = extractFiltersQuery(filters);
    return await Request.GET(`${ENDPOINT}/export/csv?${filtersQuery}`);
  };

  static getUserStats = filters => {
    const filtersQuery = extractFiltersQuery(filters);
    return Request.GET(`${ENDPOINT}/userStats?${filtersQuery}`);
  };

  static getFilterPaymentMethod = merchantId => {
    return Request.GET(`/merchant/${merchantId}/filterPaymentMethod`);
  };

  static getFilterLegalEntity = () => {
    return Request.GET('/legal-entity');
  };
}

export default SalesAPI;
