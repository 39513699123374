import { action, observable, runInAction } from 'mobx';
import DashboardAPI from 'api/dashboard';
import { getUTCOffset } from 'utils/time';
import { getLocalizedString } from 'i18n/utils';

const STATUS = 'status';

class ProblemOrders {
  constructor(Analytics) {
    this.Analytics = Analytics;
  }

  @observable items = [];
  @observable paginateOptions = {};

  @action
  getTableFormat = currency => {
    return [
      {
        name: getLocalizedString('sales.order.payment.header.phoneNumber'),
        property: 'selfServiceUserMobile',
        variant: ['left'],
      },
      {
        name: getLocalizedString('tables.data.number'),
        property: 'businessDayNumber',
        variant: ['left'],
      },
      {
        name: `${getLocalizedString('sales.data.totalPrice')}, ${currency}`,
        property: 'totalPrice',
        variant: ['left'],
      },
      {
        name: getLocalizedString('sales.data.leadTime'),
        property: 'leadTime',
        variant: ['left'],
      },
      {
        name: getLocalizedString('sales.data.customerFeedback'),
        property: 'customerFeedback',
        variant: ['left'],
      },
      {
        name: getLocalizedString('sales.data.remarks'),
        property: 'remarks',
        variant: ['left'],
      },
      {
        name: getLocalizedString('sales.data.orderStatus'),
        property: STATUS,
        variant: ['left'],
      },
    ];
  };

  @action
  fetchTable = async ({ page = 0 } = {}, { admin } = {}) => {
    const { sourceFilter, customerFilter, dates } = this.Analytics;
    const { startDate, endDate } = dates;

    const requestData = {
      startDate: startDate + getUTCOffset(startDate),
      endDate: endDate + getUTCOffset(endDate),
      sourceFilter: sourceFilter !== 'ALL' ? sourceFilter : undefined,
      savedFilterId: customerFilter,
    };

    const { items, totalPages, last, totalElements } = await DashboardAPI.fetchProblemOrders(
      { page, size: 10, ...requestData },
      admin,
    );
    runInAction(() => {
      this.items = items;
      this.paginateOptions = {
        last,
        totalElements,
        totalPages,
      };
    });
  };
}

export default ProblemOrders;
