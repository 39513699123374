export const adminRoutes = {
  adminDashboard: '/admin/',
  adminReports: '/admin/reports',
  adminRestaurants: '/admin/restaurants',
  adminNotifications: '/admin/notifications',
  adminBillings: '/admin/billing',
  adminLaunch: '/admin/launch',
  adminChecklist: '/admin/checklist',
  adminCrmPages: [
    '/admin/crm',
    '/admin/cms',
    '/admin/loyalty',
    'admin/vouchers/new',
    'admin/customers/new',
    '/admin/promotions',
    '/admin/customers',
    '/admin/loyalty/new',
    '/admin/broadcast-message',
    '/admin/loyalty-transitions',
  ],
  adminCmsPages: ['/admin/posts', '/admin/posts/new'],
  adminRestaurantsPages: ['/admin/restaurants', '/admin/restaurants/new'],
  adminMenuPages: ['/admin/menu', '/admin/menu/edit'],
  adminMerchantNew: '/admin/restaurants/new',
  adminMerchant: '/admin/merchant',
  adminCustomersNew: '/admin/customers',
  adminLoyaltyNew: '/admin/loyalty',
  adminBroadcastMessageNew: '/admin/broadcast-message',
  adminLoyaltyTransitions: '/admin/loyalty-transitions',
  adminMenu: '/admin/menu',
  adminModifiers: '/admin/menu/:currentMenu/modifiers',
  adminLegalEntity: '/admin/legal-entity',
  adminMenuEdit: '/admin/menu/edit',
  adminMenuCreate: '/admin/menu/create',
  adminProducts: '/admin/menu/:currentMenu/product/:id',
  adminProductsNew: '/admin/menu/products/new',
  adminModifiersNew: '/admin/menu/:currentMenu/modifiers/new',
  adminModifiersEdit: '/admin/menu/:currentMenu/modifiers/:modifierId',
  adminProduct: '/admin/menu/:currentMenu/product/:id',
  adminPromotions: '/admin/promotions',
  adminMerchantSetting: '/admin/restaurants-settings',
  adminVouchers: '/admin/vouchers',
  adminPosts: '/admin/posts',
  adminVouchersIssues: '/admin/vouchers/issues',
  adminUsers: '/admin/users',
  adminPayment: '/admin/payment',
};
