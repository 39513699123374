import {action, computed, observable} from 'mobx';
import without from 'lodash.without';
import difference from 'lodash.difference';
import validate from 'validate.js';

const WEEK_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const VALIDATION_SCHEMA = {
  tags: function(value, attribute) {
    if (attribute.categories && attribute.categories.length > 0) {
      return null;
    } else {
      return {presence: {allowEmpty: false}};
    }
  },
  categories:  function(value, attribute) {
    if (attribute.tags && attribute.tags.length > 0) {
      return null;
    } else {
      return {presence: {allowEmpty: false}};
    }
  },
  fromHour: function(value, attribute) {
    return {
      presence: {allowEmpty: false},
      numericality: {
        onlyInteger: true,
        lessThanOrEqualTo: Number(attribute.toHour),
      }
    };
  },
  fromMinutes: function(value, attribute) {
    const {fromHour, toHour, toMinutes} = attribute;

    if (fromHour === toHour) {
      return {
        presence: {allowEmpty: false},
        numericality: {
          onlyInteger: true,
          lessThan: Number(toMinutes),
        }
      }
    } else {
      return {
        presence: {allowEmpty: false},
        numericality: {onlyInteger: true},
      };
    }
  },
  toHour: {
    presence: {allowEmpty: false},
    numericality: {onlyInteger: true},
  },
  toMinutes: {
    presence: {allowEmpty: false},
    numericality: {onlyInteger: true},
  },
  weekDays: {
    presence: {allowEmpty: false},
  },
  discountPercentage: {
    presence: {allowEmpty: false},
    numericality: {
      greaterThan: 0,
      lessThan: 100,
    }
  },
};

class HappyHours {
  constructor(PromotionEditor, rules) {
    this.editor = PromotionEditor;

    if (rules) {
      this.rules = rules;
    }
  }

  @observable rules = {
    tags: [],
    categories: [],
    fromHour: '',
    fromMinutes: '',
    toHour: '',
    toMinutes: '',
    weekDays: [],
    discountPercentage: '',
  };

  @computed get availableTags() {
    return difference(this.editor.tags, this.rules.tags).map((tag) => ({
      text: tag,
      value: tag,
    }));
  }

  @action
  addTag = (tag) => {
    this.rules.tags.push(tag);
    this.validateValue('tags', this.rules.tags);
  };

  @action
  removeTag = (tag) => {
    this.rules.tags = without(this.rules.tags, tag);
    this.validateValue('tags', this.rules.tags);
  };

  @computed get availableCategories() {
    return difference(this.editor.categories, this.rules.categories).map((category) => ({
      text: category,
      value: category,
    }));
  }

  @action
  addCategory = (category) => {
    this.rules.categories.push(category);
    this.validateValue('categories', this.rules.categories);
  };

  @action
  removeCategory = (category) => {
    this.rules.categories = without(this.rules.categories, category);
    this.validateValue('categories', this.rules.categories);
  };

  @action
  changeHours = (hoursType, hours) => {
    if ((hours >=0) && (hours < 24)) {
      this.validateValue(hoursType, hours);
      this.rules[hoursType] = hours;
    }
  };

  @action
  changeMinutes = (minutesType, minutes) => {
    if ((minutes >=0) && (minutes < 60)) {
      this.validateValue(minutesType, minutes);
      this.rules[minutesType] = minutes;
    }
  };

  @computed get weekDays() {
    return this.rules.weekDays.map((day) => WEEK_DAYS[day - 1]);
  }

  @computed get availableWeekDays() {
    return difference(WEEK_DAYS, this.weekDays).map((day) => ({
      text: day,
      value: (WEEK_DAYS.indexOf(day) + 1),
    }));
  }

  @action
  addWeekDay = (day) => {
    this.rules.weekDays.push(day);
    this.validateValue('weekDays', this.rules.weekDays);
  };

  @action
  removeWeekDay = (day) => {
    const index = WEEK_DAYS.indexOf(day);

    this.rules.weekDays = without(this.rules.weekDays, (index + 1));
    this.validateValue('weekDays', this.rules.weekDays);
  };

  @action
  changeDiscount = (discount) => {
    this.validateValue('discountPercentage', discount);
    this.rules.discountPercentage = discount;
  };

  @observable validationErrors = {};


  @action
  validateValue = (key, value) => {
    const validator = VALIDATION_SCHEMA[key];

    if (validator) {
      this.validationErrors[key] = validate.single(value, validator);
    }
  };

  @action
  validateRules = () => {
    this.validationErrors = validate(this.rules, VALIDATION_SCHEMA) || {};
  };

  @computed get isValid() {
    const validationErrors = validate(this.rules, VALIDATION_SCHEMA);

    return !validationErrors;
  }
}

export default HappyHours;