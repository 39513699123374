const fadeInFromBottom = {
  from(height) {
    return {
      opacity: 0,
      transform: `translate3d(0px, ${height}px, 0px)`,
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 999
    }
  },
  enter: {
    opacity: 1,
    transform: 'translate3d(0, 0px,0)'
  },
  leave(height) {
    return {
      zIndex: -1,
      opacity: 0,
      transform: `translate3d(0px, ${height}px, 0px)`,
    }
  }
};

const fadeIn = {
  from: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
  }
};

export default {
  fadeIn,
  fadeInFromBottom
}