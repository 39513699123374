import LegalEntityStore from './LegalEntity';
import LegalEntityEditorStore from './LegalEntityEditor';

const LegalEntity = new LegalEntityStore();
const LegalEntityEditor = new LegalEntityEditorStore(LegalEntity);

export default {
  LegalEntity,
  LegalEntityEditor,
};
