import { observable, action, runInAction, computed } from 'mobx';
import { toFixedTwo, deductTax } from 'utils/numbers';
import ModifiersAPI from 'api/modifiers';
import { getLocalizedString } from 'i18n/utils';
import { getUniqueObjectValues } from 'utils/unique';

const NAME = 'name';
const RADIO = 'radio';
const POS_ENABLED = 'posEnabled';
const SELF_SERVICE_ENABLED = 'selfServiceEnabled';
const STOCK_ENABLED = 'stockEnabled';
const CONTENT = 'content';

export const TABLE_FORMAT = [
  {
    name: getLocalizedString('products.data.name'),
    property: NAME,
    variant: ['left'],
    width: '26%',
  },
  {
    name: getLocalizedString('modifiers.tadleData.type.title'),
    property: RADIO,
    variant: ['left'],
    width: '26%',
  },
  {
    name: '',
    property: STOCK_ENABLED,
    variant: ['left'],
    width: '26%',
  },
  {
    name: 'POS',
    property: POS_ENABLED,
    variant: ['left'],
    tooltipMessage: { id: 'products.data.header.pos', defaultMessage: 'POS' },
    width: '10%',
  },
  {
    name: 'SS',
    property: SELF_SERVICE_ENABLED,
    variant: ['left'],
    tooltipMessage: { id: 'products.data.header.service', defaultMessage: 'SS' },
    width: '10%',
  },
];

export const DROP_LIST_FORMAT = [
  {
    name: getLocalizedString('products.data.name'),
    property: NAME,
    variant: ['left'],
  },
  {
    name: getLocalizedString('modifiers.tadleData.requiredModifier.title'),
    property: 'price',
    variant: ['left'],
  },
  {
    name: getLocalizedString('modifiers.tadleData.requiredModifier.title'),
    property: 'stock',
    variant: ['left'],
  },
  {
    name: getLocalizedString('modifiers.tadleData.type.title'),
    property: 'stores',
    variant: ['left'],
    width: '14.2%',
  },
];

export const DROP_TABLE_FORMAT = [
  ...DROP_LIST_FORMAT,
  {
    name: 'POS',
    property: CONTENT,
    variant: ['left'],
    width: '14.2%',
  },
  {
    name: 'SS',
    property: CONTENT,
    variant: ['left'],
    width: '14.2%',
  },
];

class Modifiers {
  constructor(MerchantsGroup, Merchant) {
    this.merchantGroupStore = MerchantsGroup;
    this.merchantStore = Merchant;
  }

  @observable list = [];
  @observable tableFormat = TABLE_FORMAT;
  @observable dropTableFormat = DROP_TABLE_FORMAT;
  @observable dropListFormat = DROP_LIST_FORMAT;
  @observable merchatSettings = {};
  @observable groupSettings = {};
  @observable settingsClone = {};
  @observable loading = true;
  @observable paginateOptions = {};
  
  @computed get taxInclusive() {
    const { turnoverTax, taxInclusive, taxManagedByChain, currency } = this.merchantGroupStore.currentGroup;
    const {
      taxInclusive: restaurantTaxInclusive,
      turnoverTax: restaurantTurnoverTax,
    } = this.merchantStore.info.merchantConfiguration;
    const taxFee = taxManagedByChain ? turnoverTax : restaurantTurnoverTax
    let isTaxInclusive = false
    if (taxManagedByChain) {
      isTaxInclusive = taxInclusive;
    } else {
      isTaxInclusive = restaurantTaxInclusive;
    }
    return isTaxInclusive
  }

  @computed get taxFee() {
    const { turnoverTax, taxInclusive, taxManagedByChain, currency } = this.merchantGroupStore.currentGroup;
    const {
      taxInclusive: restaurantTaxInclusive,
      turnoverTax: restaurantTurnoverTax,
    } = this.merchantStore.info.merchantConfiguration;

    const taxFee = taxManagedByChain ? turnoverTax : restaurantTurnoverTax
    return taxFee
  }

  @action
  getProductSettingsByMerchant = async ({ ids, optionIds }) => {
    try {
      const settings = await ModifiersAPI.getProductSettingsByMerchant({ ids, optionIds });

      runInAction(() => {
        this.merchatSettings = { ...this.merchatSettings, ...settings };
        this.settingsClone = { ...this.settingsClone, ...settings };
      });
    } catch (e) {
      console.warn(e);
    }
  };

  @action
  getProductSettingsByGroup = async ({ ids, optionIds }) => {
    try {
      const settings = await ModifiersAPI.getProductSettingsByGroup({ ids, optionIds });
      runInAction(() => {
        this.groupSettings = { ...this.groupSettings, ...settings };
        this.settingsClone = { ...this.settingsClone, ...settings };
      });
    } catch (e) {
      console.warn(e);
    }
  };

  @action
  getProductStockByMerchant = async ids => {
    try {
      const settings = await ModifiersAPI.getProductStockByMerchant({ ids });
      runInAction(() => {
        this.merchatSettings = {
          ...this.merchatSettings,
          // productPropertyRecipeMap: {
          //   ...this.merchatSettings.productPropertyRecipeMap,
          //   ...settings.productPropertyRecipeMap,
          // },
          // stockMap: { ...this.merchatSettings.stockMap, ...settings.stockMap },
        };
        this.settingsClone = {
          ...this.settingsClone,
          // productPropertyRecipeMap: {
          //   ...this.settingsClone.productPropertyRecipeMap,
          //   ...settings.productPropertyRecipeMap,
          // },
          // stockMap: { ...this.settingsClone.stockMap, ...settings.stockMap },
        };
      });
    } catch (e) {
      console.warn(e);
    }
  };

  @action
  cancelModifiersСhanges = admin => {
    runInAction(() => {
      const settings = admin ? 'groupSettings' : 'merchatSettings';
      this[settings] = {
        ...this.settingsClone,
        posChannelMap: { ...this.settingsClone.posChannelMap },
        selfServiceChannelMap: { ...this.settingsClone.selfServiceChannelMap },
        optionPosChannelMap: { ...this.settingsClone.optionPosChannelMap },
        optionSelfServiceChannelMap: { ...this.settingsClone.optionSelfServiceChannelMap },
        // stockMap: { ...this.settingsClone.stockMap },
        optionPriceMap: { ...this.settingsClone.optionPriceMap },
      };
    });
  };

  @action
  getCountChanges = admin => {
    const settings = admin ? 'groupSettings' : 'merchatSettings';
    let newSettings = {
      posChannelMap: { ...this[settings].posChannelMap },
      selfServiceChannelMap: { ...this[settings].selfServiceChannelMap },
      optionPosChannelMap: { ...this[settings].optionPosChannelMap },
      optionSelfServiceChannelMap: { ...this[settings].optionSelfServiceChannelMap },
      // stockMap: { ...this[settings].stockMap },
      optionPriceMap: { ...this[settings].optionPriceMap },
    };

    const posChannelMap = getUniqueObjectValues(newSettings, 'posChannelMap', this.settingsClone);
    const ssChannelMap = getUniqueObjectValues(
      newSettings,
      'selfServiceChannelMap',
      this.settingsClone,
    );
    const optionPosChannelMap = getUniqueObjectValues(
      newSettings,
      'optionPosChannelMap',
      this.settingsClone,
    );
    const optionSsChannelMap = getUniqueObjectValues(
      newSettings,
      'optionSelfServiceChannelMap',
      this.settingsClone,
    );

    let idToStock = {};
    let idToPrice = {};

    // if (
    //   !admin &&
    //   this.settingsClone.stockMap &&
    //   Object.keys(this.settingsClone.stockMap).length > 0
    // ) {
    //   idToStock = getUniqueObjectValues(newSettings, 'stockMap', this.settingsClone);
    // }

    if (
      this.settingsClone.optionPriceMap &&
      Object.keys(this.settingsClone.optionPriceMap).length > 0
    ) {
      idToPrice = getUniqueObjectValues(newSettings, 'optionPriceMap', this.settingsClone);
    }

    const getLength = obj => {
      return Number(Object.keys(obj).length);
    };

    return (
      getLength(idToStock || {}) +
      getLength(posChannelMap) +
      getLength(ssChannelMap) +
      getLength(optionPosChannelMap) +
      getLength(optionSsChannelMap) +
      getLength(idToPrice)
    );
  };

  @action
  changeMerchatSettings = (key, id, value) => {
    runInAction(() => {
      const newSettings = { ...this.merchatSettings[key] };
      newSettings[id] = value;
      this.merchatSettings = { ...this.merchatSettings, [key]: { ...newSettings } };
    });
  };

  @action
  changeGroupSettings = (key, id, value) => {
    runInAction(() => {
      const newSettings = { ...this.groupSettings };
      newSettings[key][id] = value;
      this.groupSettings = { ...newSettings };
    });
  };

  @action
  confirmModifiersСhanges = async (admin, menuId, singleShop) => {
    const settings = admin ? 'groupSettings' : 'merchatSettings';
    let newSettings = {
      posChannelMap: { ...this[settings].posChannelMap },
      selfServiceChannelMap: { ...this[settings].selfServiceChannelMap },
      optionPosChannelMap: { ...this[settings].optionPosChannelMap },
      optionSelfServiceChannelMap: { ...this[settings].optionSelfServiceChannelMap },
      // stockMap: { ...this[settings].stockMap },
      optionPriceMap: { ...this[settings].optionPriceMap },
    };

    const posChannelMap = getUniqueObjectValues(newSettings, 'posChannelMap', this.settingsClone);
    const ssChannelMap = getUniqueObjectValues(
      newSettings,
      'selfServiceChannelMap',
      this.settingsClone,
    );
    const optionPosChannelMap = getUniqueObjectValues(
      newSettings,
      'optionPosChannelMap',
      this.settingsClone,
    );
    const optionSsChannelMap = getUniqueObjectValues(
      newSettings,
      'optionSelfServiceChannelMap',
      this.settingsClone,
    );

    let params = { posChannelMap, ssChannelMap, optionPosChannelMap, optionSsChannelMap };
    // if (!admin) {
    //   params.idToStock = getUniqueObjectValues(newSettings, 'stockMap', this.settingsClone);
    // }

    if (admin || singleShop) {
      let idToPrice = getUniqueObjectValues(newSettings, 'optionPriceMap', this.settingsClone);
      // if (this.taxInclusive) {
      //   Object.keys(idToPrice).map(i => {
      //     idToPrice[i] = deductTax(idToPrice[i], this.taxFee) || 0;
      //   });
      // }

      params.idToPrice = idToPrice;
    }

    runInAction(() => {
      this.settingsClone = {
        ...this[settings],
        posChannelMap: { ...this[settings].posChannelMap },
        selfServiceChannelMap: { ...this[settings].selfServiceChannelMap },
        optionPosChannelMap: { ...this[settings].optionPosChannelMap },
        optionSelfServiceChannelMap: { ...this[settings].optionSelfServiceChannelMap },
        // stockMap: { ...this[settings].stockMap },
        optionPriceMap: { ...this[settings].optionPriceMap },
      };
    });
    try {
      if (admin) {
        const merchantId = this.merchantStore.info.id
        return await ModifiersAPI.bulkMerchantGroupUpdate(params, merchantId);
      } else {
        return await ModifiersAPI.bulkMerchantUpdate(params);
      }
    } catch (e) {
      await this.fetchModifiersList(menuId);
      throw new Error(e.message);
    }
  };

  @action
  setProductStockByMerchant = async (id, value) => {
    runInAction(() => {
      this.merchatSettings = {
        ...this.merchatSettings,
        // stockMap: { ...this.merchatSettings.stockMap, [id]: value },
      };
    });
  };

  @action
  setLoading = value => {
    runInAction(() => {
      this.loading = value;
    });
  };

  @action
  fetchModifiersList = async (menuId, admin, page, size) => {
    this.setLoading(true);
    if (menuId) {
      try {
        const { items, last, totalElements, totalPages } = await ModifiersAPI.list(
          menuId,
          page,
          size,
        );
        const allOptions = items.reduce((acc, i) => [...acc, ...i.productPropertyOptions], []);
        runInAction(() => {
          this.list = items;
          this.paginateOptions = { last, totalElements, totalPages };

          const price = this.taxInclusive ? 'sellPrice' : 'price';

          this.groupSettings.optionPriceMap = allOptions
            .map(i => ({ ...i }))
            .reduce((acc, i) => ((acc[i.id] = toFixedTwo(i[price])), acc), {});
          this.merchatSettings.optionPriceMap = allOptions
            .map(i => ({ ...i }))
            .reduce((acc, i) => ((acc[i.id] = toFixedTwo(i[price])), acc), {});
          this.settingsClone.optionPriceMap = allOptions
            .map(i => ({ ...i }))
            .reduce((acc, i) => ((acc[i.id] = toFixedTwo(i[price])), acc), {});
          if (!admin) {
            this.getProductStockByMerchant(allOptions.reduce((acc, item) => [...acc, item.id], []));
          }
          const ids = items.reduce((acc, item) => [...acc, item.id], []);
          const optionIds = allOptions.reduce((acc, { id }) => [...acc, id], []);
          if (admin) {
            this.getProductSettingsByGroup({ ids, optionIds });
          } else {
            this.getProductSettingsByMerchant({ ids, optionIds });
          }
          this.setLoading(false);
        });
      } catch (error) {
        throw new Error(error.message);
      }
    }
  };

  @action
  removeModifier = async id => {
    try {
      await ModifiersAPI.deleteModifier({
        id,
      });
    } catch (error) {
      throw new Error(error.message);
    } finally {
      runInAction(() => {
        const newList = [...this.list];
        this.list = newList.filter(i => i.id !== id);
      });
    }
  };

  @action
  switchChannel = async (id, on, admin, field) => {
    runInAction(() => {
      const settings = { ...this[admin ? 'groupSettings' : 'merchatSettings'] };
      settings[field][id] = on;
      this[admin ? 'groupSettings' : 'merchatSettings'] = { ...settings };
    });
  };
}

export default Modifiers;
