import { observable, action, runInAction } from 'mobx';
import uuid from 'uuid/v4';
import validate from 'validate.js';
import history, { routes } from 'routes';
import ModifiersAPI from 'api/modifiers';
import IngredientAPI from 'api/ingredient';
import DictionaryAPI from 'api/dictionary';
import { getLocalizedString } from 'i18n/utils';
import { convertTaxToPrice, deductTax, toFixedTwo } from 'utils/numbers';
import { NONE, INGREDIENT_CARD, PRODUCT } from 'utils/constants';
import get from 'lodash/get';
import { EMPTY_PROPERTY, EMPTY_OPTION } from './constants';
import { getFullCookingPercent } from '../../../utils/get-full-cooking-persent';

const OPTION_RECIPE = {
  productPropertyOptionRecipeItems: [],
};

class ModifiersEditor {
  constructor(Modifiers, MerchantsGroup, Merchant) {
    this.modifiers = Modifiers;
    this.merchantStore = MerchantsGroup;
    this.merchant = Merchant;
  }

  @observable modifier = this.initModifier();
  @observable validationErrors = {};
  @observable optionRecipe = OPTION_RECIPE;
  @observable option = {};
  @observable ingredientsData = [];
  @observable searchIngredientsData = [];
  @observable ingredientsUnitsOptions = [];
  @observable storesStatus = NONE;
  @observable optionRecipeItemsValidationErrors = {};
  @observable taxInclusive = this.merchantStore.currentGroup.taxInclusive;
  @observable isUpdateIngredientsCard = false;

  initModifier = (name = '', radio = false, productPropertyId = '', compulsory = false) => ({
    ...EMPTY_PROPERTY,
    name,
    radio,
    compulsory,
    productPropertyId,
    productPropertyOptions: [this.initOption()],
    maxQuantity: '',
    stockEnabled: true,
  });

  initOption = () => ({
    tempId: uuid(),
    sellPrice: 0.0,
    ...EMPTY_OPTION,
  });

  @action
  fetchModifier = async id => {
    let data = [];
    try {
      data = await ModifiersAPI.fetchModifier(id);
    } catch (error) {
      throw new Error(error.message);
    }

    const { turnoverTax, taxInclusive, taxManagedByChain, currency } = this.merchantStore.currentGroup;
    const {
      taxInclusive: restaurantTaxInclusive,
      turnoverTax: restaurantTurnoverTax,
    } = this.merchant.info.merchantConfiguration;

    const taxFee = taxManagedByChain ? turnoverTax : restaurantTurnoverTax
    let isTaxInclusive = false
    if (taxManagedByChain) {
      isTaxInclusive = taxInclusive;
    } else {
      isTaxInclusive = restaurantTaxInclusive;
    }

    const ppoWithTax = data.productPropertyOptions.map(ppo => {
      return {
        ...ppo,
        price: !isTaxInclusive ? ppo.price : deductTax(ppo.sellPrice, taxFee),
        sellPrice: isTaxInclusive ? ppo.sellPrice : convertTaxToPrice(ppo.price, taxFee),
      };
    });

    runInAction(() => {
      this.modifier = { ...data, productPropertyOptions: ppoWithTax };
    });
  };

  @action
  setNewStockEnabled = (modifierId, value) => {
    runInAction(() => {
      this.modifiers.list = this.modifiers.list.map(item => {
        if (item.id === modifierId) {
          return { ...item, stockEnabled: value };
        } else {
          return item;
        }
      });
    });
  };

  getSelledPropducOption = (options = []) => {
    return options.map(option => ({
      ...option,
      // sellPrice: option.price,
      // price:
      //   option.sellPrice || deductTax(option.price, this.merchantStore.currentGroup.turnoverTax),
    }));
  };

  setModifier = modifier => {
    runInAction(() => {
      this.modifier = { ...modifier };
    });
  };

  @action
  save = async (menuId, { setMeal, create, admin }) => {
    const newModifier = {
      ...this.modifier,
      menuIds: [menuId],
      productPropertyOptions: this.getSelledPropducOption(this.modifier.productPropertyOptions),
      setMeal,
      maxQuantity: !this.modifier.radio ? this.modifier.maxQuantity : null,
    };
    try {
      if (create) {
        await ModifiersAPI.createModifier(newModifier);
      } else {
        await ModifiersAPI.updateModifier(newModifier);
      }
    } catch (error) {
      throw new Error(error.message);
    } finally {
      admin
        ? history.push(`${routes.adminMenu}/${menuId}/modifiers`)
        : history.push(`${routes.menu}/${menuId}/modifiers`);
    }
  };

  @action
  changeModifier = (key, value) => {
    runInAction(() => {
      if (Object.keys(this.validationErrors).length) {
        this.validationErrors = {};
      }
      this.modifier[key] = value;
    });
  };

  @action
  toggleCompulsory = () => {
    this.modifier.compulsory = !this.modifier.compulsory;
  };

  @action
  addPropertyOption = () => {
    this.modifier.productPropertyOptions.push(this.initOption());
  };

  findOptionIndex = optionId => {
    return this.modifier.productPropertyOptions
      .map(option => ({ ...option, sellPrice: option.sellPrice || '0.000' }))
      .findIndex(({ id, tempId }) => {
        return id ? id === optionId : tempId === optionId;
      });
  };

  @action
  changePropertyOption = (optionId, key, value) => {
    const index = this.findOptionIndex(optionId);
    const { turnoverTax, taxInclusive, taxManagedByChain, currency } = this.merchantStore.currentGroup;
    const {
      taxInclusive: restaurantTaxInclusive,
      turnoverTax: restaurantTurnoverTax,
    } = this.merchant.info.merchantConfiguration;

    const taxFee = taxManagedByChain ? turnoverTax : restaurantTurnoverTax

    if (key === 'sellPrice') {
      this.modifier.productPropertyOptions[index].price = deductTax(value, taxFee);
    }
    else if (key === 'price') {
      this.modifier.productPropertyOptions[index].sellPrice = convertTaxToPrice(value, taxFee);
    }

    runInAction(() => {
      if (Object.keys(this.validationErrors).length) {
        this.validationErrors = {};
      }
      this.modifier.categoryId = value;
      this.modifier.productPropertyOptions[index][key] = value;
    });
  };

  @action
  removePropertyOption = optionId => {
    const { productPropertyOptions } = this.modifier;

    this.modifier.productPropertyOptions = productPropertyOptions.filter(option => {
      return option.id ? option.id !== optionId : option.tempId !== optionId;
    });
  };

  @action
  validateProperty = () => {
    const newValidationErrors = {};
    this.modifier.productPropertyOptions.map((item, index) => {
      if (!item.name) {
        newValidationErrors[`name_${index}`] = true;
      }
    });

    if (!this.modifier.name) {
      newValidationErrors['name'] = true;
    }

    this.validationErrors = newValidationErrors;

    return Object.keys(newValidationErrors).length === 0;
  };

  @action
  loadOptions = async (search, prevOptions, page = 0) => {
    let filteredOptions = [];
    let hasMore = false;
    let newItems = [];
    if (!search) {
      const { items, last } = await IngredientAPI.list(page, 20);
      filteredOptions = items;
      newItems = items;
      hasMore = !last;
    } else {
      const { items } = await IngredientAPI.list(0, 20, search);
      filteredOptions = [...items];
    }
    runInAction(() => {
      if (!search) {
        this.ingredientsData = page === 0 ? [...newItems] : [...this.ingredientsData, ...newItems];
      } else {
        this.searchIngredientsData = filteredOptions;
      }
    });
    const slicedOptions = filteredOptions.map(item => ({
      value: item.id,
      label: item.name,
    }));
    return {
      options: slicedOptions,
      hasMore,
    };
  };

  @action
  fetchIngredientsUnits = async () => {
    const { items } = await DictionaryAPI.listUnits();
    runInAction(() => {
      this.ingredientsUnitsOptions = items.map(item => ({
        value: item.name,
        text: getLocalizedString(`ingredient.unit.${item.name.toLowerCase()}`),
        key: item.name,
      }));
    });
  };

  @action
  changeOptionRecipe = (key, value) => {
    if (!this.validationOrderRecipeItems[0]) {
      runInAction(() => {
        this.optionRecipeItemsValidationErrors = {};
      });
    }
    this.optionRecipe[key] = value;
  };

  @action
  changeStoresStatus = value => {
    runInAction(() => {
      this.storesStatus = value;
    });
  };

  @action
  setOption = option => {
    runInAction(() => {
      this.option = option;
    });
  };

  @action
  validationOrderRecipeItems = () => {
    let validationErrors = {};
    if (this.storesStatus === INGREDIENT_CARD) {
      if (this.optionRecipe.productPropertyOptionRecipeItems === 0) {
        throw new Error(getLocalizedString('products.editor.stock.add-field.error'));
      }
      const valueIsNaN = v => v !== v;
      this.optionRecipe.productPropertyOptionRecipeItems.map((item, index) => {
        if (validate(item, { ingredientId: { presence: { allowEmpty: false } } })) {
          validationErrors = { ...validationErrors, [`ingredientId.${index}`]: true };
        }
        if (validate(item, { ingredientUnit: { presence: { allowEmpty: false } } })) {
          validationErrors = { ...validationErrors, [`ingredientUnit.${index}`]: true };
        }
        if (validate(item, { ingredientAmount: { presence: { allowEmpty: false } } })) {
          validationErrors = { ...validationErrors, [`ingredientAmount.${index}`]: true };
        } else if (valueIsNaN(item.ingredientAmount)) {
          validationErrors = { ...validationErrors, [`ingredientAmount.${index}`]: true };
        }
      });
    }
    this.optionRecipeItemsValidationErrors = validationErrors;
    const isValid = Object.keys(validationErrors).length === 0;

    return [isValid, validationErrors];
  };

  @action
  saveIngredientsCard = async newProductRecipe => {
    if (this.isUpdateIngredientsCard) {
      await ModifiersAPI.update(newProductRecipe);
    } else {
      await ModifiersAPI.create(newProductRecipe);
    }
  };

  @action
  optionsRecipeSave = async () => {
    if (this.storesStatus === NONE) {
      return await ModifiersAPI.deleteOptionRecipe(this.option.id);
    } else if (this.storesStatus === INGREDIENT_CARD) {
      const newOptionRecipe = {
        id: this.optionRecipe.id || 0,
        name: this.option.name,
        productPropertyOptionId: this.option.id,
        cookingTimeMs: 0,
        productPropertyOptionRecipeItems: [
          ...this.optionRecipe.productPropertyOptionRecipeItems.map(item => {
            return {
              ...item,
              // createdTimestamp: Date.parse(new Date()),
              ingredientAmount:
                item.ingredientUnit !== 'PCS'
                  ? item.ingredientAmount / 1000
                  : item.ingredientAmount,
              ingredientUnit: item.ingredientUnit,
              wasteRatio: item.wasteRatio ? item.wasteRatioPersent : 0,
              cookingRatio: item.cookingRatio ? item.cookingRatioPersent : 0,
              fryingRatio: item.fryingRatio ? item.fryingRatioPersent : 0,
              stewingRatio: item.stewingRatio ? item.stewingRatioPersent : 0,
              roastingRatio: item.roastingRatio ? item.roastingRatioPersent : 0,
            };
          }),
        ],
        // createdTimestamp: Date.parse(new Date()),
        // updatedTimestamp: 0,
      };
      this.validationOrderRecipeItems(newOptionRecipe.productPropertyOptionRecipeItems);
      return await this.saveIngredientsCard(newOptionRecipe);
    } else {
      return await ModifiersAPI.default(this.option.id);
    }
  };

  @action
  structuredProductPropertyOptionRecipeItems = ingredientRecipeItems => {
    return ingredientRecipeItems.map(item => {
      const unitWeight =
        item.ingredientUnit === 'KILOGRAM'
          ? 1000
          : (get(item, 'ingredientDto.unitWeight') || get(item, 'ingredient.unitWeight') || 0) *
            1000;
      const ingredientAmount =
        item.ingredientUnit !== 'PCS'
          ? toFixedTwo(item.ingredientAmount * 1000)
          : toFixedTwo(item.ingredientAmount);
      const approxPrice =
        get(item, 'ingredientDto.approxPrice') || get(item, 'ingredient.approxPrice') || 0;
      const oneUnitWeight = unitWeight / 1000;
      const totalUnitWeight = ingredientAmount * oneUnitWeight;
      const ingredientBrutto =
        item.ingredientUnit !== 'PCS' ? totalUnitWeight : unitWeight * ingredientAmount;
      const allRatio = {
        wasteRatio: Number(item.wasteRatio) !== 0 ? true : false,
        cookingRatio: Number(item.cookingRatio) !== 0 ? true : false,
        fryingRatio: Number(item.fryingRatio) !== 0 ? true : false,
        stewingRatio: Number(item.stewingRatio) !== 0 ? true : false,
        roastingRatio: Number(item.roastingRatio) !== 0 ? true : false,
        wasteRatioPersent:
          get(item, 'ingredientDto.wasteRatio') || get(item, 'ingredientDto.wasteRatio') || 0,
        cookingRatioPersent:
          get(item, 'ingredientDto.cookingRatio') || get(item, 'ingredientDto.cookingRatio') || 0,
        fryingRatioPersent:
          get(item, 'ingredientDto.fryingRatio') || get(item, 'ingredientDto.fryingRatio') || 0,
        stewingRatioPersent:
          get(item, 'ingredientDto.stewingRatio') || get(item, 'ingredientDto.stewingRatio') || 0,
        roastingRatioPersent:
          get(item, 'ingredientDto.roastingRatio') || get(item, 'ingredientDto.roastingRatio') || 0,
      };
      const fullCookingPersent = getFullCookingPercent(allRatio);
      const totalApproxPrice =
        item.ingredientUnit !== 'PCS'
          ? (ingredientAmount / 1000) * approxPrice
          : ingredientAmount * approxPrice;
      return {
        ...item,
        ingredientAmount,
        approxPrice: approxPrice,
        ingredientBrutto: ingredientBrutto.toFixed(2),
        ingredientNetto: (
          ingredientBrutto - (ingredientBrutto / 100) * fullCookingPersent || 0
        ).toFixed(2),
        totalApproxPrice: (totalApproxPrice || 0).toFixed(2),
        unitWeight,
        ...allRatio,
      };
    });
  };

  @action
  fetchOptionRecipe = async option => {
    this.setOption(option);
    try {
      const data = await ModifiersAPI.fetchOptionRecipe(option.id);
      let status = NONE;
      if (data.productPropertyOptionId) {
        if (data.status === 'ACTIVE') {
          status = INGREDIENT_CARD;
          if (
            data.productPropertyOptionRecipeItems.length === 1 &&
            option.name === data.productPropertyOptionRecipeItems[0].ingredientName &&
            data.productPropertyOptionRecipeItems[0].ingredientUnit === 'PCS' &&
            toFixedTwo(data.productPropertyOptionRecipeItems[0].ingredientAmount) === 1
          ) {
            status = PRODUCT;
          }
        }
      }
      runInAction(() => {
        this.optionRecipe = {
          ...data,
          productPropertyOptionRecipeItems: this.structuredProductPropertyOptionRecipeItems(
            data.productPropertyOptionRecipeItems,
          ),
        };
        this.changeStoresStatus(status);
        this.isUpdateIngredientsCard = status === INGREDIENT_CARD;
      });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  clearValidation = () => {
    this.optionRecipeItemsValidationErrors = {};
    this.validationErrors = {};
    this.validationPropertyErrors = {};
    this.modifier = this.initModifier();
    this.storesStatus = NONE;
    this.isUpdateIngredientsCard = false;
  };
}

export default ModifiersEditor;
