import React from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Message, Transition, Icon } from 'semantic-ui-react';
import { NOTIFICATIONS_COLOR } from 'utils/constants';
import { FormattedMessage } from 'react-intl';
import close from 'images/close-dark.svg';

import ErrorBoundary from 'components/common/error-boundary';

import { Container } from './style';
import styles from './styles.scss';

const Notification = inject('Notification')(
  observer(({ Notification }) => {
    const { text, isError } = Notification.message;

    const hideActiveNotification = () => {
      Notification.hide();
    };

    const isSuccessMessage = text === 'Success';

    return (
      <ErrorBoundary>
        {Notification.visible && (
          <Container error={isError} onClick={hideActiveNotification}>
            <Transition visible={Notification.visible} animation="fade down" duration={400}>
              <Message
                positive={!isError}
                negative={isError}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div className={styles.leftBlock}>
                  <div className={styles.iconWrapper}>
                    <Icon
                      name={!isError ? 'check circle' : 'close'}
                      size="large"
                      style={
                        !isError
                          ? { color: NOTIFICATIONS_COLOR.SUCCESS }
                          : { color: NOTIFICATIONS_COLOR.ERROR }
                      }
                    />
                  </div>
                  <div>
                    <div className={styles.title}>
                      {!isError ? (
                        <FormattedMessage id="notifications.success" defaultMessage="Success" />
                      ) : (
                        <FormattedMessage id="notifications.error" defaultMessage="Error" />
                      )}
                    </div>
                    {!isSuccessMessage && text}
                  </div>
                </div>
                <div>
                  <img src={close} className={styles.closeIcon} onClick={hideActiveNotification} />
                </div>
              </Message>
            </Transition>
          </Container>
        )}
      </ErrorBoundary>
    );
  }),
);

Notification.wrappedComponent.propTypes = {
  Notification: MobXPropTypes.observableObject.isRequired,
};

export default Notification;
