import analytics from './analytics';
import reports from './reports';

import SalesStore from './Sales';
import DiscountsStore from './Discounts';
import UserStatsStore from './user-stats';
import RealTimeStores from './real-time';

const Sales = new SalesStore();
const Discounts = new DiscountsStore();
const UserStats = new UserStatsStore();
const RealTime = new RealTimeStores();

export default {
  ...analytics,
  ...reports,
  Sales,
  Discounts,
  UserStats,
  RealTime,
};
