import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/printer';

class PrintersAPI {
  static fetchPrintersInfo = () => Request.GET(`${ENDPOINT}/options`);

  static list = () => Request.GET(`${ENDPOINT}`);

  static fetchPrinter = printerId => Request.GET(`${ENDPOINT}/${printerId}`);

  static create = printer => Request.POST(ENDPOINT, printer);

  static update = printer => Request.PUT(ENDPOINT, printer);

  static remove = printerId => Request.DELETE(`${ENDPOINT}/${printerId}`);
}

export default PrintersAPI;
