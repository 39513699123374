import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/collection-point';

class CollectionPointAPI {
  static get = () => Request.GET(ENDPOINT);
  
  static getById = collectionId => Request.GET(`${ENDPOINT}/${collectionId}`);
  
  static create = collection => Request.POST(ENDPOINT, collection);
  
  static update = collection => Request.PUT(ENDPOINT, collection);
  
  static delete = collectionId => Request.DELETE(`${ENDPOINT}/${collectionId}`);
}

export default CollectionPointAPI;
