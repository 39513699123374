import {observable, action, runInAction, computed} from 'mobx';
import uniq from 'lodash.uniq';
import TablesAPI from 'api/tables';

const TABLE_NUMBER = 'tableNo';
const ZONE = 'zone';
const CAPACITY = 'capacity';

export const TABLES_TABLE_FORMAT = new Map([
  [TABLE_NUMBER, {id: 'tables.data.number', defaultMessage: 'Number'}],
  [ZONE, {id: 'tables.data.zone', defaultMessage: 'Zone'}],
  [CAPACITY, {id: 'tables.data.capacity', defaultMessage: 'Capacity'}],
]);

export const TABLES_LIST_FORMAT = {
  headerProperty: [TABLE_NUMBER, {id: 'tables.data.number', defaultMessage: 'Number'}],
  properties: new Map([
    [ZONE, {id: 'tables.data.zone', defaultMessage: 'Zone'}],
    [CAPACITY, {id: 'tables.data.capacity', defaultMessage: 'Capacity'}],
  ]),
};

class Tables {
  tableFormat = TABLES_TABLE_FORMAT;
  listFormat = TABLES_LIST_FORMAT;
  filters = [TABLE_NUMBER, ZONE, CAPACITY];

  @observable list = [];
  @observable totalPages = 0;
  @observable currentPage = -1;
  @observable zones = [];

  @action
  fetchTables = async (page, filters) => {
    const {items} = await TablesAPI.list(filters);

    runInAction(() => {
      this.list = items;
      this.totalPages = 1;
      this.currentPage = 0;
      this.zones = uniq(this.list.map(((table) => table.zone)));
    });
  };

  @action
  fetchTable = async (tableId) => TablesAPI.fetchTable(tableId);

  @action
  create = async (table) => {
    const createdTable = await TablesAPI.create(table);

    runInAction(() => {
      this.list = [...this.list, createdTable];
    });
  };

  findTableIndex = (tableId) => this.list.findIndex((table) => (table.id === tableId));

  @action
  update = async (table) => {
    const updatedTable = await TablesAPI.update(table);

    runInAction(() => {
      const index = this.findTableIndex(table.id);

      this.list = [
        ...this.list.slice(0, index),
        updatedTable,
        ...this.list.slice(index + 1),
      ];
    });
  } ;

  @action
  remove = async (tableId) => {
    await TablesAPI.remove(tableId);

    runInAction(() => {
      const index = this.findTableIndex(tableId);

      this.list = [
        ...this.list.slice(0, index),
        ...this.list.slice(index + 1),
      ];
    });
  };

  @action
  addZone = (zone) => {
    this.zones.push(zone);
  };

  @computed get availableZones() {
    return this.zones.map((zone) => ({
      text: zone,
      value: zone,
    }));
  }

  @action
  resetTableStore = () => {
    this.list = [];
    this.zones = [];
    this.totalPages = 0;
    this.currentPage = -1;
    this.listFormat = TABLES_LIST_FORMAT;
    this.tableFormat = TABLES_TABLE_FORMAT;
    this.filters = [TABLE_NUMBER, ZONE, CAPACITY];
  };
}

export default Tables;