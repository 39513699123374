import { action, runInAction, observable, computed } from 'mobx';

import CollectionPointAPI from 'api/collection-point';

class CollectionPointStore {
  
  @observable list = [];
  @observable loading = false;
  
  @computed get availableIpCollectionPoints() {
    return this.list.map(({ id, name }) => ({
      value: id,
      text: name,
    }));
  }
  
  @action
  startFetch = () => {
    runInAction(() => {
      this.loading = true;
    });
  };
  
  endFetch = () => {
    runInAction(() => {
      this.loading = false;
    })
  };
  
  @action
  create = async data => {
    const list = [ ...this.list ];
    
    try {
      const response =await CollectionPointAPI.create(data);
      list.push(response);
      
      runInAction(() => {
        this.list = list;
      });
      
      return response;
    } catch (e) {
      throw new Error(e.message);
    }
  };
  
  @action
  editCurrent = async (id, data) => {
    try {
      const response = await CollectionPointAPI.update({ id, ...data});
      this.resolveEditAds(response);
      return response;
    } catch (e) {
      throw new Error(e.message);
    }
  };
  
  @action
  fetchListPage = async () => {
    this.startFetch();
    
    try {
      const { items } = await CollectionPointAPI.get();
      runInAction(() => {
        this.list = items;
      });
    } catch (e) {
      throw Error(e.message);
    } finally {
      this.endFetch();
    }
  };
  
  @action
  removeAdsFromList = async kitchenId => {
    const duplicatedList = [ ...this.list ];
    
    await runInAction(() => {
      this.list = duplicatedList.filter(item => item.id !== kitchenId);
    });
  };
  
  @action
  resolveEditAds = data => {
    const list = [ ...this.list ];
  
    const editAdsIndex = list.findIndex(({ id }) => id === data.id);
    if (editAdsIndex !== -1) {
      list.splice(editAdsIndex, 1, data);
    }
    
    runInAction(() => {
      this.list = list;
    });
  };
  
  @action
  deleteAds = async collectionId => {
    try {
      this.startFetch();
      await CollectionPointAPI.delete(collectionId);
      await this.removeAdsFromList(collectionId);
    } catch (e) {
      throw new Error(e.message);
    } finally {
      this.endFetch();
    }
  }
}

export default CollectionPointStore;
