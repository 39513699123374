import { action, runInAction, observable, computed } from 'mobx';

import LegalEntityAPI from 'api/legal-entity';

class LegalEntityStore {
  @observable list = [];
  @observable loading = false;

  @computed get availableEntities() {
    return this.list.map(({ id, name }) => ({
      value: id,
      text: name,
    }));
  }

  @action
  startFetch = () => {
    runInAction(() => {
      this.loading = true;
    });
  };

  endFetch = () => {
    runInAction(() => {
      this.loading = false;
    });
  };

  @action
  fetchListPage = async () => {
    this.startFetch();

    try {
      const { items } = await LegalEntityAPI.list();
      const ids = items.map(item => item.id);
      const data = await LegalEntityAPI.getAllEntity({ ids });
      const newData = items.map(item => {
        const findData = data.items.find(i => i.legalEntityId == item.id);
        return {
          ...item,
          ...findData,
        };
      });
      runInAction(() => {
        this.list = newData;
      });
    } catch (e) {
      throw Error(e.message);
    } finally {
      this.endFetch();
    }
  };

  @action
  removeItemFromList = async id => {
    const duplicatedList = [...this.list];

    await runInAction(() => {
      this.list = duplicatedList.filter(item => item.id !== id);
    });
  };

  @action
  remove = async id => {
    try {
      this.startFetch();
      await LegalEntityAPI.remove(id);
      await this.removeItemFromList(id);
    } catch (e) {
      throw new Error(e.message);
    } finally {
      this.endFetch();
    }
  };
}

export default LegalEntityStore;
