export const dashboardRoutes = {
  shop: '/shop',
  menu: '/menu',
  dashboard: '/',
  dashboardPages: [
    '/',
    '/sales',
    '/discounts',
    '/reportsX',
    '/reportsZ',
    '/reportsY',
    '/reportsY/:id',
    '/userStats',
    '/real-time',
  ],
  menuPages: [
    '/products',
    '/menu',
    '/categories',
    '/stopList',
    '/legal-entity',
    '/legal-entity/',
    '/modifiers',
    '/modifiers/',
    '/modifiers/new',
    '/modifiers/:modifierId',
    '/products/:id',
    '/products/new',
    '/legal-entity/new',
    '/legal-entity/:id',
  ],
  printersPages: ['/pos', '/settings', '/pos/new', '/pos/:id'],
  selfServicePages: [
    '/self-service',
    '/instructions',
    '/orders',
    '/orders-ahead',
    '/delivery',
    '/notifications',
  ],
  restaurantPages: [
    '/tables',
    '/merchant',
    '/merchant/:id',
    '/merchant/:id/general',
    '/merchant/:id/process',
    '/merchant/:id/integration',
  ],
  backOfHousePages: [
    '/backOfHouse',
    '/kitchens',
    '/collection-point',
    '/kitchens/new',
    '/kitchens/:id',
  ],
  crmPages: [
    '/vouchers',
    '/promotions',
    '/customers',
    '/loyalty',
    '/broadcast-message',
    '/loyalty-transitions',
  ],
  cmsPages: ['/posts'],
  apps: '/applications',
  sales: '/sales',
  menuEdit: '/menu/edit/:id',
  menuCreate: '/menu/create',
  modifiers: '/menu/:id/modifiers',
  order: '/order',
  tables: '/tables',
  account: '/account',
  products: '/menu',
  printers: '/pos',
  restaurantProcess: '/merchant/:id/process',
  restaurantGeneral: '/merchant/:id/general',
  restaurantIntegration: '/merchant/:id/integration',
  printersSettings: '/settings',
  kitchens: '/kitchens',
  vouchers: '/vouchers',
  posts: '/posts',
  loyalty: '/loyalty',
  broadcastMessage: '/broadcast-message',
  loyaltyTransitions: '/loyalty-transitions',
  customers: '/customers',
  instructions: '/instructions',
  orders: '/orders',
  ordersAhead: '/orders-ahead',
  delivery: '/delivery',
  curbsidePickup: '/curbside-pickup',
  notifications: '/notifications',
  programs: '/programs',
  xReports: '/reportsX',
  zReports: '/reportsZ',
  yReports: '/reportsY',
  realTime: '/real-time',
  userStats: '/userStats',
  discounts: '/discounts',
  ads: '/collection-point',
  promotions: '/promotions',
  legalEntity: '/legal-entity',
  categories: '/menu/:id/categories',
  stopList: '/menu/:id/stopList',
  categoryEditor: '/category',
  selfService: '/self-service',
  productEditor: '/products/new',
  printerEditor: '/printers/new',
  users: '/users',
  billing: '/billing',
  launch: '/launch',
  checklist: '/checklist',
  merchantNew: '/merchant',
  merchants: '/merchants',
  voucherEditor: '/vouchers/new',
  postsEditor: '/posts/new',
  legalEntityEditorNew: '/legal-entity/new',
  modifiersNew: '/menu/:id/modifiers/new',
  modifiersEdit: '/menu/:id/modifiers/:modifierId',
  promotionEditorNew: '/promotions/new',
  tableEditor: '/tables/new',
  groupDashboard: '/dashboard/group',
  vouchersIssues: '/vouchers/issues',
  pos: '/printers',
  backOfHouse: '/backOfHouse',
  blockedMerchantPlaceholder: '/account/merchant/blocked',
};
