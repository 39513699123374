import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/inventory/category';
// const CATEGORIES_PER_PAGE = 1000;

class CategoriesAPI {
  static list = () => Request.GET(`${ENDPOINT}/list`);

  static loadAsOptions = () => Request.GET(`${ENDPOINT}/as-options`);

  static listByMerchantGroup = menuId =>
    Request.GET(`${ENDPOINT}/group/list${menuId ? `?menuId=${menuId}` : ''}`);

  static listByMerchantMenu = menuId => Request.GET(`${ENDPOINT}/list?menuId=${menuId}`);

  static fetchCategory = categoryId => Request.GET(`${ENDPOINT}/${categoryId}`);

  static create = categoryInfo => Request.POST(ENDPOINT, categoryInfo);

  static update = category => Request.PUT(ENDPOINT, category);

  static remove = categoryId => Request.DELETE(ENDPOINT, { id: categoryId });

  static updateCategoriesOrder = itemOrderDtos =>
    Request.PUT(`${ENDPOINT}/order`, { itemOrderDtos });
}

export default CategoriesAPI;
