import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/image';

class ImagesAPI {
  static upload = image => {
    const data = new FormData();
    data.append('file', image);

    return Request.POST_FILE(`${ENDPOINT}/upload/do`, data);
  };

  static convertBase64 = async url => {
    const data = { key: url };
    const base = await Request.POST(`${ENDPOINT}/convert`, data);
    return `data:image/jpeg;base64,${base.key}`
  };
}

export default ImagesAPI;
