import { action, observable, runInAction, computed } from 'mobx';
import history, { routes } from 'routes';
import validate from 'validate.js';

import LoyaltyProgramsAPI from 'api/loyalty/programs';

import { NEW_ROUTE_PREFIX, LOYALTY_PROGRAM_TYPE } from 'utils/constants';
import {
  status,
  MS_IN_DAY,
  initLoyaltyProgram,
  DISCOUNT_VALIDATION_SCHEMA,
  CASHBACK_VALIDATION_SCHEMA,
  PERCENT_VALIDATION_SCHEMA,
} from './constants';

class ProgramsEditor {
  @observable status = { ...status };
  @observable program = initLoyaltyProgram();
  @observable validationErrors = {};

  @computed get isValid() {
    const program =
      this.program.type === LOYALTY_PROGRAM_TYPE.DISCOUNT
        ? this.program
        : {
            ...this.program,
            delay: this.delayDays,
          };
    const errors = validate(program, this.VALIDATION_SCHEMA);

    return !errors;
  }

  @computed get VALIDATION_SCHEMA() {
    const { type } = this.program;

    return type === LOYALTY_PROGRAM_TYPE.DISCOUNT
      ? DISCOUNT_VALIDATION_SCHEMA
      : CASHBACK_VALIDATION_SCHEMA;
  }

  @computed get delayDays() {
    const { lpStartsInMs } = this.program;

    return lpStartsInMs ? lpStartsInMs / MS_IN_DAY : lpStartsInMs;
  }

  @action
  create = () => {
    history.push(`${routes.loyalty}/${NEW_ROUTE_PREFIX}`);
  };

  @action
  updatePointsDelay = (event, { value }) => {
    if (value) {
      // this.validationErrors['delay'] = validate.single(value, this.VALIDATION_SCHEMA['delay']);
      this.program.lpStartsInMs = value * MS_IN_DAY;
    } else {
      this.program.lpStartsInMs = 0;
    }
  };

  @action
  validateProgram = () => {
    const firstValidation = validate(this.program, this.VALIDATION_SCHEMA) || {};
    // const secondValidation =
    //   validate(this.program.cashbackPercentMap, PERCENT_VALIDATION_SCHEMA) || {};
    this.validationErrors = { ...firstValidation };

    return !Object.keys(this.validationErrors).length;
  };

  getTypedProgram = () => {
    const { ACTIVE, INACTIVE } = this.status;

    return {
      ...this.program,
      cashbackPercentMap: {
        TAKEAWAY: this.program.cashbackPercentMap.takeaway / 100,
        DINEIN: this.program.cashbackPercentMap.dinein / 100,
        DELIVERY: this.program.cashbackPercentMap.delivery / 100,
      },
      percent: this.program.cashbackPercentMap.dinein / 100,
      status: this.program.status ? ACTIVE : INACTIVE,
    };
  };

  @action
  save = async admin => {
    const { id } = this.program;
    const program = this.getTypedProgram();

    try {
      if (id) {
        await LoyaltyProgramsAPI.update(program);
      } else {
        await LoyaltyProgramsAPI.create(program);
      }
      admin ? history.push(`${routes.adminLoyaltyNew}`) : history.push(`${routes.loyalty}`);
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  edit = async programId => {
    try {
      const data = await LoyaltyProgramsAPI.fetchById(programId);

      runInAction(() => {
        this.program = {
          ...data,
          status: data.status === this.status.ACTIVE,
          cashbackPercentMap: {
            takeaway: data.cashbackPercentMap.TAKEAWAY * 100,
            dinein: data.cashbackPercentMap.DINEIN * 100,
            delivery: data.cashbackPercentMap.DELIVERY * 100,
          },
        };
      });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  changeProgram = (key, value) => {
    if (this.VALIDATION_SCHEMA[key]) {
      this.validationErrors[key] = validate.single(value, this.VALIDATION_SCHEMA[key]);
    }

    if (key === 'lpStartsInMs') {
      this.program.lpStartsInMs = value * MS_IN_DAY;
    }

    this.program[key] = value;
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.program = initLoyaltyProgram();
  };
}

export default ProgramsEditor;
