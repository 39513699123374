import { observable, action, computed, runInAction } from 'mobx';
import LoyaltyProgramsAPI from 'api/loyalty/programs';

const NAME = 'name';
const TYPE = 'type';
const DINEIN = 'dinein';
const DELIVERY = 'delivery';
const TAKEAWAY = 'takeaway';
const STATUS = 'status';

export const LOYALTY_TABLE_FORMAT = new Map([
  [NAME, { id: 'loyalty.program.name', defaultMessage: 'Name' }],
  [TYPE, { id: 'loyalty.program.type', defaultMessage: 'Type' }],
  [DINEIN, { id: 'loyalty.program.dinein', defaultMessage: 'Dinein' }],
  [DELIVERY, { id: 'loyalty.program.delivery', defaultMessage: 'Delivery' }],
  [TAKEAWAY, { id: 'loyalty.program.takeaway', defaultMessage: 'Takeaway' }],
  [STATUS, { id: 'loyalty.program.status', defaultMessage: 'Status' }],
]);

export const LOYALTY_LIST_FORMAT = {
  headerProperty: [NAME, { id: 'loyalty.program.name', defaultMessage: 'Name' }],
  properties: new Map([
    [TYPE, { id: 'loyalty.program.type', defaultMessage: 'Type' }],
    [DINEIN, { id: 'loyalty.program.dinein', defaultMessage: 'Dinein' }],
    [DELIVERY, { id: 'loyalty.program.delivery', defaultMessage: 'Delivery' }],
    [TAKEAWAY, { id: 'loyalty.program.takeaway', defaultMessage: 'Takeaway' }],
    [STATUS, { id: 'loyalty.program.status', defaultMessage: 'Status' }],
  ]),
};

const initLoyaltyTransitions = {
  cashbackTriggers: [],
  discountTriggers: [],
  loyaltyInformation: '',
  defaultLoyaltyProgramId: 0,
};

const initValidationErrors = {
  cashbackTriggers: {},
  discountTriggers: {},
};

class LoyaltyProgramsStore {
  listFormat = LOYALTY_LIST_FORMAT;
  tableFormat = LOYALTY_TABLE_FORMAT;

  @observable list = [];
  @observable changes = {};
  @observable actions = {};
  @observable loading = false;
  @observable defaultPrograms = [];
  @observable loyaltyTransitions = initLoyaltyTransitions;
  @observable validationErrors = initValidationErrors;

  @computed get availablePrograms() {
    return this.list.map(({ id, name }) => ({
      value: id,
      text: name,
    }));
  }

  @computed get discountOptions() {
    const options = this.list
      .filter(i => i.type === 'DISCOUNT')
      .map(({ id, name }) => ({
        value: id,
        text: name,
        key: id,
      }));
    return options;
  }

  @computed get cashbackOptions() {
    const options = this.list
      .filter(i => i.type === 'CASHBACK')
      .map(({ id, name }) => ({
        value: id,
        text: name,
        key: id,
      }));
    return options;
  }

  @action
  updateList = () => {
    const updatedList = this.list.map(program => {
      const data = this.actions[program.id] || program;
      return {
        ...data,
      };
    });

    runInAction(() => {
      this.list = updatedList;
    });
  };

  @action
  fetchLoyaltyTransitions = async () => {
    try {
      const data = await LoyaltyProgramsAPI.fetchLoyaltyTransitions();

      runInAction(() => {
        this.loyaltyTransitions = data;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  validationLoyaltyTransitions = () => {
    let validationErrors = {
      cashbackTriggers: {},
      discountTriggers: {},
    };

    this.loyaltyTransitions.cashbackTriggers.map((item, index) => {
      if (!item.loyaltyProgramId) {
        validationErrors.cashbackTriggers = {
          ...validationErrors.cashbackTriggers,
          [`loyaltyProgramId.${index}`]: true,
        };
      }
      if (!item.threshold) {
        validationErrors.cashbackTriggers = {
          ...validationErrors.cashbackTriggers,
          [`threshold.${index}`]: true,
        };
      }
    });

    this.loyaltyTransitions.discountTriggers.map((item, index) => {
      if (!item.loyaltyProgramId) {
        validationErrors.discountTriggers = {
          ...validationErrors.discountTriggers,
          [`loyaltyProgramId.${index}`]: true,
        };
      }
      if (!item.threshold) {
        validationErrors.discountTriggers = {
          ...validationErrors.discountTriggers,
          [`threshold.${index}`]: true,
        };
      }
    });

    this.validationErrors = { ...validationErrors };

    return !Object.values(this.validationErrors.cashbackTriggers).length &&
      !Object.values(this.validationErrors.discountTriggers).length
      ? true
      : false;
  };

  @action
  updateLoyaltyTransitions = async () => {
    if (this.validationLoyaltyTransitions()) {
      const newLoyaltyTransitions = [
        ...this.loyaltyTransitions.cashbackTriggers.map(i => ({
          ...i,
          loyaltyType: 'CASHBACK',
          id: i.id ? i.id : 0,
        })),
        ...this.loyaltyTransitions.discountTriggers.map(i => ({
          ...i,
          loyaltyType: 'DISCOUNT',
          id: i.id ? i.id : 0,
        })),
      ];
      try {
        await LoyaltyProgramsAPI.updateLoyaltyTransitions({
          data: newLoyaltyTransitions,
          loyaltyInformation: this.loyaltyTransitions.loyaltyInformation,
          defaultLoyaltyProgramId: this.loyaltyTransitions.defaultLoyaltyProgramId,
        });
      } catch (error) {
        throw new Error(error.message);
      } finally {
        this.fetchLoyaltyTransitions();
      }
    }
  };

  @action
  changeLoyaltyTransitions = (key, value) => {
    if (
      Object.values(this.validationErrors.cashbackTriggers).length ||
      Object.values(this.validationErrors.discountTriggers).length
    ) {
      this.validationErrors = initValidationErrors;
    }
    this.loyaltyTransitions[key] = value;
  };

  @action
  resetToDefaultStatus = () => {
    runInAction(() => {
      this.actions = {};
      this.changes = {};
      this.list = this.defaultPrograms;
    });
    this.updateList();
  };

  findProgramById = programId => this.defaultPrograms.find(program => program.id === programId);

  resolveStatusField = (programId, key) => {
    const changeKey = `${programId}_${key}`;
    const program = this.findProgramById(programId);
    const isChanged =
      this.changes.hasOwnProperty(changeKey) && program[key] === this.changes[changeKey];

    if (isChanged) {
      delete this.changes[changeKey];
    } else {
      this.changes[changeKey] = program[key];
    }
  };

  @action
  changeProgramStatus = (programId, data, key) => {
    this.actions[programId] = data;
    this.resolveStatusField(programId, key);
    this.updateList();
  };

  @action
  resetToDefaultAvaliability = () => {
    runInAction(() => {
      this.actions = {};
      this.changes = {};
      this.list = this.defaultPrograms;
    });
    this.updateList();
  };

  @action
  fetchProgram = async () => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const { items } = await LoyaltyProgramsAPI.fetch();

      runInAction(() => {
        this.list = items;
        this.defaultPrograms = items;
      });
    } catch (error) {
      throw new Error(error.message);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  updateProgram = async (program = this.program) => {
    const response = await LoyaltyProgramsAPI.update(program);

    runInAction(() => {
      this.program = response;
    });
  };

  @action
  updatePrograms = async () => {
    this.loading = true;
    try {
      await Object.values(this.actions).map(program => {
        this.updateProgram(program);
      });
    } catch (error) {
    } finally {
      runInAction(() => {
        this.actions = {};
        this.changes = {};
        this.defaultPrograms = this.list;
        this.loading = false;
      });
    }
  };

  @action
  deleteProgram = async programId => {
    try {
      await LoyaltyProgramsAPI.delete(programId);

      runInAction(() => {
        this.list = this.list.filter(program => program.id !== programId);
        this.defaultPrograms = this.defaultPrograms.filter(program => program.id !== programId);
      });
    } catch {}
  };
}

export default LoyaltyProgramsStore;
