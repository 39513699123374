import { action, computed, observable, runInAction } from 'mobx';
import PromotionsAPI from 'api/promotions';
import moment from 'moment';
import { getUTCOffset, utcOffset } from 'utils/time';
// import { CUSTOMERS_TYPE_TO_DISPLAY } from './constants';

const NAME = 'name';
const TYPE = 'type';
const DATE_RANGE = 'dateRange';
const TARGET_MERCHANT_IDS = 'targetMerchantIds';
const TARGET_SERVICE_TYPE = 'targetServiceType';

export const PROMOTIONS_TABLE_FORMAT = new Map([
  [NAME, { id: 'promotions.data.name', defaultMessage: 'Name' }],
  [TYPE, { id: 'promotions.data.type', defaultMessage: 'Type' }],
  [DATE_RANGE, { id: 'promotions.data.dateRange', defaultMessage: 'Date Range' }],
  [
    TARGET_SERVICE_TYPE,
    { id: 'vouchers.editor.targetServiceType.title', defaultMessage: 'Target service type' },
  ],
  [TARGET_MERCHANT_IDS, { id: 'navigation.restaurants', defaultMessage: 'Restaurants' }],
]);

export const PROMOTIONS_LIST_FORMAT = {
  headerProperty: [NAME, { id: 'promotions.data.name', defaultMessage: 'Name' }],
  properties: new Map([
    [TYPE, { id: 'promotions.data.type', defaultMessage: 'Type' }],
    [DATE_RANGE, { id: 'promotions.data.dateRange', defaultMessage: 'Date Range' }],
    [
      TARGET_SERVICE_TYPE,
      { id: 'vouchers.editor.targetServiceType.title', defaultMessage: 'Target service type' },
    ],
    [TARGET_MERCHANT_IDS, { id: 'navigation.restaurants', defaultMessage: 'Restaurants' }],
  ]),
};

const DATE_RANGE_FORMAT = 'D MMM YYYY';
const INACTIVE_STATUS = 'INACTIVE';

export const STATUS_KEY = 'dateRange';

class Promotions {
  tableFormat = PROMOTIONS_TABLE_FORMAT;
  listFormat = PROMOTIONS_LIST_FORMAT;
  statusKey = STATUS_KEY;

  @observable list = [];
  @observable totalPages = 0;
  @observable currentPage = -1;
  @observable dates = [];
  @observable datesHaveChanges = false;
  @observable paginateOptions = { page: 0, size: 20 };

  @action
  changePaginateOptions = value => {
    runInAction(() => {
      this.paginateOptions = value;
    });
  };

  @computed get hasMore() {
    return this.currentPage + 1 < this.totalPages;
  }

  @observable activeFilter = false;

  @action
  toggleActiveFilter = () => {
    this.activeFilter = !this.activeFilter;

    return this.fetchTablePage(0);
  };

  @action
  setDates = value => {
    runInAction(() => {
      this.dates = value;
      this.setDatesHaveChanges(true);
    });
  };

  @action
  setDatesHaveChanges = value => {
    runInAction(() => {
      this.datesHaveChanges = value;
    });
  };

  @computed get data() {
    return this.list.map(promotion => {
      // const utcOffset = getUTCOffset();
      const start = moment(promotion.startTimestamp - utcOffset).format(DATE_RANGE_FORMAT);
      const end = moment(promotion.endTimestamp - utcOffset).format(DATE_RANGE_FORMAT);

      return {
        ...promotion,
        // type: this.getPromotionType(promotion),
        dateRange: `${start} - ${end}`,
      };
    });
  }

  // @action
  // getPromotionType = ({ type, bonus }) => {
  //   return bonus.fixedPrice
  //     ? CUSTOMERS_TYPE_TO_DISPLAY.FIXED_PRICE
  //     : CUSTOMERS_TYPE_TO_DISPLAY[type];
  // };

  fetchPromotions = () => {
    const { page, size } = this.paginateOptions;
    let startDate = '';
    let endDate = '';
    const utcOffset = getUTCOffset();
    if (this.dates.length > 0) {
      startDate =
        moment(this.dates[0])
          .startOf('day')
          .valueOf() +
        getUTCOffset(
          moment(this.dates[0])
            .startOf('day')
            .valueOf(),
        );
      endDate =
        moment(this.dates[1])
          .endOf('day')
          .valueOf() +
        getUTCOffset(
          moment(this.dates[1])
            .endOf('day')
            .valueOf(),
        );
    }

    return this.activeFilter
      ? PromotionsAPI.listActive(page, size)
      : PromotionsAPI.list(page, size, { startDate, endDate });
  };

  @action
  fetchTablePage = async () => {
    const { items, totalPages, number } = await this.fetchPromotions();

    runInAction(() => {
      this.list = items;
      this.totalPages = totalPages;
      this.currentPage = number;
    });
  };

  @action
  fetchListPage = async () => {
    const { page } = this.paginateOptions;
    const { items, totalPages, number } = await this.fetchPromotions();

    runInAction(() => {
      if (page === 0) {
        this.list = items;
      } else {
        this.list = [...this.list, ...items];
      }

      this.totalPages = totalPages;
      this.currentPage = number;
    });
  };

  fetchPromotion = id => PromotionsAPI.fetchPromotion(id);

  createPromotion = promotion => PromotionsAPI.create(promotion);

  updatePromotion = promotion => PromotionsAPI.update(promotion);

  @action
  removePromotion = async id => {
    await PromotionsAPI.remove(id);

    runInAction(() => {
      const index = this.list.findIndex(promotion => promotion.id === id);

      if (index >= 0) {
        this.list = [...this.list.slice(0, index), ...this.list.slice(index + 1)];
      }
    });
  };

  promotionIsInactive = promotion => promotion.status === INACTIVE_STATUS;

  pickPromotionStatusColor = promotion => {
    const ACTUAL_COLOR = '#03b26d';
    const UPCOMING_COLOR = '#1b8eff';
    const isUpcomingPromotion = moment(
      promotion.startTimestamp - getUTCOffset(promotion.startTimestamp),
    ).isAfter(moment());

    return isUpcomingPromotion ? UPCOMING_COLOR : ACTUAL_COLOR;
  };
}

export default Promotions;
