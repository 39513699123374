import config from 'config';

export const SERVER_URL = config.serverURL;
export const APIMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const FORBIDDEN = 'FORBIDDEN';
export const SUCCESS_CODE = 'SUCCESS';
export const BAD_REQUEST = 'BAD_REQUEST';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const INACTIVE_USER_TEXT = 'User is not active';