import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment';
import ReportsAPI from 'api/reports';
import { formatReportDate, getUTCOffset } from 'utils/time';
import history, { routes } from 'routes';

const utcOffset = getUTCOffset();

const CLOSED = 'closedDate';
const OPENED = 'openedDate';
const CASHIER = 'cashierDisplayName';
const TOTAL = 'totalAmount';

const DEFAULT_START_DATE =
  moment()
    .startOf('day')
    .valueOf() + utcOffset;
const DEFAULT_END_DATE =
  moment()
    .endOf('day')
    .valueOf() + utcOffset;

const X_REPORTS_TABLE_FORMAT = new Map([
  [CLOSED, { id: 'reports.closed', defaultMessage: 'Closed' }],
  [CASHIER, { id: 'reports.cashier', defaultMessage: 'Cashier' }],
  [TOTAL, { id: 'reports.total', defaultMessage: 'Total' }],
]);

const X_REPORTS_LIST_FORMAT = {
  headerProperty: [CLOSED, { id: 'reports.closed', defaultMessage: 'Closed' }],
  properties: new Map([
    [CASHIER, { id: 'reports.cashier', defaultMessage: 'Cashier' }],
    [TOTAL, { id: 'report.total', defaultMessage: 'Total' }],
  ]),
};

const initFilters = { prevUserName: '', userName: '' };

class XReports {
  tableFormat = X_REPORTS_TABLE_FORMAT;
  listFormat = X_REPORTS_LIST_FORMAT;

  @observable list = [];
  @observable totalPages = 0;
  @observable currentPage = -1;
  @observable report = {};
  @observable filters = initFilters;
  @observable paginateOptions = { page: 0, size: 20 };

  @action
  changePaginateOptions = value => {
    runInAction(() => {
      this.paginateOptions = value;
    });
  };

  @computed get hasMore() {
    return this.currentPage + 1 < this.totalPages;
  }

  @computed get data() {
    return this.list.map(report => ({
      ...report,
      details: JSON.parse(report.detailsJson),
      cashierDisplayName: report.cashierName
        ? `${report.cashierName} (${report.cashierNumber})`
        : report.cashierNumber,
      [OPENED]: formatReportDate(report[OPENED]),
      [CLOSED]: formatReportDate(report[CLOSED]),
    }));
  }

  @action
  fetchData = (page = this.paginateOptions.page, filters) => {
    const { dateFrom, dateTo, ...rest } = filters;

    const requestFilters = {
      startDate: dateFrom
        ? moment(dateFrom).valueOf() + getUTCOffset(moment(dateFrom).valueOf())
        : DEFAULT_START_DATE,
      endDate: dateTo
        ? moment(dateTo).valueOf() + getUTCOffset(moment(dateTo).valueOf())
        : DEFAULT_END_DATE,
      username: this.filters.userName,
      size: this.paginateOptions.size,
      ...rest,
    };

    return ReportsAPI.listXReports(page, requestFilters);
  };

  @action
  fetchTablePage = async (filters, page) => {
    const { items, totalPages, number } = await this.fetchData(page, filters);

    runInAction(() => {
      this.list = items;
      this.totalPages = totalPages;
      this.currentPage = number;
    });
  };

  @action
  setFilters = (key, value) => {
    runInAction(() => {
      this.filters[key] = value;
    });
  };

  @action
  fetchListPage = async (filters, page) => {
    const { items, totalPages, number } = await this.fetchData(page, filters);

    runInAction(() => {
      if (page === 0) {
        this.list = items;
      } else {
        this.list = [...this.list, ...items];
      }

      this.totalPages = totalPages;
      this.currentPage = number;
    });
  };

  @action
  viewReport = id => {
    this.report = this.data.find(report => report.id === id);

    history.push(`${routes.xReports}/${this.report.uid}`);
  };

  @action
  resetXReportsStore = () => {
    this.list = [];
    this.totalPages = 0;
    this.currentPage = -1;
    this.filters = initFilters;
    this.tableFormat = X_REPORTS_TABLE_FORMAT;
    this.listFormat = X_REPORTS_LIST_FORMAT;
  };

  @action
  exportData = async requestFilters => {
    const { items } = await ReportsAPI.listXReports(0, requestFilters);
    return items.map(report => ({
      ...report,
      details: JSON.parse(report.detailsJson),
      cashierDisplayName: report.cashierName
        ? `${report.cashierName} (${report.cashierNumber})`
        : report.cashierNumber,
      [OPENED]: formatReportDate(report[OPENED]),
      [CLOSED]: formatReportDate(report[CLOSED]),
    }));
  };
}

export default XReports;
