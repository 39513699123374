import { action, observable, runInAction } from 'mobx';
import BroadcastAPI from 'api/broadcast-message';

const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const DATE = 'createdTimestamp';
const PHONE_NUMBER = 'phoneNumber';
const WHERE_SEND = 'whereSend';
const MERCHANT_NAME = 'merchantName';

export const MESSAGE_TABLE_FORMAT = new Map([
  [FIRST_NAME, { id: 'broadcastMessage.data.firstName', defaultMessage: 'First name' }],
  [LAST_NAME, { id: 'broadcastMessage.data.lastName', defaultMessage: 'Last name' }],
  [PHONE_NUMBER, { id: 'broadcastMessage.data.phoneNumber', defaultMessage: 'Phone number' }],
  [DATE, { id: 'broadcastMessage.data.date', defaultMessage: 'Date' }],
  [MERCHANT_NAME, { id: 'broadcastMessage.data.merchant', defaultMessage: 'Merchant' }],
  [WHERE_SEND, { id: 'broadcastMessage.data.whereSent', defaultMessage: 'Where sent' }],
]);

export const MESSAGE_LIST_FORMAT = {
  headerProperty: [
    PHONE_NUMBER,
    { id: 'broadcastMessage.data.phoneNumber', defaultMessage: 'Phone number' },
  ],
  properties: new Map([
    [FIRST_NAME, { id: 'broadcastMessage.data.firstName', defaultMessage: 'First name' }],
    [LAST_NAME, { id: 'broadcastMessage.data.lastName', defaultMessage: 'Last name' }],
    [DATE, { id: 'broadcastMessage.data.date', defaultMessage: 'Date' }],
    [MERCHANT_NAME, { id: 'broadcastMessage.data.merchant', defaultMessage: 'Merchant' }],
    [WHERE_SEND, { id: 'broadcastMessage.data.whereSent', defaultMessage: 'Where sent' }],
  ]),
};

class BroadcastMessageDetails {
  listFormat = MESSAGE_LIST_FORMAT;
  tableFormat = MESSAGE_TABLE_FORMAT;
  @observable data = [];
  @observable loading = true;
  @observable messageImageUrl = '';
  @observable messageText = '';
  @observable messageTitle = '';
  @observable currentPage = -1;
  @observable totalPages = 0;
  @observable hasMore = true;
  @observable totalElements = 0;

  @action
  fetchBroadcastMessageId = async (id, { page, size, table }) => {
    runInAction(() => {
      this.loading = true;
    });

    const {
      items,
      title,
      text,
      imageUrl,
      totalPages,
      last,
      number,
      totalElements,
    } = await BroadcastAPI.fetchBroadcastMessageId(id, page, size);

    runInAction(() => {
      if (table) {
        this.data = items;
      } else {
        if (page === 0) {
          this.data = items;
        } else {
          this.data = [...this.data, ...items];
        }
      }
      this.data = items;
      this.messageTitle = title;
      this.messageText = text;
      this.messageImageUrl = imageUrl;
      this.hasMore = !last;
      this.totalPages = totalPages;
      this.currentPage = number;
      this.totalElements = totalElements;
      this.loading = false;
    });
  };
}

export default BroadcastMessageDetails;
