import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/kitchen';

class KitchenAPI {
  static getKitchens = () => Request.GET(ENDPOINT);

  static getKitchenById = kitchenId => Request.GET(`${ENDPOINT}/${kitchenId}`);

  static create = kitchen => Request.POST(ENDPOINT, kitchen);

  static update = kitchen => Request.PUT(ENDPOINT, kitchen);

  static connectStorage = obj => Request.PUT(`${ENDPOINT}/storage`, obj);

  static setDeliverOrders = id =>
    Request.PUT(`/sales/kitchen-station/deliver-orders?kitchenStationId=${id}`);

  static delete = kitchenId => Request.DELETE(`${ENDPOINT}/${kitchenId}`);

  static fetchStorages = ids => Request.POST(`${ENDPOINT}/storage/id-list`, ids);

  static fetchCSVData = id => Request.GET(`/sales/kitchen-station?kitchenStationId=${id}`);
}

export default KitchenAPI;
