import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment';
import ReportsAPI from 'api/reports';
import { formatReportDate } from 'utils/time';
import history, { routes } from 'routes';

const CLOSED = 'closedDate';
const OPENED = 'openedDate';
const CASHIER = 'cashierDisplayName';
const TOTAL = 'totalAmount';

const PERIOD = 'period';
const AMOUNT = 'totalAmount';
const SUBMITTED_DATE = 'submittedDate';
const MANAGER_NAME = 'managerName';

const DEFAULT_START_DATE = moment()
  .startOf('day')
  .valueOf();
const DEFAULT_END_DATE = moment()
  .endOf('day')
  .valueOf();

const X_REPORTS_TABLE_FORMAT = new Map([
  [CLOSED, { id: 'reports.closed', defaultMessage: 'Closed' }],
  [CASHIER, { id: 'reports.cashier', defaultMessage: 'Cashier' }],
  [TOTAL, { id: 'reports.total', defaultMessage: 'Total' }],
]);

const X_REPORTS_LIST_FORMAT = {
  headerProperty: [CLOSED, { id: 'reports.closed', defaultMessage: 'Closed' }],
  properties: new Map([
    [CASHIER, { id: 'reports.cashier', defaultMessage: 'Cashier' }],
    [TOTAL, { id: 'report.total', defaultMessage: 'Total' }],
  ]),
};

const Y_REPORTS_TABLE_FORMAT = new Map([
  [AMOUNT, { id: 'reports.amount', defaultMessage: 'Amount' }],
  [SUBMITTED_DATE, { id: 'reports.submittedDate', defaultMessage: 'Closing time' }],
  [PERIOD, { id: 'reports.period', defaultMessage: 'Period' }],
  [MANAGER_NAME, { id: 'reports.managerName', defaultMessage: 'Passed' }],
]);

const Y_LIST_FORMAT = {
  headerProperty: null,
  properties: new Map([
    [AMOUNT, { id: 'reports.amount', defaultMessage: 'Amount' }],
    [SUBMITTED_DATE, { id: 'reports.submittedDate', defaultMessage: 'Closing time' }],
    [PERIOD, { id: 'reports.period', defaultMessage: 'Period' }],
    [MANAGER_NAME, { id: 'reports.managerName', defaultMessage: 'Passed' }],
  ]),
};

const initFilters = {
  reportType: 'ShiftReports',
  startDate: DEFAULT_START_DATE,
  endDate: DEFAULT_END_DATE,
  terminalNumber: '',
  username: '',
};

class CertainReports {
  @observable list = [];
  @observable totalPages = 0;
  @observable currentPage = -1;
  @observable report = {};
  @observable filters = initFilters;
  @observable paginateOptions = { page: 0, size: 10000 };

  @computed get data() {
    if (this.filters.reportType === 'ShiftReports') {
      return this.list.map(report => ({
        ...report,
        detail: JSON.parse(report.detailsJson),
        cashierDisplayName: report.cashierName
          ? `${report.cashierName} (${report.cashierNumber})`
          : report.cashierNumber,
        [OPENED]: formatReportDate(report[OPENED]),
        [CLOSED]: formatReportDate(report[CLOSED]),
      }));
    }
    return this.list.map(report => ({
      ...report,
      details: JSON.parse(report.detailsJson),
      [OPENED]: formatReportDate(report[OPENED]),
      [CLOSED]: formatReportDate(report[CLOSED]),
      [SUBMITTED_DATE]:
        report[SUBMITTED_DATE] === 10800000 || report[SUBMITTED_DATE] === 0
          ? ''
          : formatReportDate(report[SUBMITTED_DATE]),
    }));
  }

  @computed get tableFormat() {
    return this.filters.reportType === 'ShiftReports'
      ? X_REPORTS_TABLE_FORMAT
      : Y_REPORTS_TABLE_FORMAT;
  }

  @computed get listFormat() {
    return this.filters.reportType === 'ShiftReports' ? X_REPORTS_LIST_FORMAT : Y_LIST_FORMAT;
  }

  @action
  fetchData = () => {
    const { reportType, startDate, endDate, terminalNumber, username } = this.filters;

    return ReportsAPI.listCertainReports({
      reportType,
      startDate,
      endDate,
      terminalNumber,
      username,
    });
  };

  @action
  fetchTablePage = async () => {
    const { items, totalPages, number } = await this.fetchData();
    console.log('CertainReports: ', items, totalPages, number);
    runInAction(() => {
      this.list = items;
      this.totalPages = totalPages;
      this.currentPage = number;
    });
  };

  @action
  setFilters = (key, value) => {
    runInAction(() => {
      this.filters[key] = value;
      this.fetchTablePage();
    });
  };

  @action
  setDate = (startDate, endDate) => {
    runInAction(() => {
      this.filters['startDate'] = startDate;
      this.filters['endDate'] = endDate;
      this.fetchTablePage();
    });
  };

  @action
  viewReport = id => {
    this.report = this.data.find(report => report.id === id);
    history.push(`${routes.adminReports}/${this.report.uid}`);
  };
}

export default CertainReports;
