import UsersStore from './Users';
import UserEditorStore from './UserEditor';

const Users = new UsersStore();
const UserEditor = new UserEditorStore(Users);

export default {
  Users,
  UserEditor,
};
