import { action, computed, observable, runInAction } from 'mobx';
import validate from 'validate.js';
import moment from 'moment';
import { getLocalizedString } from 'i18n/utils';
import { getStringInLocale } from 'i18n';

import AuthAPI from 'api/auth';
import MerchantAPI from 'api/merchant';

import AccountStore from 'stores/Account';

import history, { routes } from 'routes';
import { millisecondsToTime, convertTimeToMilliseconds } from 'utils/format-date';

import {
  CURRENCY_CODE_TO_SIGN,
  IMAGE_PLACEHOLDER,
  WRITE_DOWN_PAYMENT_METHODS_ARR,
} from 'utils/constants';
import {
  VALIDATION_SCHEMA,
  EMPTY_MERCHANT,
  CLOSED_SCHEDULE,
  DAYS_OF_THE_WEEK,
  RULER_SCALE_VALUES,
} from 'stores/merchants/constants';

class MerchantEditor {
  constructor(MerchantsGroup) {
    this.account = new AccountStore();
    this.merchantsGroup = MerchantsGroup;
    this.onSave = this.merchantsGroup.createMerchant;

    this.onSaveMerchant = this.merchantsGroup.createMerchant;
  }

  @observable merchant = { ...EMPTY_MERCHANT };
  @observable validationErrorsDiscountShortcuts = {};
  @observable userInfo = {};
  @observable roundingModes = [];
  @observable clearedSelfPayment = {};
  @observable availablePaymentMethodsWriteDown = [];

  @computed get currency() {
    const { currency } = this.merchant;

    return CURRENCY_CODE_TO_SIGN[currency];
  }

  @computed get weekdays() {
    return DAYS_OF_THE_WEEK;
  }

  @computed get ruler() {
    return RULER_SCALE_VALUES;
  }

  @computed get isValid() {
    const { name, merchantConfiguration } = this.merchant;
    // merchantConfiguration.accountantEmail
    return !!name;
  }

  @computed get defaultCountryCode() {
    return this.merchant.countryCode.toLocaleLowerCase();
  }

  @observable validationErrors = {};

  @action
  validationDiscountShortcuts = merchant => {
    let validationErrors = {};

    merchant.merchantConfiguration.discountShortcuts.map((item, index) => {
      if (!item.name) {
        validationErrors = {
          ...validationErrors,
          [`name.${index}`]: true,
        };
      }
      if (item.amount === '0' || !item.amount) {
        validationErrors = {
          ...validationErrors,
          [`amount.${index}`]: true,
        };
      }
    });
    this.validationErrorsDiscountShortcuts = { ...validationErrors };

    return !Object.values(validationErrors).length ? true : false;
  };

  @action
  validateMerchant = () => {
    const emailValid =
      this.merchant.merchantConfiguration.externalSystemIntegration === 'ENTERPRISE_1C'
        ? validate(this.merchant.merchantConfiguration, { accountantEmail: { email: true } }) || {}
        : {};

    let validationDevicesErrors = {};

    this.merchant.merchantConfiguration?.glazSetup?.terminalConfigurations.map(
      ({ device, terminalToken }, index) => {
        if (!device) {
          validationDevicesErrors = {
            ...validationDevicesErrors,
            [`device.${index}`]: true,
          };
        }
        if (!terminalToken) {
          validationDevicesErrors = {
            ...validationDevicesErrors,
            [`terminalToken.${index}`]: true,
          };
        }
      },
    );
    const newValidationErrors = {
      ...(validate(this.merchant, VALIDATION_SCHEMA) || {}),
      ...emailValid,
      ...validationDevicesErrors,
    };

    const isValid = !Object.keys(newValidationErrors).length;

    this.validationErrors = newValidationErrors;

    return [isValid, this.validationErrors];
  };

  @action
  clearMerchant = () => {
    this.merchant = { ...EMPTY_MERCHANT };
  };

  @action
  fetchRoundingMode = async () => {
    const { items = [] } = await MerchantAPI.fetchRoundingMode();
    let itemsToBeRemoved = ["ROUND_ANYWHERE"];
    const modes = items.filter(({name}) => !itemsToBeRemoved.includes(name)).map(({ name }) => ({
      text: getStringInLocale(`merchant.rounding.${name}`, name),
      value: name,
    }));

    runInAction(() => {
      this.roundingModes = modes;
    });
  };

  @action
  create = () => {
    this.merchant = { ...EMPTY_MERCHANT };
    this.onSave = this.merchantsGroup.createMerchant;

    history.push(routes.adminMerchantNew);
  };

  @action
  updateToCreate = () => {
    runInAction(() => {
      this.onSave = this.merchantsGroup.createMerchant;
    });
  };

  structureBusinessDayOffsetMap = data => {
    const times = {};
    const date = new Date();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        times[key] = {
          workingDay: true,
          end: moment(date).format('hh:mm'),
          start: data[key] > 0 ? millisecondsToTime(data[key]) : '00:00',
        };
      }
    }
    return {
      ...CLOSED_SCHEDULE,
      ...times,
    };
  };

  // @action
  // edit = async id => {
  //   const merchant = await MerchantAPI.fetchMerchant(id);
  //   const times = merchant.merchantConfiguration.businessDayOffsetMap;
  //
  //   runInAction(() => {
  //     this.merchant = {
  //       ...merchant,
  //       merchantConfiguration: {
  //         ...merchant.merchantConfiguration,
  //         businessDayOffsetMap: { daysOfWeek: this.structureBusinessDayOffsetMap(times) },
  //       },
  //     };
  //
  //     this.onSave = this.merchantsGroup.updateMerchant;
  //   });
  // };

  @action
  getCurrentMerchant = async merchantId => {
    const fetchMerchant = merchantId ? MerchantAPI.fetchMerchant : MerchantAPI.fetchCurrentMerchant;

    return await fetchMerchant(merchantId);
  };

  @action
  getIntegrationFile = async date => {
    return await MerchantAPI.integrationFile(date);
  };

  @action
  editCurrent = async merchantId => {
    try {
      const merchant = await this.getCurrentMerchant(merchantId);

      runInAction(() => {
        this.merchant = {
          ...merchant,
          merchantConfiguration: {
            ...this.merchant.merchantConfiguration,
            ...merchant.merchantConfiguration,
            glazSetup: {
              apiPassword: merchant.merchantConfiguration.glazSetup.apiPassword,
              terminalConfigurations:
                merchant.merchantConfiguration.glazSetup.terminalConfigurations || [],
            },
            businessDayOffsetMap: {
              daysOfWeek: this.structureBusinessDayOffsetMap(
                merchant.merchantConfiguration.businessDayOffsetMap,
              ),
            },
            yreportSubmissionStartTime: millisecondsToTime(
              merchant.merchantConfiguration.yreportSubmissionStartTime,
            ),
            discountsEnabled: !merchant.merchantConfiguration.discountsEnabled
              ? '0'
              : merchant.merchantConfiguration.discountShortcuts.length
              ? '2'
              : '1',
            discountShortcuts: merchant.merchantConfiguration.discountShortcuts.map(i => ({
              ...i,
              amount: +i.amount * 100,
            })),
          },
        };

        this.onSave = this.merchantsGroup.updateMerchant;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  updateInfo = (key, value) => {
    this.merchant[key] = value;
  };

  @action
  updateConfiguration = (key, value) => {
    runInAction(() => {
      if (Object.values(this.validationErrorsDiscountShortcuts).length) {
        this.validationErrorsDiscountShortcuts = {};
      }
      this.merchant.merchantConfiguration[key] = value;
    });
  };

  @action
  updateGlazSetupTerminalConfig = (key, value) => {
    runInAction(() => {
      if (Object.values(this.validationErrorsDiscountShortcuts).length) {
        this.validationErrorsDiscountShortcuts = {};
      }
      this.merchant.merchantConfiguration.glazSetup.terminalConfigurations[key] = value;
    });
  };

  @observable imageFile = '';

  @action
  updateImage = ({ image }) => {
    this.imageFile = image;
  };

  @action
  removeImage = () => {
    this.merchant.logo = IMAGE_PLACEHOLDER;
  };

  @observable availablePaymentMethods = [];

  @action
  fetchAvailablePaymentMethods = async () => {
    const { items } = await MerchantAPI.fetchAvailablePaymentMethods();
    let newItems = [...items];
    // const writeDownIndex = newItems.findIndex(i => i.name === 'Write down');
    // const writeDownItem = newItems.splice(writeDownIndex, 1);
    // newItems.splice(items.length, 0, ...writeDownItem);

    runInAction(() => {
      this.availablePaymentMethods = newItems.map(item => {
        const itemName = item.name.split(' ').join('_');
        const haveLocalizedString = getLocalizedString(`merchant.paymentMethod.${itemName}`);
        if (haveLocalizedString !== undefined) {
          return { ...item, name: haveLocalizedString, value: itemName };
        } else {
          return { ...item, value: itemName };
        }
      });

      this.availablePaymentMethodsWriteDown = this.availablePaymentMethods
        .map(item => {
          if (WRITE_DOWN_PAYMENT_METHODS_ARR.some(pm => pm === item.value)) {
            return item;
          }
        })
        .filter(i => i);
    });
  };

  @action
  togglePaymentMethod = paymentMethod => {
    const { paymentMethods } = this.merchant;
    const index = paymentMethods.findIndex(method => paymentMethod.id === method.id);

    if (index > -1) {
      this.merchant.paymentMethods = [
        ...this.merchant.paymentMethods.slice(0, index),
        ...this.merchant.paymentMethods.slice(index + 1),
      ];
    } else {
      this.merchant.paymentMethods = [...this.merchant.paymentMethods, paymentMethod];
    }
  };

  getBusinessDays = timesData => {
    const times = {};
    for (const key in timesData) {
      if (timesData.hasOwnProperty(key) && timesData[key].workingDay) {
        const time = timesData[key].start;
        times[key] = convertTimeToMilliseconds(time);
      }
    }
    return times;
  };

  @action
  getDefaultPaymentMethod = ({ paymentMethods }) => {
    let newPaymentMethods = [...paymentMethods];
    const CASH_PAYMENT_METHOD = 'Cash';

    if (!newPaymentMethods.length) {
      const cashMethod = this.availablePaymentMethods.find(
        item => item.name === CASH_PAYMENT_METHOD,
      );

      return [cashMethod];
    }
    return newPaymentMethods;
  };

  @action
  getStructuredMerchant = () => {
    return {
      ...this.merchant,
      paymentMethods: this.getDefaultPaymentMethod(this.merchant),
      mobile: this.merchant.mobile.replace(/\+|\(|\)|-/gi, ''),
      merchantConfiguration: {
        ...this.merchant.merchantConfiguration,
        businessDayOffsetMap: this.getBusinessDays(
          this.merchant.merchantConfiguration.businessDayOffsetMap.daysOfWeek,
        ),
        yreportSubmissionStartTime: convertTimeToMilliseconds(
          this.merchant.merchantConfiguration.yreportSubmissionStartTime,
        ),
        discountsEnabled:
          this.merchant.merchantConfiguration.discountsEnabled === '0' ? false : true,
        discountShortcuts:
          this.merchant.merchantConfiguration.discountsEnabled === '2'
            ? this.merchant.merchantConfiguration.discountShortcuts.map(i => ({
                ...i,
                amount: +i.amount / 100,
              }))
            : [],
      },
    };
  };

  @action
  save = async (isShopInfoEditRouteAdmin, isShopInfoEditRoute) => {
    const merchant = this.getStructuredMerchant();
    if (
      this.merchant.merchantConfiguration.discountShortcuts.length &&
      this.merchant.merchantConfiguration.discountsEnabled
    ) {
      if (this.validationDiscountShortcuts(merchant)) {
        await this.onSave(merchant, this.imageFile);
        const userInfo = await AuthAPI.getUserInfo();

        runInAction(() => {
          this.userInfo = userInfo;
        });

        // bool ? history.push(routes.adminRestaurants) : history.push(routes.dashboard);
      } else {
        throw new Error(getLocalizedString('merchant.discount.error.message'));
      }
    } else {
      await this.onSave(merchant, this.imageFile);
      const userInfo = await AuthAPI.getUserInfo();

      runInAction(() => {
        this.userInfo = userInfo;
      });
      if (isShopInfoEditRouteAdmin) {
        history.push(routes.adminRestaurants);
      }
      if (isShopInfoEditRoute) {
        history.push(routes.dashboard);
      }
    }
  };

  @action
  saveOrderManagement = async bool => {
    const merchant = this.getStructuredMerchant();

    await this.onSave(merchant, this.imageFile);
    const userInfo = await AuthAPI.getUserInfo();

    runInAction(() => {
      this.userInfo = userInfo;
    });

    history.push(routes.backOfHouse);
  };

  @action
  toggleBusinessDay = day => {
    const currentState = this.merchant.merchantConfiguration.businessDayOffsetMap.daysOfWeek[day]
      .workingDay;

    runInAction(() => {
      this.merchant.merchantConfiguration.businessDayOffsetMap.daysOfWeek[
        day
      ].workingDay = !currentState;
      this.merchant = {
        ...this.merchant,
      };
    });
  };

  @action
  changeBusinessDaySchedule = (day, prop, time) => {
    const merchant = { ...this.merchant };
    merchant.merchantConfiguration.businessDayOffsetMap.daysOfWeek[day][prop] = time;

    runInAction(() => {
      this.merchant = merchant;
    });
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.merchant = { ...EMPTY_MERCHANT };
    this.validationErrorsDiscountShortcuts = {};
  };
}

export default MerchantEditor;
