import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment';
import validate from 'validate.js';
import CustomersAPI from 'api/loyalty/customers';
import history, { routes } from 'routes';
import { NEW_ROUTE_PREFIX } from 'utils/constants';
import { VALIDATION_SCHEMA, NEW_CUSTOMER } from './constants';

class CustomerEditor {
  constructor(Customers) {
    this.customers = Customers;
    this.onSave = this.customers.create;
    this.validationErrors = {};
  }

  @observable customer = NEW_CUSTOMER;
  @observable validationErrors = {};

  @observable orderHistory = {
    items: [],
    currentPage: 0,
    paginateOptionst: { size: 20 },
  };

  @observable voucherList = {
    items: [],
    currentPage: 0,
    paginateOptionst: { size: 20 },
  };

  @observable cashbackHistoryList = {
    items: [],
    currentPage: 0,
    paginateOptionst: { size: 20 },
  };

  @action
  changePaginateOptionst = (key, value) => {
    runInAction(() => {
      this[key].paginateOptionst = value;
    });
  };

  @action
  validateCustomer = () => {
    this.validationErrors = validate(this.customer, VALIDATION_SCHEMA);
  };

  @computed get isValid() {
    const { id } = this.customer;
    const errors = validate(this.customer, VALIDATION_SCHEMA);
    const { phoneNumber } = this.validationErrors;

    if (id) {
      return !errors;
    } else {
      return !!(!errors && !phoneNumber);
    }
  }

  @computed get phoneNumber() {
    const { phoneNumber } = this.customer;

    return phoneNumber;
  }

  @computed get birthDate() {
    const { yearOfBirth, monthOfBirth, dayOfBirth } = this.customer;
    if (yearOfBirth === 0) {
      return null;
    } else {
      return moment()
        .year(yearOfBirth)
        .month(monthOfBirth)
        .date(dayOfBirth)
        .valueOf();
    }
  }

  @action
  changeCustomer = (key, value) => {
    if (VALIDATION_SCHEMA[key]) {
      this.validationErrors[key] = validate.single(value, VALIDATION_SCHEMA[key]);
    }

    this.customer[key] = value;
  };

  @action
  changePhone = number => {
    if (!this.customer.id) {
      this.customer.phoneNumber = number;
    }
  };

  @action
  changeBirthDate = date => {
    const birthDate = moment(date);

    this.customer.dayOfBirth = birthDate.date();
    this.customer.monthOfBirth = birthDate.month();
    this.customer.yearOfBirth = birthDate.year();
  };

  @action
  create = admin => {
    runInAction(() => {
      this.customer = {
        ...NEW_CUSTOMER,
      };
      this.onSave = this.customers.create;
      history.push(`${admin ? '/admin' : ''}${routes.customers}/${NEW_ROUTE_PREFIX}`);
    });
  };

  @action
  setCustomer = customer => {
    runInAction(() => {
      this.customer = customer;
    });
  };

  @action
  edit = async customerId => {
    const customer = await CustomersAPI.edit(customerId);

    runInAction(() => {
      this.customer = customer;
      this.onSave = this.customers.update;
    });
  };

  parsePhoneNumber = phone => {
    return phone.replace(/\+|\(|\)|-/gi, '');
  };

  @action
  save = async admin => {
    const customerPhone = `${this.customer.phoneNumber}`;

    const customer = {
      ...this.customer,
      phoneNumber: this.parsePhoneNumber(customerPhone),
    };

    await this.onSave(customer);
    admin ? history.push(routes.adminCustomersNew) : history.push(routes.customers);
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.customer = { ...NEW_CUSTOMER };
  };

  @action
  getOrderHistory = async params => {
    const orderHistory = await CustomersAPI.getOrderHistory(this.customer.phoneNumber, params);

    runInAction(() => {
      this.orderHistory = orderHistory;
    });
  };

  @action
  fetchTablePage = async (page = 0) => {
    const { size } = this.orderHistory.paginateOptionst;
    if (this.customer.phoneNumber) {
      const { items, last, totalPages, number } = await CustomersAPI.getOrderHistory(
        this.customer.phoneNumber,
        { page, size },
      );

      runInAction(() => {
        this.orderHistory = {
          items,
          currentPage: number,
          totalPages,
          hasMore: !last,
          paginateOptionst: this.orderHistory.paginateOptionst,
        };
      });
    }
  };

  @action
  fetchListPage = async (page = 0) => {
    const { size } = this.orderHistory.paginateOptionst;
    if (this.customer.phoneNumber) {
      const { items, last, totalPages, number } = await CustomersAPI.getOrderHistory(
        this.customer.phoneNumber,
        { page, size },
      );

      runInAction(() => {
        this.orderHistory = {
          items: page ? [...this.voucherList.items, items] : items,
          currentPage: number,
          totalPages,
          hasMore: !last,
          paginateOptionst: this.orderHistory.paginateOptionst,
        };
      });
    }
  };

  @action
  fetchVoucherTablePage = async (page = 0) => {
    const { size } = this.voucherList.paginateOptionst;
    if (this.customer.id) {
      const { items, last, totalPages, number } = await CustomersAPI.getVoucherHistory(
        this.customer.id,
        { page, size },
      );

      runInAction(() => {
        this.voucherList = {
          items,
          currentPage: number,
          totalPages,
          hasMore: !last,
          paginateOptionst: this.voucherList.paginateOptionst,
        };
      });
    }
  };

  @action
  getCashbackHistory = async (customerId, { page = 0, table = '' }) => {
    try {
      const { size } = this.cashbackHistoryList.paginateOptionst;
      const { items, last, totalPages, number } = await CustomersAPI.getCashbackHistory(
        customerId,
        page,
        size,
      );

      runInAction(() => {
        if (table) {
          this.cashbackHistoryList = {
            items,
            hasMore: !last,
            totalPages,
            currentPage: number,
            paginateOptionst: this.cashbackHistoryList.paginateOptionst,
          };
        } else {
          if (page === 0) {
            this.cashbackHistoryList = {
              items,
              hasMore: !last,
              totalPages,
              currentPage: number,
              paginateOptionst: this.cashbackHistoryList.paginateOptionst,
            };
          } else {
            this.cashbackHistoryList = {
              items: [...this.cashbackHistoryList.items, ...items],
              hasMore: !last,
              totalPages,
              currentPage: number,
              paginateOptionst: this.cashbackHistoryList.paginateOptionst,
            };
          }
        }
      });
    } catch (error) {
      throw new Error(error.message);
    } finally {
      this.loading = false;
    }
  };

  @action
  fetchVoucherListPage = async (page = 0) => {
    const { size } = this.voucherList.paginateOptionst;
    if (this.customer.id) {
      const { items, last, totalPages, number } = await CustomersAPI.getVoucherHistory(
        this.customer.id,
        { page, size },
      );

      runInAction(() => {
        this.voucherList = {
          items: page ? [...this.orderHistory.items, items] : items,
          currentPage: number,
          totalPages,
          hasMore: !last,
          paginateOptionst: this.voucherList.paginateOptionst,
        };
      });
    }
  };
}

export default CustomerEditor;
