import { observable, action, runInAction } from 'mobx';
import MerchantAPI from 'api/merchant';
import InfoAPI from 'api/info';
import { CURRENCY_CODE_TO_SIGN } from 'utils/constants';

class ORTY {
  @observable currencies = [];
  @observable apkDownloadUrl = '';
  @observable kdsDownloadUrl = '';

  fetchSystemInfo = () => {
    this.fetchDownloadApkLink();
    this.fetchDownloadApkKdsLink();
    return this.fetchAvailableCurrencies();
  };

  @action
  fetchAvailableCurrencies = async () => {
    const { items } = await MerchantAPI.fetchCurrencies();

    runInAction(() => {
      this.currencies = items.map(currency => ({
        text: CURRENCY_CODE_TO_SIGN[currency.name],
        value: currency.name,
      }));
    });
  };

  @action
  fetchDownloadApkKdsLink = async () => {
    const { apkDownloadUrl } = await InfoAPI.getKdsinfo();

    runInAction(() => {
      this.kdsDownloadUrl = apkDownloadUrl;
    });
  };

  @action
  fetchDownloadApkLink = async () => {
    const { apkDownloadUrl } = await InfoAPI.mPosInfo();

    runInAction(() => {
      this.apkDownloadUrl = apkDownloadUrl;
    });
  };

  openLink = link => window.location.assign(link);
}

export default ORTY;
