import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/supply/storage';

class StorageApi {
  static list = () => Request.GET(`${ENDPOINT}`);
}

export default StorageApi;
