import { getUTCOffset } from 'utils/time';
import moment from 'moment';
import { getLocalizedString } from 'i18n/utils';

const utcOffset = getUTCOffset();

export const DATE = 'date';
export const USERNAME = 'displayName';
const REMARKS = 'remarks';
const ORDER_NUMBER = 'busDayNumber';
const ORDER_TYPE = 'orderType';
const TOTAL_PRICE = 'totalPrice';
const DELIVERY_INFO = 'deliveryInfo';
const ORDER_ITEM_NAME = 'orderItemName';
const QUANTITY = 'quantity';
const DISCOUNT = 'discountAmount';
const SUB_TOTAL = 'subTotal';
const H_T_ORDER_INFO = 'headerTitleOrderInfo';
const OPTIONS = 'options';
const GUESTS_NUMBER = 'guestsNumber';
const PAYMENT_AMOUNT = 'paymentAmount';
const PAYMENT_METHOD = 'paymentMethod';
const H_T_PAYMENT_METHOD = 'headerTitlePaymentMethod';
const SSUM = 'selfServiceUserMobile';
const USER_HUMAN_NAME = 'userHumanName';
const DATA = 'timestamp';
const ACTION = 'action';
const CUSTOMER_FEEDBACK = 'customerFeedback';
export const ORDER_STATUS = 'orderStatus';
const H_T_CUSTOMER_INFO = 'headerTitleCustomerInfo';
const H_T_DELIVERY_INFO = 'headerTitleDeliveryInfo';
const ORDER_PRICE = 'subTotalNotDiscounted';
const CUSTOMER_NAME = 'customerName';
const PAYMENT_INFOS = 'paymentInfosMethod';
const REPORTED = 'reported';
const DEVICE = 'device';
const SELF_SERVICE_USER_MOBILE = 'selfServiceUserMobile';
const REMARK = 'remark';
const DELIVERED_DATE = 'deliveredTimestamp';
const CREATED_DATE = 'createdTimestamp';

export const DEFAULT_START_DATE =
  moment()
    .startOf('day')
    .valueOf() + utcOffset;
export const DEFAULT_END_DATE =
  moment()
    .endOf('day')
    .valueOf() + utcOffset;

export const getSalesTableFormat = totalPrice => {
  return new Map([
    [
      ORDER_NUMBER,
      {
        id: 'sales.data.orderNumber',
        defaultMessage: '#',
        property: 'BUS_DAY_NUMBER',
        sortable: true,
      },
    ],
    [
      USERNAME,
      {
        id: 'sales.data.username',
        defaultMessage: 'Cashier',
        property: 'DISPLAY_NAME',
        sortable: true,
      },
    ],
    [
      TOTAL_PRICE,
      {
        id: 'sales.data.totalPrice',
        defaultMessage: 'Order Amount',
        property: 'TOTAL_PRICE',
        sortable: true,
        totalValue: totalPrice,
      },
    ],
    [
      ORDER_TYPE,
      { id: 'sales.data.type', defaultMessage: 'Type', property: 'ORDER_TYPE', sortable: true },
    ],
    [
      ORDER_STATUS,
      {
        id: 'sales.data.orderStatus',
        defaultMessage: 'Status',
        property: 'ORDER_STATUS',
        sortable: true,
      },
    ],
    [
      CREATED_DATE,
      {
        id: 'sales.data.createdDate',
        defaultMessage: 'Created date',
        property: 'CREATED_DATE',
        sortable: true,
      },
    ],
    [
      DELIVERED_DATE,
      {
        id: 'sales.data.deliveredDate',
        defaultMessage: 'Delivered date',
        property: 'DELIVERED_DATE',
        sortable: true,
      },
    ],
    [
      PAYMENT_INFOS,
      {
        id: 'sales.data.paymentMethod',
        defaultMessage: 'Payment method',
        // property: 'PAYMENT_INFOS_METHOD',
        // sortable: true,
      },
    ],
    [
      REPORTED,
      {
        id: 'navigation.xReport',
        defaultMessage: 'Shift report',
        property: 'REPORTED',
        sortable: true,
      },
    ],
    [
      CUSTOMER_FEEDBACK,
      {
        id: 'sales.data.customerFeedback',
        defaultMessage: 'Customer feedback',
        property: 'CUSTOMER_FEEDBACK',
        sortable: true,
      },
    ],
    [
      CUSTOMER_NAME,
      {
        id: 'sales.data.customerName',
        defaultMessage: 'Customer name',
        // property: 'CUSTOMER_NAME',
        // sortable: true,
      },
    ],
    [
      SELF_SERVICE_USER_MOBILE,
      {
        id: 'sales.data.selfServiceUserMobile',
        defaultMessage: 'Selfservice user mobile',
        // property: 'CUSTOMER_MOBILE',
        // sortable: true,
      },
    ],
    [
      REMARKS,
      { id: 'sales.data.remarks', defaultMessage: 'Remarks', property: 'REMARKS', sortable: true },
    ],
  ]);
};

export const SALES_LIST_FORMAT = {
  headerProperty: [
    [ORDER_NUMBER, { id: 'sales.data.orderNumber', defaultMessage: '#' }],
    [ORDER_TYPE, { id: 'sales.data.type', defaultMessage: 'Type' }],
  ],
  properties: new Map([
    [USERNAME, { id: 'sales.data.username', defaultMessage: 'Cashier' }],
    [
      CREATED_DATE,
      {
        id: 'sales.data.createdDate',
        defaultMessage: 'Created date',
      },
    ],
    [
      DELIVERED_DATE,
      {
        id: 'sales.data.deliveredDate',
        defaultMessage: 'Delivered date',
      },
    ],
    [TOTAL_PRICE, { id: 'sales.data.totalPrice', defaultMessage: 'Order Amount' }],
    [CUSTOMER_FEEDBACK, { id: 'sales.data.customerFeedback', defaultMessage: 'Customer feedback' }],
    [
      CUSTOMER_NAME,
      {
        id: 'sales.data.customerName',
        defaultMessage: 'Customer name',
      },
    ],
    [
      SELF_SERVICE_USER_MOBILE,
      {
        id: 'sales.data.selfServiceUserMobile',
        defaultMessage: 'Selfservice user mobile',
      },
    ],
    [REMARKS, { id: 'sales.data.remarks', defaultMessage: 'Remarks' }],
    [ORDER_STATUS, { id: 'sales.data.orderStatus', defaultMessage: 'Status' }],
    [
      REPORTED,
      {
        id: 'sales.data.Xreported',
        defaultMessage: 'X-reported',
      },
    ],
    [PAYMENT_INFOS, { id: 'sales.data.paymentMethod', defaultMessage: 'Payment method' }],
  ]),
};

export const ORDER_CUSTOMER_INFO_TABLE = new Map([
  [SSUM, { id: 'users.editor.phone', defaultMessage: 'Phone', width: '20%' }],
  [
    CUSTOMER_NAME,
    {
      id: 'dashboard.problemOrders.name.FirstName',
      defaultMessage: 'First name, last name',
      width: '20%',
    },
  ],
  [
    CUSTOMER_FEEDBACK,
    { id: 'sales.data.customerFeedback', defaultMessage: 'Сustomer Feedback', width: '60%' },
  ],
]);

export const ORDER_CUSTOMER_INFO_LIST = {
  headerProperty: [H_T_CUSTOMER_INFO],
  properties: new Map([
    [SSUM, { id: 'users.editor.phone', defaultMessage: 'Phone' }],
    [
      CUSTOMER_NAME,
      { id: 'dashboard.problemOrders.name.FirstName', defaultMessage: 'First name, last name' },
    ],
    [CUSTOMER_FEEDBACK, { id: 'sales.data.customerFeedback', defaultMessage: 'Сustomer Feedback' }],
  ]),
};

export const PAYMENT_TABLE = new Map([
  [
    PAYMENT_METHOD,
    { id: 'sales.order.payment.header.method', defaultMessage: 'Payment method', widht: '20%' },
  ],
  [
    PAYMENT_AMOUNT,
    { id: 'sales.order.payment.header.amount', defaultMessage: 'Amount', width: '20%' },
  ],
  [
    GUESTS_NUMBER,
    { id: 'sales.order.payment.header.guestAmount', defaultMessage: 'Guest amount', width: '20%' },
  ],
  [
    USER_HUMAN_NAME,
    { id: 'sales.order.payment.header.customer', defaultMessage: 'Сustomer name', width: '40%' },
  ],
]);

export const PAYMENT_LIST = {
  headerProperty: [H_T_PAYMENT_METHOD],
  properties: new Map([
    [PAYMENT_METHOD, { id: 'sales.order.payment.header.method', defaultMessage: 'Payment method' }],
    [PAYMENT_AMOUNT, { id: 'sales.order.payment.header.amount', defaultMessage: 'Amount' }],
    [
      GUESTS_NUMBER,
      { id: 'sales.order.payment.header.guestAmount', defaultMessage: 'Guest amount' },
    ],
    [
      USER_HUMAN_NAME,
      { id: 'sales.order.payment.header.customer', defaultMessage: 'Сustomer name' },
    ],
  ]),
};

export const DELIVERY_TABLE = new Map([
  [
    DELIVERY_INFO,
    {
      id: 'dashboard.problemOrders.delivery.address',
      defaultMessage: 'Delivery address',
      width: '80%',
    },
  ],
  [ORDER_STATUS, { id: 'sales.data.orderStatus', defaultMessage: 'Order status', width: '20%' }],
]);

export const DELIVERY_LIST = {
  headerProperty: [H_T_DELIVERY_INFO],
  properties: new Map([
    [
      DELIVERY_INFO,
      { id: 'dashboard.problemOrders.delivery.address', defaultMessage: 'Delivery address' },
    ],
    [ORDER_STATUS, { id: 'sales.data.orderStatus', defaultMessage: 'Order status' }],
  ]),
};

export const AUDIT_TABLE = new Map([
  [DATA, { id: 'dashboard.sales.tab3.title', defaultMessage: 'Date', width: '20%' }],
  [ACTION, { id: 'sales.order.audit.header.actions', defaultMessage: 'Actions', width: '20%' }],
  [ORDER_STATUS, { id: 'sales.data.orderStatus', defaultMessage: 'Status', width: '20%' }],
  [USER_HUMAN_NAME, { id: 'sales.order.audit.header.user', defaultMessage: 'User', width: '20%' }],
  [DEVICE, { id: 'sales.order.audit.header.device', defaultMessage: 'User', width: '20%' }],
]);

export const AUDIT_LIST = {
  headerProperty: null,
  properties: new Map([
    [DATA, { id: 'dashboard.sales.tab3.title', defaultMessage: 'Date' }],
    [ACTION, { id: 'sales.order.audit.header.actions', defaultMessage: 'Actions' }],
    [ORDER_STATUS, { component: true }],
    [USER_HUMAN_NAME, { id: 'sales.order.audit.header.user', defaultMessage: 'User' }],
    [DEVICE, { id: 'sales.order.audit.header.device', defaultMessage: 'User', width: '20%' }],
  ]),
};

export const ORDER_TABLE = new Map([
  [
    ORDER_ITEM_NAME,
    { id: 'promotions.editor.conditions.product', defaultMessage: 'Dish', width: '20%' },
  ],
  [
    QUANTITY,
    { id: 'dashboard.problemOrders.orderItem.quantity', defaultMessage: 'Quantity', width: '20%' },
  ],
  [ORDER_PRICE, { id: 'sales.order.table.price', defaultMessage: 'Prise', width: '20%' }],
  [DISCOUNT, { id: 'sales.order.table.discount', defaultMessage: 'Discount', width: '20%' }],
  [SUB_TOTAL, { id: 'sales.order.table.total', defaultMessage: 'Total', width: '20%' }],
]);

export const ORDER_LIST = {
  headerProperty: [H_T_ORDER_INFO],
  properties: new Map([
    [QUANTITY, { id: 'dashboard.problemOrders.orderItem.quantity', defaultMessage: 'Quantity' }],
    [ORDER_PRICE, { id: 'sales.order.table.price', defaultMessage: 'Prise' }],
    [OPTIONS, { id: 'products.editor.property.options', defaultMessage: 'Options' }],
    [DISCOUNT, { id: 'sales.order.table.discount', defaultMessage: 'Discount' }],
    [SUB_TOTAL, { id: 'sales.order.table.total', defaultMessage: 'Total' }],
  ]),
};

export const REMARKS_TABLE = new Map([
  [REMARK, { id: 'sales.order.remark', defaultMessage: ' Remark', width: '100%' }],
]);

export const REMARKS_LIST = {
  headerProperty: null,
  properties: new Map([
    [REMARK, { id: 'sales.order.remark', defaultMessage: ' Remark', width: '100%' }],
  ]),
};

export const ordersStatus = [
  'NEW',
  'VOID',
  'REFUNDED',
  'DELIVERED',
  'IN_KITCHEN',
  'ASSEMBLING',
  'READY_FOR_DELIVER',
  'PENDING_PAYMENT',
  'STUCK_AT_BANK',
  'PAYMENT_FAILED',
  'PAYMENT_CANCELLED',
  'DECLINED',
  'DELAYED',
].map((item, index) => ({
  text: getLocalizedString(`sales.data.orderStatus.${item}`),
  value: item,
  key: index,
}));

export const terminalStatus = [
  'NEW',
  'IN_KITCHEN',
  'ASSEMBLING',
  'PENDING_PAYMENT',
  'STUCK_AT_BANK',
  'DELAYED',
  'ON_HOLD',
  'READY_FOR_DELIVER',
];
