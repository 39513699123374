import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/merchant';

class MerchantAPI {
  static register = async merchantInfo => {
    const { token, refreshToken } = await Request.POST(`${ENDPOINT}/register`, merchantInfo);

    Request.token = token;
    Request.refreshToken = refreshToken;
  };

  static fetchMerchant = async merchantId => {
    if (Number.isInteger(parseInt(merchantId))) {
      return await Request.GET(`${ENDPOINT}/${merchantId}`);
    }
  };

  static fetchCurrentMerchant = async options => await Request.GET(`${ENDPOINT}/info`, options);

  static fetchMerchantGroupInfo = async () => await Request.GET(`${ENDPOINT}/group/info`);

  static updateMerchant = merchant => Request.PUT(ENDPOINT, merchant);

  static fetchCurrencies = () => Request.GET(`${ENDPOINT}/currency`);

  static createGroupName = async name => await Request.PUT(`${ENDPOINT}/group/name/${name}`);

  static fetchRoundingMode = () => Request.GET(`${ENDPOINT}/roundingMode`);

  static resendConfirmationEmail = email =>
    Request.POST(`${ENDPOINT}/register/resendConfirmation`, { email });

  static create = async email => {
    const { token, refreshToken } = await Request.POST(`${ENDPOINT}/create`, { email });

    Request.token = token;
    Request.refreshToken = refreshToken;
  };

  static fetchAvailablePaymentMethods = () => Request.GET(`${ENDPOINT}/paymentMethod`);

  static fetchAvailableSelfPaymentMethod = () => Request.GET(`${ENDPOINT}/selfPaymentMethod`);

  static asignPaymentMethods = (merchantId, paymentMethods) =>
    Request.POST(`${ENDPOINT}/${merchantId}/paymentMethod/assign`, paymentMethods);

  static updateSelfService = selfService =>
    Request.PUT(`${ENDPOINT}/selfServiceConfiguration`, selfService);

  static getSelfService = () => Request.GET(`${ENDPOINT}/selfServiceConfiguration`);

  static fetchGroup = (queryString = '') => Request.GET(`${ENDPOINT}/group/members${queryString}`);

  static updateGroup = group => Request.PUT(`${ENDPOINT}/group`, group);

  static fetchGroupById = groupId => Request.GET(`${ENDPOINT}/group/${groupId}`);

  static fetchSupportedLocales = () => Request.GET(`${ENDPOINT}/supportedLocales`);

  static removeGroupItem = itemId => Request.DELETE(`${ENDPOINT}/${itemId}`);

  static addToGroup = merchant => Request.POST(`${ENDPOINT}/addToGroup`, merchant);

  static setCurrentMerchant = async (id, options) => {
    const data = await Request.PUT(`${ENDPOINT}/current`, { id }, options);

    if (data) {
      const { token, refreshToken } = data;
      Request.updateTokens(token, refreshToken);
    }
  };

  static getDeliveryServices = () => Request.GET(`${ENDPOINT}/deliveryService`);

  static onBoard = params => Request.PUT(`${ENDPOINT}/on-boarding`, params);

  static startSell = () => Request.PUT(`${ENDPOINT}/start-sell`, {});

  static integrationFile = date => Request.GET(`/sales/integrationFile?startDate=${date}`);
}

export default MerchantAPI;
