import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/inventory/admin/storage';

class StoragesAPI {
  static list = () => Request.GET(`${ENDPOINT}`);
}

export default StoragesAPI;
