import { createBrowserHistory } from 'history';

import { authRoutes } from './auth';
import { settingRoutes } from './account';
import { dashboardRoutes } from './dashboard';
import { adminRoutes } from './admin';

export const routes = {
  ...authRoutes,
  ...settingRoutes,
  ...dashboardRoutes,
  ...adminRoutes,
};

const history = createBrowserHistory();

export default history;
