import { observable, computed, action, runInAction } from 'mobx';
import momentTimezone from 'moment-timezone';
import queryString from 'query-string';
import MerchantAPI from 'api/merchant';
import { hostname } from 'utils/env';

import { CURRENCY_CODE_TO_SIGN, CURRENCY_CODE_TO_SMS_PRICE } from 'utils/constants';

const EMPTY_MERCHANT = {
  name: '',
  mobile: '',
  description: '',
  address: '',
  currency: '',
  serviceFee: '0',
  turnoverTax: '0',
  countryMobileCode: '',
  logo: 'https://orty-public.sgp1.digitaloceanspaces.com/placeholder/merchant_logo_placeholder.png',
  merchantConfiguration: {
    printDocket: true,
    printChit: true,
    payOnMPos: true,
    externalSystemIntegration: '',
    timezone: momentTimezone.tz.guess(),
  },
  paymentMethods: [],
};

class Merchant {
  constructor(Account) {
    this.account = Account;
    this.loading = false;
    this.supportedLocales = [];
    this.permissionsLength = 0;
    this.currentMerchantIndex = 0;
    this.sendFiredDelayedAutomaticallyClone = false;
  }

  @observable info = { ...EMPTY_MERCHANT };

  @observable taxes = [{ name: 'GST', value: '0.00' }];

  @computed get availableTaxes() {
    return this.taxes.map(tax => ({
      text: tax.name,
      value: tax.value,
    }));
  }

  @computed get currency() {
    const { currency } = this.info;

    return CURRENCY_CODE_TO_SIGN[currency];
  }

  @action
  createLink = ({ path = '', categoryId, ...rest } = {}, merchant) => {
    const { id, alias } = merchant ? merchant : this.info;

    const params = {
      ...rest,
    };

    if (!alias) {
      params.mid = id;
    }

    if (categoryId) {
      params.c = categoryId;
    }

    const query = queryString.stringify(params);

    return `https://${alias || hostname}/${path}${query ? `?${query}` : ''}`;
  };

  @action
  smsPrice = currency => {
    return CURRENCY_CODE_TO_SMS_PRICE[currency];
  };

  @computed get turnoverTax() {
    return this.info.turnoverTax;
  }

  @action
  setCurrentMerchant = async id => {
    if (id) {
      await MerchantAPI.setCurrentMerchant(id);
      //  error handler for blocked merchant
      /*{
        errorHandler: (error) => {
          if (UNAUTHORIZED === error.code) {
            this.redirectMerchant();

            if (this.currentMerchantIndex >= this.permissionsLength) {
              runInAction(() => {
                this.loading = false;
              });

              history.push (routes.blockedMerchantPlaceholder);
            }
          }
        }
      }*/

      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  redirectMerchant = async merchantId => {
    const { permissionDtos } = this.account.info;
    const id = Number(
      merchantId || (permissionDtos && permissionDtos[this.currentMerchantIndex].merchantId),
    );

    if (parseInt(id)) {
      runInAction(() => {
        this.loading = true;
        this.permissionsLength = permissionDtos.length;
        this.currentMerchantIndex += 1;
      });

      await this.setCurrentMerchant(id);
      await this.fetchInfo();
      await this.account.authorizeUser();
    }
  };

  @action
  signUp = merchantInfo => MerchantAPI.register({ ...merchantInfo });

  @action
  fetchInfo = async () => {
    const info = await MerchantAPI.fetchCurrentMerchant();
    runInAction(() => {
      this.info = info;
      this.sendFiredDelayedAutomaticallyClone =
        info.merchantConfiguration.sendFiredDelayedAutomatically;
    });
  };

  @action
  updateInfo = (key, value) => {
    this.info[key] = value;
  };

  @action
  updateConfiguration = (key, value) => {
    this.info.merchantConfiguration[key] = value;
  };

  @observable imageFile = '';

  @action
  updateImage = ({ image }) => {
    this.imageFile = image;
  };

  @action
  fulFillMerchantInfo = async () => {
    const merchant = {
      id: this.account.info.merchantId,
      ...this.info,
    };
    const info = await MerchantAPI.updateMerchant(merchant);

    runInAction(() => {
      this.info = info;
      this.sendFiredDelayedAutomaticallyClone =
        info.merchantConfiguration.sendFiredDelayedAutomatically;
    });
  };

  @action
  fetchSupportedLocales = async () => {
    const { items } = await MerchantAPI.fetchSupportedLocales();

    const supportedLocales = items.map(({ key }) => ({
      text: key,
      value: key,
    }));

    runInAction(() => {
      this.supportedLocales = supportedLocales;
    });
  };
}

export default Merchant;
