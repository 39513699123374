import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { inject, observer } from 'mobx-react';
import MomentUtils from '@date-io/moment';
import LoadingBar from 'components/common/loading-bar';
import { routes } from 'routes';

import Auth from 'scenes/Authentication';
import Dashboard from 'scenes/Dashboard';
import Admin from 'scenes/Admin';
import Info from 'components/common/AppInfo';
import Notification from 'components/common/Notification';
import ModalProvider from 'components/dashboard/ModalProvider';

const App = withRouter(
  inject('Account', 'MerchantsGroup')(
    observer(({ Account, MerchantsGroup }) => {
      useEffect(() => {
        if (!MerchantsGroup.currentGroupLoaded && Account.canLoadFetchGroupById) {
          MerchantsGroup.fetchMerchantGroupInfo();
        }
      }, [Account.canLoadFetchGroupById]);

      return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <LoadingBar />
          <Notification />

          <Switch>
            <Route path={routes.auth} component={Auth} />
            <Route path={routes.adminDashboard} component={Admin} />
            <Route component={Dashboard} />
          </Switch>

          <Info />
          <ModalProvider />
        </MuiPickersUtilsProvider>
      );
    }),
  ),
);

export default App;
