import React, { PureComponent } from 'react';
import Bar from 'react-top-loading-bar';

class LoadingBar extends PureComponent {
  state = {
    count: 0,
  };

  loadingBar = null;

  componentDidMount() {
    window.startRequest = () => {
      this.setState(prevState => ({ count: prevState.count + 1 }));
    };
    window.endRequest = () => {
      this.setState(prevState => ({ count: prevState.count - 1 }));
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { count } = this.state;
    if (prevState.count === 0 && count > 0) {
      this.loadingBar.continuousStart();
    } else if (prevState.count > 0 && count === 0) {
      this.loadingBar.complete();
    }
  }

  onRef = ref => {
    this.loadingBar = ref;
  };

  render() {
    return (
      <div style={{ position: 'absolute', zIndex: 1000000 }}>
        <Bar height={3} color="rgb(27, 142, 255)" onRef={this.onRef} />
      </div>
    );
  }
}

export default LoadingBar;
