import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/inventory/product/property';
const ENDPOINT_RECIPE = '/supply/product-property-option-recipe/';

class ModifiersAPI {
  static list = (menuId, page = 0, size = 1000) =>
    Request.GET(`${ENDPOINT}/${menuId}/list?page=${page}&size=${size}`);

  static fetchModifier = propertyId => Request.GET(`${ENDPOINT}/${propertyId}`);

  static createModifier = property => Request.POST(ENDPOINT, property);

  static updateModifier = property => Request.PUT(ENDPOINT, property);

  static deleteModifier = id => Request.DELETE(ENDPOINT, id);

  static switchPOSChannel = (params, on, merchant) =>
    Request.PUT(
      `/inventory/product-property/settings${merchant ? '/merchant' : ''}/POS/${on ? 'on' : 'off'}`,
      params,
    );

  static switchSelfServiceChannel = (params, on, merchant) =>
    Request.PUT(
      `/inventory/product-property/settings${merchant ? '/merchant' : ''}/SELF_SERVICE/${
        on ? 'on' : 'off'
      }`,
      params,
    );

  static getProductSettingsByMerchant = params =>
    Request.POST(`/inventory/product-property/settings/merchant`, params);

  static getProductSettingsByGroup = params =>
    Request.POST(`/inventory/product-property/settings`, params);

  static getProductStockByMerchant = params =>
    Request.POST(`/inventory/product-property/settings/merchant/stock`, params);

  static setProductStockByMerchant = params =>
    Request.PUT(`/inventory/product-property/settings/merchant/stock`, params);

  static fetchOptionRecipe = id => Request.GET(`${ENDPOINT_RECIPE}${id}`);

  static create = productRecipe => Request.POST(ENDPOINT_RECIPE, productRecipe);

  static update = productRecipe => Request.PUT(ENDPOINT_RECIPE, productRecipe);

  static deleteOptionRecipe = id => Request.DELETE(`${ENDPOINT_RECIPE}${id}`);

  static default = id => Request.PUT(`${ENDPOINT_RECIPE}${id}/default`);

  static bulkMerchantUpdate = params =>
    Request.PUT('/inventory/product-property/settings/bulk/merchant', params);

  static bulkMerchantGroupUpdate = (params, id) =>
    Request.PUT(`/inventory/product-property/settings/bulk/merchant-group/${id}`, params);
}

export default ModifiersAPI;
