import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/menu';

class MenuAPI {
  static fetchMenuByMerchant = () => {
    return Request.GET(ENDPOINT);
  };

  static updateMenusOrder = itemOrderDtos => Request.PUT(`${ENDPOINT}/order`, { itemOrderDtos });

  static fetchMenuById = id => {
    return Request.GET(`${ENDPOINT}/${id}`);
  };

  static createMenu = menu => {
    return Request.POST(`${ENDPOINT}`, menu);
  };

  static cloneMenu = async menu => {
    return await Request.POST(`${ENDPOINT}/${menu.id}/copy`);
  };

  static updateMenu = menu => {
    return Request.PUT(`${ENDPOINT}`, menu);
  };

  static deleteMenu = async menu => {
    return await Request.DELETE(`${ENDPOINT}`, menu.id);
  };

  static fetchMenuForMerchantGroup = () => {
    return Request.GET(`${ENDPOINT}/group`);
  };

  static uploadFile = ({ file, menuId }) => {
    const data = new FormData();
    data.append('file', file);

    return Request.POST_FILE(`/inventory/product/${menuId}/import/file`, data);
  };
}

export default MenuAPI;
