import APIConnector from './utils/APIConnector';
import { extractFiltersQuery } from './utils/helpers';

const Request = APIConnector.instance;
const ENDPOINT = '/user';

class UsersAPI {
  static listAll = (page, filters) => {
    const filtersQuery = extractFiltersQuery(filters);
    return Request.GET(`${ENDPOINT}/list?page=${page}${filtersQuery}`);
  };

  static fetchUsersRole = () => Request.GET(`${ENDPOINT}/role/list`);

  static fetchUser = userId => Request.GET(`${ENDPOINT}/${userId}`);

  static createUser = userInfo => Request.POST(ENDPOINT, userInfo);

  static updateUser = userInfo => Request.PUT(ENDPOINT, userInfo);

  static blockUser = userId => Request.POST(`${ENDPOINT}/block`, { id: userId });

  static unblockUser = userId => Request.POST(`${ENDPOINT}/unblock`, { id: userId });

  static removeUser = userId => Request.DELETE(ENDPOINT, { userId });

  static completeOnBoarding = () => Request.PUT(`${ENDPOINT}/on-boarding`, {});

  static getInventoryAccessLevel = () => Request.GET(`${ENDPOINT}/inventory-access-level/list`, {});
}

export default UsersAPI;
