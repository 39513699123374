import { action, observable, runInAction } from 'mobx';
import history, { routes } from 'routes';
import PostsAPI from 'api/posts';
import ImagesAPI from 'api/images';
import { IMAGE_PLACEHOLDER } from 'utils/constants';

const initPost = {
  name: '',
  title: '',
  body: '',
  type: 'CUSTOM',
  targetAudiences: [],
  merchantGroupId: '',
  image: IMAGE_PLACEHOLDER,
  beforeCategoryIds: [],
  merchantIds: [],
};

class PostsEditor {
  @observable post = initPost;
  @observable imageFile = '';

  @action
  create = path => {
    history.push(path);
  };

  @action
  changePost = (key, value) => {
    this.post[key] = value;
  };

  @action
  edit = async id => {
    const data = await PostsAPI.fetchPost(id);

    runInAction(() => {
      this.post = data;
    });
  };

  @action
  submitPost = async (post, { isEdit }) => {
    let newPost = { ...post };
    if (this.imageFile) {
      const { url } = await ImagesAPI.upload(this.imageFile);

      newPost.image = url;
    }
    if (isEdit) {
      await PostsAPI.updatePost(post.id, newPost);
    } else {
      await PostsAPI.createPost(newPost);
    }
  };

  @action
  save = async ({ admin, isEdit, merchantGroupId, singleShop, merchantId }) => {
    await this.submitPost(
      {
        ...this.post,
        merchantGroupId,
        merchantIds: singleShop ? [merchantId] : this.post.merchantIds,
      },
      { isEdit },
    );

    admin ? history.push(routes.adminPosts) : history.push(routes.posts);
  };

  @action
  updateImage = props => {
    this.imageFile = props.image;
  };

  @action
  removeImage = () => {
    this.post = {
      ...this.post,
      image: IMAGE_PLACEHOLDER,
    };
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.customersSelections = [];
    this.post = initPost;
    this.imageFile = '';
    this.conditionSelections = [];
  };
}

export default PostsEditor;
