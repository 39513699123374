import { action, computed, observable, runInAction } from 'mobx';
import UsersAPI from 'api/users';
import history, { routes } from 'routes';

import { getLocalizedString } from 'i18n/utils';
import { DISPLAY_USER_ROLES } from './constants';

const USERNAME = 'username';
const FULL_NAME = 'fullName';
const ROLE = 'role';

export const USERS_TABLE_FORMAT = new Map([
  [USERNAME, { id: 'users.data.username', defaultMessage: 'Username' }],
  [FULL_NAME, { id: 'users.data.fullName', defaultMessage: 'Full name' }],
  [ROLE, { id: 'users.data.role', defaultMessage: 'Role' }],
]);

export const USERS_LIST_FORMAT = {
  headerProperty: [USERNAME, { id: 'users.data.username', defaultMessage: 'Username' }],
  properties: new Map([
    [FULL_NAME, { id: 'users.data.fullName', defaultMessage: 'Full name' }],
    [ROLE, { id: 'users.data.role', defaultMessage: 'Role' }],
  ]),
};

const ACTIVE = 'ACTIVE';
const BLOCKED = 'BLOCKED';

class Users {
  tableFormat = USERS_TABLE_FORMAT;
  listFormat = USERS_LIST_FORMAT;

  @observable list = [];
  @observable totalPages = 0;
  @observable currentPage = -1;

  @computed get data() {
    return this.list.map(user => {
      const blockedMessage = getLocalizedString('global.blocked');
      const isUserBlocked = user.status === BLOCKED;

      return {
        ...user,
        username: isUserBlocked ? `${user.username} (${blockedMessage})` : user.username,
        role: DISPLAY_USER_ROLES[user.role],
        fullName: `${user.firstName} ${user.lastName}`,
      };
    });
  }

  @computed get availableUsers() {
    return this.list.map(({ email }) => ({
      value: email,
      text: email,
    }));
  }

  @computed get hasMore() {
    return this.currentPage + 1 < this.totalPages;
  }

  @action
  fetchListPage = async (page, merchantId) => {
    const { items, totalPages, number } = await UsersAPI.listAll(page, { merchantId });

    runInAction(() => {
      if (page === 0) {
        this.list = items;
      } else {
        this.list = [...this.list, ...items];
      }

      this.totalPages = totalPages;
      this.currentPage = number;
    });
  };

  @action
  fetchTablePage = async (page, merchantId) => {
    const { items, totalPages, number } = await UsersAPI.listAll(page, { merchantId });

    runInAction(() => {
      this.list = items;
      this.totalPages = totalPages;
      this.currentPage = number;
    });
  };

  @action
  fetchUser = userId => UsersAPI.fetchUser(userId);

  @action
  createUser = async userInfo => {
    await UsersAPI.createUser(userInfo);

    history.push(routes.users);
  };

  @action
  updateUser = userInfo => UsersAPI.updateUser(userInfo);

  getUserIndexById = userId => this.list.findIndex(user => user.id === userId);

  @action
  refreshUser = async userId => {
    const user = await this.fetchUser(userId);
    const index = this.getUserIndexById(userId);

    runInAction(() => {
      this.list = [...this.list.slice(0, index), user, ...this.list.slice(index + 1)];
    });
  };

  @action
  toggleBlockUser = (userId, blocked = false) => {
    const list = [...this.list];
    const index = this.getUserIndexById(userId);
    list[index].status = blocked ? BLOCKED : ACTIVE;

    runInAction(() => {
      this.list = list;
    });
  };

  @action
  blockUser = async userId => {
    await UsersAPI.blockUser(userId);

    this.toggleBlockUser(userId, true);
    return this.refreshUser(userId);
  };

  @action
  unblockUser = async userId => {
    await UsersAPI.unblockUser(userId);

    this.toggleBlockUser(userId);
    return this.refreshUser(userId);
  };

  @action
  removeUser = async userId => {
    try {
      await UsersAPI.removeUser(userId);
    } catch (error) {
      throw new Error(error.message);
    }

    runInAction(() => {
      const index = this.list.findIndex(user => user.id === userId);

      if (index) {
        this.list = [...this.list.slice(0, index), ...this.list.slice(index + 1)];
      }
    });
  };

  isUserBlocked = userId => {
    const index = this.list.findIndex(user => user.id === userId);

    return this.list[index] ? this.list[index].status === BLOCKED : false;
  };
}

export default Users;
