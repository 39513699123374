import { action, computed, observable, runInAction } from 'mobx';
import validate from 'validate.js';
import { getLocalizedString } from 'i18n/utils';
import MerchantAPI from 'api/merchant';
import UsersAPI from 'api/users';
import StoragesAPI from 'api/storages';
import history from 'routes';

const NEW_USER = {
  email: '',
  mobile: '',
  password: '',
  role: '',
  firstName: '',
  lastName: '',
  pin: '',
  supervisorId: 0,
  supervisorEmail: '',
  permissionDtos: [],
  canManageSupply: true,
  inventoryAccessLevel: 'EMPLOYEE',
  storagePermissionIds: [],
};

const VALIDATION_SCHEMA = {
  mobile: {
    presence: {
      allowEmpty: false,
    },
  },
  firstName: {
    presence: {
      allowEmpty: false,
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
    },
  },
  email: {
    presence: {
      allowEmpty: false,
    },
  },
  pin: {
    presence: {
      allowEmpty: false,
    },
  },
  // supervisorEmail: {
  //   presence: {
  //     allowEmpty: false,
  //   },
  //   email: true,
  // },
  storagePermissionIds: {
    presence: {
      allowEmpty: false,
    },
  },
  permissionDtos: {
    presence: {
      allowEmpty: false,
    },
  },
};

const PASSWORD_PLACEHOLDER = 'PASSWORD_PLACEHOLDER';

class UserEditor {
  constructor(Users) {
    this.users = Users;
    this.onSave = this.users.createUser;
  }

  @observable user = {
    ...NEW_USER,
  };
  @observable merchantGroupShops = [];
  @observable merchantGroupStorages = [];
  @observable userRoles = [];
  @observable touchAllowedShop = false;
  @observable inventoryAccessLevelOptions = [];

  @action
  clearUser = () => {
    this.user = { ...NEW_USER };
  };

  @action
  fetchMerchantGroupShops = async () => {
    const { items } = await MerchantAPI.fetchGroup('?filteredByPermission=false');

    runInAction(() => {
      this.merchantGroupShops = items.map(merchant => ({
        id: null,
        name: merchant.name,
        merchantId: merchant.id,
      }));
    });
  };

  @action
  fetchMerchantGroupStorages = async () => {
    const { items } = await StoragesAPI.list();

    runInAction(() => {
      this.user.storagePermissionIds = this.user.storagePermissionIds.filter(val =>
        items.some(({ id }) => id === val),
      );
      if (this.user.storagePermissionIds.length === 0) {
        this.user.storagePermissionIds = items.reduce((acc, item) => [...acc, item.id], []);
      }
      this.merchantGroupStorages = items;
    });
  };

  @action
  setTouchAllowedShop = () => {
    runInAction(() => {
      this.touchAllowedShop = true;
    });
  };

  @action
  toggleAllowedStorage = (checked, storageId) => {
    if (!this.touchAllowedShop) {
      this.setTouchAllowedShop();
    }

    let newStoragePermissionIds = [...this.user.storagePermissionIds];

    if (checked) {
      newStoragePermissionIds.push(storageId);
    } else {
      newStoragePermissionIds = this.user.storagePermissionIds.filter(item => item !== storageId);
    }
    runInAction(() => {
      this.user.storagePermissionIds = newStoragePermissionIds;
    });
  };

  @action
  toggleAllowedShop = allowedShop => {
    if (!this.touchAllowedShop) {
      this.setTouchAllowedShop();
    }
    const { permissionDtos } = this.user;

    const index = permissionDtos.findIndex(shop => allowedShop.merchantId === shop.merchantId);

    if (index > -1) {
      this.user.permissionDtos = [
        ...this.user.permissionDtos.slice(0, index),
        ...this.user.permissionDtos.slice(index + 1),
      ];
    } else {
      let permissionDto = {
        id: null,
        name: allowedShop.name,
        merchantId: allowedShop.merchantId,
      };
      this.user.permissionDtos = [...this.user.permissionDtos, permissionDto];
    }
  };

  @computed get allowedMerchantIds() {
    return this.user.permissionDtos.map(shop => shop.merchantId);
  }

  @action
  getInventoryAccessLevel = async () => {
    const { items } = await UsersAPI.getInventoryAccessLevel();
    runInAction(() => {
      this.inventoryAccessLevelOptions = items;
    });
  };

  @action
  fetchUsersRole = async () => {
    const { roles } = await UsersAPI.fetchUsersRole();
    const newRoles = roles.map(item => {
      return {
        text: getLocalizedString(`global.users.role.${item}`),
        value: item,
      };
    });

    runInAction(() => {
      this.userRoles = newRoles;
    });
  };

  @action
  create = path => {
    this.user = {
      ...NEW_USER,
    };
    this.onSave = this.users.createUser;

    history.push(path);
  };

  @action
  edit = async userId => {
    const user = await this.users.fetchUser(userId);

    runInAction(() => {
      this.user = {
        ...user,
        supervisorEmail: user.supervisorEmail === '' ? 'empty' : user.supervisorEmail,
        password: PASSWORD_PLACEHOLDER,
      };
      this.onSave = this.users.updateUser;
    });
  };

  @observable validationErrors = {};

  @action
  validateUser = () => {
    this.validationErrors = validate(this.user, VALIDATION_SCHEMA) || {};
  };

  @computed get isValid() {
    const errors = validate(this.user, VALIDATION_SCHEMA);

    return !errors;
  }

  @action
  changeUser = (key, value) => {
    if (VALIDATION_SCHEMA[key]) {
      this.validationErrors[key] = validate.single(value, VALIDATION_SCHEMA[key]);
    }

    if (key === 'mobile') {
      this.user[key] = value.replace('+', '');
    } else {
      this.user[key] = value;
    }
  };

  @action
  save = async redirect => {
    let newUser = {
      ...this.user,
      supervisorEmail: this.user.supervisorEmail === 'empty' ? '' : this.user.supervisorEmail,
    };
    if (newUser.password === PASSWORD_PLACEHOLDER) {
      delete newUser.password;
    }

    await this.onSave(newUser);
    redirect();
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.touchAllowedShop = false;
  };
}

export default UserEditor;
