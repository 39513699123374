import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/supply/ingredient';

class IngredientAPI {
  static list = (page = 0, size = 1000, name = '') => {
    const newName = name.length ? `&name=${name}` : '';
    return Request.GET(`${ENDPOINT}?page=${page}&size=${size}${newName}`);
  };
}

export default IngredientAPI;
