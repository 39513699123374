import React from 'react';
import { bool, func, shape, string, any } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button, Modal } from 'semantic-ui-react';
import ErrorBoundary from 'components/common/error-boundary';

import warningIcon from '../../../../images/dashboard/warning.svg';

import { PopupActions, Content, Headline, Text, Icon } from './styles';

export const WarningModal = ({
  open,
  subject,
  closeModal,
  submitModal,
  isLoading,
  size = '',
  cancelText = <FormattedMessage id="global.popups.delete.decline" defaultMessage="Cancel" />,
  applyText = <FormattedMessage id="global.popups.delete.confirm" defaultMessage="Remove" />,
}) => {
  return (
    <ErrorBoundary>
      <Modal open={open} onClose={closeModal} className="popupModal" size={size || 'mini'}>
        <Modal.Content>
          <Modal.Description>
            <Content>
              <Icon src={warningIcon} alt="warning icon" />
              <Headline>
                <FormattedMessage id="global.popups.warning.title" defaultMessage="PAY ATTENTION" />
              </Headline>

              <Text>{subject}</Text>
            </Content>
            <PopupActions>
              <Button onClick={closeModal} size="big">
                {cancelText}
              </Button>
              <Button
                primary
                size="big"
                type="submit"
                onClick={submitModal}
                disabled={isLoading}
                loading={isLoading}
              >
                {applyText}
              </Button>
            </PopupActions>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </ErrorBoundary>
  );
};

WarningModal.defaultProps = {
  subject: '',
  size: '',
  applyText: <FormattedMessage id="global.popups.warning.confirm" defaultMessage="Yes, I agree" />,
  cancelText: <FormattedMessage id="global.popups.warning.decline" defaultMessage="Cancel" />,
};

WarningModal.propTypes = {
  subject: any,
  size: string,
  applyText: shape(),
  cancelText: shape(),
  open: bool.isRequired,
  isLoading: bool.isRequired,
  closeModal: func.isRequired,
  submitModal: func.isRequired,
};
