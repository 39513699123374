import { action, observable, runInAction } from 'mobx';
import SalesAPI from 'api/sales';
import { getUTCOffset } from 'utils/time';
import moment from 'moment';
import { ordersStatus } from '../constants';

const utcOffset = getUTCOffset();

const USER_NAME = 'userHumanName';
const ASSEMBLING = 'assembling';
const DELIVERED = 'delivered';
const IN_KITCHEN = 'inKitchen';
const READY_FOR_DELIVER = 'readyForDeliver';
const NEW = 'new';

const DEFAULT_START_DATE =
  moment()
    .startOf('day')
    .valueOf() + utcOffset;
const DEFAULT_END_DATE =
  moment()
    .endOf('day')
    .valueOf() + utcOffset;

const TABLE_FORMAT = new Map([
  [USER_NAME, { id: 'sales.data.username', defaultMessage: 'Сashier' }],
  [NEW, { id: 'sales.data.сreated.onPOS', defaultMessage: 'New' }],
  [IN_KITCHEN, { id: 'sales.data.orderStatus.IN_KITCHEN', defaultMessage: 'In kitchen' }],
  [ASSEMBLING, { id: 'sales.data.orderStatus.ASSEMBLING', defaultMessage: 'Assembling' }],
  [
    READY_FOR_DELIVER,
    { id: 'sales.data.orderStatus.READY_FOR_DELIVER', defaultMessage: 'Ready for deliver' },
  ],
  [DELIVERED, { id: 'sales.data.orderStatus.DELIVERED', defaultMessage: 'Delivered' }],
]);

const LIST_FORMAT = {
  headerProperty: [USER_NAME, { id: 'sales.data.username', defaultMessage: 'Сashier' }],
  properties: new Map([
    [NEW, { id: 'sales.data.сreated.onPOS', defaultMessage: 'New' }],
    [IN_KITCHEN, { id: 'sales.data.orderStatus.IN_KITCHEN', defaultMessage: 'In kitchen' }],
    [ASSEMBLING, { id: 'sales.data.orderStatus.ASSEMBLING', defaultMessage: 'Assembling' }],
    [
      READY_FOR_DELIVER,
      { id: 'sales.data.orderStatus.READY_FOR_DELIVER', defaultMessage: 'Ready for deliver' },
    ],
    [DELIVERED, { id: 'sales.data.orderStatus.DELIVERED', defaultMessage: 'Delivered' }],
  ]),
};

class UserStats {
  @observable tableFormat = TABLE_FORMAT;
  @observable listFormat = LIST_FORMAT;
  @observable list = [];
  @observable ordersStatusOptions = ordersStatus;

  @action
  getUserStats = async filters => {
    try {
      const { startDate, endDate, ...rest } = filters;
      const requestFilters = {
        startDate: startDate
          ? moment(startDate).valueOf() + getUTCOffset(moment(startDate).valueOf())
          : DEFAULT_START_DATE,
        endDate: endDate
          ? moment(endDate).valueOf() + getUTCOffset(moment(endDate).valueOf())
          : DEFAULT_END_DATE,
        ...rest,
      };
      const { items } = await SalesAPI.getUserStats(requestFilters);
      runInAction(() => {
        this.list = items;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };
}

export default UserStats;
