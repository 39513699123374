import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/alert';

class AlertHandlerAPI {
  static handleError = async stack => await Request.POST(`${ENDPOINT}`, { stack })
}

export default AlertHandlerAPI;
