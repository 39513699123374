import APIConnector from './utils/APIConnector';
import { extractFiltersQuery } from './utils/helpers';

const Request = APIConnector.instance;
const ENDPOINT = '/promotion';

class PromotionsAPI {
  static list = (page, size, dates) => {
    const filtersQuery = extractFiltersQuery(dates);
    return Request.GET(`${ENDPOINT}/list?page=${page}&size=${size}${filtersQuery}`);
  };

  static listActive = (page, size) => {
    return Request.GET(`${ENDPOINT}/list/active?page=${page}&size=${size}`);
  };

  static fetchPromotion = id => Request.GET(`${ENDPOINT}/${id}`);

  static create = promotionInfo => Request.POST(ENDPOINT, promotionInfo);

  static update = promotionInfo => Request.PUT(ENDPOINT, promotionInfo);

  static remove = id => Request.DELETE(ENDPOINT, { id });

  static fetchAnalytics = (promoId, allFilters) => {
    const queryFilters = extractFiltersQuery(allFilters).substring(1);
    return Request.GET(`${ENDPOINT}/${promoId}/sales?${queryFilters}`);
  };
}

export default PromotionsAPI;
