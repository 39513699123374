import CustomersStore from './customers/Customers';
import LoyaltyProgramsStore from './programs/Programs';
import CustomerEditorStore from './customers/CustomerEditor';
import LoyaltyProgramEditorStore from './programs/ProgramEditor';

const LoyaltyPrograms = new LoyaltyProgramsStore();
const Customers = new CustomersStore(LoyaltyPrograms);
const CustomerEditor = new CustomerEditorStore(Customers);
const LoyaltyProgramEditor = new LoyaltyProgramEditorStore();

export default {
  Customers,
  CustomerEditor,
  LoyaltyPrograms,
  LoyaltyProgramEditor,
};
