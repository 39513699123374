import APIConnector from './utils/APIConnector';
import { extractFiltersQuery } from './utils/helpers';

const Request = APIConnector.instance;
const ENDPOINT = '/report';

class ReportsAPI {
  static listXReports = (page, filters) => {
    const filtersQuery = extractFiltersQuery(filters);

    return Request.GET(`${ENDPOINT}/x/history?page=${page}${filtersQuery}`);
  };

  static listZReports = (page, filters) => {
    const filtersQuery = extractFiltersQuery(filters);

    return Request.GET(`${ENDPOINT}/z/history?page=${page}${filtersQuery}`);
  };

  static listYReports = (page, filters) => {
    const filtersQuery = extractFiltersQuery(filters);

    return Request.GET(`${ENDPOINT}/y/history?page=${page}${filtersQuery}`);
  };

  static loadYOptions = () => Request.GET(`${ENDPOINT}/y/options`);

  static generateActiveYReport = filters => {
    return Request.POST(`${ENDPOINT}/y/generate`, filters);
  };

  static submitYReport = params => {
    return Request.POST(`${ENDPOINT}/y/submit`, params);
  };

  static listCertainReports = filters => {
    return Request.POST(`${ENDPOINT}/getShiftHandoverDaily`, { ...filters, page: 0, size: 10000 });
  };
}

export default ReportsAPI;
