import { action, observable, computed, runInAction } from 'mobx';
import CategoriesAPI from 'api/categories';
import { arrayMove } from 'react-sortable-hoc';

const ACTIVE_STATUS = 'ACTIVE';
const INACTIVE_STATUS = 'INACTIVE';

class CategoriesStore {
  constructor(Menu) {
    this.menuStorage = Menu;
  }

  @observable list = [];
  @observable merchantGroupList = [];
  @observable totalPages = 0;
  @observable loading = false;
  @observable currentPage = -1;
  @observable activeCategoryId = 0;

  @computed get availableCategories() {
    return this.list.map(category => ({
      value: category.id,
      text: category.name,
    }));
  }

  findById = id => this.merchantGroupList.find(category => category.id === id);

  findIndexById = id => this.merchantGroupList.findIndex(category => category.id === id);

  @action
  fetchCategories = async () => {};

  @action
  fetchCategoriesForMerchantGroup = async () => {
    const { items } = await CategoriesAPI.listByMerchantGroup();

    runInAction(() => {
      this.merchantGroupList = items;
    });
  };

  @action
  fetchCategoriesForMenu = async (menuId, admin, categoryId) => {
    if (!menuId) {
      return;
    }
    try {
      const func = admin ? 'listByMerchantGroup' : 'listByMerchantMenu';
      const response = await CategoriesAPI[func](menuId);
      runInAction(() => {
        this.merchantGroupList = response.items;
      });

      const { items } = response;

      if (items && items[0]) {
        const hasCurrentCategory = items.some(i => i.id === +categoryId);
        await this.menuStorage.changeActiveCategoryId(
          hasCurrentCategory ? Number(categoryId) : items[0].id,
        );
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  changeActiveCategoryId = async activeCategoryId => {
    runInAction(() => {
      this.activeCategoryId = activeCategoryId;
    });
  };

  @action
  setCurrentCategoryId = async () => {
    if (!this.activeCategoryId) {
      runInAction(() => {
        this.activeCategoryId = this.availableCategories[0] && this.availableCategories[0].value;
      });
    }
  };

  @action
  fetchCategory = async categoryId => CategoriesAPI.fetchCategory(categoryId);

  @action
  create = async (category, currentMenu) => {
    try {
      const createdCategory = await CategoriesAPI.create({ ...category, menuId: currentMenu });

      runInAction(() => {
        this.merchantGroupList = [...this.merchantGroupList, createdCategory];
      });

      return createdCategory;
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  update = async category => {
    const updatedCategory = await CategoriesAPI.update(category);

    runInAction(() => {
      const index = this.findIndexById(category.id);

      this.merchantGroupList = [
        ...this.merchantGroupList.slice(0, index),
        updatedCategory,
        ...this.merchantGroupList.slice(index + 1),
      ];
    });
  };

  @action
  remove = async categoryId => {
    try {
      await CategoriesAPI.remove(categoryId);

      runInAction(() => {
        const index = this.findIndexById(categoryId);

        if (index >= 0) {
          this.merchantGroupList = [
            ...this.merchantGroupList.slice(0, index),
            ...this.merchantGroupList.slice(index + 1),
          ];
        }
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  toggleStatusOrHide = async ({ categoryId, type }) => {
    const { status, hidden, ...category } = this.findById(categoryId);
    const index = this.findIndexById(categoryId);

    let newCategory =
      type === 'status'
        ? {
            status: status === ACTIVE_STATUS ? INACTIVE_STATUS : ACTIVE_STATUS,
            ...category,
          }
        : {
            hidden: !hidden,
            ...category,
          };

    try {
      const updatedCategory = await CategoriesAPI.update(newCategory);

      runInAction(() => {
        this.merchantGroupList = [
          ...this.merchantGroupList.slice(0, index),
          updatedCategory,
          ...this.merchantGroupList.slice(index + 1),
        ];
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action
  onDragEnd = ({ oldIndex, newIndex, currentMenu }) => {
    const sortedList = arrayMove(this.merchantGroupList, oldIndex, newIndex);
    const reorderedCategories = async listAfterDrag => {
      try {
        const result = listAfterDrag.map((category, index) => {
          return {
            itemId: category.id,
            orderNumber: ++index,
            parentItemId: currentMenu,
          };
        });

        await CategoriesAPI.updateCategoriesOrder(result);
      } catch (error) {
        return new Error(error);
      }
    };

    runInAction(() => {
      this.merchantGroupList = sortedList;
      reorderedCategories(sortedList);
    });
  };
}

export default CategoriesStore;
