import { action, computed, observable, runInAction } from 'mobx';
import moment from 'moment';

import AuthAPI from 'api/auth';
import UsersAPI from 'api/users';
import MerchantAPI from 'api/merchant';
import NotificationsAPI from 'api/notifications';

import history, { routes } from 'routes';

import Intercom from 'thirdparty/Intercom';
import { isKybio, userComAPIkey } from 'utils/env';

const ADMIN = 'POS_ADMIN';
const MANAGER = 'POS_MANAGER';
// const RIDER = 'POS_RIDER';
const MARKETING_MANAGER = 'POS_MARKETING_MANAGER';
const SHIFT_MANAGER = 'POS_SHIFT_MANAGER';
const NEW_USER_STATUS = 'NEW';
const INACTIVE_STATUS = 'INACTIVE';
const REDIRECT_RULE = 'redirect_rule';
const POS_EXECUTIVE = 'POS_EXECUTIVE';

class Account {
  @observable info = {};
  @observable isUserFromPOS = false;

  resetPasswordEmailToken = null;

  @computed get isAuthorized() {
    if (this.info) {
      const { id, merchantId, role } = this.info;
      return !!(id && merchantId && role);
    } else {
      return false;
    }
  }

  @computed get hasOnePermission() {
    return this.info.permissionDtos.length === 1;
  }

  @computed get notConfirmed() {
    const { status } = this.info;

    return status && status === NEW_USER_STATUS;
  }

  @computed get isAdmin() {
    return this.info.role === ADMIN || this.info.role === POS_EXECUTIVE;
  }

  @computed get isShiftManager() {
    return this.info.role === SHIFT_MANAGER;
  }

  @computed get canEditCrm() {
    return (
      this.info.role === ADMIN ||
      this.info.role === MARKETING_MANAGER ||
      this.info.role === POS_EXECUTIVE
    );
  }

  @computed get canLoadFetchGroupById() {
    return (
      this.info.role === ADMIN ||
      this.info.role === MARKETING_MANAGER ||
      this.info.role === MANAGER ||
      this.info.role === POS_EXECUTIVE ||
      this.info.role === SHIFT_MANAGER
    );
  }

  @computed get userInfo() {
    return this.info;
  }

  @action
  redirectUserBasedOnPermissions = (userInfo = {}) => {
    const { pathname } = location;
    const { permissionDtos } = userInfo;

    if (permissionDtos.length === 1 && userInfo.status === INACTIVE_STATUS) {
      history.push(routes.blockedMerchantPlaceholder);
    }

    if (pathname === routes.login) {
      history.push(permissionDtos.length === 1 ? routes.dashboard : routes.dashboard);
    }
  };

  @action
  setRegistrationInfo = registrationData => {
    if (registrationData) {
      runInAction(() => {
        this.info = registrationData;
      });
    }
  };

  @action
  authorizeUser = async (groupMerchantCallback = () => {}) => {
    const userInfo = await AuthAPI.getUserInfo();

    if (
      userInfo.role === ADMIN ||
      userInfo.role === MANAGER ||
      userInfo.role === MARKETING_MANAGER ||
      userInfo.role === POS_EXECUTIVE
    ) {
      await groupMerchantCallback(userInfo.merchantGroupId);
    }
    runInAction(() => {
      this.info = {
        ...this.info,
        ...userInfo,
      };

      if (isKybio) {
        Intercom.loginUser(userInfo.email, userInfo.mobile);
      } else {
        window.civchat = {
          apiKey: userComAPIkey,
          name: `${userInfo.firstName} ${userInfo.lastName}`,
          user_id: `${userInfo.id}`,
          email: `${userInfo.email}`,
          gender: '',
          status: `${userInfo.role}`,
          date_attr: moment().toISOString(),
          phone_number: `+${userInfo.mobile}`,
          score: 0,
          company: {
            name: '',
            company_id: `${userInfo.merchantGroupId}`,
            revenue: '',
          },
        };
      }
    });

    this.redirectUserBasedOnPermissions(userInfo);
    return userInfo;
  };

  @action
  refreshToken = async () => {
    AuthAPI.refreshTokenOnly();
  };

  refreshUserInfo = async () => {
    const userInfo = await AuthAPI.getUserInfo();

    runInAction(() => {
      this.info = userInfo;
    });
  };

  @action
  addPermission = async (name, merchantId) => {
    this.info.permissionDtos.push({ name, merchantId });

    runInAction(() => {
      this.info = { ...this.info };
    });
  };

  @action
  deletePermission = async merchantIdToDelete => {
    const permissions = this.info.permissionDtos.filter(
      ({ merchantId }) => merchantId !== merchantIdToDelete,
    );
    this.info.permissionDtos = permissions;

    runInAction(() => {
      this.info = { ...this.info };
    });

    return permissions;
  };

  setRedirectSignUpRule = route => {
    localStorage.setItem(REDIRECT_RULE, route);
  };

  getRedirectSignUpRule = () => {
    const redirect = localStorage.getItem(REDIRECT_RULE);
    localStorage.removeItem(REDIRECT_RULE);
    return redirect;
  };

  signUp = async () => {
    await this.authorizeUser();
    const redirect = this.getRedirectSignUpRule();

    if (redirect) {
      history.push(redirect);
    } else {
      history.push(`${routes.products}?freshUser`);
    }
  };

  @action
  confirmSignUp = async confirmationCode => {
    await AuthAPI.confirmRegistration(confirmationCode);
  };

  autoSignUp = async email => {
    await MerchantAPI.create(email);
    await this.authorizeUser();
  };

  login = async (credentials, groupMerchantCallback) => {
    await AuthAPI.login(credentials);
    await this.authorizeUser(groupMerchantCallback);
  };

  @action
  autoLogin = async (token, fromPOS) => {
    AuthAPI.setToken(token);
    await this.authorizeUser();

    this.isUserFromPOS = fromPOS;

    history.push(routes.dashboard);
  };

  logout = () => {
    AuthAPI.logout();
    if (isKybio) {
      Intercom.shutDown();
    }
    runInAction(() => {
      this.info = {};
    });
    location.reload();
    history.replace(routes.login);
  };

  @action
  updateInfo = (key, value) => {
    this.info[key] = value;
  };

  @action
  updateAccount = () => UsersAPI.updateUser(this.info);

  resetPassword = async mobile => {
    try {
      await AuthAPI.resetPassword(mobile);

      history.push(routes.setNewPassword);
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  resetConfirmationCode = async mobile => {
    try {
      await AuthAPI.resendConfirmationCode(mobile);
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  checkUserCredentials = async credentials => {
    try {
      await AuthAPI.checkCredentials(credentials);
    } catch (e) {
      throw new Error(e.message);
    }
  };

  setResetPasswordInfo = (mobile, password) => {
    const info = {
      mobile,
      password,
    };
    localStorage.setItem('reset_password_info', JSON.stringify(info));
  };

  getResetPasswordInfo = () => {
    const info = localStorage.getItem('reset_password_info');
    return info ? JSON.parse(info) : {};
  };

  @action
  resendResetPassword = () => NotificationsAPI.resend(this.resetPasswordEmailToken);

  @action
  createNewPassword = async body => {
    await AuthAPI.createNewPassword(body);
    await this.authorizeUser();

    history.push(routes.dashboard);
  };

  @action
  resendConfirmationEmail = () => MerchantAPI.resendConfirmationEmail(this.info.email);

  @action
  completeOnBoarding = params => UsersAPI.completeOnBoarding(params);

  @action
  createGroupNameOnBoarding = async name => await MerchantAPI.createGroupName(name);
}

export default Account;
