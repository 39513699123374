export const settingRoutes = {
  users: '/account/users',
  billing: '/account/billing',
  userEditor: '/account/user',
  programs: '/account/loyalty/programs',
  customers: '/account/loyalty/customers',
  merchants: '/account/merchants',
  groupDashboard: '/dashboard/group',
  merchantEditor: '/merchant',
  merchantOrderManagementAnchor: '/account/merchant#order-management',
};
