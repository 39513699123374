import { observable, action, runInAction, computed } from 'mobx';
import uuid from 'uuid/v4';
import validate from 'validate.js';
import without from 'lodash.without';
import history, { routes } from 'routes';
import difference from 'lodash.difference';
import ProductRecipeAPI from 'api/product-recipe';
import ProductsAPI from 'api/products';
import TagsAPI from 'api/tags';
import TaxGroupAPI from 'api/tax-group';
import get from 'lodash/get';
import ModifiersAPI from 'api/modifiers';
import { convertTaxToPrice, deductTax, toFixedTwo } from 'utils/numbers';
import IngredientAPI from 'api/ingredient';
import DictionaryAPI from 'api/dictionary';
import { getLocalizedString } from 'i18n/utils';
import { NONE, INGREDIENT_CARD, PRODUCT } from 'utils/constants';
import { getFullCookingPercent } from 'utils/get-full-cooking-persent';
import { EMPTY_PRODUCT, MEAL_VALIDATION_SCHEMA, PRODUCT_VALIDATION_SCHEMA } from './constants';

const PRODUCT_RECIPE = {
  productRecipeItems: [],
};

class ProductEditor {
  constructor(Products, Merchant, MerchantsGroup, MenuStore, Categories) {
    this.products = Products;
    this.merchantStore = Merchant;
    this.merchantGroupStore = MerchantsGroup;
    this.onSave = this.products.createProduct;
    this.menuStore = MenuStore;
    this.categoriesStore = Categories;
  }

  @observable tags = [];
  @observable loading = false;
  @observable imageFile = null;
  @observable thumbnail = null;
  @observable validationErrors = {};
  @observable product = { ...EMPTY_PRODUCT };
  // @observable productsForRelation = [];
  @observable ingredientsData = [];
  @observable productRecipe = PRODUCT_RECIPE;
  @observable ingredientsUnitsOptions = [];
  @observable searchIngredientsData = [];
  @observable storesStatus = NONE;
  @observable productRecipeItemsValidationErrors = {};
  @observable productList = [];
  @observable searchProductList = [];
  @observable propertyList = [];
  @observable searchPropertyList = [];
  @observable relatedProductsValue = [];
  @observable isUpdateIngredientsCard = false;
  @observable taxGroup = [];

  @computed get VALIDATION_SCHEMA() {
    return this.product.setMeal ? MEAL_VALIDATION_SCHEMA : PRODUCT_VALIDATION_SCHEMA;
  }

  @action
  create = (admin, currentMenu) => {
    this.product = { ...EMPTY_PRODUCT };
    this.imageFile = null;
    this.thumbnail = null;
    this.onSave = this.products.createProduct;

    admin
      ? history.push(`${routes.adminProductsNew}?menuId=${currentMenu}`)
      : history.push(`${routes.productEditor}?menuId=${currentMenu}`);
  };

  structureMealProperties = (properties, setMeal = false, menuId, filtered) => {
    if (filtered) {
      const newProperties = properties.filter(i => i.setMeal === setMeal);
      return newProperties
        .map(property => ({
          ...property,
          setMeal,
          menuIds: [menuId],
        }))
        .sort((a, b) => a.radio - b.radio);
    } else {
      return properties
        .map(property => ({
          ...property,
          setMeal,
          menuIds: [menuId],
        }))
        .sort((a, b) => a.radio - b.radio);
    }
  };

  @action
  edit = async productId => {
    let product = this.products.list.find(i => i.id == productId);

    if (!product) {
      product = await this.products.fetchProduct(productId);
    }
    const relatedProductIds = await this.getRelatedProductList(product.relatedProductIds);

    const round = n => n && Math.round((n + Number.EPSILON) * 100) / 100;

    const { turnoverTax, taxInclusive, taxManagedByChain, currency } = this.merchantGroupStore.currentGroup;
    const {
      taxInclusive: restaurantTaxInclusive,
      turnoverTax: restaurantTurnoverTax,
    } = this.merchantStore.info.merchantConfiguration;

    const taxFee = taxManagedByChain ? turnoverTax : restaurantTurnoverTax
    let isTaxInclusive = false
    if (taxManagedByChain) {
      isTaxInclusive = taxInclusive;
    } else {
      isTaxInclusive = restaurantTaxInclusive;
    }
    
    runInAction(() => {
      this.product = {
        ...product,
        divisible: product.divisible,
        price: !isTaxInclusive ? toFixedTwo(product.price) : deductTax(product.sellPrice, taxFee),
        sellPrice: isTaxInclusive ? toFixedTwo(product.sellPrice) : convertTaxToPrice(product.price, taxFee),
        productProperties: this.structureMealProperties(product.productProperties, product.setMeal),
        relatedProductIds,
        taxGroupIds: !product.taxGroupIds ? [] : product.taxGroupIds,
      };
      this.thumbnail = null;
      this.imageFile = null;
      this.onSave = this.products.updateProduct;
    });
  };

  @action
  getSelledPropducPropertiesOption = (options = [], menuId) => {
    return options.map(option => ({
      ...option,
      sellPrice: 0.0,
      menuIds: [menuId],
    }));
  };

  @action
  getRelatedProductList = async productIds => {
    const { items } = await ProductsAPI.getRelatedProductList({ ids: productIds });
    return items.map(i => ({
      value: i.id,
      label: i.name,
    }));
  };

  @action
  loadResaleProductsOptions = async (search, prevOptions, page = 0, menuId) => {
    let filteredOptions = [];
    let hasMore = false;
    let newItems = [];
    if (!search) {
      const { items, last } = await ProductsAPI.getProductList(menuId, page, 20);
      filteredOptions = items;
      newItems = items.map(item => {
        return {
          ...item,
          categoryName: get(item, 'categories[0].name'),
        };
      });
      hasMore = !last;
    } else {
      const { items } = await ProductsAPI.getProductList(menuId, 0, 20, search);
      filteredOptions = [...items];
    }
    runInAction(() => {
      if (!search) {
        this.productList = page === 0 ? [...newItems] : [...this.ingredientsData, ...newItems];
      } else {
        this.searchProductList = filteredOptions;
      }
    });
    const slicedOptions = filteredOptions.map(item => ({
      value: item.id,
      label: `${item.name} (${get(item, 'categories[0].name')})`,
    }));
    return {
      options: slicedOptions,
      hasMore,
    };
  };

  @action
  loadPropertyOptions = async (search, prevOptions, page = 0, menuId, filters) => {
    let filteredOptions = [];
    let hasMore = false;
    let newItems = [];
    if (!search) {
      const { items, last } = await ProductsAPI.getPropertyList(menuId, page, 20, filters);
      filteredOptions = items;
      newItems = items;
      hasMore = !last;
    } else {
      const { items } = await ProductsAPI.getPropertyList(menuId, 0, 20, {
        ...filters,
        propertyName: search,
      });
      filteredOptions = [...items];
    }
    const setIsMeal = arr => {
      return arr.map(item => {
        return {
          ...item,
          ...filters,
        };
      });
    };

    runInAction(() => {
      if (!search) {
        this.propertyList =
          page === 0 ? [...setIsMeal(newItems)] : [...this.ingredientsData, ...setIsMeal(newItems)];
      } else {
        this.searchPropertyList = setIsMeal(filteredOptions);
      }
    });
    const slicedOptions = filteredOptions.map(item => ({
      value: item.id,
      label: item.name,
    }));
    return {
      options: slicedOptions,
      hasMore,
    };
  };

  @action
  structuredIngredientRecipeItems = ingredientRecipeItems => {
    return ingredientRecipeItems.map(item => {
      const unitWeight =
        item.ingredientUnit === 'KILOGRAM'
          ? 1000
          : (get(item, 'ingredientDto.unitWeight') || get(item, 'ingredient.unitWeight') || 0) *
            1000;
      const ingredientAmount =
        item.ingredientUnit !== 'PCS'
          ? toFixedTwo(item.ingredientAmount * 1000)
          : toFixedTwo(item.ingredientAmount);
      const approxPrice =
        get(item, 'ingredientDto.approxPrice') || get(item, 'ingredient.approxPrice') || 0;
      const oneUnitWeight = unitWeight / 1000;
      const totalUnitWeight = ingredientAmount * oneUnitWeight;
      const ingredientBrutto =
        item.ingredientUnit !== 'PCS' ? totalUnitWeight : unitWeight * ingredientAmount;
      const allRatio = {
        wasteRatio: Number(item.wasteRatio) !== 0 ? true : false,
        cookingRatio: Number(item.cookingRatio) !== 0 ? true : false,
        fryingRatio: Number(item.fryingRatio) !== 0 ? true : false,
        stewingRatio: Number(item.stewingRatio) !== 0 ? true : false,
        roastingRatio: Number(item.roastingRatio) !== 0 ? true : false,
        wasteRatioPersent:
          get(item, 'ingredientDto.wasteRatio') || get(item, 'ingredient.wasteRatio') || 0,
        cookingRatioPersent:
          get(item, 'ingredientDto.cookingRatio') || get(item, 'ingredient.cookingRatio') || 0,
        fryingRatioPersent:
          get(item, 'ingredientDto.fryingRatio') || get(item, 'ingredient.fryingRatio') || 0,
        stewingRatioPersent:
          get(item, 'ingredientDto.stewingRatio') || get(item, 'ingredient.stewingRatio') || 0,
        roastingRatioPersent:
          get(item, 'ingredientDto.roastingRatio') || get(item, 'ingredient.roastingRatio') || 0,
      };
      const fullCookingPersent = getFullCookingPercent(allRatio);
      const totalApproxPrice =
        item.ingredientUnit !== 'PCS'
          ? (ingredientAmount / 1000) * approxPrice
          : ingredientAmount * approxPrice;
      return {
        ...item,
        ingredientAmount,
        approxPrice: approxPrice,
        ingredientBrutto: ingredientBrutto.toFixed(2),
        ingredientNetto: (
          ingredientBrutto - (ingredientBrutto / 100) * fullCookingPersent || 0
        ).toFixed(2),
        totalApproxPrice: (totalApproxPrice || 0).toFixed(2),
        unitWeight,
        ...allRatio,
      };
    });
  };

  @action
  fetchProductRecipe = async id => {
    try {
      const data = await ProductRecipeAPI.fetchProductRecipe(id);
      let status = NONE;
      if (data.id) {
        if (data.status === 'ACTIVE') {
          status = INGREDIENT_CARD;
          if (
            data.productRecipeItems.length === 1 &&
            this.product.name.toLocaleLowerCase() ==
              data.productRecipeItems[0].ingredientName.toLocaleLowerCase() &&
            data.productRecipeItems[0].ingredientUnit === 'PCS' &&
            toFixedTwo(data.productRecipeItems[0].ingredientAmount) === 1
          ) {
            status = PRODUCT;
          }
        }
      }
      runInAction(() => {
        this.productRecipe = {
          ...data,
          productRecipeItems: this.structuredIngredientRecipeItems(data.productRecipeItems),
        };
        this.changeStoresStatus(status);
        this.isUpdateIngredientsCard = status === INGREDIENT_CARD;
      });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  fetchIngredientsUnits = async () => {
    const { items } = await DictionaryAPI.listUnits();
    runInAction(() => {
      this.ingredientsUnitsOptions = items.map(item => ({
        value: item.name,
        text: getLocalizedString(`ingredient.unit.${item.name.toLowerCase()}`),
        key: item.name,
      }));
    });
  };

  @action
  validationProductRecipeItems = () => {
    let validationErrors = {};
    if (this.storesStatus === INGREDIENT_CARD) {
      if (this.productRecipe.productRecipeItems === 0) {
        throw new Error(getLocalizedString('products.editor.stock.add-field.error'));
      }
      const valueIsNaN = v => v !== v;
      this.productRecipe.productRecipeItems.map((item, index) => {
        if (validate(item, { ingredientId: { presence: { allowEmpty: false } } })) {
          validationErrors = { ...validationErrors, [`ingredientId.${index}`]: true };
        }
        if (validate(item, { ingredientUnit: { presence: { allowEmpty: false } } })) {
          validationErrors = { ...validationErrors, [`ingredientUnit.${index}`]: true };
        }
        if (validate(item, { ingredientAmount: { presence: { allowEmpty: false } } })) {
          validationErrors = { ...validationErrors, [`ingredientAmount.${index}`]: true };
        } else if (valueIsNaN(item.ingredientAmount)) {
          validationErrors = { ...validationErrors, [`ingredientAmount.${index}`]: true };
        }
      });
    }
    this.productRecipeItemsValidationErrors = validationErrors;
    const isValid = Object.keys(validationErrors).length === 0;

    return [isValid, validationErrors];
  };

  @action
  saveIngredientsCard = async newProductRecipe => {
    if (this.isUpdateIngredientsCard) {
      await ProductRecipeAPI.update(newProductRecipe);
    } else {
      await ProductRecipeAPI.create(newProductRecipe);
    }
  };

  @action
  handleStoresSave = async () => {
    try {
      if (this.storesStatus === NONE) {
        await ProductRecipeAPI.deleteProductRecipe(this.product.id);
      } else if (this.storesStatus === INGREDIENT_CARD) {
        const newProductRecipe = {
          ...this.productRecipe,
          // id: this.productRecipe.productId,
          id: this.productRecipe.id || 0,
          name: this.product.name,
          productId: this.product.id,
          cookingTimeMs: 0,
          productRecipeItems: [
            ...this.productRecipe.productRecipeItems.map(item => {
              return {
                ...item,
                // createdTimestamp: Date.parse(new Date()),
                ingredientAmount:
                  item.ingredientUnit !== 'PCS'
                    ? item.ingredientAmount / 1000
                    : item.ingredientAmount,
                ingredientUnit: item.ingredientUnit,
                wasteRatio: item.wasteRatio ? item.wasteRatioPersent : 0,
                cookingRatio: item.cookingRatio ? item.cookingRatioPersent : 0,
                fryingRatio: item.fryingRatio ? item.fryingRatioPersent : 0,
                stewingRatio: item.stewingRatio ? item.stewingRatioPersent : 0,
                roastingRatio: item.roastingRatio ? item.roastingRatioPersent : 0,
              };
            }),
          ],
          // createdTimestamp: Date.parse(new Date()),
          // updatedTimestamp: 0,
        };
        this.validationProductRecipeItems(newProductRecipe.productRecipeItems);
        if (Object.keys(this.productRecipeItemsValidationErrors).length === 0) {
          await this.saveIngredientsCard(newProductRecipe);
        }
      } else {
        await ProductRecipeAPI.default(this.product.id);
      }
      if (Object.keys(this.productRecipeItemsValidationErrors).length === 0) {
        history.goBack();
      } else {
        throw new Error(getLocalizedString('notifications.editor.error'));
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  changeStoresStatus = value => {
    this.storesStatus = value;
  };

  @action
  changeProductRecipe = (key, value) => {
    this.productRecipe[key] = value;
  };

  @action
  save = async (admin, currentMenu, editingMode, noRedirect) => {
    const propeties = (this.product.productProperties || []).map(property => ({
      ...property,
      productPropertyOptions: this.getSelledPropducPropertiesOption(
        property.productPropertyOptions,
        currentMenu,
      ),
    }));
    // const newProperties = this.product.setMeal ? [] : propeties
    // const

    const response = await this.onSave(
      {
        ...this.product,
        sellPrice: parseFloat(this.product.sellPrice),
        productProperties: this.structureMealProperties(
          propeties,
          this.product.setMeal,
          currentMenu,
          true,
        ),
        relatedProductIds: this.product.relatedProductIds.map(i => i.value),
      },
      {
        imageFile: this.imageFile,
        // thumbnailImage: this.thumbnail,
      },
    );
    runInAction(() => {
      if (!editingMode) {
        this.product = response;
      }
      if (!noRedirect) {
        history.goBack();
      }
    });
  };

  @action
  validateProduct = () => {
    this.validationErrors = validate(this.product, this.VALIDATION_SCHEMA) || {};
    const hasImage = !!(this.product.image || this.imageFile);

    const hasProductPropertyError = this.product.productProperties.some(p => !p.id);

    if (hasProductPropertyError) {
      this.validationErrors['productProperties'] = true;
    }

    const isValid =
      hasImage && !Object.keys(this.validationErrors).length && !hasProductPropertyError;

    return [isValid, this.validationErrors];
  };

  @action
  changeProduct = (key, value, turnoverTax) => {
    if (key === 'description') {
      if (value.length > 999) return;
    }

    if (key === 'sellPrice') {
      this.product.price = value !== '' ? deductTax(value, turnoverTax) : '';
    }

    if (key === 'price') {
      this.product.sellPrice = value !== '' ? convertTaxToPrice(value, turnoverTax) : '';
    }
    runInAction(() => {
      this.product[key] = value;
    });
  };

  @action
  changeSelectedKitchen = value => {
    const product = { ...this.product };

    product.kitchenStationId = value;

    runInAction(() => {
      this.product = product;
    });
  };

  @action
  changeSelectedLegalEntity = entity => {
    const product = { ...this.product };

    product.legalEntityId = entity.value;
    product.legalEntityName = entity.text;

    runInAction(() => {
      this.product = product;
    });
  };

  @computed get availableTags() {
    return difference(this.tags, this.product.tags).map(tag => ({
      text: tag,
      value: tag,
    }));
  }

  @action
  fetchTags = async () => {
    const { items } = await TagsAPI.list();

    runInAction(() => {
      this.tags = items.map(item => item.name);
    });
  };

  @action
  addTag = tag => {
    this.product.tags.push(tag);
  };

  @action
  addCategory = async _category => {
    let category = _category;
    if (typeof _category === 'string') {
      category = await this.categoriesStore.create(
        { name: _category },
        this.menuStore.activeMenu.id,
      );
    }

    runInAction(() => {
      const haveCategory = this.product.categories.find(
        categoryItem => categoryItem.name === category.name,
      );
      if (!haveCategory) {
        this.product.categories.push({ ...category });
      }
    });
  };

  @action
  createTag = async tagName => {
    const { name } = await TagsAPI.create({ name: tagName });

    this.tags.push(name);
    this.product.tags.push(name);
  };

  @action
  removeTag = tag => {
    this.product.tags = without(this.product.tags, tag);
  };

  @action
  removeCategory = categoryParam => {
    this.product.categories = this.product.categories.filter(
      category => category.id !== categoryParam,
    );
  };

  @action
  addTaxGroup = async _taxGroup => {
    let taxGroup = _taxGroup;
    if (typeof taxGroup === 'string') {
      taxGroup = await TaxGroupAPI.create({ name: taxGroup });
      runInAction(() => {
        this.taxGroup = [...this.taxGroup, taxGroup];
      });
    }

    runInAction(() => {
      const haveTaxGroup = this.product.taxGroupIds.find(id => id === taxGroup.id);
      if (!haveTaxGroup) {
        this.product.taxGroupIds = [...this.product.taxGroupIds, taxGroup.id];
      }
    });
  };

  @action
  fetchTaxGroup = async () => {
    const { items } = await TaxGroupAPI.list();

    runInAction(() => {
      this.taxGroup = items;
    });
  };

  @action
  removeTaxGroup = taxGroupId => {
    runInAction(() => {
      this.product.taxGroupIds = without(this.product.taxGroupIds, taxGroupId);
    });
  };

  @action
  addRelatedProduct = ids => {
    this.product.relatedProductIds = ids;
  };

  @action
  updateImage = ({ image, thumbnail }) => {
    this.thumbnail = thumbnail;
    this.imageFile = image;
  };

  @observable properties = [];

  @computed get availableProperties() {
    return this.properties;
  }

  @action
  fetchProperties = async menuId => {
    if (menuId) {
      const { items } = await ModifiersAPI.list(menuId);

      runInAction(() => {
        this.properties = items;
      });
    }
  };

  fetchProperty = async id => await ModifiersAPI.fetchModifier(id);

  @action
  createProperty = async body => {
    const newProperty = await ModifiersAPI.createModifier(body);
    const property = {
      ...newProperty,
      setMeal: body.setMeal,
    };

    runInAction(() => {
      this.properties.push(property);

      this.pickProperty(body.productPropertyId, newProperty.id, body.setMeal);
    });
  };

  @action
  updateProperty = async property => {
    const updatedProperty = await ModifiersAPI.updateModifier(property);

    runInAction(() => {
      const indexInProperties = this.properties.findIndex(
        property => property.id === updatedProperty.id,
      );
      const indexInProduct = this.product.productProperties.findIndex(
        property => property.id === updatedProperty.id,
      );

      this.properties[indexInProperties] = {
        ...updatedProperty,
        setMeal: property.setMeal,
      };

      if (indexInProduct >= 0) {
        this.product.productProperties[indexInProduct] = {
          ...updatedProperty,
          setMeal: property.setMeal,
        };
      }
    });
  };

  @computed
  get radioProperties() {
    return this.product.productProperties.filter(property => property.radio && !property.setMeal);
  }

  @computed
  get mealProperties() {
    return this.product.productProperties.filter(property => property.setMeal);
  }

  @computed
  get multiProperties() {
    return this.product.productProperties.filter(property => !property.radio && !property.setMeal);
  }

  @action
  addProductProperty = (radio, setMeal) => {
    this.product.productProperties.push({
      tempId: uuid(),
      name: '',
      radio,
      setMeal,
    });
  };

  @action
  pickProperty = (propertyId, id, setMeal = false) => {
    const index = this.findPropertyIndex(propertyId);
    const property = this.properties.find(property => property.id === id);
    const isDuplicate = this.findPropertyIndex(id) >= 0;

    if (index >= 0 && property && !isDuplicate) {
      this.product.productProperties[index] = { ...property, setMeal };
    }
  };

  @action
  loadOptions = async (search, prevOptions, page = 0) => {
    let filteredOptions = [];
    let hasMore = false;
    let newItems = [];
    if (!search) {
      const { items, last } = await IngredientAPI.list(page, 20);
      filteredOptions = items;
      newItems = items;
      hasMore = !last;
    } else {
      const { items } = await IngredientAPI.list(0, 20, search);
      filteredOptions = [...items];
    }
    runInAction(() => {
      if (!search) {
        this.ingredientsData = page === 0 ? [...newItems] : [...this.ingredientsData, ...newItems];
      } else {
        this.searchIngredientsData = filteredOptions;
      }
    });
    const slicedOptions = filteredOptions.map(item => ({
      value: item.id,
      label: item.name,
    }));
    return {
      options: slicedOptions,
      hasMore,
    };
  };

  @action
  removeProductProperty = id => {
    this.product.productProperties = this.product.productProperties.filter(property => {
      return property.id ? property.id !== id : property.tempId !== id;
    });
  };

  findPropertyIndex = id =>
    this.product.productProperties.findIndex(property => {
      return property.id ? property.id === id : property.tempId === id;
    });

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.product = { ...EMPTY_PRODUCT };
    this.productRecipeItemsValidationErrors = {};
    this.productRecipe = PRODUCT_RECIPE;
    this.storesStatus = NONE;
    this.isUpdateIngredientsCard = false;
  };
}

export default ProductEditor;
