import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AlertHandlerAPI from 'api/alert';

import { Message } from 'semantic-ui-react';

import { ErrorBoundaryStyle } from './styles';

export default class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  async sendError(error) {
    await AlertHandlerAPI.handleError(JSON.stringify(error.stack));
  }

  componentDidCatch(a, b) {
    console.error(a, b, 'ERROR__'); // eslint-disable-line
    this.setState({ hasError: true });
    // this.sendError(error);
  }

  render() {
    const { hasError } = this.state;
    const { showError, children } = this.props;

    if (hasError && showError) {
      return (
        <ErrorBoundaryStyle>
          <Message negative className="error-boundary__message">
            <Message.Header>Oh there was an error</Message.Header>
            <p>We will fix it soon</p>
          </Message>
        </ErrorBoundaryStyle>
      );
    } else {
      return children;
    }
  }
}

ErrorBoundary.defaultProps = {
  showError: true,
};

ErrorBoundary.propTypes = {
  showError: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
