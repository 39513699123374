import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/public/app-info';

class AppAPI {
  static mPosInfo = () => Request.GET(`${ENDPOINT}/mpos`);

  static getKdsinfo = () => Request.GET(`${ENDPOINT}/kds`);
}

export default AppAPI;
