import { getUserLocales, getUserLocale as userLocale } from 'get-user-locale';
import { getI18nData } from './index';

export const getAcceptLanguageHeader = () => getUserLocales().join();

export const getLocalizedString = path => {
  return getI18nData().messages[path];
};

export const getUserLocale = () => {
  const locale = userLocale();
  return locale.split(/[_-]+/)[0];
};

export const polyfillI18n = () => {
  if (!global.Intl) {
    require.ensure(['intl', 'intl/locale-data/jsonp/en.js'], function(require) {
      require('intl');
      require('intl/locale-data/jsonp/en.js');
    });
  }
};
