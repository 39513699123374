import { action, computed, observable, runInAction } from 'mobx';
import MerchantAPI from 'api/merchant';
import { toObject } from 'utils/common';
import moment from 'moment';
import validate from 'validate.js';
import { getLocalizedString } from 'i18n/utils';
import get from 'lodash/get';
import { DAYS_OF_THE_WEEK, RULER_SCALE_VALUES } from '../merchants/constants';

const OPEN = {
  slots: [{ start: RULER_SCALE_VALUES[0], end: RULER_SCALE_VALUES[RULER_SCALE_VALUES.length - 1] }],
  workingDay: true,
};

const DEFAULT_SCHEDULE = toObject(DAYS_OF_THE_WEEK, OPEN);

const EMPTY_SELF_SERVICE = {
  id: 0,
  merchantId: 0,
  workingHours: { daysOfWeek: DEFAULT_SCHEDULE },
  opcSmsNotificationEnabled: false,
  opcEmailNotificationEnabled: false,
  orderReadinessMessage: '',
  opcPostPaymentsEnabled: false,
  lpPaymentsEnabled: false,
  invoicePaymentsEnabled: false,
  invoiceAccountantEmail: '',
  minDeliveryAmount: 0,
  opcDeliveryEnabled: false,
  opcCurbsideEnabled: false,
  opcCustomerDisplayEnabled: false,
  opcPushNotificationEnabled: false,
  dineInEnabled: true,
  takeawayEnabled: true,
  deliveryFee: 0,
  prePayedOrderConfirmationEnabled: false,
  postPayedOrderConfirmationEnabled: false,
  recommendationsEnabled: false,
  selfPaymentMethods: null,
  legalInfo: '',
  gtmTag: '',
  delayedOrdersEnabled: false,
  immediateOrdersEnabled: false,
  delayedOrdersFrameStart: 0,
  delayedOrdersFrameEnd: 0,
  deliveryServices: null,
  delayedOrdersHours: { daysOfWeek: DEFAULT_SCHEDULE },
  deliveryFeeRanges: [],
  hideCallButton: false,
  telegramBotName: '',
  telegramBotToken: '',
  invoicePaymentDetails: {
    bankCardNumber: '',
    bankName: '',
    providerName: '',
    recipientCode: '',
  },
  stripeConnectRegistrationMethod: false,
  delayedOrderExecutionMinutes: '0',
  enablePostpaidShipping: false,
  moderateFailedOrders: false,
  orderModeratorIds: [],
  curbsidePickupSlots: [],
};

class SelfService {
  @observable availableSelfPaymentMethods = [];
  @observable selfService = { ...EMPTY_SELF_SERVICE };
  @observable deliveryServices = [];
  @observable invoiceAccountantEmailValidate = false;
  @observable initSelfPaymentMethods = {};
  @observable stripeConnectRegistrationMethod = false;
  // @observable invoicePaymentDetails = {
  //   bankCardNumber: '',
  //   bankName: '',
  //   providerName: '',
  //   recipientCode: '',
  // };

  @computed get weekdays() {
    return DAYS_OF_THE_WEEK;
  }

  @computed get ruler() {
    return RULER_SCALE_VALUES;
  }

  @action
  getDeliveryServices = async () => {
    const { items } = await MerchantAPI.getDeliveryServices();

    runInAction(() => {
      this.deliveryServices = items;
    });
  };

  @action
  changeStripeConnectRegistrationMethod = () => {
    runInAction(() => {
      this.stripeConnectRegistrationMethod = !this.stripeConnectRegistrationMethod;
    });
  };

  @action
  clearSelfPaymentMethod = () => {
    runInAction(() => {
      this.selfService.selfPaymentMethods = null;
    });
  };

  @action
  fetchAvailableSelfPaymentMethods = async () => {
    const { items } = await MerchantAPI.fetchAvailableSelfPaymentMethod();

    runInAction(() => {
      this.availableSelfPaymentMethods = items;
    });
  };

  @computed get selfPaymentMethods() {
    return this.availableSelfPaymentMethods;
  }

  @action
  changeSelfService = (key, value) => {
    const selfService = { ...this.selfService };
    if (key) {
      selfService[key] = value;
    }

    runInAction(() => {
      this.selfService = selfService;
    });
  };

  @action
  changePaymentsDetails = (name, value) => {
    const invoicePaymentDetails = { ...this.selfService.invoicePaymentDetails };
    invoicePaymentDetails[name] = value;

    runInAction(() => {
      this.selfService.invoicePaymentDetails = invoicePaymentDetails;
    });
  };

  @action
  editCurrent = async () => {
    try {
      const response = await MerchantAPI.getSelfService();
      runInAction(() => {
        this.selfService = {
          ...response,
          invoicePaymentDetails: response.invoicePaymentDetails
            ? JSON.parse(response.invoicePaymentDetails)
            : '',
          // curbsidePickupSlots: !response.curbsidePickupSlots
          //   ? ['']
          //   : JSON.parse(response.curbsidePickupSlots) || [''],
        };
        this.initSelfPaymentMethods = response.selfPaymentMethods;
        this.stripeConnectRegistrationMethod = true;// !!response.selfPaymentMethods.detailsJson.accountId;
      });

      return response;
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  save = async () => {
    const sendDeliveryFeeRanges = ['Lalamove', 'MrSpeedy', 'GoGet', 'iPOST'].some(
      i => i === get(this.selfService, 'deliveryServices.name'),
    );
    try {
      const newSelfService = {
        ...this.selfService,
        orderModeratorIds: this.selfService.moderateFailedOrders
          ? this.selfService.orderModeratorIds
          : [],
        deliveryFeeRanges: sendDeliveryFeeRanges ? [] : this.selfService.deliveryFeeRanges,
        deliveryRemarks: this.selfService.deliveryRemarks,
      };

      const { name } = newSelfService.selfPaymentMethods;

      if (name === 'STRIPE' && !this.stripeConnectRegistrationMethod) {
        delete newSelfService.selfPaymentMethods.detailsJson.accountId;
      }
      if (newSelfService.invoicePaymentsEnabled) {
        if (
          newSelfService.invoiceAccountantEmail !== undefined &&
          !validate.single(newSelfService.invoiceAccountantEmail, { email: true })
        ) {
          await MerchantAPI.updateSelfService({
            ...newSelfService,
            invoicePaymentDetails: JSON.stringify(newSelfService.invoicePaymentDetails),
            deliveryFeeRanges: newSelfService.deliveryFeeRanges.filter(({ range }) => !!range),
          });
        } else {
          this.invoiceAccountantEmailValidate = true;
          throw new Error(getLocalizedString('selfService.accountantEmail.error'));
        }
      } else if (
        name === 'STRIPE' &&
        name !== this.initSelfPaymentMethods.name &&
        this.stripeConnectRegistrationMethod
      ) {
        throw new Error(getLocalizedString('merchant.paymentMethod.stripe.error'));
      } else {
        await MerchantAPI.updateSelfService({
          ...newSelfService,
          invoicePaymentDetails: JSON.stringify(
            newSelfService.invoicePaymentDetails ? newSelfService.invoicePaymentDetails : '',
          ),
          deliveryFeeRanges: newSelfService.deliveryFeeRanges.filter(({ range }) => !!range),
        });
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  toggleSelfServiceDay = day => {
    const currentState = this.selfService.workingHours.daysOfWeek[day].workingDay;

    runInAction(() => {
      this.selfService.workingHours.daysOfWeek[day].workingDay = !currentState;
      this.selfService = {
        ...this.selfService,
      };
    });
  };

  @action
  changeSelfServiceSchedule = (day, prop, time, index) => {
    const selfService = { ...this.selfService };
    selfService.workingHours.daysOfWeek[day]['slots'][index][prop] = time;

    runInAction(() => {
      this.selfService = selfService;
    });
  };

  @action
  addSelfServiceSchedule = (day, selectedDateEnd) => {
    const selfService = { ...this.selfService };

    selfService.workingHours.daysOfWeek[day]['slots'].push({
      start: moment(selectedDateEnd)
        .add(1, 'minutes')
        .format('HH:mm'),
      end: RULER_SCALE_VALUES[RULER_SCALE_VALUES.length - 1],
    });

    runInAction(() => {
      this.selfService = selfService;
    });
  };

  @action
  removeSelfServiceSchedule = (day, index) => {
    const selfService = { ...this.selfService };

    selfService.workingHours.daysOfWeek[day]['slots'].splice(index, 1);

    runInAction(() => {
      this.selfService = selfService;
    });
  };

  @action
  toggleSelfServiceDayDelayedOrders = day => {
    const currentState = this.selfService.delayedOrdersHours.daysOfWeek[day].workingDay;

    runInAction(() => {
      this.selfService.delayedOrdersHours.daysOfWeek[day].workingDay = !currentState;
      this.selfService = {
        ...this.selfService,
      };
    });
  };

  @action
  changeSelfServiceScheduleDelayedOrders = (day, prop, time, index) => {
    const selfService = { ...this.selfService };
    selfService.delayedOrdersHours.daysOfWeek[day]['slots'][index][prop] = time;

    runInAction(() => {
      this.selfService = selfService;
    });
  };

  @action
  addSelfServiceScheduleDelayedOrders = (day, selectedDateEnd) => {
    const selfService = { ...this.selfService };

    selfService.delayedOrdersHours.daysOfWeek[day]['slots'].push({
      start: moment(selectedDateEnd)
        .add(1, 'minutes')
        .format('HH:mm'),
      end: RULER_SCALE_VALUES[RULER_SCALE_VALUES.length - 1],
    });

    runInAction(() => {
      this.selfService = selfService;
    });
  };

  @action
  removeSelfServiceScheduleDelayedOrders = (day, index) => {
    const selfService = { ...this.selfService };

    selfService.delayedOrdersHours.daysOfWeek[day]['slots'].splice(index, 1);

    runInAction(() => {
      this.selfService = selfService;
    });
  };

  @action
  changeOpcNotifications = name => {
    if (name) {
      const selfService = { ...this.selfService };
      selfService[name] = !selfService[name];

      runInAction(() => {
        this.selfService = selfService;
      });
    }
  };

  @action
  changeInvoicePaymentsEnabled = () => {
    const selfService = { ...this.selfService };
    selfService.invoicePaymentsEnabled = !selfService.invoicePaymentsEnabled;

    runInAction(() => {
      this.selfService = selfService;
    });
  };

  @action
  changeInvoiceAccountantEmail = value => {
    if (this.invoiceAccountantEmailValidate) {
      this.invoiceAccountantEmailValidate = false;
    }
    const selfService = { ...this.selfService };
    selfService.invoiceAccountantEmail = value;

    runInAction(() => {
      this.selfService = selfService;
    });
  };

  @action
  changeOpcPostPayments = () => {
    const selfService = { ...this.selfService };
    selfService.opcPostPaymentsEnabled = !selfService.opcPostPaymentsEnabled;

    runInAction(() => {
      this.selfService = selfService;
    });
  };

  @action
  toggleSelfServiceValues = key => {
    const selfService = { ...this.selfService };
    selfService[key] = !selfService[key];

    runInAction(() => {
      this.selfService = selfService;
    });
  };

  @action
  changeDineIn = () => {
    const selfService = { ...this.selfService };
    selfService.dineInEnabled = !selfService.dineInEnabled;

    runInAction(() => {
      this.selfService = selfService;
    });
  };

  @action
  changeTakeAway = () => {
    const selfService = { ...this.selfService };
    selfService.takeawayEnabled = !selfService.takeawayEnabled;

    runInAction(() => {
      this.selfService = selfService;
    });
  };

  @action
  changeLpPayments = () => {
    const selfService = { ...this.selfService };
    selfService.lpPaymentsEnabled = !selfService.lpPaymentsEnabled;

    runInAction(() => {
      this.selfService = selfService;
    });
  };

  @action
  changeGtmTag = gtmTag => {
    const selfService = { ...this.selfService };
    selfService.gtmTag = gtmTag;

    runInAction(() => {
      this.selfService = selfService;
    });
  };

  @action
  clearValidation = () => {
    this.selfPaymentMethod = null;
    this.selfService = { ...EMPTY_SELF_SERVICE };
    this.invoiceAccountantEmailValidate = {};
  };
}

export default SelfService;
