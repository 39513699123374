import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/dashboard/real-time';

class RealTimeAPI {
  static getSummary = () => Request.POST(`${ENDPOINT}/summary`);
}

export default RealTimeAPI;
