import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/tax-group';

class TaxGroupAPI {
  static list = () => Request.GET(`${ENDPOINT}/list`);

  static create = taxGroupInfo => Request.POST(ENDPOINT, taxGroupInfo);
}

export default TaxGroupAPI;
