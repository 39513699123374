import { action, observable, runInAction } from 'mobx';
import MenuAPI from 'api/menu';
import history from 'routes';
import { computed } from 'mobx/lib/mobx';
import validate from 'validate.js/validate';
import { arrayMove } from 'react-sortable-hoc';
import moment from 'moment';
import { validateDay } from 'components/common/shedule-with-addition-fields/validateDay';
import { ARRAY_DAYS } from 'utils/constants';
import { MENU_VALIDATION_SCHEMA, EMPTY_MENU, MENU_VALIDATION_SCHEMA_SINGLE } from './constants';
import { DAYS_OF_THE_WEEK, RULER_SCALE_VALUES } from '../merchants/constants';

const NAME = 'name';
const CATEGORY = 'categoryName';
const SHOP = 'shopNames';

export const MENU_TABLE_FORMAT = new Map([
  [NAME, { id: 'products.data.name', defaultMessage: 'Display Name' }],
  [CATEGORY, { id: 'products.data.categories', defaultMessage: 'Category' }],
]);

export const MENU_LIST_FORMAT = {
  headerProperty: [NAME, { id: 'products.data.name', defaultMessage: 'Display Name' }],
  properties: new Map([[CATEGORY, { id: 'products.data.categories', defaultMessage: 'Category' }]]),
};

export const MENU_TABLE_FORMAT_SHOP = new Map([
  [NAME, { id: 'products.data.name', defaultMessage: 'Display Name' }],
]);

export const MENU_LIST_FORMAT_SHOP = {
  headerProperty: [NAME, { id: 'products.data.name', defaultMessage: 'Display Name' }],
  properties: new Map([]),
};

export const GROUP_MENU_TABLE_FORMAT = new Map([
  [NAME, { id: 'products.data.name', defaultMessage: 'Display Name' }],
  [SHOP, { id: 'navigation.restaurants', defaultMessage: 'Restaurants' }],
]);

export const GROUP_MENU_LIST_FORMAT = {
  headerProperty: [NAME, { id: 'products.data.name', defaultMessage: 'Display Name' }],
  properties: new Map([[SHOP, { id: 'navigation.restaurants', defaultMessage: 'Restaurants' }]]),
};

class MenuStore {
  @observable list = [];
  @observable loading = false;
  @observable fileLoading = false;
  @observable activeMenu = false;
  @observable editMenu = EMPTY_MENU;
  @observable activeCategoryId = 0;
  @observable totalElements = 0;
  @observable validationErrors = {};
  @observable searchValue = '';

  tableFormat = MENU_TABLE_FORMAT;
  tableFormatShop = MENU_TABLE_FORMAT_SHOP;
  listFormat = MENU_LIST_FORMAT;
  listFormatShop = MENU_LIST_FORMAT_SHOP;
  groupTableFormat = GROUP_MENU_TABLE_FORMAT;
  groupListFormat = GROUP_MENU_LIST_FORMAT;

  startFetch = () => {
    runInAction(() => {
      this.loading = true;
    });
  };

  @computed get ruler() {
    return RULER_SCALE_VALUES;
  }

  @computed get weekdays() {
    return DAYS_OF_THE_WEEK;
  }

  
  merchantId(menuId) {
    const activeMenu = this.list.find(menu => {
      return menu.id === parseInt(menuId);
    });
    return activeMenu ? activeMenu.merchantProxies[0].id : null;
  }


  @action
  toggleAvailableHoursDay = day => {
    const editMenu = { ...this.editMenu };
    editMenu.availableHours.daysOfWeek[day].workingDay = !editMenu.availableHours.daysOfWeek[day]
      .workingDay;
    runInAction(() => {
      this.editMenu = { ...editMenu };
    });
  };

  @action
  setSearchValue = value => {
    runInAction(() => {
      this.searchValue = value;
    });
  };

  @action
  changeAvailableHoursSchedule = (day, prop, time, index) => {
    const editMenu = { ...this.editMenu };
    editMenu.availableHours.daysOfWeek[day]['slots'][index][prop] = time;

    runInAction(() => {
      this.editMenu = { ...editMenu };
    });
  };

  @action
  addAvailableHoursSchedule = (day, selectedDateEnd) => {
    const editMenu = { ...this.editMenu };

    editMenu.availableHours.daysOfWeek[day]['slots'].push({
      start: moment(selectedDateEnd)
        .add(1, 'minutes')
        .format('HH:mm'),
      end: RULER_SCALE_VALUES[RULER_SCALE_VALUES.length - 1],
    });

    runInAction(() => {
      this.editMenu = { ...editMenu };
    });
  };

  @action
  removeAvailableHoursSchedule = (day, index) => {
    const editMenu = { ...this.editMenu };

    editMenu.availableHours.daysOfWeek[day]['slots'].splice(index, 1);

    runInAction(() => {
      this.editMenu = { ...editMenu };
    });
  };

  @action
  getValidationErrors = admin => {
    let errors = {};

    const arrayDays = ARRAY_DAYS;

    arrayDays.map(dayName => {
      if (validateDay(this.editMenu.availableHours.daysOfWeek[dayName])) {
        errors[dayName] = true;
      }
    });
    const menuValidationShema = admin ? MENU_VALIDATION_SCHEMA : MENU_VALIDATION_SCHEMA_SINGLE;
    const editMenuErrors = validate(this.editMenu, menuValidationShema) || {};
    this.validationErrors = { ...errors, ...editMenuErrors };
  };

  @computed get isValid() {
    const errors = validate(this.editMenu, MENU_VALIDATION_SCHEMA);

    return !errors;
  }

  @action
  changeMenu = (key, value) => {
    if (MENU_VALIDATION_SCHEMA[key]) {
      this.validationErrors[key] = validate.single(value, MENU_VALIDATION_SCHEMA[key]);
    }
    runInAction(() => {
      this.editMenu[key] = value;
    });
  };

  @action
  setCurrentMenuId = async menuId => {
    const activeMenu = this.list.find(menu => {
      return menu.id === parseInt(menuId);
    });

    activeMenu &&
      runInAction(() => {
        this.activeMenu = activeMenu;
        this.activeCategoryId = 0;
      });
  };
  @action
  removeActiveMenu = async () => {
    runInAction(() => {
      this.activeMenu = false;
      this.activeCategoryId = 0;
      this.searchValue = '';
    });
  };

  @action
  changeActiveCategoryId = async categoryId => {
    runInAction(() => {
      this.activeCategoryId = categoryId;
    });
  };

  @action
  fetchListMenuByMerchant = async () => {
    // this.startFetch();
    const { items, totalCount } = await MenuAPI.fetchMenuByMerchant();
    runInAction(() => {
      this.list = items;
      this.totalElements = totalCount;
    });
  };
  // @observable editMenu = false;

  @action
  fetchMenuById = async id => {
    const response = await MenuAPI.fetchMenuById(id);
    runInAction(() => {
      const hours = response.availableHours ? response.availableHours : EMPTY_MENU.availableHours;
      this.editMenu = { ...response, availableHours: hours };
    });
  };

  @action
  createMenu = () => {
    // this.startFetch();

    runInAction(() => {
      this.editMenu = { ...EMPTY_MENU };
    });
  };

  @action
  postMenu = async (mid, admin, merchantProxies) => {
    this.getValidationErrors(admin);
    const data = {
      ...this.editMenu,
      merchantGroupId: mid,
      status: 'NEW',
    };

    if (merchantProxies) {
      data.merchantProxies = merchantProxies;
    }

    if (Object.keys(this.validationErrors).length === 0) {
      const menu = await MenuAPI.createMenu(data);
      admin ? history.push('/admin/menu') : history.push('/menu/');

      runInAction(() => {
        this.list = [...this.list, menu];
        this.editMenu = { ...EMPTY_MENU };
      });
    }
  };

  @action
  addCategory = async _category => {
    let category = _category;
    if (typeof _category === 'string') {
      category = { name: _category };
    }

    if (this.editMenu.categories.every(item => item.name !== category.name)) {
      runInAction(() => {
        this.editMenu = {
          ...this.editMenu,
          categories: [...this.editMenu.categories, category],
        };
      });
    }
  };

  @action
  addMerchantProxy = merchant => {
    if (!this.editMenu.merchantProxies.find(item => item.id === merchant.id)) {
      runInAction(() => {
        this.editMenu = {
          ...this.editMenu,
          merchantProxies: [...this.editMenu.merchantProxies, merchant],
        };
      });
    }
  };

  @action
  removeCategory = categoryParam => {
    runInAction(() => {
      this.editMenu.categories = this.editMenu.categories.filter(
        category => category.name !== categoryParam,
      );
    });
  };

  @action
  removeMerchantProxy = merchantParam => {
    runInAction(() => {
      this.editMenu.merchantProxies = this.editMenu.merchantProxies.filter(
        category => category.id !== merchantParam,
      );
    });
  };

  @action
  updateMenu = async admin => {
    this.getValidationErrors();
    if (Object.keys(this.validationErrors).length === 0) {
      await MenuAPI.updateMenu({ ...this.editMenu });

      const indexUpdated = this.list.findIndex(item => item.id === this.editMenu.id);
      runInAction(() => {
        this.list = this.list.map((item, i) => (i === indexUpdated ? this.editMenu : item));
        this.editMenu = { ...EMPTY_MENU };
      });
      admin ? history.push('/admin/menu/') : history.push('/menu/');
    }
  };

  @action
  createMenuPost = mid => {
    MenuAPI.createMenu({ ...this.editMenu, merchantGroupId: mid });
  };

  @action
  duplicateMenu = async menu => {
    await MenuAPI.cloneMenu(menu);
  };

  @action
  deleteMenu = async menu => {
    await MenuAPI.deleteMenu(menu);
  };

  @action
  onDragEnd = ({ oldIndex, newIndex }) => {
    const sortedList = arrayMove(this.list, oldIndex, newIndex);
    const reorderedMenus = async listAfterDrag => {
      try {
        const result = listAfterDrag.map((category, index) => {
          return {
            itemId: category.id,
            orderNumber: ++index,
          };
        });

        await MenuAPI.updateMenusOrder(result);
      } catch (error) {
        return new Error(error);
      }
    };

    runInAction(() => {
      this.list = sortedList;
      reorderedMenus(sortedList);
    });
  };

  @action
  fetchListMenuForMerchantGroup = async () => {
    // this.startFetch();
    const { items, totalCount } = await MenuAPI.fetchMenuForMerchantGroup();
    runInAction(() => {
      this.list = items;
      this.totalElements = totalCount;
    });
  };
  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.editMenu = { ...EMPTY_MENU };
  };

  @action
  uploadFile = async data => {
    try {
      runInAction(() => {
        this.fileLoading = true;
      });
      await MenuAPI.uploadFile(data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      runInAction(() => {
        this.fileLoading = false;
      });
    }
  };
}

export default MenuStore;
