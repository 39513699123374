import { action, computed, observable, runInAction } from 'mobx';
import history, { routes } from 'routes';
import validate from 'validate.js';
import moment from 'moment';
import { getUTCOffset } from 'utils/time';
import without from 'lodash.without';
import difference from 'lodash.difference';
import uniq from 'lodash/uniq';
import MenuAPI from 'api/menu';
import TagsAPI from 'api/tags';
import CategoriesAPI from 'api/categories';
import ProductsAPI from 'api/products';
import VouchersAPI from 'api/vouchers';
import { getLocalizedString } from 'i18n/utils';
import { SELECTION_LIST, SELECTION_MAPPER_LIST } from 'stores/promotions/constants';

const initVoucher = () => ({
  id: 0,
  name: '',
  endDate:
    moment()
      .add(1, 'days')
      .valueOf() +
    getUTCOffset(
      moment()
        .add(1, 'days')
        .valueOf(),
    ),
  price: 0,
  faceValue: '',
  returnPrice: 0,
  priceInPoints: 0,
  voucherType: 'DEPOSIT',
  tags: [],
  crm: true,
  qr: false,
  newcomer: false,
  totalAmountTrigger: '',
  productSelector: null,
  targetMerchantId: '',
  relativeEndTime: '',
  dailyDeductionAmount: '',
  targetServiceType: [],
  selectorTrigger: {
    count: '',
    productSelector: {
      menuIds: [],
      categoryIds: [],
      productIds: [],
      tags: [],
    },
  },
});

const VALIDATION_SCHEMA = {
  name: { presence: { allowEmpty: false } },
  productSelector: { presence: { allowEmpty: false } },
  endDate: { presence: { allowEmpty: false } },
  faceValue: { numericality: { greaterThan: 0 } },
};

class VoucherEditor {
  constructor(Vouchers) {
    this.vouchers = Vouchers;
    this.onSave = this.vouchers.createVoucher;
    this.updateVoucher = this.vouchers.updateVoucher;
  }

  @observable voucher = initVoucher();
  @observable tags = [];
  @observable customersSelections = [];
  @observable currentRadio = 'crm';

  @action
  setCurrentRadio = value => {
    runInAction(() => {
      this.currentRadio = value;
    });
  };

  @action
  create = path => {
    this.voucher = initVoucher();
    this.onSave = this.vouchers.createVoucher;
    this.updateVoucher = this.vouchers.updateVoucher;

    history.push(path);
  };

  @action
  changeProductSelector = value => {
    runInAction(() => {
      this.voucher.productSelector = [...value];
    });
  };

  @action
  onUpdateVoucher = async admin => {
    try {
      const voucher = this.getStructuredVoucher();
      const response = await this.updateVoucher(voucher);

      admin ? history.push(routes.adminVouchers) : history.push(routes.vouchers);
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @computed get disableBefore() {
    return (
      moment()
        .endOf('day')
        .valueOf() +
      getUTCOffset(
        moment()
          .endOf('day')
          .valueOf(),
      )
    );
  }

  @computed get availableTags() {
    return difference(this.tags, this.voucher.tags).map(tag => ({
      text: tag,
      value: tag,
    }));
  }

  @observable products = [];

  fetchProducts = async () => {
    const { items } = await ProductsAPI.loadAsOptions();

    runInAction(() => {
      this.products = items.map(({ name, menuName, optionId }) => ({
        label: `${name} (${menuName})`,
        value: optionId,
        type: 'products',
      }));
    });
  };

  @observable menus = [];

  fetchMenus = async () => {
    const { items } = await MenuAPI.fetchMenuForMerchantGroup();

    runInAction(() => {
      this.menus = items.map(({ name, id }) => ({
        label: name,
        value: id,
        type: 'menus',
      }));
    });
  };

  fetchTags = async () => {
    const { items } = await TagsAPI.list();

    runInAction(() => {
      this.tags = items.map(({ name }) => ({
        label: name,
        value: name,
        type: 'tags',
      }));
    });
  };

  @observable categories = [];

  @action
  fetchCategories = async () => {
    const { items } = await CategoriesAPI.loadAsOptions();

    runInAction(() => {
      this.categories = items.map(({ name, optionId, menuName }) => ({
        label: `${name} (${menuName})`,
        value: optionId,
        type: 'categories',
      }));
    });
  };

  @action
  setProductSelector = (list = {}) => {
    const result = [];

    if (list.all === true) {
      result.push({ type: 'all', value: 'all', label: getLocalizedString('vouchers.editor.all') });
    }

    SELECTION_LIST.map(key => {
      list[key].map(item => {
        this[SELECTION_MAPPER_LIST[key]].forEach(property => {
          if (item === property.value) {
            result.push({
              ...property,
              type: SELECTION_MAPPER_LIST[key],
            });
          }
        });
      });
    });

    return result;
  };

  @action
  fetchVoucherId = async ({ id, duplicate }) => {
    const data = await VouchersAPI.fetchVoucher(id);
    await Promise.all([
      this.fetchTags(),
      this.fetchCategories(),
      this.fetchProducts(),
      this.fetchMenus(),
    ]);
    runInAction(() => {
      if (data.crm) {
        this.currentRadio = 'crm';
      } else if (data.qr) {
        this.currentRadio = 'qr';
      } else if (data.totalAmountTrigger > 0) {
        this.currentRadio = 'automatic';
      } else if (data.newcomer) {
        this.currentRadio = 'newcomer';
      } else {
        this.currentRadio = 'includes';
      }
      this.voucher = {
        ...data,
        id: duplicate ? 0 : data.id,
        name: duplicate ? `${data.name}_1` : data.name,
        totalAmountTrigger: data.totalAmountTrigger,
        productSelector: this.setProductSelector(data.productSelector),
        targetServiceType: data.targetServiceType === '' ? [] : data.targetServiceType.split(','),
        selectorTrigger: data.selectorTrigger
          ? data.selectorTrigger
          : {
              count: '',
              productSelector: {
                menuIds: [],
                categoryIds: [],
                productIds: [],
                tags: [],
              },
            },
        endDate: data.endDate - getUTCOffset(data.endDate),
      };
      this.setProductSelector(data.productSelector, 'voucher.productSelector');
      this.customersSelections = this.setProductSelector(data.productSelector);
    });
  };

  @action
  addTag = tagName => {
    const voucherTags = [...this.voucher.tags, tagName];
    const tags = [...this.tags];
    runInAction(() => {
      this.tags = tags.filter(item => item !== tagName);
      this.voucher.tags = voucherTags;
    });
  };

  @action
  changeSelections = value => {
    runInAction(() => {
      this.customersSelections = [...value];
    });
  };

  getCustomersSelections = (field, key = 'value') => {
    return this.customersSelections.filter(item => item.type === field).map(item => item[key]);
  };

  @action
  createTag = async tagName => {
    const { name } = await TagsAPI.create({ name: tagName });

    this.tags.push(name);
    this.voucher.tags.push(name);
  };

  @action
  removeTag = tagName => {
    const tags = [...this.tags, tagName];
    runInAction(() => {
      this.voucher.tags = without(this.voucher.tags, tagName);
      this.tags = tags;
    });
  };
  @action
  getProductSelections = (field, key = 'value') => {
    return this.voucher.productSelector.filter(item => item.type === field).map(item => item[key]);
  };

  @action
  getStructuredVoucher = () => {
    const { selectorTrigger, totalAmountTrigger, qr, crm, newcomer } = this.voucher;

    const tags = this.getCustomersSelections('tags', 'label');
    const productIds = this.getCustomersSelections('products');
    const categoryIds = this.getCustomersSelections('categories');
    const menuIds = this.getCustomersSelections('menus');

    const conditionTags = this.getProductSelections('tags', 'label');
    const conditionProductIds = this.getProductSelections('products');
    const conditionCategoryIds = this.getProductSelections('categories');
    const conditionMenuIds = this.getProductSelections('menus');
    const conditionAll = !!this.getProductSelections('all').length;
    const endDate = moment(this.voucher.endDate)
      .endOf('day')
      .valueOf();

    const voucher = {
      ...this.voucher,
      targetServiceType:
        this.voucher.targetServiceType[0] === 'all' ? '' : this.voucher.targetServiceType.join(','),
      totalAmountTrigger: selectorTrigger.count > 0 ? '' : totalAmountTrigger,
      endDate: endDate + getUTCOffset(endDate) - 1,
      productSelector: {
        all: conditionAll,
        menuIds: uniq(conditionMenuIds),
        tags: uniq(conditionTags),
        categoryIds: uniq(conditionCategoryIds),
        productIds: uniq(conditionProductIds),
      },
    };

    if (qr || crm || totalAmountTrigger || newcomer) {
      delete voucher.selectorTrigger;
    } else {
      voucher.selectorTrigger = {
        count: voucher.selectorTrigger.count,
        productSelector: {
          menuIds,
          tags,
          productIds,
          categoryIds,
        },
      };
    }

    return voucher;
  };

  @action
  save = async admin => {
    try {
      const voucher = this.getStructuredVoucher();
      const response = await this.onSave(voucher);

      admin ? history.push(routes.adminVouchers) : history.push(routes.vouchers);
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @observable validationErrors = {};

  @action
  validateVoucher = () => {
    this.validationErrors = validate(this.voucher, VALIDATION_SCHEMA) || {};

    return !Object.keys(this.validationErrors).length;
  };

  @action
  changeSelectorTrigger = (key, value) => {
    this.voucher.selectorTrigger[key] = value;
  };

  @action
  changeVoucher = (key, value) => {
    if (VALIDATION_SCHEMA[key]) {
      this.validationErrors[key] = validate.single(value, VALIDATION_SCHEMA[key]);
    }
    runInAction(() => {
      if (key === 'targetServiceType') {
        if (value[0] === 'all') {
          runInAction(() => {
            this.voucher[key] = value.filter(i => i !== 'all');
          });
        } else if (value.some(i => i === 'all')) {
          this.voucher[key] = [];
        } else {
          this.voucher[key] = value;
        }
      } else {
        this.voucher[key] = value;
      }
    });
  };

  @action
  setEndDate = value => {
    this.voucher.endDate = value;
  };

  @computed get endDate() {
    return this.voucher.endDate;
  }

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.customersSelections = [];
    this.voucher = initVoucher();
  };
}

export default VoucherEditor;
