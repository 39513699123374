import moment from 'moment';
import { getUTCOffset } from 'utils/time';

import { DATE_FORMAT } from '../constants';

const formatDate = (date, format = DATE_FORMAT, timezone = '') => {
  return timezone
    ? moment(date)
        .tz(timezone)
        .format(format)
    : moment(date).format(format);
};

export function convertMillisecondsToTime(number) {
  const SECONDS_IN_MINUTE = 60;
  const MILLISECONDS_IN_MINUTE = SECONDS_IN_MINUTE * 60;
  const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * 60;
  const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * 24;

  function normalizeTime(number) {
    const string = number < 10 ? '0' : '';
    return string + number;
  }

  const days = Math.floor(number / MILLISECONDS_IN_DAY);
  const daysInMilliseconds = days * MILLISECONDS_IN_DAY;
  const hours = Math.floor((number - daysInMilliseconds) / MILLISECONDS_IN_HOUR);
  const hoursInMilliseconds = hours * MILLISECONDS_IN_HOUR;
  const minutes = Math.floor((number - hoursInMilliseconds) / MILLISECONDS_IN_MINUTE);

  return `${normalizeTime(hours)}:${normalizeTime(minutes)}`;
}

export function convertTimeToMilliseconds(hms) {
  const times = hms.split(':');
  const MILLISECONDS_IN_MINUTE = 60 * 1000;
  const MILLISECONDS_IN_HOUR = 60 * MILLISECONDS_IN_MINUTE;
  return Number(times[0]) * MILLISECONDS_IN_HOUR + +times[1] * MILLISECONDS_IN_MINUTE;
}

export function toUtcMilliseconds(time = '') {
  return moment.utc(moment(time, 'hh:mm')).valueOf();
}

export function formatDateWithUTC(timestamp, format = DATE_FORMAT, timezone = '') {
  const timestampWithUTC = timestamp - getUTCOffset(timestamp);
  return timezone
    ? moment(timestampWithUTC)
        .tz(timezone)
        .format(format)
    : moment(timestampWithUTC).format(format);
}

function prependZero(value) {
  return value < 10 ? '0' + value : value;
}

export function millisecondsToTime(milliseconds = 0) {
  let minutes = parseInt((milliseconds / (1000 * 60)) % 60);
  let hours = parseInt((milliseconds / (1000 * 60 * 60)) % 24);

  return prependZero(hours) + ':' + prependZero(minutes);
}

export const minutesToHours = minutes => {
  return minutes / 60 || '0';
};

export const hoursToMinutes = hours => {
  return hours * 60 || '0';
};

export default formatDate;
