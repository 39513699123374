import BroadcastAPI from 'api/broadcast-message';
import { action, observable, runInAction } from 'mobx/lib/mobx';

const DATE = 'lastSentTimestamp';
const STATUS = 'status';
const TITLE = 'title';
const TYPE = 'type';

export const BLOCKS_TABLE_FORMAT = new Map([
  [DATE, { id: 'broadcastMessage.data.date', defaultMessage: 'Date' }],
  [TITLE, { id: 'broadcastMessage.data.title', defaultMessage: 'Message subject' }],
  [TYPE, { id: 'broadcastMessage.data.type', defaultMessage: 'Type' }],
  [STATUS, { id: 'broadcastMessage.data.status', defaultMessage: ' Status' }],
]);

export const BLOCKS_LIST_FORMAT = {
  headerProperty: [DATE, { id: 'broadcastMessage.data.date', defaultMessage: 'Date' }],
  properties: new Map([
    [TITLE, { id: 'broadcastMessage.data.title', defaultMessage: 'Message subject' }],
    [TYPE, { id: 'broadcastMessage.data.type', defaultMessage: 'Type' }],
    [STATUS, { id: 'broadcastMessage.data.status', defaultMessage: ' Status' }],
  ]),
};

class BroadcastMessageStore {
  listFormat = BLOCKS_LIST_FORMAT;
  tableFormat = BLOCKS_TABLE_FORMAT;
  @observable data = [];
  @observable loading = true;
  @observable currentPage = -1;
  @observable totalPages = 0;
  @observable hasMore = true;
  @observable totalElements = 0;
  @observable paginateOptions = { page: 0, size: 20 };

  @action
  changePaginateOptions = value => {
    runInAction(() => {
      this.paginateOptions = value;
    });
  };

  @action
  fetchData = async ({ type = '', table }) => {
    const { page, size } = this.paginateOptions;
    const {
      items,
      last,
      totalPages,
      number,
      totalElements,
    } = await BroadcastAPI.fetchBroadcastMessage(page, size, type);

    runInAction(() => {
      if (table) {
        this.data = items;
      } else {
        if (page === 0) {
          this.data = items;
        } else {
          this.data = [...this.data, ...items];
        }
      }

      this.totalPages = totalPages;
      this.hasMore = !last;
      this.currentPage = number;
      this.totalElements = totalElements;
      this.loading = false;
    });
  };

  @action
  changeStatus = async (item, status) => {
    const obj = {
      id: item.id,
      text: item.text,
      savedFilterId: item.savedFilterId,
      status: status,
      type: item.type,
    };

    runInAction(() => {
      this.loading = true;
    });

    const newObj = await BroadcastAPI.changeStatus(obj);

    const newData = this.data.map(i => {
      if (i.id === item.id) {
        return newObj;
      } else {
        return i;
      }
    });

    runInAction(() => {
      this.data = newData;
      this.loading = false;
    });
  };
}

export default BroadcastMessageStore;
