import APIConnector from './utils/APIConnector';
import { extractFiltersQuery } from './utils/helpers';

const Request = APIConnector.instance;
const ENDPOINT = '/inventory/product';

class ProductsAPI {
  static list = filters => {
    const filtersQuery = extractFiltersQuery(filters);

    return Request.GET(`${ENDPOINT}/list?${filtersQuery}`);
  };

  static search = filters => {
    return Request.POST(`${ENDPOINT}/search`, filters);
  };

  static loadAsOptions = () => Request.GET(`${ENDPOINT}/as-options`);

  static getRelatedProductList = productIds => Request.POST(`${ENDPOINT}/list`, productIds);

  static getProductList = (menuId, page = 0, size = 20, name = '') => {
    const newName = name.length ? `&productName=${name}` : '';
    return Request.GET(`${ENDPOINT}/${menuId}/list?page=${page}&size=${size}${newName}`);
  };

  static getPropertyList = (menuId, page = 0, size = 20, filters) => {
    const filtersQuery = extractFiltersQuery(filters);

    return Request.GET(
      `${ENDPOINT}/property/${menuId}/list?page=${page}&size=${size}${filtersQuery}`,
    );
  };

  // static getProductInfo = productId => Request.GET(`${ENDPOINT}/${productId}`);

  static getProductInfo = productId => Request.GET(`${ENDPOINT}/${productId}`);

  static getStopList = menuId => Request.GET(`${ENDPOINT}/${menuId}/stop-list`);

  static createProduct = product => Request.POST(ENDPOINT, product);

  static updateProduct = product => Request.PUT(ENDPOINT, product);

  static disableChannel = (channel, ids) => Request.PUT(`${ENDPOINT}/${channel}/off`, { ids });

  static enableChannel = (channel, ids) => Request.PUT(`${ENDPOINT}/${channel}/on`, { ids });

  static deleteProduct = productId => Request.DELETE(ENDPOINT, { id: productId });

  static updateProductsOrder = itemOrderDtos => Request.PUT(`${ENDPOINT}/order`, { itemOrderDtos });

  static getProductSettingsByMerchant = params =>
    Request.POST(`${ENDPOINT}/settings/merchant`, params);

  static getProductSettingsByGroup = params => Request.POST(`${ENDPOINT}/settings`, params);

  static productLegalAssign = (params, legalId) =>
    Request.PUT(`${ENDPOINT}/settings/merchant/assign-entity/${legalId}`, params);

  static productLegalUnAssign = params =>
    Request.PUT(`${ENDPOINT}/settings/merchant/remove-entity`, params);

  static productKitchenAssign = (params, id) =>
    Request.PUT(`${ENDPOINT}/settings/merchant/assign-kitchen/${id}`, params);

  static productKitchenUnAssign = params =>
    Request.PUT(`${ENDPOINT}/settings/merchant/remove-kitchen`, params);

  static productCategoriesAssign = params => Request.PUT(`${ENDPOINT}/category`, params);

  static switchPOSChannel = (params, on, merchant) =>
    Request.PUT(
      `${ENDPOINT}/settings${merchant ? '/merchant' : ''}/POS/${on ? 'on' : 'off'}`,
      params,
    );

  static switchSelfServiceChannel = (params, on, merchant) =>
    Request.PUT(
      `${ENDPOINT}/settings${merchant ? '/merchant' : ''}/SELF_SERVICE/${on ? 'on' : 'off'}`,
      params,
    );

  static changeStock = params => Request.PUT(`${ENDPOINT}/settings/merchant/stock`, params);

  static bulkMerchantUpdate = params => Request.PUT(`${ENDPOINT}/settings/bulk/merchant`, params);

  static bulkMerchantGroupUpdate = (params, id) =>
    Request.PUT(`${ENDPOINT}/settings/bulk/merchant-group/${id}`, params);

  static fetchDataToCSV = menuId => {
    return Request.GET(`/inventory/product/${menuId}/list`);
  };
}

export default ProductsAPI;
