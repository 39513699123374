import styled from 'styled-components';
import { NOTIFICATIONS_COLOR } from 'utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  @media screen and (max-width: 991px){
    justify-content: center;
    align-items: center;
  }

  .ui.visible.visible.visible.visible.message {
    opacity: 1;
    align-items: center;
    border-left: 4px solid ${NOTIFICATIONS_COLOR.SUCCESS};
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.2);
    color: #000 !important;
    background-color: #fff !important
    display: flex !important;
    font-size: 12px !important;
    justify-content: center;
    max-width: 400px;
    width: 100%;
    position: absolute;

    @media screen and (min-width: 768px){
      bottom: 25px;
      right: 100px;
    }

    @media screen and (max-width: 767px){
      top: 49px;
    }

    ${({ error }) =>
      error &&
      `
      border-left: 4px solid ${NOTIFICATIONS_COLOR.ERROR};
    `}
  }
`;
