import { action, computed, observable } from 'mobx';

import { DASHBOARD_RETROSPECTIVE_TYPE } from 'utils/constants';
import moment from 'moment';
import { getLocalizedString } from 'i18n/utils';
import { demicalFormat } from 'utils/demical-format';

const { DAILY, HOURLY } = DASHBOARD_RETROSPECTIVE_TYPE;

class RetrospectiveWidget {
  @observable hourlyData = {};
  @observable dailyData = {};
  @observable charCurrency = null;
  @observable salesRetrospective = {};
  @observable hourlySalesRetrospective = {};
  @observable mode = DAILY;

  @action
  setMode = mode => {
    this.mode = mode;
  };

  @action
  setMerchantCurrency = currency => {
    this.charCurrency = currency;
  };

  @action
  updateData = analyticsData => {
    const { avgHourlySalesResponse, salesRetrospectiveResponse } = analyticsData;

    this.hourlyData = avgHourlySalesResponse;
    this.dailyData = salesRetrospectiveResponse;
  };

  @computed get currentMode() {
    return this.mode;
  }

  @computed get widgetData() {
    switch (this.mode) {
      case DAILY:
        return this.dailyData;
      case HOURLY:
        return this.hourlyData;
      default:
        return this.dailyData;
    }
  }

  @computed get chartData() {
    let chartData = [];
    const { summaryMap } = this.widgetData;

    const amount = getLocalizedString('merchant.paymentMethod.amount') || 'Amount';
    const count = getLocalizedString('merchant.paymentMethod.count') || 'Orders count';
    const summaryKeys = Object.keys(summaryMap || {}).sort((a, b) => Number(a) - Number(b));
    summaryKeys.map(key => {
      if (summaryMap[key]) {
        let newData = {};
        newData = {
          name: this.mode === DAILY ? moment(Number(key)).format('DD.MM') : key + 'h',
          [count]: Number(summaryMap[key].count),
          [amount]: Number(summaryMap[key].amount),
        };
        chartData.push(newData);
      }
    });

    const total = chartData.reduce((sum, item) => sum + item[amount], 0);

    return total > 0 ? chartData : [];
  }

  @computed get downloadCsvData() {
    let chartData = [];
    const { summaryMap } = this.widgetData;
    if (this.mode === DAILY) {
      const summaryKeys = Object.keys(summaryMap || {}).sort((a, b) => Number(a) - Number(b));
      summaryKeys.map(key => {
        if (summaryMap.hasOwnProperty(key)) {
          const paymentMethodsKeys = Object.keys(summaryMap[key].paymentMethods);
          let newPaymentMethods = {};
          paymentMethodsKeys.map(paymentMethodKey => {
            newPaymentMethods[paymentMethodKey] = demicalFormat(
              summaryMap[key].paymentMethods[paymentMethodKey],
            );
          });

          chartData.push({
            date: moment(Number(key)).format('DD.MM'),
            amount: Number(summaryMap[key].amount),
            count: Number(summaryMap[key].count),
            ...newPaymentMethods,
          });
        }
      });
    }

    const total = chartData.reduce((sum, item) => sum + item.amount, 0);

    return total > 0 ? chartData : [];
  }
}

export default RetrospectiveWidget;
